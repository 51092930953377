import React from 'react'
import { GridWrapper, GridItem } from '@jsluna/grid'
import moment from 'moment'
import PropTypes from 'prop-types'
import * as Constants from 'utils/constants'
import ActivityCard from '../../Shared/ActivityCard/ActivityCard'
import TodoItem from '../../Activities/TodoItem/TodoItem'

const WeekGroupedItems = ({ weeks }) => {
  const weekContainsActivities = week => week.some(day => day.items.length > 0)
  const weekItemsByTypes = (week, types) =>
    week
      .flatMap(day => day.items)
      .filter((item, i, arr) => {
        const ids = arr.map(x => x.id)
        return types.includes(item.type) && i === ids.indexOf(item.id)
      })
  return (
    <GridWrapper className="ln-u-margin-bottom*2">
      <div className="ln-u-text-align-center">
        <GridItem size={{ md: '4/5', lg: '3/5' }} className="ln-u-soft-ends">
          <h2>Schedule</h2>
        </GridItem>
      </div>
      {weeks.map(week => (
        <div
          className="ln-u-text-align-center ln-u-push-bottom-xl"
          key={week[0].date.format()}
        >
          <GridItem size={{ md: '4/5', lg: '3/5' }} className="ln-u-margin-top">
            <h6 className="ln-u-soft-left-sm">
              {moment(week[0].date).format('ddd DD MMM')} -{' '}
              {moment(week[6].date).format('ddd DD MMM')}
            </h6>
            {!weekContainsActivities(week) && (
              <p className="ln-u-soft-left-sm">No activities for this week</p>
            )}
            {weekContainsActivities(week) && (
              <div className="ln-u-margin-bottom*4">
                {weekItemsByTypes(week, [
                  Constants.ACTIVITY_TYPE_TODO,
                  Constants.ACTIVITY_TYPE_SURVEY
                ]).map(item => (
                  <TodoItem item={item} key={item.id} />
                ))}
              </div>
            )}
            {weekItemsByTypes(week, [Constants.ACTIVITY_TYPE_INFO]).map(
              item => (
                <ActivityCard item={item} key={item.id} />
              )
            )}
          </GridItem>
        </div>
      ))}
    </GridWrapper>
  )
}

WeekGroupedItems.propTypes = {
  weeks: PropTypes.array.isRequired
}

export default WeekGroupedItems
