import { connectionIsOnline, connectionIsOffline } from '../actions'
import history from '../configureHistory'
import ActivitiesAPI from '../api'
import { ROUTE_DISCONNECTED_PATH } from './clientHost'

let reduxStore

export const registerConnectivityHelper = store => {
  reduxStore = store
}

export const checkConnectionStatus = () => {
  const previouslyOnline = reduxStore.getState().connection.isOnline
  ActivitiesAPI.healthCheck()
    .then(response => {
      if (response.ok) {
        reduxStore.dispatch(connectionIsOnline())
        if (
          !previouslyOnline &&
          window.location.pathname === ROUTE_DISCONNECTED_PATH
        ) {
          if (history.length > 0) {
            history.goBack()
          } else {
            history.push('/')
          }
        }
      } else {
        reduxStore.dispatch(connectionIsOffline())
        history.push(ROUTE_DISCONNECTED_PATH)
      }
    })
    .catch(() => {
      if (previouslyOnline) {
        reduxStore.dispatch(connectionIsOffline())
        history.push(ROUTE_DISCONNECTED_PATH)
      }
    })
}
