import React from 'react'
import PropTypes from 'prop-types'
import { RiskNoColour } from '@jsainsburyplc/activity-icons'

const TaskNumber = ({ templateId }) => {
  if (!templateId) return null

  return (
    <div>
      <RiskNoColour viewBox="4 -2 30 30" />
      <span className="activity-icon-padder ln-u-margin-left">
        #{templateId}
      </span>
    </div>
  )
}

TaskNumber.propTypes = {
  templateId: PropTypes.number
}

TaskNumber.defaultProps = {
  templateId: null
}

export default TaskNumber
