import React from 'react'
import { v1 } from 'uuid'
import { StatusCard } from '@jsluna/card'
import ItemHolder from './ItemHolder'

const createItemHolders = (day, history, visibleItemsLength, isPeriodView) => {
  const elements = []
  for (let i = 0; i < visibleItemsLength; i += 1) {
    const item = day.items.find(x => x.position === i && x.shouldStartOnThisDay)
    const element = item ? (
      <ItemHolder
        history={history}
        key={v1()}
        item={item}
        isPeriodView={isPeriodView}
      />
    ) : (
      <StatusCard key={v1()} className="c-activity-item standard u-invisible">
        Default view
      </StatusCard>
    )
    elements.push(element)
  }
  return elements
}

export default createItemHolders
