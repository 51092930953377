import React from 'react'
import PropTypes from 'prop-types'
import { TODO_TYPE_ATTRIBUTES_CATEGORY } from 'utils/constants'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import Banner from '../Units/Banner'
import Title from '../Units/Title'
import Summary from '../Units/Summary'
import Description from '../Units/Description'
import Media from '../Units/Media'
import Attributes from '../Units/Attributes'
import ProcessTodo from '../Processes/ProcessTodo'
import History from '../Units/History'
import Feedback from '../Units/Feedback'
import FromToRange from '../Units/FromToRange'
import ActivityRestrictionInfo from '../Units/ActivityRestriction/ActivityRestrictionInfo'
import ActivityRestrictionDetail from '../Units/ActivityRestriction/ActivityRestrictionDetail'
import {
  isValidContentData,
  getCheckListItems
} from '../../../../utils/checklistHelper'
import ChecklistContent from '../Units/ChecklistContent'

const Todo = ({ activity, history }) => {
  if (!activity) return null

  return (
    <div className="ln-u-padding-bottom*4">
      <Banner activity={activity} />
      <Card>
        <GridWrapper matrix className="activity-page-padder">
          <GridItem size="1/1">
            <FromToRange from={activity.activeFromDate} to={activity.dueDate} />
            <ActivityRestrictionInfo audience={activity?.audience} />
            <Title title={activity.title} />
            <Summary summary={activity.summary} />
            <Description description={activity.description} />
            <Media
              hasMedia={activity.hasMedia}
              mediaAttachments={activity.mediaAttachments}
            />
            <Attributes
              attributes={activity.attributes}
              ignoredCategories={[TODO_TYPE_ATTRIBUTES_CATEGORY]}
            />
            <ActivityRestrictionDetail audience={activity?.audience} />
          </GridItem>
        </GridWrapper>
      </Card>
      <Card className="activity-page-padder">
        {isValidContentData && (
          <ChecklistContent
            activity={activity}
            items={getCheckListItems(activity)}
          />
        )}
      </Card>

      <ProcessTodo activity={activity} history={history} />
      <History activity={activity} showOverdue />
      <Feedback activityId={activity.id} isHelpful={activity.isHelpful} />
    </div>
  )
}

Todo.propTypes = {
  history: PropTypes.object,
  activity: PropTypes.object
}

Todo.defaultProps = {
  history: null,
  activity: null
}

export default Todo
