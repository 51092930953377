import React from 'react'
import PropTypes from 'prop-types'
import { MarkdownReader } from '@jsainsburyplc/activity-markdown-reader'

const Description = ({ description }) => {
  if (!description) return null

  return (
    <div className="ln-u-margin-top activity-wrap-description">
      <MarkdownReader input={description} />
    </div>
  )
}

Description.propTypes = {
  description: PropTypes.string
}

Description.defaultProps = {
  description: null
}

export default Description
