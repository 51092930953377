import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import Banner from '../Units/Banner'
import Title from '../Units/Title'
import DueDate from '../Units/DueDate'
import Summary from '../Units/Summary'
import Description from '../Units/Description'
import Media from '../Units/Media'
import Attributes from '../Units/Attributes'
import SurveyContent from '../Processes/Survey/SurveyContent'
import LegacySurveyContent from '../Processes/Survey/LegacySurveyContent'
import CompletedSurveyModal from '../Processes/Survey/CompletedSurveyModal'
import ProcessSurvey from '../Processes/ProcessSurvey'
import ProcessLegacySurvey from '../Processes/ProcessLegacySurvey'
import History from '../Units/History'
import Feedback from '../Units/Feedback'
import ActivityRestrictionInfo from '../Units/ActivityRestriction/ActivityRestrictionInfo'
import ActivityRestrictionDetail from '../Units/ActivityRestriction/ActivityRestrictionDetail'

const Survey = ({ activity, history }) => {
  const [response, setResponse] = useState(null)
  const [isResponseValid, setIsResponseValid] = useState(false)

  if (!activity) return null

  return (
    <div className="ln-u-padding-bottom*4">
      <Banner activity={activity} />
      <Card className="ln-u-margin-bottom">
        <GridWrapper matrix className="activity-page-padder">
          <GridItem size="1/1">
            <DueDate dueDate={activity.dueDate} />
            <ActivityRestrictionInfo audience={activity?.audience} />
            <Title title={activity.title} />
            <Summary summary={activity.summary} />
            <Description description={activity.description} />
            <Media
              hasMedia={activity.hasMedia}
              mediaAttachments={activity.mediaAttachments}
            />
            <Attributes attributes={activity.attributes} />
            <ActivityRestrictionDetail audience={activity?.audience} />
          </GridItem>
        </GridWrapper>
      </Card>
      <Card className="activity-page-padder">
        {!activity.isCompleted && !activity.isMissed && (
          <div>
            {activity.content && activity.content.data ? (
              <SurveyContent
                questions={JSON.parse(activity.content.data).questions}
                setResponse={setResponse}
                setIsResponseValid={setIsResponseValid}
              />
            ) : (
              <LegacySurveyContent activity={activity} />
            )}
          </div>
        )}
        {activity.isCompleted && !activity.isMissed && (
          <CompletedSurveyModal feedbackMessage="Your input will go towards improving our business for everyone." />
        )}
      </Card>
      {activity.content && activity.content.data ? (
        <ProcessSurvey
          activity={activity}
          response={response}
          isResponseValid={isResponseValid}
          history={history}
        />
      ) : (
        <ProcessLegacySurvey activity={activity} />
      )}
      <History activity={activity} showOverdue />
      <Feedback activityId={activity.id} isHelpful={activity.isHelpful} />
    </div>
  )
}

Survey.propTypes = {
  activity: PropTypes.object,
  history: PropTypes.object
}

Survey.defaultProps = {
  activity: null,
  history: null
}

export default Survey
