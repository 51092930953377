import React from 'react'
import PropTypes from 'prop-types'

const Summary = ({ summary }) => {
  if (!summary) return null

  return <div className="ln-u-margin-top">{summary}</div>
}

Summary.propTypes = {
  summary: PropTypes.string
}

Summary.defaultProps = {
  summary: null
}

export default Summary
