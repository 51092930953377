import {
  SEARCH_ACTIVITIES_REQUEST,
  SEARCH_ACTIVITIES_SUCCESS,
  SEARCH_ACTIVITIES_FAILURE,
  SEARCH_ACTIVITIES_UPDATE_PAGE
} from '../actions'

const initialState = {
  text: '',
  results: [],
  isNextPageRequest: false,
  pageNumber: 1,
  isSearching: false,
  error: '',
  totalResultsFound: 0
}

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_ACTIVITIES_REQUEST:
      return {
        ...state,
        results: state.results,
        text: action.searchText,
        isSearching: true
      }
    case SEARCH_ACTIVITIES_SUCCESS:
      return {
        ...state,
        results: action.results,
        isSearching: false
      }
    case SEARCH_ACTIVITIES_FAILURE:
      return {
        ...state,
        results: [],
        isSearching: false,
        error: action.err
      }
    case SEARCH_ACTIVITIES_UPDATE_PAGE:
      return {
        ...state,
        pageNumber: action.pageNumber
      }
    default:
      return {
        ...state
      }
  }
}

export default searchReducer
