import React, { useState } from 'react'
import {
  EVENT_AUDIT_COMPLETION,
  EVENT_AUDIT_SATISFACTION,
  ROUTE_RISK,
  SATISFIED_VALUE,
  NOTSATISFIED_VALUE
} from 'utils/constants'
import PropTypes from 'prop-types'
import { submitEventRequestThenComplete, createActionRequest } from 'actions'
import { OutlinedButton } from '@jsluna/button'
import { connect } from 'react-redux'
import { useAnalytics } from '@jsainsburyplc/retail-analytics'
import ActionModal from './CreateActionModal'
import EvidenceModal from './EvidenceModal'

export const AuditHandler = ({
  activity,
  completeAudit,
  isUploadingImages,
  history,
  audit,
  submitAction
}) => {
  const { sendEvent } = useAnalytics()
  const satisfied = SATISFIED_VALUE
  const notSatisfied = NOTSATISFIED_VALUE

  const [evidenceText, setEvidenceText] = useState('')
  const [evidenceMedia, setEvidenceMedia] = useState([])
  const [satisfaction, setSatisfaction] = useState('')
  const [isSatisfiedModalOpen, setIsSatisfiedModalOpen] = useState(false)
  const [isNotSatisfiedModalOpen, setIsNotSatisfiedModalOpen] = useState(false)
  const [actionDescription, setActionDescription] = useState('')
  const [assignments, setAssignments] = useState([])

  const resetEvidence = () => {
    setEvidenceText('')
    setEvidenceMedia([])
  }

  const handleCompleteRequest = (eventType, response = satisfaction) => {
    sendEvent('activity_complete', {
      label: activity.title,
      type: activity.type.toLowerCase()
    })

    completeAudit(
      activity.referenceId,
      eventType,
      {
        satisfaction: response,
        text: evidenceText,
        media: evidenceMedia,
        checklistItems: activity?.response
      },
      () => history.push(ROUTE_RISK)
    )
  }

  const handleAnswerToggle = answer => {
    resetEvidence()
    setSatisfaction(answer)

    if (answer === satisfied) {
      if (activity.evidenceRequired) {
        setIsSatisfiedModalOpen(true)
      } else {
        handleCompleteRequest(EVENT_AUDIT_SATISFACTION, answer)
      }
    } else if (
      answer === notSatisfied &&
      !audit.some(x => x.parsedResponse.action !== null)
    ) {
      setIsNotSatisfiedModalOpen(true)
    } else if (audit.length > 0) {
      setSatisfaction(satisfaction === '' ? notSatisfied : satisfaction)
      handleCompleteRequest(EVENT_AUDIT_COMPLETION, answer)
    } else {
      setIsNotSatisfiedModalOpen(true)
    }
  }

  const handleCloseModal = () => {
    setIsSatisfiedModalOpen(false)
    setIsNotSatisfiedModalOpen(false)
    setSatisfaction('')
    resetEvidence()
  }

  let timeout

  const handleSetEvidenceChange = text => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => setEvidenceText(text), 500)
  }

  return (
    <>
      <ActionModal
        isOpen={isNotSatisfiedModalOpen}
        closeModal={handleCloseModal}
        setEvidenceText={setEvidenceText}
        evidenceText={evidenceText}
        setEvidenceMedia={setEvidenceMedia}
        evidenceMedia={evidenceMedia}
        setActionDescription={setActionDescription}
        actionDescription={actionDescription}
        satisfaction={satisfaction}
        reference={activity.referenceId}
        handleActionSubmission={handleCompleteRequest}
        submitAction={submitAction}
        activityTitle={activity.title}
        setAssignments={setAssignments}
        assignments={assignments}
        attributes={activity.attributes}
      />
      <EvidenceModal
        isOpen={isSatisfiedModalOpen}
        closeModal={handleCloseModal}
        isUploadingImages={isUploadingImages}
        setEvidenceMedia={e => setEvidenceMedia(e)}
        setEvidenceText={e => handleSetEvidenceChange(e)}
        isValid={!isUploadingImages && evidenceText.length > 0}
        handleSubmitEvidence={handleCompleteRequest}
      />

      {(audit.length === 0 ||
        !audit.some(x => x.parsedResponse.action !== null)) && (
        <>
          <OutlinedButton
            className="ln-u-margin-bottom*2"
            fullWidth
            onClick={() => handleAnswerToggle(satisfied)}
          >
            Satisfied
          </OutlinedButton>
          <OutlinedButton
            fullWidth
            onClick={() => handleAnswerToggle(notSatisfied)}
          >
            Not satisfied
          </OutlinedButton>
        </>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  isUploadingImages: state.activities.showUploadingSpinner,
  audit: state.activities.audit
})

const mapDispatchToProps = dispatch => ({
  completeAudit: (activityReference, eventType, event, rerouter) =>
    dispatch(
      submitEventRequestThenComplete(
        activityReference,
        eventType,
        event,
        rerouter
      )
    ),
  submitAction: request => dispatch(createActionRequest(request))
})

AuditHandler.propTypes = {
  activity: PropTypes.object.isRequired,
  completeAudit: PropTypes.func.isRequired,
  isUploadingImages: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  audit: PropTypes.array,
  submitAction: PropTypes.func.isRequired
}

AuditHandler.defaultProps = {
  audit: []
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditHandler)
