import React from 'react'
import PropTypes from 'prop-types'
import { requestCompleteActivity } from 'actions'
import { FilledButton } from '@jsluna/button'
import { connect } from 'react-redux'
import { useAnalytics } from '@jsainsburyplc/retail-analytics'

export const Complete = ({ activity, complete, disabled, response }) => {
  const { sendEvent } = useAnalytics()

  const handleCompleteRequest = () => {
    sendEvent('activity_complete', {
      label: activity.title,
      type: activity.type.toLowerCase()
    })
    complete(activity.referenceId, activity.type, response)
  }

  return (
    <FilledButton
      fullWidth
      onClick={() => handleCompleteRequest()}
      disabled={disabled}
    >
      Complete activity
    </FilledButton>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    complete: (referenceId, type, response) =>
      dispatch(requestCompleteActivity(referenceId, type, response))
  }
}

Complete.propTypes = {
  activity: PropTypes.object.isRequired,
  complete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  response: PropTypes.any
}

Complete.defaultProps = {
  disabled: false,
  response: null
}

export default connect(null, mapDispatchToProps)(Complete)
