import ActivitiesAPI from 'api'
import { createNotification, fetchColleagueAssignmentsRequest } from './index'
import * as Constants from '../utils/constants'
import { getStoreFromStorage } from '../utils/currentStore'

export const ASSIGN_ACTIVITY_REQUEST = 'ASSIGN_ACTIVITY_REQUEST'
export const ASSIGN_ACTIVITY_SUCCESS = 'ASSIGN_ACTIVITY_SUCCESS'
export const ASSIGN_ACTIVITY_FAILURE = 'ASSIGN_ACTIVITY_FAILURE'

export const assignActivityRequest = () => ({
  type: ASSIGN_ACTIVITY_REQUEST
})

export const assignActivitySuccess = (colleagueId, colleagueName) => ({
  type: ASSIGN_ACTIVITY_SUCCESS,
  colleagueId,
  colleagueName
})

export const assignActivityFailure = () => ({
  type: ASSIGN_ACTIVITY_FAILURE
})

export const assignActivity =
  (activityReferenceId, colleagueId, colleagueName) => dispatch => {
    dispatch(assignActivityRequest())
    return ActivitiesAPI.assignActivity(activityReferenceId, colleagueId)
      .then(() => {
        dispatch(assignActivitySuccess(colleagueId, colleagueName))
        dispatch(fetchColleagueAssignmentsRequest(activityReferenceId))
      })
      .catch(() => {
        dispatch(assignActivityFailure())
        dispatch(
          createNotification(Constants.ASSIGN_ACTIVITY_ERROR_MESSAGE, 'danger')
        )
      })
  }

export const REMOVE_ASSIGNMENT_REQUEST = 'REMOVE_ASSIGNMENT_REQUEST'
export const REMOVE_ASSIGNMENT_SUCCESS = 'REMOVE_ASSIGNMENT_SUCCESS'
export const REMOVE_ASSIGNMENT_FAILURE = 'REMOVE_ASSIGNMENT_FAILURE'

export const removeAssignmentRequest = () => ({
  type: REMOVE_ASSIGNMENT_REQUEST
})

export const removeAssignmentSuccess = () => ({
  type: REMOVE_ASSIGNMENT_SUCCESS
})

export const removeAssignmentFailure = () => ({
  type: REMOVE_ASSIGNMENT_FAILURE
})

export const removeAssignment =
  (activityReference, unassignedToId) => dispatch => {
    dispatch(removeAssignmentRequest())
    return ActivitiesAPI.removeAssignment(activityReference, unassignedToId)
      .then(() => {
        dispatch(removeAssignmentSuccess())
        dispatch(fetchColleagueAssignmentsRequest(activityReference))
      })
      .catch(() => {
        dispatch(removeAssignmentFailure())
        dispatch(
          createNotification(
            Constants.CLEAR_ACTIVITY_ASSIGNMENT_ERROR_MESSAGE,
            'danger'
          )
        )
      })
  }
export const FETCH_ASSIGNMENT_EVENTS_PAGE_REQUEST =
  'FETCH_ASSIGNMENT_EVENTS_PAGE_REQUEST'
export const FETCH_ASSIGNMENT_EVENTS_PAGE_SUCCESS =
  'FETCH_ASSIGNMENT_EVENTS_PAGE_SUCCESS'
export const FETCH_ASSIGNMENT_EVENTS_PAGE_FAILURE =
  'FETCH_ASSIGNMENT_EVENTS_PAGE_FAILURE'

export const fetchAssignmentEventsPageRequest = () => ({
  type: FETCH_ASSIGNMENT_EVENTS_PAGE_REQUEST
})

export const fetchAssignmentEventsPageSuccess = assignmentEventsPage => ({
  type: FETCH_ASSIGNMENT_EVENTS_PAGE_SUCCESS,
  assignmentEventsPage
})

export const fetchAssignmentEventsPageFailure = error => ({
  type: FETCH_ASSIGNMENT_EVENTS_PAGE_FAILURE,
  error
})

export const fetchAssignmentEventsPage =
  (activityReference, pageNumber = 1, pageSize = 1) =>
  dispatch => {
    dispatch(fetchAssignmentEventsPageRequest())
    const storeCode = getStoreFromStorage().code
    return ActivitiesAPI.getInstanceAssignmentEventsPage(
      activityReference,
      pageNumber,
      pageSize,
      storeCode
    )
      .then(response => response.json())
      .then(json => {
        dispatch(fetchAssignmentEventsPageSuccess(json))
      })
      .catch(err => {
        dispatch(fetchAssignmentEventsPageFailure(err))
      })
  }
