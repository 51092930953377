import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { SSC_STORE_CODE } from 'utils/constants'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import AssignmentControl from '../Units/AssignmentControl'
import AlreadyCompletedModal from './Survey/AlreadyCompletedModal'
import ImageConfirmationModal from './Survey/ImageConfirmationModal'
import Complete from '../Units/Complete'

export const ProcessSurvey = ({
  activity,
  currentStore,
  response,
  isResponseValid,
  isCompletingActivity,
  history
}) => {
  if (
    !activity ||
    activity.isCompleted ||
    activity.isMissed ||
    currentStore?.code === SSC_STORE_CODE
  )
    return null

  return (
    <>
      <Card className="ln-u-margin-top">
        <GridWrapper matrix className="activity-page-padder">
          <GridItem size="1/1">
            <h3>Action this activity</h3>
            <AssignmentControl />
            <Complete
              activity={activity}
              response={response}
              history={history}
              disabled={
                activity.hasBeenCompletedWhileViewing ||
                !isResponseValid ||
                isCompletingActivity
              }
            />
          </GridItem>
        </GridWrapper>
      </Card>
      <AlreadyCompletedModal
        activityCompleted={activity.hasBeenCompletedWhileViewing}
      />
      <ImageConfirmationModal />
    </>
  )
}

const mapStateToProps = state => ({
  currentStore: state.currentStore,
  isCompletingActivity: state.activities.completingActivity
})

ProcessSurvey.propTypes = {
  activity: PropTypes.object.isRequired,
  currentStore: PropTypes.object.isRequired,
  response: PropTypes.array.isRequired,
  isResponseValid: PropTypes.bool.isRequired,
  isCompletingActivity: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
}

export default connect(mapStateToProps, null)(ProcessSurvey)
