import { call, put, all } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import ActivitiesAPI from 'api'
import moment from 'moment'
import {
  COMPLETE_ACTIVITY_ERROR_MESSAGE,
  RETRY_TIMEOUT,
  MAX_IMAGE_UPLOAD_RETRYS,
  FAILED_EVENT_DATA_SUBMISSION,
  FAILED_AUDIT_SUBMISSION,
  MAX_RETRY,
  FILE_TYPE_IMAGE,
  FILE_TYPE_PDF
} from 'utils/constants'
import { getStoreFromStorage } from 'utils/currentStore'
import { getFileExt, createForm } from 'utils/helpers'

import * as actions from 'actions'

function* submitMedia(activityId, storecode, index, media) {
  let error
  try {
    for (let i = 0; i < MAX_IMAGE_UPLOAD_RETRYS; i += 1) {
      try {
        const filename = `${storecode}_${activityId}_${index}_${moment().valueOf()}`

        const response = yield call(
          ActivitiesAPI.uploadEventMedia,
          createForm(filename, media)
        )

        return {
          index,
          url: response,
          storecode,
          description: media.description,
          type:
            getFileExt(response) === FILE_TYPE_PDF
              ? FILE_TYPE_PDF
              : FILE_TYPE_IMAGE
        }
      } catch (err) {
        error = err
      }
      yield call(delay, RETRY_TIMEOUT)
    }
    throw error
  } catch (err) {
    yield put(actions.showUploadingActivitySpinner(false))
    yield put(actions.submitEventFailure(err))
    yield put(
      actions.createNotification(FAILED_EVENT_DATA_SUBMISSION, 'danger')
    )
    throw err
  }
}

function* submitAllMedia(activityId, media) {
  const storecode = getStoreFromStorage().code

  const response = yield all(
    media.map((m, index) => submitMedia(activityId, storecode, index, m))
  )
  return response
}

export function* submitEvent({ activityId, eventType, event }) {
  yield put(actions.showUploadingActivitySpinner(true))

  const imageResponses = yield submitAllMedia(activityId, event.media)
  const completeEvent = {
    satisfaction: event.satisfaction,
    text: event.text,
    media: imageResponses,
    action: event.action
  }

  let error
  try {
    for (let i = 0; i < MAX_RETRY; i += 1) {
      try {
        yield call(
          ActivitiesAPI.submitEvent,
          activityId,
          eventType,
          completeEvent
        )
        yield put(actions.showUploadingActivitySpinner(false))
        yield put(actions.submitEventSuccess())
        return
      } catch (err) {
        error = err
      }
      yield call(delay, RETRY_TIMEOUT)
    }
    throw error
  } catch (err) {
    yield put(actions.showUploadingActivitySpinner(false))
    yield put(actions.submitEventFailure(err))
    yield put(actions.createNotification(FAILED_AUDIT_SUBMISSION, 'danger'))
    throw err
  }
}

export function* submitEventThenComplete({
  activityId,
  eventType,
  event,
  rerouter
}) {
  try {
    yield submitEvent({ activityId, eventType, event })

    yield put(actions.showUploadingActivitySpinner(true))
    yield put(actions.requestCompleteActivity(activityId))
    yield call(ActivitiesAPI.completeActivity, activityId)
    yield put(actions.requestCompleteActivitySuccess())
    yield call(rerouter)
  } catch (error) {
    yield put(actions.requestCompleteActivityFailure(error))
    yield put(
      actions.createNotification(COMPLETE_ACTIVITY_ERROR_MESSAGE, 'danger')
    )
  } finally {
    yield put(actions.showUploadingActivitySpinner(false))
  }
}

export default {
  submitEvent,
  submitEventThenComplete
}
