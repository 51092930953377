import React from 'react'
import PropTypes from 'prop-types'
import { GridItem } from '@jsluna/grid'

const ItemHeader = ({ title, className }) => (
  <GridItem
    id="item-header-container"
    size="1/1"
    className={`ln-u-text-align-left ln-u-soft-left ${className}`}
  >
    <h2 id="item-header">
      {title.length > 0 ? (
        title
      ) : (
        <div className="text-input__loading">
          <div className="text-input__loading--line" />
        </div>
      )}
    </h2>
  </GridItem>
)

export default ItemHeader

ItemHeader.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
}

ItemHeader.defaultProps = {
  title: '',
  className: ''
}
