import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { GridItem, GridWrapper } from '@jsluna/grid'
import {
  ProgressIndicator,
  ProgressMessage,
  ProgressSpinner
} from '@jsluna/progress'
import { Tabs, TabLink } from '@jsluna/tabs'
import { ToggleButtonGroup, ToggleButton } from '@jsluna/toggle-button'
import {
  financialWeeksRequest,
  riskTaskReportRequest,
  riskRegionTaskReportRequest,
  riskTaskOverviewReportRequest,
  riskRegionTaskOverviewReportRequest,
  riskActionReportRequest,
  requestAttributeCategories
} from 'actions'
import ExemptStoreMessage from './ExemptStoreMessage'
import TaskReport from './Report/TaskReport'
import ActionReport from './Report/ActionReport'
import FailedToFetch from '../ErrorPages/FailedToFetch'
import { RISK_TRIAL_GROUP_LIST } from '../../../utils/constants'
import RegionTaskReport from './Report/RegionTaskReport'

const tabs = [
  { label: 'Risk tasks', value: 'task' },
  { label: 'Risk actions', value: 'action' }
]

export const RiskCompliance = ({
  loading,
  error,
  financialWeeks,
  getFinancialWeeks,
  getRiskTaskOverviewReport,
  getRiskRegionTaskOverviewReport,
  getRiskTaskReport,
  getRiskRegionTaskReport,
  getRiskActionReport,
  getAttributeCategories,
  attributeCategories,
  storeGroups,
  isMobile
}) => {
  const riskEnabled =
    storeGroups &&
    storeGroups.some(group => RISK_TRIAL_GROUP_LIST.includes(group))

  const [selectedFinancialWeek, setSelectedFinancialWeek] = useState(0)
  const [selectedReport, setSelectedReport] = useState('task')
  const [selectedLevel, setSelectedLevel] = useState('store')

  const reportLevels = [
    { label: 'Store', value: 'store' },
    { label: 'Region', value: 'region' }
  ]

  useEffect(() => {
    if (!riskEnabled) return
    getFinancialWeeks()
  }, [riskEnabled, storeGroups])

  useEffect(() => {
    if (!financialWeeks.length) return

    if (attributeCategories === null) getAttributeCategories(true)

    const financialWeek = financialWeeks.find(
      x => x.key === selectedFinancialWeek
    )
    const { fromDate, toDate } = financialWeek

    switch (selectedReport) {
      case 'task':
        switch (selectedLevel) {
          case 'store':
            getRiskTaskReport(fromDate, toDate)
            break
          case 'region':
            getRiskRegionTaskReport(fromDate, toDate)
            break
          default:
            break
        }
        break
      case 'action':
        getRiskActionReport(fromDate, toDate)
        break
      default:
        break
    }
  }, [loading, selectedFinancialWeek, selectedReport, selectedLevel])

  useEffect(() => {
    if (!financialWeeks.length) return

    switch (selectedReport) {
      case 'task':
        switch (selectedLevel) {
          case 'store':
            getRiskTaskOverviewReport(
              financialWeeks[financialWeeks.length - 1].fromDate,
              financialWeeks[0].toDate
            )
            break
          case 'region':
            getRiskRegionTaskOverviewReport(
              financialWeeks[financialWeeks.length - 1].fromDate,
              financialWeeks[0].toDate
            )
            break
          default:
            break
        }
        break
      default:
        break
    }
  }, [loading, selectedReport, selectedLevel])

  if (error) return <FailedToFetch />

  const loader = (
    <ProgressIndicator loading preventFocus>
      <ProgressSpinner className="ln-u-push-right-sm" />
      <ProgressMessage>Loading...</ProgressMessage>
    </ProgressIndicator>
  )

  const page = (
    <>
      <Tabs type="pill">
        {tabs.map(tab => (
          <TabLink
            key={tab.value}
            onClick={() => setSelectedReport(tab.value)}
            active={selectedReport === tab.value}
          >
            {tab.label}
          </TabLink>
        ))}
      </Tabs>
      {selectedReport === 'task' && (
        <GridItem
          size={{ default: '1/1', sm: '1/2' }}
          offsetRight={{ default: '', sm: '1/2' }}
        >
          <ToggleButtonGroup
            value={selectedLevel}
            onChange={(e, value) => setSelectedLevel(value)}
          >
            {reportLevels.map(x => (
              <ToggleButton key={x.value} value={x.value}>
                {x.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </GridItem>
      )}
      <div className="ln-u-padding">
        {selectedReport === 'task' && selectedLevel === 'store' && (
          <TaskReport
            selectedFinancialWeek={selectedFinancialWeek}
            setSelectedFinancialWeek={setSelectedFinancialWeek}
          />
        )}
        {selectedReport === 'task' && selectedLevel === 'region' && (
          <RegionTaskReport
            selectedFinancialWeek={selectedFinancialWeek}
            setSelectedFinancialWeek={setSelectedFinancialWeek}
          />
        )}
        {selectedReport === 'action' && (
          <ActionReport
            selectedFinancialWeek={selectedFinancialWeek}
            setSelectedFinancialWeek={setSelectedFinancialWeek}
          />
        )}
      </div>
    </>
  )

  return (
    <div
      className={`filter-top-margin filter-content ${
        isMobile ? 'ln-u-margin sides' : ''
      }`}
    >
      <GridWrapper matrix horizontalAlign="center">
        {!riskEnabled && (
          <GridItem size={{ default: '1/1', sm: '1/2' }}>
            <ExemptStoreMessage />
          </GridItem>
        )}
        {riskEnabled && (
          <GridItem size={{ default: '1/1', sm: '1/2' }}>
            <h2>Weekly compliance</h2>
            {loading ? loader : page}
          </GridItem>
        )}
      </GridWrapper>
    </div>
  )
}

const mapStateToProps = state => ({
  attributeCategories: state.attributes.attributeCategories,
  loading: state.financialCalendar.loading,
  error: state.financialCalendar.error,
  financialWeeks: state.financialCalendar.financialWeeks.map((x, i) => ({
    ...x,
    key: i
  })),
  storeGroups: state.storeGroups.groups,
  isMobile: state.activities.isMobile
})

const mapDispatchToProps = dispatch => ({
  getFinancialWeeks: () => {
    dispatch(financialWeeksRequest())
  },
  getRiskTaskOverviewReport: (fromDate, toDate) => {
    dispatch(riskTaskOverviewReportRequest(fromDate, toDate))
  },
  getRiskRegionTaskOverviewReport: (fromDate, toDate) => {
    dispatch(riskRegionTaskOverviewReportRequest(fromDate, toDate))
  },
  getRiskTaskReport: (fromDate, toDate) => {
    dispatch(riskTaskReportRequest(fromDate, toDate))
  },
  getRiskRegionTaskReport: (fromDate, toDate) => {
    dispatch(riskRegionTaskReportRequest(fromDate, toDate))
  },
  getRiskActionReport: (fromDate, toDate) => {
    dispatch(riskActionReportRequest(fromDate, toDate))
  },
  getAttributeCategories: activeOnly =>
    dispatch(requestAttributeCategories(activeOnly))
})

RiskCompliance.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  financialWeeks: PropTypes.array,
  getFinancialWeeks: PropTypes.func.isRequired,
  getRiskTaskOverviewReport: PropTypes.func.isRequired,
  getRiskRegionTaskOverviewReport: PropTypes.func.isRequired,
  getRiskTaskReport: PropTypes.func.isRequired,
  getRiskRegionTaskReport: PropTypes.func.isRequired,
  getRiskActionReport: PropTypes.func.isRequired,
  getAttributeCategories: PropTypes.func.isRequired,
  attributeCategories: PropTypes.array,
  storeGroups: PropTypes.array,
  isMobile: PropTypes.bool.isRequired
}

RiskCompliance.defaultProps = {
  loading: false,
  error: false,
  financialWeeks: [],
  attributeCategories: null,
  storeGroups: null
}

export default connect(mapStateToProps, mapDispatchToProps)(RiskCompliance)
