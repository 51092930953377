import { orderBy } from 'lodash'
import { formatDateTimeWithYear } from 'utils/datetimeformat'
import {
  EVENT_AUDIT_DEFER_DATE,
  EVENT_AUDIT_SATISFACTION,
  EVENT_FOLLOW_UP_ACTION_CREATED
} from 'utils/constants'

export const icon = {
  cancel: 'Cancel',
  tick: 'Tick'
}

export const styles = {
  black: 'activity-page-event--black',
  blue: 'activity-page-event--blue',
  green: 'activity-page-event--green',
  red: 'activity-page-event--red'
}

export const status = {
  created: 'Created',
  started: 'Started',
  assigned: 'Assigned',
  unassigned: 'Unassigned',
  dateUpdated: 'Date updated',
  satisfied: 'Satisfied',
  notSatisfied: 'Not satisfied',
  overdue: 'Overdue',
  action: 'Action',
  completed: 'Completed',
  missed: 'Missed'
}

export const getCreated = (activity, audit) => {
  const actionCreatedEvent =
    audit && audit.find(x => x.eventType === EVENT_FOLLOW_UP_ACTION_CREATED)

  const showRiskParent =
    activity?.source === 'risk' && activity?.type === 'Audit'
  return activity && activity.visibleFromDate
    ? [
        {
          icon: null,
          styles: null,
          status: status.created,
          description:
            !showRiskParent && actionCreatedEvent
              ? `${actionCreatedEvent.userName}, ${formatDateTimeWithYear(
                  activity.visibleFromDate
                )} created from task: ${activity.title}`
              : formatDateTimeWithYear(activity.visibleFromDate),
          comments: null,
          media: null,
          orderBy: activity.visibleFromDate
        }
      ]
    : []
}

export const getStarted = activity =>
  activity && activity.markedInProgressByName && activity.markedInProgressOn
    ? [
        {
          icon: null,
          styles: styles.blue,
          status: status.started,
          description: `${
            activity.markedInProgressByName
          } started, ${formatDateTimeWithYear(activity.markedInProgressOn)}`,
          comments: null,
          media: null,
          orderBy: activity.markedInProgressOn
        }
      ]
    : []

export const getAssignments = assignmentEvents => {
  const items = (assignmentEvents && assignmentEvents.items) || []

  return items.map(x => ({
    icon: null,
    styles: x.eventType === 0 ? styles.blue : styles.black,
    status: x.eventType === 0 ? status.assigned : status.unassigned,
    description: `${x.context}, ${formatDateTimeWithYear(x.eventDate)}`,
    comments: null,
    media: null,
    orderBy: x.eventDate
  }))
}

export const getDueDateUpdated = audit =>
  (audit &&
    audit
      .filter(x => x.eventType === EVENT_AUDIT_DEFER_DATE)
      .map(x => ({
        icon: null,
        styles: styles.black,
        status: status.dateUpdated,
        description: `${x.userName} updated the date, ${formatDateTimeWithYear(
          x.eventDate
        )}`,
        comments: x?.parsedResponse?.text,
        media: null,
        orderBy: x.eventDate
      }))) ||
  []

export const getSatisfaction = (showSatisfaction, audit) =>
  (showSatisfaction &&
    audit &&
    audit
      .filter(x => x.eventType === EVENT_AUDIT_SATISFACTION)
      .map(x => {
        const satisfied = x.parsedResponse?.satisfaction === 0
        return {
          icon: satisfied ? icon.tick : icon.cancel,
          styles: satisfied ? styles.green : styles.red,
          status: satisfied ? status.satisfied : status.notSatisfied,
          description: `${x.userName} marked as ${
            satisfied ? 'satisfied' : 'not satisfied'
          }, ${formatDateTimeWithYear(x.eventDate)}`,
          comments:
            x?.parsedResponse?.text.length === 0
              ? 'No comments added'
              : x?.parsedResponse?.text,
          media: x?.parsedResponse?.media,
          orderBy: x.eventDate
        }
      })) ||
  []

export const getOverdue = (showOverdue, activity) =>
  showOverdue &&
  activity &&
  (activity.isOverdue ||
    (activity.isCompleted && activity.completedOn > activity.dueDate))
    ? [
        {
          icon: null,
          styles: styles.red,
          status: status.overdue,
          description: formatDateTimeWithYear(activity.dueDate),
          comments: null,
          media: null,
          orderBy: activity.dueDate
        }
      ]
    : []

export const getCompleted = (showActionOnComplete, activity, showCompleted) =>
  showCompleted && activity && activity.completedBy && activity.completedOn
    ? [
        showActionOnComplete
          ? {
              icon: null,
              styles: styles.black,
              status: status.action,
              description: `${
                activity.completedBy
              } created an action, ${formatDateTimeWithYear(
                activity.completedOn
              )}`,
              comments: null,
              media: null,
              orderBy: activity.completedOn
            }
          : {
              icon: icon.tick,
              styles: styles.green,
              status: status.completed,
              description: `${
                activity.completedBy
              } completed, ${formatDateTimeWithYear(activity.completedOn)}`,
              comments: null,
              media: null,
              orderBy: activity.completedOn
            }
      ]
    : []

export const getMissed = activity =>
  activity && activity.isMissed
    ? [
        {
          icon: null,
          styles: styles.red,
          status: status.missed,
          description: formatDateTimeWithYear(activity.expiryDate),
          comments: null,
          media: null,
          orderBy: activity.expiryDate
        }
      ]
    : []

const build = (
  activity,
  audit,
  assignmentEvents,
  showSatisfaction,
  showActionOnComplete,
  showOverdue,
  showCompleted
) => {
  const created = getCreated(activity, audit)
  const started = getStarted(activity)
  const assignments = getAssignments(assignmentEvents)
  const dueDateUpdated = getDueDateUpdated(audit)
  const satisfaction = getSatisfaction(showSatisfaction, audit)
  const overdue = getOverdue(showOverdue, activity)
  const completed = getCompleted(showActionOnComplete, activity, showCompleted)
  const missed = getMissed(activity)

  const history = [
    ...created,
    ...started,
    ...assignments,
    ...dueDateUpdated,
    ...satisfaction,
    ...overdue,
    ...completed,
    ...missed
  ]

  return orderBy(history, ['orderBy'], ['asc'])
}

export default build
