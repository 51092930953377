import * as actions from '../actions/audience'

const initialState = {
  loading: false,
  error: false,
  canAccessRisk: false
}

const audienceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.RISK_ACCESS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        canAccessRisk: false
      }
    case actions.RISK_ACCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        canAccessRisk: action.payload
      }
    case actions.RISK_ACCESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        canAccessRisk: false
      }
    default:
      return {
        ...state
      }
  }
}

export default audienceReducer
