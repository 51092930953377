import ActivitiesAPI from '../api'

export const SET_CHECKLIST_REQUEST = 'SET_CHECKLIST_REQUEST'
export const SET_CHECKLIST_SUCCESS = 'SET_CHECKLIST_SUCCESS'
export const SET_CHECKLIST_FAILURE = 'SET_CHECKLIST_FAILURE'

export const setChecklistRequest = instanceId => ({
  type: SET_CHECKLIST_REQUEST,
  instanceId
})

export const setChecklistSuccess = response => ({
  type: SET_CHECKLIST_SUCCESS,
  response
})

export const setChecklistFailure = () => ({
  type: SET_CHECKLIST_FAILURE
})

export const setChecklistResponse = (instanceId, selectedItems) => dispatch => {
  dispatch(setChecklistRequest(instanceId))
  return ActivitiesAPI.submitChecklistResponse(instanceId, selectedItems)
    .then(response => {
      dispatch(setChecklistSuccess(response))
    })
    .catch(e => {
      dispatch(setChecklistFailure(e))
    })
}
