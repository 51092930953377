import * as actions from '../actions'

const initialState = {
  isOnline: true
}

const connectivityReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CONNECTION_ONLINE:
      return {
        isOnline: true
      }
    case actions.CONNECTION_OFFLINE:
      return {
        isOnline: false
      }
    default:
      return state
  }
}

export default connectivityReducer
