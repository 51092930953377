import * as actions from '../actions/filter'
import {
  filterTypes,
  getDateOption
} from '../components/Areas/Filtering/options'

const initialState = {
  isPanelOpen: false,
  staging: [],
  applied: [],
  pageNumber: 1
}

const add = (item, filters) => [item, ...filters]
const remove = (value, filters) => filters.filter(item => item.value !== value)

const addDateFilters = (fromDate, toDate, filters) => {
  const updatedFilters = filters
    .filter(x => x.filterType !== filterTypes.fromDate)
    .filter(x => x.filterType !== filterTypes.toDate)

  updatedFilters.push(getDateOption(fromDate, filterTypes.fromDate))

  updatedFilters.push(getDateOption(toDate, filterTypes.toDate))

  return updatedFilters
}

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FILTER_PANEL_TOGGLE:
      return {
        ...state,
        isPanelOpen: action.isOpen
      }
    case actions.FILTER_STAGING_TOGGLE:
      return {
        ...state,
        staging: !state.staging.some(x => x.value === action.item.value)
          ? add(action.item, state.staging)
          : remove(action.item.value, state.staging)
      }
    case actions.FILTER_HYDRATE:
      return {
        ...state,
        applied: [...action.filters],
        staging: [...action.filters]
      }
    case actions.FILTER_APPLY:
      return {
        ...state,
        applied: [...state.staging]
      }
    case actions.FILTER_RESET:
      return {
        ...state,
        ...initialState
      }
    case actions.FILTER_REMOVE:
      return {
        ...state,
        applied: remove(action.item.value, state.applied),
        staging: remove(action.item.value, state.staging)
      }
    case actions.FILTER_CHANGE_PAGE:
      return {
        ...state,
        pageNumber: action.pageNumber
      }
    case actions.FILTER_ADD_DATES:
      return {
        ...state,
        staging: addDateFilters(action.fromDate, action.toDate, state.staging)
      }
    default:
      return {
        ...state
      }
  }
}

export default filterReducer
