import React from 'react'
import { PropTypes } from 'prop-types'
import StoreDisplay from '../StoreDisplay'
import Loading from '../Loading'

const selectNewStore =
  (storeSelect, resetFilters, resetSearch, history) => store => {
    storeSelect(store)
    resetFilters()
    resetSearch()
    history.push('/')
  }

const StoreList = ({
  stores,
  selectStore,
  currentStore,
  loading,
  searchText,
  resetSearch,
  history,
  requestPerformed,
  resetFilters
}) => {
  if (loading) return <Loading />

  if (!searchText && currentStore)
    return (
      <span>
        Current store is {currentStore.name} ({currentStore.code}).
      </span>
    )

  const noStoresFoundMessage = () => {
    if (
      searchText &&
      requestPerformed &&
      (!stores || stores.length === 0 || stores[0] === undefined)
    ) {
      return <li className="ln-o-bare-list__item">No stores found</li>
    }
    return ''
  }

  return (
    <ul id="storeList" className="ln-o-bare-list ln-o-bare-list--spaced">
      {noStoresFoundMessage()}
      {stores.map((store, index) =>
        StoreDisplay(
          store,
          index,
          currentStore,
          selectNewStore(selectStore, resetFilters, resetSearch, history)
        )
      )}
    </ul>
  )
}

StoreList.propTypes = {
  stores: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectStore: PropTypes.func.isRequired,
  currentStore: PropTypes.object,
  loading: PropTypes.bool,
  searchText: PropTypes.string.isRequired,
  resetSearch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  requestPerformed: PropTypes.bool,
  resetFilters: PropTypes.func.isRequired
}

StoreList.defaultProps = {
  currentStore: null,
  loading: false,
  requestPerformed: false
}

export default StoreList
