import React from 'react'
import PropTypes from 'prop-types'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import Banner from '../Units/Banner'
import Title from '../Units/Title'
import DueDate from '../Units/DueDate'
import TaskNumber from '../Units/TaskNumber'
import Summary from '../Units/Summary'
import Description from '../Units/Description'
import Media from '../Units/Media'
import Attributes from '../Units/Attributes'
import ProcessAudit from '../Processes/ProcessAudit'
import History from '../Units/History'
import { NOTSATISFIED_VALUE } from '../../../../utils/constants'
import ChecklistContent from '../Units/ChecklistContent'
import {
  isValidContentData,
  getCheckListItems
} from '../../../../utils/checklistHelper'

const Audit = ({ activity, history, audit }) => {
  if (!activity) return null
  const showCompleted =
    (audit &&
      audit.length > 0 &&
      JSON.parse(audit[audit.length - 1].eventPayload)?.satisfaction ===
        NOTSATISFIED_VALUE) ??
    false

  return (
    <div className="ln-u-padding-bottom*4">
      <Banner activity={activity} />
      <Card>
        <GridWrapper matrix className="activity-page-padder">
          <GridItem size="1/1">
            <DueDate dueDate={activity.dueDate} />
            <TaskNumber templateId={activity.templateId} />
            <Title title={activity.title} smallerVariant />
            <Summary summary={activity.summary} />
            <Description description={activity.description} />
            <Media
              hasMedia={activity.hasMedia}
              mediaAttachments={activity.mediaAttachments}
            />
            <Attributes attributes={activity.attributes} />
          </GridItem>
        </GridWrapper>
      </Card>
      <Card className="activity-page-padder">
        {isValidContentData && (
          <ChecklistContent
            activity={activity}
            items={getCheckListItems(activity)}
          />
        )}
      </Card>

      <ProcessAudit activity={activity} history={history} audit={audit} />

      <History
        activity={activity}
        audit={audit}
        showSatisfaction
        showActionOnComplete
        showCompleted={showCompleted}
      />
    </div>
  )
}

Audit.propTypes = {
  history: PropTypes.object,
  activity: PropTypes.object,
  audit: PropTypes.array
}

Audit.defaultProps = {
  history: null,
  activity: null,
  audit: []
}

export default Audit
