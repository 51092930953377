import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { fetchActivity } from 'actions'
import { useAnalytics } from '@jsainsburyplc/retail-analytics'
import BackLink from 'components/BackLink'
import Skeleton from './Skeleton'
import ActivityPageSwitcher from './ActivityPageSwitcher'

export const ViewActivity = ({
  match,
  getCurrentActivity,
  fetchActivityError,
  currentActivity,
  history,
  activityDeletedReferenceId
}) => {
  const { activityReference } = match.params
  const { sendEvent } = useAnalytics()

  const [gaBound, setGaBound] = useState(false)

  useEffect(() => {
    getCurrentActivity(activityReference)
    window.scrollTo(0, 0)
    return () => {
      setGaBound(false)
    }
  }, [])

  useEffect(() => {
    if (activityDeletedReferenceId === activityReference) {
      history.push('/')
    }

    const activity = currentActivity
    if (activity && activity.id) {
      if (!gaBound) {
        sendEvent('activity_viewed', {
          label: activity.title
        })
        setGaBound(true)
      }
    }
  }, [])

  return (
    <div className="view-activity-container">
      <GridWrapper horizontalAlign="center">
        <GridItem size={{ md: '4/5', lg: '3/5', xxl: '2/5' }}>
          {fetchActivityError && (
            <div className="ln-u-text-align-center">
              <br />
              <p>
                {'Sorry, something went wrong trying to view this activity'}
              </p>
              <Link to="/" className="ln-c-standalone-link">
                Back to all activities
              </Link>
            </div>
          )}
          {!fetchActivityError && (
            <>
              <BackLink
                className="ln-u-soft-left ln-u-soft-top"
                onClick={() => {
                  history.goBack()
                }}
              />
              {!currentActivity ? (
                <Card>
                  <Skeleton />
                </Card>
              ) : (
                <ActivityPageSwitcher
                  activity={currentActivity}
                  history={history}
                />
              )}
            </>
          )}
        </GridItem>
      </GridWrapper>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    currentActivity: state.activities.currentActivity,
    gettingActivity: state.activities.gettingActivity,
    fetchActivityError: state.activities.fetchActivityError,
    activityDeletedReferenceId: state.activities.activityDeletedReferenceId
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCurrentActivity: activityReference =>
      dispatch(fetchActivity(activityReference))
  }
}

ViewActivity.propTypes = {
  match: PropTypes.object.isRequired,
  getCurrentActivity: PropTypes.func.isRequired,
  fetchActivityError: PropTypes.bool,
  currentActivity: PropTypes.object,
  history: PropTypes.object.isRequired,
  activityDeletedReferenceId: PropTypes.string
}

ViewActivity.defaultProps = {
  fetchActivityError: false,
  currentActivity: null,
  activityDeletedReferenceId: ''
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewActivity)
