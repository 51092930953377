import React from 'react'
import { Redirect } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { getColleagueSettingsFromStorage } from 'utils/currentStore'
import { Card } from '@jsluna/card'
import StoreSearch from './StoreSearch'
import StoreList from './StoreList'
import Loading from './Loading'

class StoreSelector extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
      searchingText: ''
    }

    this.searchChanged = this.searchChanged.bind(this)
    this.resetSearch = this.resetSearch.bind(this)
  }

  searchChanged(onChange, location, value) {
    if (this.timeout) clearTimeout(this.timeout)
    if (value.match(/^$/)) {
      this.resetSearch()
    } else {
      this.setState({ searchText: value })
      if (value.length > 2) {
        this.setState({ searchText: value, searchingText: value })
        this.timeout = setTimeout(() => {
          if (value.match(/^[0-9]+$/)) {
            onChange({ code: value })
          } else {
            onChange({
              query: value,
              lat: location.success ? location.coords.latitude : null,
              lon: location.success ? location.coords.longitude : null
            })
          }
        }, 500)
      }
    }
  }

  resetSearch() {
    this.setState({ searchText: '', searchingText: '' })
    this.props.resetStores()
  }

  render() {
    const colleagueSettings = getColleagueSettingsFromStorage()
    if (colleagueSettings === null) {
      return <Redirect to="/today" />
    }
    if (this.props.closestStoreLoading) {
      return <Loading message="Selecting closest store..." />
    }
    return (
      <div
        className={`${
          this.props.isMobile && 'ln-u-margin-top*4 ln-u-padding-top*4'
        }`}
      >
        <Card>
          <StoreSearch
            searchText={this.state.searchText}
            searchChanged={this.searchChanged}
          />
          <StoreList
            searchText={this.state.searchingText}
            resetSearch={this.resetSearch}
          />
        </Card>
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isMobile: state.activities.isMobile,
    ...ownProps
  }
}

StoreSelector.propTypes = {
  resetStores: PropTypes.func.isRequired,
  closestStoreLoading: PropTypes.bool,
  isMobile: PropTypes.bool
}

StoreSelector.defaultProps = {
  closestStoreLoading: false,
  isMobile: false
}

export default connect(mapStateToProps)(StoreSelector)
