import moment from 'moment'

export const DAY_MONTH_FORMAT = 'DD MMM'
export const DAY_MONTH_YEAR_FORMAT = 'DD MMM YYYY'
export const DAY_MONTH_TIME_FORMAT = 'DD MMM, HH:mm'
export const DAY_MONTH_YEAR_TIME_FORMAT = 'DD MMM YYYY, HH:mm'
export const TIME_FORMAT = 'HH:mm'
const HEADING_DATE_FORMAT = 'ddd DD MMM'

export const timeIsEndOfDay = date => date.hour() === 23 && date.minute() === 59
export const dateIsToday = date => date.isSame(moment(), 'day')
const timeIsStartOfDay = date => date.hour() === 0 && date.minute() === 0
const datesAreSameDay = (fromDate, toDate) => fromDate.isSame(toDate, 'day')
const upperCaseFirstChar = str =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`

const timeRangeSpansWholeDay = (fromDate, toDate) => {
  const fromDateIsStartOfDay = timeIsStartOfDay(fromDate)
  const toDateIsEndOfDay = timeIsEndOfDay(toDate)
  return fromDateIsStartOfDay && toDateIsEndOfDay
}

const dateRangeSpansWholeOfSameDate = (fromDate, toDate) =>
  datesAreSameDay(fromDate, toDate) && timeRangeSpansWholeDay(fromDate, toDate)

export default function formatDateTime(date) {
  const parsedDate = moment(date)
  const isEndOfDay = timeIsEndOfDay(parsedDate)
  if (dateIsToday(parsedDate)) {
    return `today${isEndOfDay ? '' : `, ${parsedDate.format(TIME_FORMAT)}`}`
  }
  const dateFormat = isEndOfDay ? DAY_MONTH_FORMAT : DAY_MONTH_TIME_FORMAT
  return parsedDate.format(dateFormat)
}

export const formatDateTimeWithYear = date => {
  const parsedDate = moment(date)
  const isEndOfDay = timeIsEndOfDay(parsedDate)
  if (dateIsToday(parsedDate)) {
    return `today${isEndOfDay ? '' : `, ${parsedDate.format(TIME_FORMAT)}`}`
  }
  const dateFormat = isEndOfDay
    ? DAY_MONTH_YEAR_FORMAT
    : DAY_MONTH_YEAR_TIME_FORMAT
  return parsedDate.format(dateFormat)
}

export const displayDateRange = (fromDate, toDate) => {
  const parsedFromDate = moment(fromDate)
  const parsedToDate = moment(toDate)
  if (dateRangeSpansWholeOfSameDate(parsedFromDate, parsedToDate)) {
    return `${upperCaseFirstChar(formatDateTimeWithYear(toDate))}`
  }
  const formattedFromDate = formatDateTimeWithYear(parsedFromDate)
  const formattedToDate = formatDateTimeWithYear(parsedToDate)
  return `${upperCaseFirstChar(formattedFromDate)} - ${formattedToDate}`
}

export const getFormattedDateWithTime = date => {
  const localisedDate = moment.utc(date).local()
  if (dateIsToday(localisedDate)) {
    return `Today${localisedDate.format(', HH:mm')}`
  }
  return localisedDate.format(DAY_MONTH_TIME_FORMAT)
}

export const parseDate = date => {
  let parsedDate = date
  if (!moment.isMoment(date)) {
    const dateParsed = Date.parse(date)
    if (isNaN(dateParsed)) {
      return null
    }
    parsedDate = moment.utc(date).local()
  }
  return parsedDate
}

export const parseHeadingDate = date => {
  const parsedDate = parseDate(date)
  return parsedDate !== null ? parsedDate.format(HEADING_DATE_FORMAT) : null
}

export const parsedDateForTimeSpan = date => moment(date)

export const isDateAfterUtcNow = date => {
  const validDate = parseDate(date)
  return validDate !== null ? moment.utc().isAfter(date) : false
}
