import React from 'react'
import PropTypes from 'prop-types'
import { Label } from '@jsluna/form'
import { isActivityRestricted } from './ActivityRestrictionInfo'

const ActivityRestrictionDetail = ({ audience }) => {
  const isRestricted = isActivityRestricted(audience)

  if (!isRestricted) {
    return null
  }

  const audienceList = [
    ...audience?.jobGrades?.sort(),
    ...audience?.jobRoles?.sort()
  ]

  return (
    <div style={{ marginTop: '0.5rem' }}>
      <Label htmlFor="audiences" className="ln-u-margin-top ln-u-flush-bottom">
        Visible for these colleagues
      </Label>
      <div>
        {audienceList.map(x => (
          <div key={x} className="activity-page-attribute">
            {x}
          </div>
        ))}
      </div>
    </div>
  )
}

ActivityRestrictionDetail.propTypes = {
  audience: PropTypes.object.isRequired
}

export default ActivityRestrictionDetail
