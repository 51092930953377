import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import parseHeadingDate from 'utils/datetimeformat'
import { FilledButton } from '@jsluna/button'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { setMarkAsRead } from 'actions'
import moment from 'moment'

export const ProcessMarkAsRead = ({
  activity,
  submittingMarkAsRead,
  sendMarkAsRead
}) => {
  if (!activity) return null

  let displayDate = null

  const displayTitle = () => {
    if (activity?.isRead) {
      displayDate = moment()
      if (activity?.readOn !== null && activity?.readOn !== 'undefined') {
        displayDate = activity?.readOn
      }
      return `Marked as read by myself,  ${parseHeadingDate(displayDate)}`
    }
    return `Mark as read, just for me`
  }

  const handleMarkAsReadRequest = isInstanceRead => {
    if (isInstanceRead) {
      sendMarkAsRead(activity?.id, isInstanceRead)
    }
  }

  return (
    <>
      <Card className="ln-u-margin-top">
        <GridWrapper matrix className="activity-page-padder">
          <GridItem size="1/1">
            <h3>Action this activity</h3>
            <FilledButton
              fullWidth
              onClick={() => handleMarkAsReadRequest(true)}
              disabled={activity.isRead || submittingMarkAsRead}
            >
              {displayTitle()}
            </FilledButton>

            <p className="ln-u-margin-top*2">
              This will move this into to the history tab and only affect your
              view. It will not affect anyone else&apos;s view of this info
            </p>
          </GridItem>
        </GridWrapper>
      </Card>
    </>
  )
}

function mapStateToProps(state) {
  return {
    submittingMarkAsRead: state.activities.submittingMarkAsRead
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendMarkAsRead: (instanceId, isRead) =>
      dispatch(setMarkAsRead(instanceId, isRead))
  }
}

ProcessMarkAsRead.propTypes = {
  activity: PropTypes.object.isRequired,
  sendMarkAsRead: PropTypes.func.isRequired,
  submittingMarkAsRead: PropTypes.bool
}

ProcessMarkAsRead.defaultProps = {
  submittingMarkAsRead: false
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessMarkAsRead)
