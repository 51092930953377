import React from 'react'
import { Link } from 'react-router-dom'
import * as Constants from 'utils/constants'
import { FilledButton } from '@jsluna/button'

const PageNotFound = () => (
  <div className="error-page">
    <img
      src="/baguette-background-min.png"
      alt="baguette-img"
      className="error-image"
    />
    <h1 className="ln-u-color-orange error-message">
      Oh,
      <br />
      crumbs!
    </h1>
    <div className="error-instructions">
      <p>Something&apos;s gone wrong, along the way.</p>
      <p>Don&apos;t worry, let&apos;s go back to somewhere familiar:</p>
      <Link to={`/${Constants.TODAY.toLowerCase()}`}>
        <FilledButton type="button" className="redirect-button">
          {"Today's activities"}
        </FilledButton>
      </Link>
    </div>
  </div>
)

export default PageNotFound
