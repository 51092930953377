import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useAnalytics } from '@jsainsburyplc/retail-analytics'
import { submitEventRequestThenComplete } from 'actions'
import { FilledButton, OutlinedButton } from '@jsluna/button'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import {
  ROUTE_RISK,
  EVENT_AUDIT_COMPLETION,
  SATISFIED_TEXT,
  SATISFIED_VALUE,
  NOTSATISFIED_VALUE
} from 'utils/constants'

import AssignmentControl from '../Units/AssignmentControl'
import CantCompleteModal from './Audit/CantCompleteModal'
import EvidenceModal from './Audit/EvidenceModal'

export const ProcessAction = ({
  activity,
  history,
  audit,
  completeAudit,
  isUploadingImages
}) => {
  const { sendEvent } = useAnalytics()

  const [showEvidenceModal, setShowEvidenceModal] = useState(false)
  const [showCantCompleteModal, setShowCantCompleteModal] = useState(false)

  const [evidenceText, setEvidenceText] = useState('')
  const [evidenceMedia, setEvidenceMedia] = useState([])

  const handleCloseModal = () => {
    setShowEvidenceModal(false)
    setEvidenceText('')
    setEvidenceMedia([])
  }

  const handleCompleteRequest = () => {
    sendEvent('activity_complete', {
      label: activity.title,
      type: activity.type.toLowerCase()
    })
    completeAudit(
      activity.referenceId,
      EVENT_AUDIT_COMPLETION,
      {
        satisfaction: SATISFIED_TEXT,
        text: evidenceText,
        media: evidenceMedia
      },
      () => history.push(ROUTE_RISK)
    )
  }

  if (!activity || activity.isCompleted || activity.isMissed) return null

  return (
    <>
      <Card className="ln-u-margin-top">
        <GridWrapper matrix className="activity-page-padder">
          <GridItem size="1/1">
            <h3>Action this activity</h3>
            <AssignmentControl />
            <FilledButton fullWidth onClick={() => setShowEvidenceModal(true)}>
              Add a comment
            </FilledButton>
            <OutlinedButton
              className="ln-u-margin-top*2"
              fullWidth
              onClick={() => setShowCantCompleteModal(true)}
            >
              I can&apos;t complete this yet
            </OutlinedButton>
          </GridItem>
        </GridWrapper>
      </Card>
      <EvidenceModal
        isOpen={showEvidenceModal}
        closeModal={() => handleCloseModal()}
        isUploadingImages={isUploadingImages}
        setEvidenceText={e => setEvidenceText(e)}
        setEvidenceMedia={e => setEvidenceMedia(e)}
        isValid={!isUploadingImages && evidenceText?.length > 0}
        handleSubmitEvidence={handleCompleteRequest}
      />
      <CantCompleteModal
        showModal={showCantCompleteModal}
        toggleModal={setShowCantCompleteModal}
        reference={activity.referenceId}
        satisfaction={audit?.length > 0 ? NOTSATISFIED_VALUE : SATISFIED_VALUE}
        evidenceText={evidenceText}
        evidenceMedia={evidenceMedia}
        sendEvidence
      />
    </>
  )
}

const mapStateToProps = state => ({
  isUploadingImages: state.activities.showUploadingSpinner
})

const mapDispatchToProps = dispatch => ({
  completeAudit: (activityReference, eventType, event, rerouter) =>
    dispatch(
      submitEventRequestThenComplete(
        activityReference,
        eventType,
        event,
        rerouter
      )
    )
})

ProcessAction.propTypes = {
  history: PropTypes.object.isRequired,
  completeAudit: PropTypes.func.isRequired,
  audit: PropTypes.array,
  activity: PropTypes.object,
  isUploadingImages: PropTypes.bool
}

ProcessAction.defaultProps = {
  audit: [],
  activity: null,
  isUploadingImages: false
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessAction)
