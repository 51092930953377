import React from 'react'
import { IconFor } from '@jsainsburyplc/activity-icons'
import PropTypes from 'prop-types'

export const isActivityRestricted = audience =>
  audience?.jobGrades?.length > 0 || audience?.jobRoles?.length > 0

const ActivityRestrictionInfo = ({ audience }) => {
  const isRestricted = isActivityRestricted(audience)

  return (
    isRestricted && (
      <div className="activity-card--line-managers" style={{ display: 'flex' }}>
        <div className="activity-restricted-icon">
          <IconFor value="Restricted" />
        </div>
        <span className="activity-icon-padder">
          <strong>Visible for some colleagues</strong>
        </span>
      </div>
    )
  )
}

ActivityRestrictionInfo.propTypes = {
  audience: PropTypes.object.isRequired
}

export default ActivityRestrictionInfo
