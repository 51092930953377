import React from 'react'
import { Button } from '@jsluna/button'
import { Container, GridWrapper, GridItem } from '@jsluna/grid'
import { ArrowLeft, ArrowRight, Calendar } from '@jsluna/icons'
import PropTypes from 'prop-types'
import FilterHeader from '../../../Filtering/FilterHeader'

const MobilePaginationContainer = ({
  title,
  pageForward,
  pageForwardDisabled,
  pageBackward,
  pageBackwardDisabled,
  pageToday
}) => (
  <Container className="c-pagination-container-mobile">
    <div className="ln-u-margin-left ln-u-margin-top">
      <FilterHeader />
    </div>
    <GridWrapper horizontalAlign="center">
      <GridItem size="1/1" className="u-pagination-buttons">
        <GridItem size="1/4" className="c-mobile-header-grid-item">
          <Button
            id="backButton"
            className="u-white-background"
            disabled={pageBackwardDisabled}
            onClick={pageBackward}
            fullWidth
          >
            <ArrowLeft />
          </Button>
        </GridItem>
        <GridItem size="2/4" className="c-mobile-header-grid-item">
          <Button
            id="todayButton"
            className="u-white-background"
            onClick={pageToday}
            fullWidth
          >
            <Calendar size="large" />
            <span className="activity-icon-padder">Today</span>
          </Button>
        </GridItem>
        <GridItem size="1/4" className="c-mobile-header-grid-item">
          <Button
            id="forwardButton"
            className="u-white-background"
            disabled={pageForwardDisabled}
            onClick={pageForward}
            fullWidth
          >
            <ArrowRight />
          </Button>
        </GridItem>
      </GridItem>
      <GridItem size="1/1" className="u-week-view-title">
        <div className="ln-u-padding-left*2 ln-u-padding-top">
          <h3 className="c-calendar-title">{title}</h3>
        </div>
      </GridItem>
    </GridWrapper>
  </Container>
)

MobilePaginationContainer.propTypes = {
  title: PropTypes.string.isRequired,
  pageForward: PropTypes.func.isRequired,
  pageBackward: PropTypes.func.isRequired,
  pageBackwardDisabled: PropTypes.bool.isRequired,
  pageToday: PropTypes.func.isRequired,
  pageForwardDisabled: PropTypes.bool.isRequired
}

export default MobilePaginationContainer
