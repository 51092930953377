import React from 'react'
import PropTypes from 'prop-types'
import { TODO_TYPE_ATTRIBUTES_CATEGORY } from 'utils/constants'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import Banner from '../Units/Banner'
import Title from '../Units/Title'
import CreationReason from '../Units/CreationReason'
import DueDate from '../Units/DueDate'
import Summary from '../Units/Summary'
import Description from '../Units/Description'
import Media from '../Units/Media'
import Attributes from '../Units/Attributes'
import ProcessAction from '../Processes/ProcessAction'
import History from '../Units/History'

const AdhocAction = ({ activity, history, audit }) => {
  if (!activity) return null

  return (
    <div className="ln-u-padding-bottom*4">
      <Banner activity={activity} />
      <Card>
        <GridWrapper matrix className="activity-page-padder">
          <GridItem size="1/1">
            <DueDate dueDate={activity.dueDate} />
            <Title title={activity.title} />
            <CreationReason creationReason={activity.creationReason} />
            <Summary summary={activity.summary} />
            <Description description={activity.description} />
            <Media
              hasMedia={activity.hasMedia}
              mediaAttachments={activity.mediaAttachments}
            />
            <Attributes
              attributes={activity.attributes}
              ignoredCategories={[TODO_TYPE_ATTRIBUTES_CATEGORY]}
            />
          </GridItem>
        </GridWrapper>
      </Card>
      <ProcessAction activity={activity} history={history} audit={audit} />
      <History activity={activity} audit={audit} showOverdue />
    </div>
  )
}

AdhocAction.propTypes = {
  history: PropTypes.object,
  activity: PropTypes.object,
  audit: PropTypes.array
}

AdhocAction.defaultProps = {
  history: null,
  activity: null,
  audit: []
}

export default AdhocAction
