import { connect } from 'react-redux'
import actions from 'store/stores/actions'
import StoreSearch from './StoreSearch'

const mapStateToProps = () => ({
  // location: state.currentLocation.location
})

const mapDispatchToProps = dispatch => ({
  searchStores: params => {
    dispatch({ type: actions.fetch, params })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(StoreSearch)
