import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card } from '@jsluna/card'
import RiskPage from './RiskWithFilters'

const RiskPageCheckpoint = ({ canAccessRisk }) => {
  if (canAccessRisk) {
    return <RiskPage />
  }

  return (
    <Card className="ln-u-text-align-center">
      Sorry you don&apos;t have access to this page.
    </Card>
  )
}

const mapStateToProps = state => ({
  canAccessRisk: state.audience.canAccessRisk
})

RiskPageCheckpoint.propTypes = {
  canAccessRisk: PropTypes.bool
}

RiskPageCheckpoint.defaultProps = {
  canAccessRisk: false
}

export default connect(mapStateToProps, null)(RiskPageCheckpoint)
