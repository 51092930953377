import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { riskAccessRequest } from 'actions/audience'

const Audience = ({ forRisk }) => {
  useEffect(() => {
    forRisk()
  }, [])

  return null
}

const mapDispatchToProps = dispatch => ({
  forRisk: () => {
    dispatch(riskAccessRequest())
  }
})

Audience.propTypes = {
  forRisk: PropTypes.func.isRequired
}

Audience.defaultProps = {}

export default connect(null, mapDispatchToProps)(Audience)
