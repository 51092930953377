import React from 'react'
import PropTypes from 'prop-types'
import { Account } from '@jsluna/icons'

const render = text => (
  <div>
    <Account size="large" />
    <span className="activity-icon-padder">{text}</span>
  </div>
)

const AssignedTo = ({ assignedTo, startedBy }) => {
  if (!assignedTo && !startedBy) return null

  if (assignedTo && !startedBy) {
    return render(`Assigned to ${assignedTo}`)
  }

  if (!assignedTo && startedBy) {
    return render(`Started by ${startedBy}`)
  }

  if (assignedTo === startedBy) {
    return render(`Assigned & started by ${assignedTo}`)
  }

  return render(`Assigned to ${assignedTo} & started by ${startedBy}`)
}

AssignedTo.propTypes = {
  assignedTo: PropTypes.string,
  startedBy: PropTypes.string
}

AssignedTo.defaultProps = {
  assignedTo: null,
  startedBy: null
}

export default AssignedTo
