// eslint-disable-next-line max-len

export const displayAttributeIcon = item => {
  const { attributes } = item

  const result = attributes.filter(x => x.name === 'Christmas')
  return result.length ? result[0].icon : attributes[0].icon
}

export const addMatchesFilterProp = (activities, selectedAttributes) => {
  const attributeIds = selectedAttributes.map(x => x.id)
  const filteredActivities = Object.assign({}, activities)
  if (filteredActivities && filteredActivities.items) {
    filteredActivities.items =
      selectedAttributes.length > 0
        ? activities.items.map(x => ({
            ...x,
            matchesFilter: x.attributes.some(y => attributeIds.includes(y.id))
          }))
        : activities.items.map(x => ({ ...x, matchesFilter: true }))
  }
  return filteredActivities
}

export default displayAttributeIcon
