import React from 'react'
import WarningIcon from 'assets/WarningIcon'

const FailedToFetch = () => (
  <div className="ln-u-text-align-center ln-u-soft-top">
    <WarningIcon />
    <h4>Failed to fetch activities</h4>
    <p>
      {
        "We failed to get any activities for you, however try again\
     and hopefully we'll have better luck next time!"
      }
    </p>
  </div>
)

export default FailedToFetch
