import React, { useState } from 'react'
import { FilledButton, TextButton } from '@jsluna/button'
import { TextArea } from '@jsluna/form'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Modal, ModalHeading } from '@jsluna/modal'
import moment from 'moment'
import { getStoreFromStorage } from 'utils/currentStore'
import { EVENT_AUDIT_SATISFACTION } from 'utils/constants'
import { startActivity, submitEventRequest, completeActivity } from 'actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import 'react-dates/initialize'
import { SingleDateTimePicker } from '@jsainsburyplc/retail-datetime-picker'
import { MediaSelector } from '@jsainsburyplc/activity-media-upload'
import ColleagueSearch from '../ColleagueSearch'
import SensitiveDataText from '../../../../Risk/SensitiveDataText'

export const CreateActionModal = ({
  isOpen,
  closeModal,
  setEvidenceText,
  evidenceText,
  evidenceMedia,
  setEvidenceMedia,
  setActionDescription,
  actionDescription,
  handleActionSubmission,
  submitAction,
  activityTitle,
  reference,
  setAssignments,
  assignments,
  attributes
}) => {
  const [selectedDate, setSelectedDate] = useState(null)

  const handleSubmit = () => {
    const request = {
      activityId: 2,
      type: 'Action',
      creationReason: '',
      mediaAttachments: evidenceMedia,
      title: activityTitle,
      description: actionDescription,
      dueDate: selectedDate,
      storeAssignments: JSON.stringify({
        StoreCodeAssignmentItems: [getStoreFromStorage().code]
      }),
      AssignToIds: assignments.map(x => x.value),
      linkedInstance: reference,
      attributeIds: attributes.length > 0 ? attributes.map(x => x.id) : null
    }
    handleActionSubmission(EVENT_AUDIT_SATISFACTION)
    submitAction(request)
    closeModal(false)
  }

  return (
    <Modal fullScreen open={isOpen} alert handleClose={() => closeModal(false)}>
      <GridWrapper verticalAlign="middle" className="ln-u-push-bottom">
        <GridItem size="1/1">
          <ModalHeading element="h3" className="ln-u-margin-bottom*1/2">
            Provide evidence and create a new action
          </ModalHeading>
          <SensitiveDataText className="ln-u-margin-bottom*2" />
        </GridItem>
        <GridItem className="ln-c-form-group" size="1/1">
          <label htmlFor="attachMediaLabel" className="ln-c-label">
            {'Add a comment to support your observation'}
          </label>
          <TextArea
            id="evidence-text-box"
            name="evidence-text-box"
            className="ln-c-text-input ln-c-text-input--textarea"
            onChange={e => setEvidenceText(e.target.value)}
          />
        </GridItem>
        <GridItem size="1/1">
          <MediaSelector
            title="Add a photo to support your observation"
            buttonText="Upload image"
            onMediaUpdated={e => setEvidenceMedia(e)}
            style={{ margin: '2rem 0 2rem 0' }}
            maxUploads={5}
            imageOnly
          />
        </GridItem>
        <GridItem size="1/1">
          <p className="ln-u-soft-top">
            Create a new action to help solve the problems you found with
            completing this task
          </p>
          <h4>Action directions</h4>
          <TextArea
            id="directions-text-box"
            name="directions-text-box"
            className="ln-c-text-input ln-c-text-input--textarea"
            onChange={e => setActionDescription(e.target.value)}
            maxLength={550}
          />
        </GridItem>
        <GridItem
          size={{ default: '1/1', sm: '1/2' }}
          className="ln-u-soft-top"
        >
          <ColleagueSearch
            onSearchItemSelected={selectedArray =>
              setAssignments(selectedArray)
            }
            selectedOptions={assignments}
          />
        </GridItem>
        <GridItem
          size={{ default: '1/1', sm: '1/2' }}
          className="ln-u-soft-top"
        >
          <SingleDateTimePicker
            datePickerLabel="Must be completed by"
            defaultTime="23:30"
            pickerClass="DateTimePicker-colleague"
            selectedDate={selectedDate}
            valueChanged={d => setSelectedDate(d)}
            datePickerProps={{
              orientation: 'horizontal',
              numberOfMonths: 1,
              openDirection: 'up'
            }}
            isOutsideRange={day => day.isBefore(moment().startOf('day'))}
          />
        </GridItem>
        <GridItem size="1/1" className="ln-u-soft-top">
          <FilledButton
            id="createButton"
            fullWidth
            onClick={() => handleSubmit()}
            disabled={
              (evidenceText === '' && evidenceMedia.length === 0) ||
              actionDescription === '' ||
              selectedDate === null
            }
          >
            Create
          </FilledButton>
        </GridItem>
        <GridItem size="1/1" className="ln-u-padding-top">
          <TextButton
            id="cancelButton"
            color="dark"
            fullWidth
            onClick={() => closeModal()}
          >
            Cancel
          </TextButton>
        </GridItem>
      </GridWrapper>
    </Modal>
  )
}

const mapStateToProps = state => ({
  isUploadingImages: state.activities.showUploadingSpinner
})

const mapDispatchToProps = dispatch => ({
  start: activityReference => dispatch(startActivity(activityReference)),
  submitEvidence: (activityReference, eventType, event) =>
    dispatch(submitEventRequest(activityReference, eventType, event)),
  complete: activityReference => dispatch(completeActivity(activityReference))
})

CreateActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  setEvidenceText: PropTypes.func.isRequired,
  setEvidenceMedia: PropTypes.func.isRequired,
  evidenceText: PropTypes.string.isRequired,
  evidenceMedia: PropTypes.array.isRequired,
  setActionDescription: PropTypes.func.isRequired,
  actionDescription: PropTypes.string.isRequired,
  handleActionSubmission: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  activityTitle: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  setAssignments: PropTypes.func.isRequired,
  assignments: PropTypes.array.isRequired,
  attributes: PropTypes.array.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateActionModal)
