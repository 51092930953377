import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  OutlinedButton,
  TextButton
} from '@jsluna/button'
import { Modal, ModalHeading } from '@jsluna/modal'

export const AlreadyCompletedModal = ({ history, activityCompleted }) => {
  const [shouldShowModal, setShouldShowModal] = useState(true)
  return (
    <div>
      <Modal
        fullScreen
        restrictClose
        alert
        preventFocus
        handleClose={() => setShouldShowModal(false)}
        open={activityCompleted && shouldShowModal}
      >
        <ModalHeading element="h3">This survey has been completed</ModalHeading>
        <p>
          Someone has just completed this survey, you can choose to review your
          answers but you wont be able to submit the survey.
        </p>
        <ButtonGroupWrapper actionBar>
          <ButtonGroupPrimary>
            <OutlinedButton onClick={() => setShouldShowModal(false)}>
              Review my answers
            </OutlinedButton>
            <TextButton
              onClick={() => {
                setShouldShowModal(false)
                history.push('/')
              }}
            >
              Leave this survey
            </TextButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Modal>
    </div>
  )
}

AlreadyCompletedModal.propTypes = {
  history: PropTypes.object.isRequired,
  activityCompleted: PropTypes.bool
}

AlreadyCompletedModal.defaultProps = {
  activityCompleted: false
}

export default withRouter(AlreadyCompletedModal)
