import React from 'react'
import PropTypes from 'prop-types'
import { IconFor } from '@jsainsburyplc/activity-icons'
import { Label } from '@jsluna/form'

const Attributes = ({ attributes, ignoredCategories }) => {
  if (!attributes) return null

  const attributesToShow = attributes.filter(
    x => !ignoredCategories.includes(x.category)
  )

  if (!attributesToShow.length) return null

  return (
    <>
      <Label htmlFor="attributes" className="ln-u-margin-top ln-u-flush-bottom">
        Job families
      </Label>
      <div>
        {attributesToShow.map(x => (
          <div key={x.id} className="activity-page-attribute">
            <IconFor value={x.name} /> {x.name}
          </div>
        ))}
      </div>
    </>
  )
}

Attributes.propTypes = {
  attributes: PropTypes.array,
  ignoredCategories: PropTypes.array
}

Attributes.defaultProps = {
  attributes: null,
  ignoredCategories: []
}

export default Attributes
