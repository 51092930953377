import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { SSC_STORE_CODE } from 'utils/constants'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import AssignmentControl from '../Units/AssignmentControl'
import Start from '../Units/Start'
import Complete from '../Units/Complete'
import Undo from '../Units/Undo'
import { isDateAfterUtcNow } from '../../../../utils/datetimeformat'

const isUndoHidden = activity => activity.isCommunication

export const ProcessTodo = ({ activity, history, currentStore }) => {
  if (!activity || currentStore?.code === SSC_STORE_CODE) return null

  const isCompletable = isDateAfterUtcNow(activity.activeFromDate)
  const {
    isCompleted,
    isMissed,
    canBeMarkedInProgress,
    markedInProgressByName
  } = activity

  const showStart =
    !isCompleted &&
    !isMissed &&
    canBeMarkedInProgress &&
    !markedInProgressByName

  const showComplete =
    !isCompleted &&
    !isMissed &&
    (!canBeMarkedInProgress ||
      (canBeMarkedInProgress && markedInProgressByName))

  const showUndo = !isUndoHidden(activity) && isCompleted && !isMissed

  if (!showStart && !showComplete && !showUndo) return null

  return (
    <Card className="ln-u-margin-top">
      <GridWrapper matrix className="activity-page-padder">
        <GridItem size="1/1">
          <h3>Action this activity</h3>
          {(showStart || showComplete) && <AssignmentControl />}
          {showStart && <Start activity={activity} />}
          {showComplete && (
            <Complete
              activity={activity}
              history={history}
              disabled={!isCompletable}
            />
          )}
          {!isCompletable && (
            <p className="ln-u-margin-top*2">
              This activity needs to be completed in the future, so can&apos;t
              currently be completed.
            </p>
          )}
          {showUndo && <Undo activity={activity} />}
        </GridItem>
      </GridWrapper>
    </Card>
  )
}

const mapDispatchToProps = () => ({})

const mapStateToProps = state => ({
  currentStore: state.currentStore
})

ProcessTodo.propTypes = {
  history: PropTypes.object.isRequired,
  activity: PropTypes.object,
  currentStore: PropTypes.object
}

ProcessTodo.defaultProps = {
  activity: null,
  currentStore: null
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessTodo)
