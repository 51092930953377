export const SEARCH_ACTIVITIES_REQUEST = 'SEARCH_ACTIVITIES_REQUEST'
export const SEARCH_ACTIVITIES_SUCCESS = 'SEARCH_ACTIVITIES_SUCCESS'
export const SEARCH_ACTIVITIES_FAILURE = 'SEARCH_ACTIVITIES_FAILURE'
export const SEARCH_ACTIVITIES_UPDATE_PAGE = 'SEARCH_ACTIVITIES_UPDATE_PAGE'

export const searchActivities = (storeCode, pageNumber, searchText) => ({
  type: SEARCH_ACTIVITIES_REQUEST,
  storeCode,
  pageNumber,
  searchText
})

export const searchActivitiesSuccess = results => ({
  type: SEARCH_ACTIVITIES_SUCCESS,
  results
})

export const searchActivitiesFailure = err => ({
  type: SEARCH_ACTIVITIES_FAILURE,
  err
})

export const searchActivitiesUpdatePage = pageNumber => ({
  type: SEARCH_ACTIVITIES_UPDATE_PAGE,
  pageNumber
})
