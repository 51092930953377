import { ASSIGNMENT_ACTION_REMOVE, ASSIGNMENT_ACTION_ADD } from '../constants'

export const findColleagueMissingFromSecondaryArray = (
  primaryArray,
  secondaryArray
) => {
  for (let i = 0; i < primaryArray.length; i += 1) {
    const colleague = primaryArray[i]
    if (!secondaryArray.includes(colleague)) {
      return colleague
    }
  }
  return null
}

export const getActionPerformed = (
  selectedArray,
  existingColleagueAssignments
) => {
  if (selectedArray.length < existingColleagueAssignments.length) {
    return ASSIGNMENT_ACTION_REMOVE
  }
  if (selectedArray.length > existingColleagueAssignments.length) {
    return ASSIGNMENT_ACTION_ADD
  }
  return null
}

export const assignColleagueToActivity = (
  activityReferenceId,
  selectedArray,
  existingColleagueAssignments,
  assignmentAction
) => {
  const colleagueToAdd = findColleagueMissingFromSecondaryArray(
    selectedArray,
    existingColleagueAssignments
  )
  assignmentAction(
    activityReferenceId,
    colleagueToAdd.value,
    colleagueToAdd.label
  )
}

export const removeColleagueFromActivity = (
  activityReferenceId,
  selectedArray,
  existingColleagueAssignments,
  removalAction
) => {
  const colleagueToRemove = findColleagueMissingFromSecondaryArray(
    existingColleagueAssignments,
    selectedArray
  )
  removalAction(activityReferenceId, colleagueToRemove.value)
}

export default getActionPerformed
