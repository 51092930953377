import React from 'react'
import PropTypes from 'prop-types'
import { IconFor } from '@jsainsburyplc/activity-icons'
import textColourMapper from './textColourMapper'

const JobFamilies = ({ attributes }) => {
  if (!attributes || !attributes.length) return null

  if (attributes.length === 1) {
    const attribute = attributes[0].name
    const icon = <IconFor value={attribute} />
    const textColourStyle = textColourMapper(attribute)

    return (
      <div className={`${textColourStyle} ln-u-margin-bottom`}>
        {icon} <strong>{attribute}</strong>
      </div>
    )
  }

  return (
    <div className="ln-u-margin-bottom">
      <span className="activity-card-badge">{attributes.length}</span>
      <span className="activity-card--job-families">
        <strong>Job families</strong>
      </span>
    </div>
  )
}

JobFamilies.propTypes = {
  attributes: PropTypes.array
}

JobFamilies.defaultProps = {
  attributes: null
}

export default JobFamilies
