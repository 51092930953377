import React from 'react'
import PropTypes from 'prop-types'
import { StatusCard } from '@jsluna/card'
import { NotificationItem } from '@jsluna/notification'

const Notification = ({ status, message, handleClose, isOpen }) => (
  <NotificationItem
    id="notification-item"
    notification
    className="c-notification"
    data-tut="reactour__scroll--hidden"
    open={isOpen}
    handleClose={() => handleClose()}
  >
    <StatusCard status={status}>{message}</StatusCard>
  </NotificationItem>
)

Notification.propTypes = {
  status: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
}

export default Notification
