import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getStoreFromStorage } from 'utils/currentStore'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Pagination } from '@jsluna/pagination'
import { toggleNewActionModal, createActionRequest } from 'actions'
import { requestRiskActivities, RiskChangePage } from '../../../actions'
import FailedToFetch from '../ErrorPages/FailedToFetch'
import ActivityCard from '../Shared/ActivityCard/ActivityCard'
import AdhocActionModal from '../ViewActivity/Processes/Audit/AdhocActionModal'
import RiskProgressDisplay from './RiskProgressDisplay'
import {
  ACTIVITY_TYPE_ACTION,
  ACTIVITY_TYPE_AUDIT,
  ACTIVITY_TYPE_TODO
} from '../../../utils/constants'
import SkeletonItem from '../Activities/SkeletonItem'
import ItemHeader from '../Activities/ItemHeader'

export const Risk = ({
  getRiskActivities,
  activities,
  error,
  gettingActivities,
  changePage,
  pageNumber,
  showNewActionModal,
  closeNewActionModal,
  submitAction,
  filters
}) => {
  const [selectedTypes, setSelectedTypes] = useState([])
  useEffect(() => {
    const store = getStoreFromStorage()
    if (store !== null) {
      getRiskActivities(store, pageNumber, selectedTypes)
    }
  }, [pageNumber, selectedTypes, filters])

  const skeletonItems = () => {
    const loopedItems = []
    for (let i = 0; i < 3; i += 1) {
      loopedItems.push(<SkeletonItem key={i} />)
    }
    return loopedItems
  }

  const actions = []
  const tasks = []
  const allActions = []

  const groupActivities = item => {
    const { type, source } = item

    if (
      type === ACTIVITY_TYPE_ACTION ||
      (type === ACTIVITY_TYPE_TODO && source === 'colleague')
    ) {
      allActions.push(item)
      actions.push(item)
      return
    }

    if (type === ACTIVITY_TYPE_AUDIT) {
      tasks.push(item)
    }
  }

  const hasActivities =
    activities &&
    activities.items &&
    activities.items.length > 0 &&
    !gettingActivities

  if (hasActivities) {
    activities.items.map(item => groupActivities(item))
  }

  return (
    <div>
      <GridWrapper matrix className="ln-u-soft-top">
        {!gettingActivities && (
          <GridItem size="1/1">
            <RiskProgressDisplay
              selectedTypes={selectedTypes}
              setSelectedTypes={setSelectedTypes}
              openTasksCount={tasks.length}
              openActionsCount={allActions.length}
            />
          </GridItem>
        )}
        <div>
          {gettingActivities && (
            <div className="ln-u-push-bottom-lg ln-u-margin-left*2">
              <ItemHeader />
              {skeletonItems()}
            </div>
          )}
          {error && <FailedToFetch />}
          {!gettingActivities && !hasActivities && (
            <div className="ln-u-text-align-center no-activities-message">
              <p>No Risk activities</p>
            </div>
          )}
          {!gettingActivities && hasActivities && (
            <div>
              {actions.length > 0 && (
                <div className="ln-u-padding-bottom*2">
                  <GridItem size="1/1">
                    <h3>Actions</h3>
                  </GridItem>
                  {actions.map(item => (
                    <GridItem size="1/1" key={item.id}>
                      <ActivityCard item={item} />
                    </GridItem>
                  ))}
                </div>
              )}
              {tasks.length > 0 && (
                <div className="ln-u-padding-bottom*2">
                  <GridItem size="1/1">
                    <h3>Tasks</h3>
                  </GridItem>
                  {tasks.map(item => (
                    <GridItem size="1/1" key={item.id}>
                      <ActivityCard item={item} />
                    </GridItem>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        {hasActivities && activities.pageCount > 1 && (
          <GridItem size="1/1">
            <Pagination
              fullWidth
              showFirstAndLast
              showPages
              current={pageNumber}
              total={activities.pageCount}
              onChange={(x, e) => {
                e.preventDefault()
                changePage(x)
              }}
            />
          </GridItem>
        )}
        <AdhocActionModal
          isOpen={showNewActionModal}
          closeNewActionModal={closeNewActionModal}
          submitAction={submitAction}
        />
      </GridWrapper>
    </div>
  )
}

Risk.propTypes = {
  getRiskActivities: PropTypes.func.isRequired,
  activities: PropTypes.object,
  error: PropTypes.bool,
  gettingActivities: PropTypes.bool,
  changePage: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  showNewActionModal: PropTypes.bool.isRequired,
  closeNewActionModal: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  filters: PropTypes.array
}

const mapDispatchToProps = dispatch => ({
  getRiskActivities: (store, pageNumber, filters) =>
    dispatch(requestRiskActivities(store.code, pageNumber, filters)),
  changePage: pageNumber => dispatch(RiskChangePage(pageNumber)),
  closeNewActionModal: () => dispatch(toggleNewActionModal(false)),
  submitAction: request => dispatch(createActionRequest(request))
})

const mapStateToProps = state => ({
  filters: state.filter.applied,
  activities: state.activities.activities,
  error: state.activities.error,
  gettingActivities: state.activities.gettingActivities,
  pageNumber: state.activities.riskPageNumber,
  showNewActionModal: state.activities.showNewActionModal
})

Risk.defaultProps = {
  filters: [],
  activities: {},
  error: false,
  gettingActivities: false
}

export default connect(mapStateToProps, mapDispatchToProps)(Risk)
