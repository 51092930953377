import { ATTRIBUTES } from 'utils/constants'

const textColourMapper = attribute => {
  switch (attribute) {
    case ATTRIBUTES.argos:
      return 'activity-card--argos'

    case ATTRIBUTES.beauty:
      return 'activity-card--beauty'

    case ATTRIBUTES.countdownToChristmas:
      return 'activity-card--countdown-to-christmas'

    case ATTRIBUTES.customerExperience:
      return 'activity-card--customer-experience'

    case ATTRIBUTES.event:
      return 'activity-card--event'

    case ATTRIBUTES.food:
      return 'activity-card--food'

    case ATTRIBUTES.foodProduction:
      return 'activity-card--food-production'

    case ATTRIBUTES.foodServices:
      return 'activity-card--food-services'

    case ATTRIBUTES.gmAndClothing:
      return 'activity-card--gm-and-clothing'

    case ATTRIBUTES.groceriesOnline:
      return 'activity-card--groceries-online'

    case ATTRIBUTES.habitat:
      return 'activity-card--habitat'

    case ATTRIBUTES.lineManagers:
      return 'activity-card--line-managers'

    case ATTRIBUTES.nightTwilightShift:
      return 'activity-card--night-twilight-shift'

    case ATTRIBUTES.risk:
      return 'activity-card--risk'

    case ATTRIBUTES.runningTheStore:
      return 'activity-card--running-the-store'

    default:
      return null
  }
}

export default textColourMapper
