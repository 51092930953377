import { AZURE_CURRENTUSER_SET } from '../actions'

const initialState = {
  currentUser: null
}

const azureReducer = (state = initialState, action) => {
  switch (action.type) {
    case AZURE_CURRENTUSER_SET:
      return {
        currentUser: action.username,
        currentUserId: action.azureId,
        currentUserEmail: action.useremail
      }
    default:
      return {
        ...state
      }
  }
}

export default azureReducer
