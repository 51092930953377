import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FilledButton } from '@jsluna/button'
import { useAnalytics } from '@jsainsburyplc/retail-analytics'
import moment from 'moment'
import { undoCompleteActivity } from 'actions'

export const Undo = ({ activity, undoComplete }) => {
  const { sendEvent } = useAnalytics()

  const handleUndoCompleteRequest = () => {
    if (activity.isCompleted) {
      sendEvent('activity_uncomplete', {
        label: activity.title,
        type: activity.type.toLowerCase()
      })
      undoComplete(activity.referenceId)
    }
  }

  const instanceExpired = moment().isAfter(moment(activity.expiryDate))

  return (
    <>
      <FilledButton
        fullWidth
        onClick={() => handleUndoCompleteRequest()}
        disabled={instanceExpired}
      >
        Undo
      </FilledButton>

      {instanceExpired && (
        <div className="ln-u-margin-top">
          This activity has past the date in which it&apos;s status can be
          changed.
        </div>
      )}
    </>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  undoComplete: activityReference =>
    dispatch(undoCompleteActivity(activityReference))
})

Undo.propTypes = {
  activity: PropTypes.object.isRequired,
  undoComplete: PropTypes.func.isRequired
}

Undo.defaultProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Undo)
