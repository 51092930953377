import React from 'react'
import PropTypes from 'prop-types'
import { Calendar } from '@jsluna/icons'
import { formatDateTimeWithYear } from 'utils/datetimeformat'

const DueDate = ({ dueDate }) => {
  if (!dueDate) return null

  return (
    <div>
      <Calendar size="large" />
      <span className="activity-icon-padder">
        Due {formatDateTimeWithYear(dueDate)}
      </span>
    </div>
  )
}

DueDate.propTypes = {
  dueDate: PropTypes.string
}

DueDate.defaultProps = {
  dueDate: null
}

export default DueDate
