import { connect } from 'react-redux'

import actions from 'store/stores/actions'
import StoreSelector from './StoreSelector'

const mapStateToProps = () => ({
  closestStoreLoading: false
})

const mapDispatchToProps = dispatch => ({
  resetStores: () => {
    dispatch({ type: actions.reset })
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(StoreSelector)
