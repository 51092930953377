import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import 'react-dates/initialize'
import { FilledButton, IconButton, OutlinedButton } from '@jsluna/button'
import { DateRangePicker } from '@jsluna/date-picker'
import { CheckboxField, Label } from '@jsluna/form'
import { GridItem, GridWrapper } from '@jsluna/grid'
import { Cancel } from '@jsluna/icons'
import { IconFor } from '@jsainsburyplc/activity-icons'
import { useAnalytics } from '@jsainsburyplc/retail-analytics'
import { FILTER_PAGES, RISK_IGNORED_ATTRIBUTES } from 'utils/constants'
import moment from 'moment'
import { sortBy } from 'lodash'
import {
  requestAttributeCategories,
  filterPanelToggle,
  filterStagingToggle,
  filterApply,
  filterReset,
  addDateFilters
} from '../../../actions'
import { contextOptions, getFilterOptionsByPage, filterTypes } from './options'

const attributeOptions = (attributes, currentSection) => {
  const options = attributes
    .filter(x => x.name !== 'Risk Type')
    .reduce(
      (result, item) =>
        result.concat(
          (currentSection === FILTER_PAGES.risk
            ? item.activityAttributes.filter(
                x => !RISK_IGNORED_ATTRIBUTES.includes(x.name)
              )
            : item.activityAttributes
          ).map(x => ({
            label: x.name,
            value: x.id.toString(),
            icon: <IconFor value={x.name} />
          }))
        ),
      []
    )

  return sortBy(options, 'label')
}

const riskAreaOptions = attributes => {
  const options = attributes
    .filter(x => x.name === 'Risk Type')
    .reduce(
      (result, item) =>
        result.concat(
          item.activityAttributes.map(x => ({
            label: x.name,
            value: x.id.toString(),
            pages: [FILTER_PAGES.risk, FILTER_PAGES.history]
          }))
        ),
      []
    )

  return sortBy(options, 'label')
}

const option = ({
  label,
  value,
  pages,
  filters,
  toggleFilter,
  applyFilter,
  filterType,
  icon = null
}) => ({
  label: icon ? (
    <span>
      {icon} {label}
    </span>
  ) : (
    label
  ),
  value,
  checked: filters.some(item => item.value === value),
  onChange: () => {
    toggleFilter({ filterType, label, value, pages })
    applyFilter()
  }
})

export const FilterPanel = ({
  isMobile,
  isOpen,
  closePanel,
  attributes,
  filters,
  getAttributes,
  toggleFilter,
  applyFilter,
  resetFilter,
  applyDateFilters,
  currentSection,
  storeGroups,
  canAccessRisk
}) => {
  const { sendEvent } = useAnalytics()

  useEffect(() => {
    if (!attributes && storeGroups) {
      getAttributes({ activeOnly: true })
    }
  }, [attributes])

  useEffect(() => {
    if (storeGroups) {
      getAttributes({ activeOnly: true })
    }
  }, [storeGroups])

  useEffect(() => {
    closePanel()
  }, [currentSection])
  const options = getFilterOptionsByPage(
    currentSection && currentSection.toLowerCase(),
    canAccessRisk
  )

  const resolveDateFilter = filter => {
    if (filter.length === 0) return null
    if (filter[0].value === null) return null
    return moment(filter[0].value)
  }

  const isOutsideRange = () => false

  const [focusedInput, setFocusedInput] = useState(null)

  const fromDateFilter = filters.filter(
    x => x.filterType === filterTypes.fromDate
  )
  const toDateFilter = filters.filter(x => x.filterType === filterTypes.toDate)

  const handleToggleFilter = item => {
    const { label, value } = item
    const isChecked = filters.some(x => x.value === value)

    sendEvent(`${isChecked ? 'un' : ''}select_filter`, {
      filter: label
    })

    toggleFilter(item)
  }

  const handleApplyFilter = () => {
    if (!isMobile) {
      applyFilter()
    }
  }

  return (
    <div
      className={
        isOpen
          ? `filter-panel is-open ${currentSection}-filter`
          : 'filter-panel'
      }
    >
      <GridWrapper className="ln-u-bg-color-white ln-u-soft-sides">
        <GridItem size="1/1" className="ln-u-push-top">
          {isMobile && (
            <div className="ln-u-text-align-right">
              <IconButton
                className="ln-u-color-grey-dark"
                variant="text"
                label="Close"
                onClick={() => {
                  applyFilter()
                  closePanel()
                }}
              >
                <Cancel />
              </IconButton>
            </div>
          )}
          {currentSection === FILTER_PAGES.search && (
            <CheckboxField
              label=""
              name="filter-by-context"
              fullWidth
              options={contextOptions.map(x =>
                option({
                  ...x,
                  filters,
                  toggleFilter: handleToggleFilter,
                  applyFilter: handleApplyFilter,
                  filterType: filterTypes.context
                })
              )}
            />
          )}
          {currentSection === FILTER_PAGES.history && (
            <div>
              <Label htmlFor="due-date">Due Date</Label>
              <div className="ln-u-padding-ends*2">
                <DateRangePicker
                  startDate={resolveDateFilter(fromDateFilter)}
                  appendToBody
                  orientation={isMobile ? 'vertical' : 'horizontal'}
                  displayFormat="DD/MM/YYYY"
                  isOutsideRange={isOutsideRange}
                  startDateId="fromDate"
                  endDate={resolveDateFilter(toDateFilter)}
                  endDateId="toDate"
                  onDatesChange={({ startDate, endDate }) => {
                    applyDateFilters(
                      startDate &&
                        startDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                      endDate &&
                        endDate.endOf('day').format('YYYY-MM-DDTHH:mm:ss')
                    )
                    handleApplyFilter()
                  }}
                  numberOfMonths={2}
                  focusedInput={focusedInput}
                  onFocusChange={input => setFocusedInput(input)}
                  readOnly
                />
              </div>
            </div>
          )}
          {options.types.length > 0 && (
            <CheckboxField
              label="Type"
              name="filter-by-type"
              fullWidth
              options={options.types.map(x =>
                option({
                  ...x,
                  filters,
                  toggleFilter: handleToggleFilter,
                  applyFilter: handleApplyFilter,
                  filterType: filterTypes.type
                })
              )}
            />
          )}
          {attributes &&
            attributeOptions(attributes, currentSection).length > 0 && (
              <CheckboxField
                label="Filter by"
                name="filter-by-attribute"
                fullWidth
                options={attributeOptions(attributes, currentSection).map(x =>
                  option({
                    ...x,
                    filters,
                    toggleFilter: handleToggleFilter,
                    applyFilter: handleApplyFilter,
                    filterType: filterTypes.attribute
                  })
                )}
              />
            )}
          {attributes &&
            attributes.length > 0 &&
            riskAreaOptions(attributes).length > 0 &&
            (currentSection === FILTER_PAGES.risk ||
              currentSection === FILTER_PAGES.history) && (
              <CheckboxField
                label="Risk area"
                name="filter-by-risk-area"
                fullWidth
                options={riskAreaOptions(attributes).map(x =>
                  option({
                    ...x,
                    filters,
                    toggleFilter: handleToggleFilter,
                    applyFilter: handleApplyFilter,
                    filterType: filterTypes.attribute
                  })
                )}
              />
            )}
          {options.statuses.length > 0 && (
            <CheckboxField
              label="Status"
              name="filter-by-status"
              fullWidth
              options={options.statuses.map(x =>
                option({
                  ...x,
                  filters,
                  toggleFilter: handleToggleFilter,
                  applyFilter: handleApplyFilter,
                  filterType: filterTypes.status
                })
              )}
            />
          )}
          {isMobile && (
            <FilledButton
              disabled={!filters.length}
              fullWidth
              onClick={() => {
                applyFilter()
                closePanel()
              }}
            >
              Apply
            </FilledButton>
          )}
          <OutlinedButton
            fullWidth
            className="ln-u-push-ends"
            onClick={() => resetFilter()}
          >
            Clear
          </OutlinedButton>
        </GridItem>
      </GridWrapper>
    </div>
  )
}

const mapStateToProps = state => ({
  isMobile: state.activities.isMobile,
  isOpen: !state.activities.isMobile || state.filter.isPanelOpen,
  attributes: state.attributes.attributeCategories,
  filters: state.filter.staging,
  currentSection: state.activities.currentSection,
  storeGroups: state.storeGroups.groups,
  canAccessRisk: state.audience.canAccessRisk
})

const mapDispatchToProps = dispatch => ({
  closePanel: () => {
    dispatch(filterPanelToggle({ isOpen: false }))
  },
  getAttributes: ({ activeOnly }) => {
    dispatch(requestAttributeCategories(activeOnly))
  },
  toggleFilter: item => {
    dispatch(filterStagingToggle(item))
  },
  applyFilter: () => {
    dispatch(filterApply())
  },
  resetFilter: () => {
    dispatch(filterReset())
  },
  applyDateFilters: (fromDate, toDate) =>
    dispatch(addDateFilters(fromDate, toDate))
})

FilterPanel.propTypes = {
  isMobile: PropTypes.bool,
  isOpen: PropTypes.bool,
  attributes: PropTypes.array,
  filters: PropTypes.array,
  closePanel: PropTypes.func.isRequired,
  getAttributes: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  applyDateFilters: PropTypes.func.isRequired,
  currentSection: PropTypes.string.isRequired,
  storeGroups: PropTypes.array,
  canAccessRisk: PropTypes.bool
}

FilterPanel.defaultProps = {
  isMobile: false,
  isOpen: false,
  attributes: [],
  filters: [],
  storeGroups: null,
  canAccessRisk: false
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterPanel)
