import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { RISK_TRIAL_GROUP_LIST } from '../../../../utils/constants'
import { getFilters } from '../../../../utils/browserStorage'
import NothingToSeeWithFilters from './NothingToSeeWithFilters'
import NothingToSeeWithNoFilters from './NothingToSeeWithNoFilters'

const NothingToSee = ({ canAccessRisk, storeGroups }) => {
  const hasRiskPage =
    canAccessRisk &&
    storeGroups &&
    storeGroups.some(group => RISK_TRIAL_GROUP_LIST.includes(group))

  const filters = getFilters()

  if (filters && filters.length > 0) return <NothingToSeeWithFilters />
  return <NothingToSeeWithNoFilters hasRiskPage={hasRiskPage} />
}

const mapStateToProps = state => ({
  canAccessRisk: state.audience.canAccessRisk,
  storeGroups: state.storeGroups.groups
})

NothingToSee.propTypes = {
  canAccessRisk: PropTypes.bool,
  storeGroups: PropTypes.array
}

NothingToSee.defaultProps = {
  canAccessRisk: false,
  storeGroups: null
}

export default connect(mapStateToProps, null)(NothingToSee)
