import { isObject } from 'lodash'
import { v4 } from 'uuid'

export default path => {
  window.location = path
}

export const handleResponse = response => {
  if (isObject(response)) {
    try {
      return response.json().then(result => {
        if (response.ok) {
          return Promise.resolve(result)
        }
        return Promise.reject(result)
      })
    } catch (e) {
      return Promise.reject(
        new Error({
          type: 'InvalidJSON',
          status: response.status,
          body: response.text()
        })
      )
    }
  }
  return Promise.reject(response)
}

export const handleMediaResponse = async (response, media) => ({
  fileName: media.file.name,
  type: media.type,
  description: media.description,
  url: await handleResponse(response)
})

export const getFileExt = FileRef =>
  FileRef.substring(FileRef.lastIndexOf('.') + 1, FileRef.length) || FileRef

export const createForm = (filename, media) => {
  const form = new FormData()
  form.append(`${filename}`, media.file)
  return form
}

export const generateUniqueStoreFileName = storeCode => `${v4()}_${storeCode}`

export const generateActivitiesFilterModelParams = filterModel => {
  if (!filterModel || !filterModel.length) return null

  let queryParams = ''

  filterModel.forEach(filter => {
    queryParams += `&${filter.filterType}=${filter.value}`
  })

  return `?${queryParams.substring(1)}`
}

const appendFiles = (formData, responses, storeCode) => {
  for (let i = 0; i < responses.length; i += 1) {
    const response = responses[i]

    response.value.forEach((x, ii) => {
      const { file } = response.value[ii]
      formData.append(`${storeCode}_${response.questionId}_${ii + 1}`, file)
    })
  }
  return formData
}

export const parseResponseFormData = (responses, storeCode) => {
  let formData = new FormData()
  formData = appendFiles(formData, responses, storeCode)
  return formData
}

export const getResponse = response => {
  if (response === null || response === undefined || response.length === 0)
    return null

  return JSON.parse(response)
}
