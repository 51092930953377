import { combineReducers } from 'redux'
import stores from 'store/stores/reducer'

import currentStore from 'store/current-store/reducer'

import activitiesReducer from './activitiesReducer'
import audienceReducer from './audienceReducer'
import colleagueLayoutReducer from './colleagueLayoutReducer'
import notificationReducer from './notificationReducer'
import connectivityReducer from './connectivityReducer'
import azureReducer from './azureReducer'
import searchReducer from './searchReducer'
import financialCalendarReducer from './financialCalendarReducer'
import reportReducer from './reportReducer'
import filterReducer from './filterReducer'
import attributesReducer from './attributesReducer'
import storeGroupsReducer from './storeGroupsReducer'
import authenticationReducer from './authenticationReducer'

const activityManager = combineReducers({
  activities: activitiesReducer,
  audience: audienceReducer,
  currentStore,
  stores,
  colleagueLayout: colleagueLayoutReducer,
  notifications: notificationReducer,
  connection: connectivityReducer,
  azure: azureReducer,
  search: searchReducer,
  financialCalendar: financialCalendarReducer,
  report: reportReducer,
  filter: filterReducer,
  attributes: attributesReducer,
  storeGroups: storeGroupsReducer,
  authentication: authenticationReducer
})

const rootReducer = (state, action) => activityManager(state, action)

export default rootReducer
