import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { GridItem, GridWrapper } from '@jsluna/grid'
import FilterPanel from './FilterPanel'
import FilterHeader from './FilterHeader'

export const FilterLayout = ({
  header,
  children,
  isPanelOpen,
  page,
  isMobile
}) => (
  <GridWrapper matrix>
    <GridItem
      size={{ md: '1/4' }}
      style={{
        paddingBottom: page === 'planning' && '0rem',
        display: page === 'planning' && !isPanelOpen ? 'none' : 'inline-block'
      }}
    >
      <FilterPanel page={page} />
    </GridItem>
    <GridItem size={isPanelOpen ? '3/4' : '1/1'}>
      <div
        className={`filter-content ${
          page !== 'planning' ? 'filter-top-margin' : ''
        } ${isMobile && page !== 'planning' ? 'ln-u-margin-sides' : ''} ${
          isPanelOpen ? 'panel-is-open' : ''
        }`}
        style={{
          marginLeft: page !== 'planning' && !isMobile && !isPanelOpen && '20%',
          marginRight: page !== 'planning' && !isMobile && '20%'
        }}
      >
        {page !== 'planning' && <FilterHeader heading={header} />}
        {children}
      </div>
    </GridItem>
  </GridWrapper>
)

const mapStateToProps = state => ({
  isPanelOpen: !state.activities.isMobile || state.filter.isPanelOpen,
  page: state.activities.currentSection,
  isMobile: state.activities.isMobile
})

FilterLayout.propTypes = {
  header: PropTypes.any,
  children: PropTypes.any,
  isPanelOpen: PropTypes.bool,
  page: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired
}

FilterLayout.defaultProps = {
  header: null,
  children: null,
  isPanelOpen: false
}

export default connect(mapStateToProps, null)(FilterLayout)
