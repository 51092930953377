import { cloneDeep } from 'lodash'
import * as Constants from 'utils/constants'
import moment from 'moment'
import {
  ACTIVITIES_FAILURE,
  ACTIVITIES_REQUEST,
  ACTIVITIES_SUCCESS,
  ACTIVITY_REQUEST,
  ACTIVITY_SUCCESS,
  ACTIVITY_FAILURE,
  ACTIVITY_COMPLETE_REQUEST,
  ACTIVITY_COMPLETE_SUCCESS,
  ACTIVITY_COMPLETE_FAILURE,
  ACTIVITY_UNDOCOMPLETE_REQUEST,
  ACTIVITY_UNDOCOMPLETE_SUCCESS,
  ACTIVITY_UNDOCOMPLETE_FAILURE,
  ACTIVITY_START_REQUEST,
  ACTIVITY_START_FAILURE,
  ACTIVITY_START_SUCCESS,
  SET_IS_MOBILE,
  ACTIVITY_SUBMITFEEDBACK_SUCCESS,
  ACTIVITY_SUBMITFEEDBACK_REQUEST,
  ACTIVITY_SUBMITFEEDBACK_FAILURE,
  ACTIVITY_DELETEFEEDBACK_SUCCESS,
  ACTIVITY_DELETEFEEDBACK_REQUEST,
  ACTIVITY_DELETEFEEDBACK_FAILURE,
  ASSIGN_ACTIVITY_REQUEST,
  ASSIGN_ACTIVITY_SUCCESS,
  ASSIGN_ACTIVITY_FAILURE,
  FETCH_ASSIGNMENT_EVENTS_PAGE_REQUEST,
  FETCH_ASSIGNMENT_EVENTS_PAGE_FAILURE,
  FETCH_ASSIGNMENT_EVENTS_PAGE_SUCCESS,
  FETCH_COLLEAGUE_ASSIGNMENTS_REQUEST,
  FETCH_COLLEAGUE_ASSIGNMENTS_SUCCESS,
  FETCH_COLLEAGUE_ASSIGNMENTS_FAILURE,
  ACTIVITIES_FOR_WEEK_REQUEST,
  ACTIVITIES_FOR_WEEK_SUCCESS,
  ACTIVITIES_FOR_WEEK_FAILURE,
  ACTIVITIES_FOR_PERIOD_REQUEST,
  ACTIVITIES_FOR_PERIOD_SUCCESS,
  ACTIVITIES_FOR_PERIOD_FAILURE,
  NAVIGATE_NEXT_WEEK,
  NAVIGATE_PREV_WEEK,
  CHANGE_PLANNING_VIEW,
  SELECT_CALENDAR_DAY,
  UPDATE_ACTIVITY_QUESTION,
  ACTIVITY_QUESTIONS_REQUEST,
  ACTIVITY_QUESTIONS_SUCCESS,
  ACTIVITY_QUESTIONS_FAILURE,
  ACTIVITY_QUESTIONS_COMPLETE_REQUEST,
  ACTIVITY_QUESTIONS_COMPLETE_SUCCESS,
  ACTIVITY_QUESTIONS_COMPLETE_FAILURE,
  TOGGLE_COMPLETED_SURVEY_POPUP,
  CURRENT_ACTIVITY_HAS_BEEN_COMPLETED,
  ACTIVITY_QUESTIONS_FILE_UPLOAD_REQUEST,
  ACTIVITY_QUESTIONS_FILE_UPLOAD_SUCCESS,
  ACTIVITY_QUESTIONS_FILE_UPLOAD_FAILURE,
  SHOW_UPLOADING_SURVEY_SPINNER,
  SHOW_RETRYING_SURVEY_SPINNER,
  TOGGLE_IMAGECONFIRMATION_POPUP,
  TOGGLE_FILTER_MODAL,
  UPDATED_SELECTED_ATTRIBUTES,
  UPDATED_SELECTED_ATTRIBUTES_SUCCESS,
  UPDATED_SELECTED_ATTRIBUTES_FAILURE,
  REQUEST_ACTIVITY_MEDIA,
  REQUEST_ACTIVITY_MEDIA_SUCCESS,
  REQUEST_ACTIVITY_MEDIA_FAILURE,
  SUBMIT_EVENT_DATA_SUCCESS,
  SUBMIT_EVENT_DATA_FAILURE,
  SUBMIT_EVENT_DATA_REQUEST,
  SUBMIT_EVENT_DATA_THEN_COMPLETE_REQUEST,
  CHANGE_INSTANCE_DUE_DATE_REQUEST,
  CHANGE_INSTANCE_DUE_DATE_SUCCESS,
  CHANGE_INSTANCE_DUE_DATE_FAILURE,
  TOGGLE_CANT_COMPLETE_MODAL,
  FETCH_AUDIT_EVENTS,
  FETCH_AUDIT_EVENTS_SUCCESS,
  FETCH_AUDIT_EVENTS_FAILURE,
  GET_STORE_RISK_COMPLIANCE_REQUEST,
  GET_STORE_RISK_COMPLIANCE_SUCCESS,
  GET_STORE_RISK_COMPLIANCE_FAILURE,
  TOGGLE_NEW_ACTION_MODAL,
  CREATE_ACTION_REQUEST,
  CREATE_ACTION_SUCCESS,
  CREATE_ACTION_FAILURE,
  SEARCH_ACTIVITIES_REQUEST,
  ACTIVITIES_HISTORY_REQUEST,
  ACTIVITIES_HISTORY_SUCCESS,
  ACTIVITIES_HISTORY_FAILURE,
  RISK_ACTIVITIES_REQUEST,
  RISK_ACTIVITIES_SUCCESS,
  RISK_ACTIVITIES_FAILURE,
  RISK_CHANGE_PAGE,
  TOGGLE_ALL_CALENDAR_ITEMS,
  SET_ACTIVITY_MARKASREAD_REQUEST,
  SET_ACTIVITY_MARKASREAD_SUCCESS,
  SET_ACTIVITY_MARKASREAD_FAILURE,
  SET_CHECKLIST_REQUEST,
  SET_CHECKLIST_SUCCESS,
  SET_CHECKLIST_FAILURE
} from '../actions'
import currentStoreActions from '../store/current-store/actions'

const setIsHelpful = (state, isHelpful) => {
  const newState = cloneDeep(state)

  newState.currentActivity.isHelpful = isHelpful

  return newState
}

const setIsRead = (state, readOn, isRead) => {
  const newState = cloneDeep(state)
  newState.currentActivity.isRead = isRead
  newState.currentActivity.readOn = readOn
  return newState
}

const initialState = {
  weekInView: moment().startOf('week'),
  periodInView: moment(),
  isMobile: false,
  planningView: Constants.PLANNING_WEEK_VIEW,
  currentActivity: null,
  currentActivityQuestions: {
    isValid: false,
    questions: []
  },
  currentSection: null,
  isFetchingColleagueAssignments: false,
  isFetchingCurrentActivityQuestions: false,
  showCompletedActivityModal: false,
  currentActivityFileUploadResponse: [],
  showUploadingSpinner: false,
  showRetryingSpinner: false,
  showUpdatingSpinner: false,
  completingActivity: false,
  showImageConfirmationModal: false,
  showCantCompleteActivityModal: false,
  showFilterModal: false,
  selectedAttributes: [],
  filteredActivities: null,
  periodActivities: {},
  weelActivities: {},
  completeActivityError: false,
  isFetching: false,
  storeRiskCompliance: { complete: 0, total: 0 },
  showNewActionModal: false,
  selectedCalendarDay: moment(),
  riskPageNumber: 1,
  showAllCalendarItems: false
}

const setSubmitChecklist = (state, selectedItems) => {
  const newState = cloneDeep(state)
  newState.currentActivity.response = selectedItems
  return newState
}

const activities = (state = initialState, action) => {
  switch (action.type) {
    case currentStoreActions.SET_CURRENT_STORE:
      return {
        ...initialState,
        isMobile: state.isMobile
      }
    case ACTIVITY_START_REQUEST:
      return {
        ...state,
        requestingStartActivity: true,
        error: false
      }
    case ACTIVITY_START_FAILURE:
      return {
        ...state,
        requestingStartActivity: false,
        error: true
      }
    case ACTIVITY_START_SUCCESS:
      return {
        ...state,
        requestingStartActivity: false
      }
    case ACTIVITY_REQUEST:
      return {
        ...state,
        currentActivity:
          state.currentActivity === null ||
          state.currentActivity.referenceId !== action.activityId
            ? null
            : state.currentActivity,
        gettingActivity: true,
        fetchActivityError: false,
        activityDeletedReferenceId: ''
      }
    case ACTIVITY_SUCCESS:
      return {
        ...state,
        gettingActivity: false,
        currentActivity: {
          ...state.currentActivity,
          ...action.activity
        }
      }
    case ACTIVITY_FAILURE:
      return {
        ...state,
        gettingActivity: false,
        fetchActivityError: true
      }
    case REQUEST_ACTIVITY_MEDIA:
      return {
        ...state,
        currentActivity: {
          ...state.currentActivity,
          isFetchingMedia: true,
          failedToFetchMedia: false
        }
      }
    case REQUEST_ACTIVITY_MEDIA_SUCCESS:
      return {
        ...state,
        currentActivity: {
          ...state.currentActivity,
          isFetchingMedia: false,
          failedToFetchMedia: false,
          mediaAttachments: action.media
        }
      }
    case REQUEST_ACTIVITY_MEDIA_FAILURE:
      return {
        ...state,
        currentActivity: {
          ...state.currentActivity,
          isFetchingMedia: false,
          failedToFetchMedia: true
        }
      }
    case ACTIVITY_QUESTIONS_REQUEST:
      return {
        ...state,
        isFetchingCurrentActivityQuestions: true,
        currentActivityQuestions: initialState.currentActivityQuestions
      }
    case ACTIVITY_QUESTIONS_SUCCESS:
      return {
        ...state,
        isFetchingCurrentActivityQuestions: false,
        currentActivityQuestions: {
          isValid: false,
          questions: action.questions
        }
      }
    case ACTIVITY_QUESTIONS_FAILURE:
      return {
        ...state,
        fetchActivityError: true
      }
    case ACTIVITY_COMPLETE_REQUEST:
      return {
        ...state,
        completingActivity: true,
        error: false
      }
    case ACTIVITY_COMPLETE_SUCCESS:
      return {
        ...state,
        completingActivity: false
      }
    case ACTIVITY_COMPLETE_FAILURE:
      return {
        ...state,
        completingActivity: false,
        error: true
      }
    case ACTIVITY_QUESTIONS_COMPLETE_REQUEST:
      return {
        ...state,
        completingActivity: true,
        completeActivityQuestionsError: false
      }
    case ACTIVITY_QUESTIONS_COMPLETE_SUCCESS:
      return {
        ...state,
        completingActivity: false
      }
    case ACTIVITY_QUESTIONS_COMPLETE_FAILURE:
      return {
        ...state,
        completingActivity: false,
        completeActivityQuestionsError: true
      }
    case ACTIVITY_SUBMITFEEDBACK_REQUEST:
      return {
        ...state,
        submittingFeedback: true
      }
    case ACTIVITY_SUBMITFEEDBACK_SUCCESS: {
      const newState = setIsHelpful(state, action.isHelpful)
      return {
        ...newState,
        submittingFeedback: false
      }
    }
    case ACTIVITY_SUBMITFEEDBACK_FAILURE:
      return {
        ...state,
        submittingFeedback: false,
        submitFeedbackError: true
      }
    case ACTIVITY_DELETEFEEDBACK_REQUEST:
      return {
        ...state,
        submittingFeedback: true
      }
    case ACTIVITY_DELETEFEEDBACK_SUCCESS: {
      const newState = setIsHelpful(state, null)
      return {
        ...newState,
        submittingFeedback: false
      }
    }
    case ACTIVITY_DELETEFEEDBACK_FAILURE:
      return {
        ...state,
        submittingFeedback: false
      }
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.isMobile
      }
    case ASSIGN_ACTIVITY_REQUEST: {
      return {
        ...state,
        assigningActivity: true,
        assigningActivityError: false
      }
    }
    case ASSIGN_ACTIVITY_SUCCESS: {
      return {
        ...state,
        assigningActivity: false
      }
    }
    case ASSIGN_ACTIVITY_FAILURE: {
      return {
        ...state,
        assigningActivity: false,
        assigningActivityError: true
      }
    }
    case FETCH_ASSIGNMENT_EVENTS_PAGE_REQUEST: {
      return {
        ...state,
        fetchingAssignmentEvents: true,
        fetchingAssignmentEventsError: undefined
      }
    }
    case FETCH_ASSIGNMENT_EVENTS_PAGE_FAILURE: {
      return {
        ...state,
        fetchingAssignmentEvents: false,
        fetchingAssignmentEventsError: action.error
      }
    }
    case FETCH_ASSIGNMENT_EVENTS_PAGE_SUCCESS: {
      return {
        ...state,
        fetchingAssignmentEvents: false,
        assignmentEventsPage: action.assignmentEventsPage
      }
    }
    case SELECT_CALENDAR_DAY:
      return {
        ...state,
        selectedCalendarDay: action.day,
        weekInView: action.day.date.clone().startOf('week'),
        periodInView: action.day.date.clone(),
        calendarDayShouldUpdate: false
      }
    case FETCH_COLLEAGUE_ASSIGNMENTS_REQUEST:
      return {
        ...state,
        isFetchingColleagueAssignments: true
      }
    case FETCH_COLLEAGUE_ASSIGNMENTS_SUCCESS: {
      return {
        ...state,
        isFetchingColleagueAssignments: false,
        currentActivity: {
          ...state.currentActivity,
          colleagueAssignments: action.colleagueAssignments
        }
      }
    }
    case CURRENT_ACTIVITY_HAS_BEEN_COMPLETED: {
      return {
        ...state,
        currentActivity: {
          ...state.currentActivity,
          hasBeenCompletedWhileViewing: true
        }
      }
    }
    case FETCH_COLLEAGUE_ASSIGNMENTS_FAILURE:
      return {
        ...state,
        isFetchingColleagueAssignments: false,
        error: true
      }
    case TOGGLE_COMPLETED_SURVEY_POPUP:
      return {
        ...state,
        showCompletedActivityModal: action.showCompletedActivityModal
      }
    case UPDATE_ACTIVITY_QUESTION:
      return {
        ...state,
        currentActivityQuestions: {
          ...state.currentActivityQuestions,
          isValid: state.currentActivityQuestions.questions.every(x =>
            x.isValid === null ? true : x.isValid
          )
        }
      }
    case ACTIVITY_QUESTIONS_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        error: false
      }
    case ACTIVITY_QUESTIONS_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        currentActivityFileUploadResponse: [
          ...state.currentActivityFileUploadResponse,
          action.fileResponse
        ]
      }
    case ACTIVITY_QUESTIONS_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        error: true
      }
    case SHOW_UPLOADING_SURVEY_SPINNER:
      return {
        ...state,
        showUploadingSpinner: action.showUploadingSpinner
      }
    case SHOW_RETRYING_SURVEY_SPINNER:
      return {
        ...state,
        showRetryingSpinner: action.showRetryingSpinner,
        showUploadingSpinner: false
      }
    case TOGGLE_IMAGECONFIRMATION_POPUP:
      return {
        ...state,
        showImageConfirmationModal: action.showImageConfirmationModal
      }
    case TOGGLE_FILTER_MODAL:
      return {
        ...state,
        showFilterModal: action.showFilterModal
      }
    case UPDATED_SELECTED_ATTRIBUTES:
      return {
        ...state,
        selectedAttributes: action.selectedAttributes,
        isFetching: true,
        error: false
      }
    case UPDATED_SELECTED_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activities: action.filteredActivities,
        periodActivities: action.filteredPeriodActivities,
        weekActivities: action.filteredWeekActivities
      }
    case UPDATED_SELECTED_ATTRIBUTES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true
      }
    case SUBMIT_EVENT_DATA_REQUEST:
    case SUBMIT_EVENT_DATA_THEN_COMPLETE_REQUEST:
      return {
        ...state,
        error: false,
        showUploadingSpinner: true
      }
    case SUBMIT_EVENT_DATA_SUCCESS:
      return {
        ...state,
        error: false,
        showUploadingSpinner: false
      }
    case SUBMIT_EVENT_DATA_FAILURE:
      return {
        ...state,
        showUploadingSpinner: false,
        error: true
      }
    case CHANGE_INSTANCE_DUE_DATE_REQUEST:
      return {
        ...state,
        showUpdatingSpinner: true,
        error: false
      }
    case CHANGE_INSTANCE_DUE_DATE_SUCCESS:
      return {
        ...state,
        showUpdatingSpinner: false,
        showCantCompleteActivityModal: false,
        error: false
      }
    case CHANGE_INSTANCE_DUE_DATE_FAILURE:
      return {
        ...state,
        showUpdatingSpinner: false,
        error: true
      }
    case TOGGLE_CANT_COMPLETE_MODAL:
      return {
        ...state,
        showCantCompleteActivityModal: action.toggleCantComplete
      }
    case FETCH_AUDIT_EVENTS:
      return {
        ...state,
        isFetching: true,
        error: false
      }
    case FETCH_AUDIT_EVENTS_SUCCESS:
      return {
        ...state,
        audit: action.audit,
        isFetching: false,
        error: false
      }
    case FETCH_AUDIT_EVENTS_FAILURE:
      return {
        ...state,
        error: true
      }
    case GET_STORE_RISK_COMPLIANCE_REQUEST:
      return {
        ...state,
        error: false
      }
    case GET_STORE_RISK_COMPLIANCE_SUCCESS:
      return {
        ...state,
        storeRiskCompliance: action.storeRiskCompliance,
        error: false
      }
    case GET_STORE_RISK_COMPLIANCE_FAILURE:
      return {
        ...state,
        error: true
      }
    case TOGGLE_NEW_ACTION_MODAL:
      return {
        ...state,
        showNewActionModal: action.showNewActionModal
      }
    case CREATE_ACTION_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case CREATE_ACTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: false
      }
    case CREATE_ACTION_FAILURE:
      return {
        ...state,
        error: true
      }
    case ACTIVITIES_HISTORY_REQUEST:
      return {
        ...state,
        gettingActivities: true,
        activitiesReceived: false,
        activities:
          state.currentSection === Constants.FILTER_PAGES.history
            ? state.activities
            : {},
        currentSection: action.section,
        currentActivity: null,
        error: false,
        activityDeletedReferenceId: ''
      }
    case ACTIVITIES_HISTORY_SUCCESS:
      return {
        ...state,
        gettingActivities: false,
        activitiesReceived: true,
        activities: action.activities
      }
    case ACTIVITIES_HISTORY_FAILURE:
      return {
        ...state,
        gettingActivities: false,
        activitiesReceived: false,
        error: true,
        activities: {}
      }
    case ACTIVITIES_FOR_WEEK_REQUEST: {
      return {
        ...state,
        currentSection: action.section,
        weekInView: moment(action.weekDate),
        error: false,
        isFetching: true,
        currentActivity: null,
        activityDeletedReferenceId: '',
        weekActivities:
          action.section === state.currentSection ? state.weekActivities : {}
      }
    }
    case ACTIVITIES_FOR_WEEK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        weekActivities: action.activities,
        calendarDayShouldUpdate: true
      }
    case ACTIVITIES_FOR_WEEK_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        weekActivities: {}
      }
    case ACTIVITIES_FOR_PERIOD_REQUEST:
      return {
        ...state,
        currentSection: action.section,
        periodInView: moment(action.periodDate),
        error: false,
        isFetching: true,
        activityDeletedReferenceId: '',
        currentActivity: null,
        weekActivities:
          action.section === state.currentSection ? state.periodActivities : {}
      }
    case ACTIVITIES_FOR_PERIOD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        periodActivities: action.activities,
        calendarDayShouldUpdate: true
      }
    case ACTIVITIES_FOR_PERIOD_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        periodActivities: {}
      }
    case ACTIVITIES_REQUEST:
      return {
        ...state,
        currentSection: action.section,
        error: false,
        isFetching: true,
        currentActivity: null,
        activityDeletedReferenceId: '',
        currentActivityFileUploadResponse: [],
        activities:
          action.section === state.currentSection ? state.activities : {}
      }
    case ACTIVITIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activities: action.activities
      }
    case ACTIVITIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true,
        activities: {}
      }
    case CHANGE_PLANNING_VIEW:
      return {
        ...state,
        planningView: action.planningView
      }
    case NAVIGATE_NEXT_WEEK:
      return {
        ...state,
        weekInView: action.weekInView,
        isFetching: true
      }
    case NAVIGATE_PREV_WEEK:
      return {
        ...state,
        weekInView: action.weekInView,
        isFetching: true
      }
    case RISK_ACTIVITIES_REQUEST:
      return {
        ...state,
        gettingActivities: true,
        activitiesReceived: false,
        activities:
          state.currentSection === Constants.FILTER_PAGES.risk
            ? state.activities
            : {},
        currentSection: action.section,
        currentActivity: null,
        error: false
      }
    case RISK_ACTIVITIES_SUCCESS:
      return {
        ...state,
        gettingActivities: false,
        activitiesReceived: true,
        activities: action.activities
      }
    case RISK_ACTIVITIES_FAILURE:
      return {
        ...state,
        gettingActivities: false,
        activitiesReceived: false,
        error: true,
        activities: {}
      }
    case ACTIVITY_UNDOCOMPLETE_REQUEST:
      return {
        ...state,
        uncompletingActivity: true,
        error: false
      }
    case ACTIVITY_UNDOCOMPLETE_SUCCESS:
      return {
        ...state,
        uncompletingActivity: false
      }
    case ACTIVITY_UNDOCOMPLETE_FAILURE:
      return {
        ...state,
        uncompletingActivity: false,
        error: true
      }
    case SEARCH_ACTIVITIES_REQUEST:
      return {
        ...state,
        currentSection: 'search'
      }
    case RISK_CHANGE_PAGE:
      return {
        ...state,
        riskPageNumber: action.pageNumber
      }
    case TOGGLE_ALL_CALENDAR_ITEMS:
      return {
        ...state,
        showAllCalendarItems: action.showAll
      }
    case SET_ACTIVITY_MARKASREAD_REQUEST:
      return {
        ...state,
        submittingMarkAsRead: true
      }

    case SET_ACTIVITY_MARKASREAD_SUCCESS: {
      const newState = setIsRead(state, action.readOn, action.isRead)
      return {
        ...newState,
        submittingMarkAsRead: false
      }
    }
    case SET_ACTIVITY_MARKASREAD_FAILURE:
      return {
        ...state,
        submittingMarkAsRead: false,
        submitMarkAsReadError: true
      }
    case SET_CHECKLIST_REQUEST:
      return {
        ...state,
        submittingChecklist: true,
        failedToSubmitChecklist: false
      }

    case SET_CHECKLIST_SUCCESS: {
      const newState = setSubmitChecklist(state, action.response)
      return {
        ...newState,
        submittingChecklist: false
      }
    }
    case SET_CHECKLIST_FAILURE:
      return {
        ...state,
        submittingChecklist: false,
        failedToSubmitChecklist: true
      }
    default:
      return state
  }
}

export default activities
