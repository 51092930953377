import React from 'react'
import CurrentDayHeader from '../CurrentDayHeader'
import FilterLayout from '../Filtering/FilterLayout'
import Activities from './Activities'

const ActivitiesWithFilters = props => (
  <div>
    <FilterLayout header={<CurrentDayHeader />}>
      <Activities {...props} />
    </FilterLayout>
  </div>
)

export default ActivitiesWithFilters
