import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Container } from '@jsluna/grid'
import { Modal, ModalHeading } from '@jsluna/modal'
import { withRouter } from 'react-router-dom'
import { Tick } from '@jsluna/icons'
import { toggleCompletedModalPopup } from 'actions'

export const CompletedSurveyModal = ({
  history,
  showCompletedModal,
  dismissCompletedModalPopup,
  feedbackMessage
}) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Container soft="xs" size="xs" className="blocktext">
      <Modal
        small
        handleClose={() => {
          history.push('/')
          dismissCompletedModalPopup()
        }}
        open={showCompletedModal}
        preventFocus
      >
        <ModalHeading element="h1" className="feedback-header">
          <div className="tick-holder">
            <div className="green-circle">
              <Tick className="circle-tick" />
            </div>
          </div>
          Thanks for your feedback!
        </ModalHeading>
        <p className="feedback-text">{feedbackMessage}</p>
      </Modal>
    </Container>
  </div>
)

const mapStateToProps = state => ({
  showCompletedModal: state.activities.showCompletedActivityModal
})

const mapDispatchToProps = dispatch => ({
  dismissCompletedModalPopup: () => dispatch(toggleCompletedModalPopup(false))
})

CompletedSurveyModal.propTypes = {
  history: PropTypes.object.isRequired,
  showCompletedModal: PropTypes.bool.isRequired,
  dismissCompletedModalPopup: PropTypes.func.isRequired,
  feedbackMessage: PropTypes.string.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CompletedSurveyModal))
