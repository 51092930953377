import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { v4 } from 'uuid'
import {
  ACTIVITY_SOURCE_COLLEAGUE,
  ACTIVITY_TYPE_ACTION,
  ACTIVITY_TYPE_TODO,
  ACTIVITY_TYPE_INFO
} from 'utils/constants'
import { getResponse } from '../../../../utils/helpers'

const renderTag = (styles, text) => (
  <div key={v4()} className={`activity-card-tag ${styles}`.trim()}>
    {text}
  </div>
)

const style = {
  danger: 'activity-card-tag--danger',
  success: 'activity-card-tag--success',
  none: null
}

const styleRead = {
  read: 'activity-page-event--grey-light',
  unread: 'activity-page-event--grey-dark',
  none: null
}

const tag = {
  urgent: {
    render: item => (item.isUrgent ? [renderTag(style.danger, 'Urgent')] : [])
  },
  overdue: {
    render: item =>
      item.isOverdue && !item.isMissed
        ? [renderTag(style.danger, 'Overdue')]
        : []
  },
  missed: {
    render: item => (item.isMissed ? [renderTag(style.danger, 'Missed')] : [])
  },
  action: {
    render: item =>
      item.type === ACTIVITY_TYPE_ACTION ||
      (item.source === ACTIVITY_SOURCE_COLLEAGUE &&
        item.type === ACTIVITY_TYPE_TODO)
        ? [renderTag(style.none, 'Action')]
        : []
  },
  updated: {
    render: item =>
      item.hasNotifiableTemplateUpdate ? [renderTag(style.none, 'Updated')] : []
  },
  sayYouveStarted: {
    render: item =>
      item.canBeMarkedInProgress && !item.markedInProgressByName
        ? [renderTag(style.none, "Say you've started")]
        : []
  },
  assignedToMe: {
    render: (item, currentUser) => {
      if (!item.colleagueAssignmentSummary || !currentUser) return []

      const assignees = item.colleagueAssignmentSummary.split(', ')
      if (assignees && assignees.includes(currentUser))
        return [renderTag(style.none, 'Assigned to me')]

      return []
    }
  },
  started: {
    render: item =>
      item.markedInProgressByName ? [renderTag(style.success, 'Started')] : []
  },
  completed: {
    render: item =>
      item.completedOn ? [renderTag(style.success, 'Completed')] : []
  },
  satisfied: {
    render: item =>
      getResponse(item.response) !== null &&
      item.completedOn &&
      getResponse(item.response).payload?.satisfaction === 'Satisfied'
        ? [renderTag(style.success, 'Satisfied')]
        : []
  },
  unsatisfied: {
    render: item =>
      getResponse(item.response) !== null &&
      item.completedOn &&
      getResponse(item.response).payload?.satisfaction === 'NotSatisfied'
        ? [renderTag(style.danger, 'Not Satisfied')]
        : []
  },
  read: {
    render: item =>
      item.isRead
        ? [renderTag(styleRead.read, 'Read')]
        : [renderTag(styleRead.unread, 'Unread')]
  }
}

export const Tags = ({ item, currentUser }) => {
  if (!item) return null

  const tags = [
    ...tag.urgent.render(item),
    ...tag.overdue.render(item),
    ...tag.missed.render(item),
    ...tag.action.render(item),
    ...tag.updated.render(item),
    ...tag.sayYouveStarted.render(item),
    ...tag.assignedToMe.render(item, currentUser),
    ...tag.started.render(item),
    ...tag.completed.render(item),
    ...tag.satisfied.render(item),
    ...tag.unsatisfied.render(item)
  ]

  if (
    item.type === ACTIVITY_TYPE_INFO &&
    item.isCommunication &&
    (item.isRead || item.isPast)
  ) {
    tags.push(tag.read.render(item))
  }
  return tags
}

const mapStateToProps = state => ({
  currentUser: state.azure.currentUser
})

Tags.propTypes = {
  item: PropTypes.object,
  currentUser: PropTypes.string
}

Tags.defaultProps = {
  item: null,
  currentUser: null
}

export default connect(mapStateToProps, null)(Tags)
