import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Routes from 'utils/navigation-routes'
import { getStoreFromStorage } from 'utils/currentStore'
import { requestActivities } from '../../../actions'
import Content from './Content'

const Activities = ({
  isMobile,
  section,
  isFetching,
  activities,
  error,
  currentUser,
  filters,
  onFetch
}) => {
  useEffect(() => {
    const store = getStoreFromStorage()
    const lookupRoute = Routes.filter(route => route.text === section)[0]
    if (store !== null) {
      onFetch(lookupRoute.route, store)
    }
  }, [filters])

  const hasError = !isFetching && error
  const hasActivities =
    !isFetching &&
    !hasError &&
    activities &&
    activities.items &&
    activities.items.length > 0
  const hasNoActivities =
    !isFetching &&
    !hasError &&
    activities &&
    activities.items &&
    activities.items.length === 0

  const items =
    activities.items !== undefined
      ? activities.items.filter(x => x.matchesFilter)
      : []

  const content = {
    isMobile,
    hasNoActivities,
    hasActivities,
    hasError,
    items,
    section,
    currentUser
  }

  return (
    <>
      <Content {...content} />
    </>
  )
}

function mapStateToProps(state, ownProps) {
  return {
    isMobile: state.activities.isMobile,
    isFetching: state.activities.isFetching,
    activities: state.activities.activities,
    error: state.activities.error,
    filters: state.filter.applied,
    currentUser: state.azure.currentUser,
    ...ownProps
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onFetch: (section, store) => {
      dispatch(requestActivities(store.code, section))
    }
  }
}

Activities.propTypes = {
  isMobile: PropTypes.bool,
  section: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,
  activities: PropTypes.object,
  error: PropTypes.bool,
  onFetch: PropTypes.func.isRequired,
  filters: PropTypes.array,
  currentUser: PropTypes.string
}

Activities.defaultProps = {
  isMobile: false,
  isFetching: false,
  activities: { items: [] },
  error: false,
  currentUser: '',
  filters: []
}

export default connect(mapStateToProps, mapDispatchToProps)(Activities)
