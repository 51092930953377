export const FILTER_PANEL_TOGGLE = 'FILTER_PANEL_TOGGLE'

export const FILTER_STAGING_TOGGLE = 'FILTER_STAGING_TOGGLE'

export const FILTER_HYDRATE = 'FILTER_HYDRATE'
export const FILTER_APPLY = 'FILTER_APPLY'
export const FILTER_RESET = 'FILTER_RESET'
export const FILTER_REMOVE = 'FILTER_REMOVE'
export const FILTER_CHANGE_PAGE = 'FILTER_CHANGE_PAGE'

export const FILTER_ADD_DATES = 'FILTER_ADD_DATES'

export const filterPanelToggle = ({ isOpen }) => ({
  type: FILTER_PANEL_TOGGLE,
  isOpen
})

export const filterStagingToggle = item => ({
  type: FILTER_STAGING_TOGGLE,
  item
})

export const filterHydrate = filters => ({
  type: FILTER_HYDRATE,
  filters
})

export const filterApply = () => ({
  type: FILTER_APPLY
})

export const filterReset = () => ({
  type: FILTER_RESET
})

export const filterRemove = item => ({
  type: FILTER_REMOVE,
  item
})

export const filterChangePage = pageNumber => ({
  type: FILTER_CHANGE_PAGE,
  pageNumber
})

export const addDateFilters = (fromDate, toDate) => ({
  type: FILTER_ADD_DATES,
  fromDate,
  toDate
})
