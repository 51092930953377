import React from 'react'
import PropTypes from 'prop-types'
import { GridWrapper, GridItem } from '@jsluna/grid'
import {
  ACTIVITY_TYPE_TODO,
  ACTIVITY_TYPE_INFO,
  ACTIVITY_TYPE_SURVEY
} from 'utils/constants'
import ActivityCard from '../../Shared/ActivityCard/ActivityCard'
import TodoItem from '../../Activities/TodoItem/TodoItem'

export const DayGroupedItems = ({ day }) => {
  const dayContainsActivities = day && day.items.length > 0
  return day ? (
    <GridWrapper className="ln-u-margin-bottom*2">
      <div className="ln-u-text-align-center" key={day.date.format()}>
        <GridItem size={{ md: '4/5', lg: '3/5' }} className="ln-u-margin-top">
          <h6 className="ln-u-soft-left-sm">{day.date.format('ddd DD MMM')}</h6>
          {!dayContainsActivities && (
            <p className="ln-u-soft-left-sm">No activities for this day</p>
          )}
          {dayContainsActivities && (
            <div className="todo-items-grouped ln-u-margin-bottom*4">
              {day.items
                .filter(
                  x =>
                    x.type === ACTIVITY_TYPE_TODO ||
                    x.type === ACTIVITY_TYPE_SURVEY
                )
                .map(item => (
                  <TodoItem item={item} key={item.id} />
                ))}
            </div>
          )}
          {day.items
            .filter(x => x.type === ACTIVITY_TYPE_INFO)
            .map(item => (
              <ActivityCard item={item} key={item.id} />
            ))}
        </GridItem>
      </div>
    </GridWrapper>
  ) : (
    <div />
  )
}

DayGroupedItems.propTypes = {
  day: PropTypes.object
}

DayGroupedItems.defaultProps = {
  day: null
}

export default DayGroupedItems
