import React from 'react'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'

const ExemptStoreMessage = () => (
  <GridWrapper matrix horizontalAlign="center" className="ln-u-margin-top*4">
    <GridItem size="1/1">
      <Card>This feature is currently only available in trial stores.</Card>
    </GridItem>
  </GridWrapper>
)

export default ExemptStoreMessage
