import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Pagination } from '@jsluna/pagination'
import { v4 } from 'uuid'
import { getStoreFromStorage } from 'utils/currentStore'
import FailedToFetch from 'components/Areas/ErrorPages/FailedToFetch'
import NothingToSee from '../Messages/NothingToSee'
import ActivityCard from '../Shared/ActivityCard/ActivityCard'
import { requestHistoricActivities, filterChangePage } from '../../../actions'
import SkeletonItem from '../Activities/SkeletonItem'
import ItemHeader from '../Activities/ItemHeader'

export const WeeklyGroupedActivities = ({
  filters,
  pageNumber,
  changeFilterPage,
  activities,
  gettingActivities,
  error,
  getHistoricActivities
}) => {
  useEffect(() => {
    const store = getStoreFromStorage()
    if (store !== null) {
      getHistoricActivities(store, pageNumber)
    }
  }, [pageNumber, filters])

  const skeletonItems = () => {
    const loopedItems = []
    for (let i = 0; i < 3; i += 1) {
      loopedItems.push(<SkeletonItem key={i} />)
    }
    return loopedItems
  }

  let loading = ''
  let body = ''
  const hasActivities =
    activities.items && activities.items.length > 0 && !gettingActivities
  if (gettingActivities && !hasActivities) {
    loading = (
      <div className="ln-u-push-bottom-lg">
        <ItemHeader />
        {skeletonItems()}
      </div>
    )
  } else {
    body = <NothingToSee />
    if (error) {
      body = <FailedToFetch />
    }
  }

  if (hasActivities) {
    const weeksAgoToText = weeksAgo => {
      if (weeksAgo === 0) {
        return 'This week'
      }
      if (weeksAgo === 1) {
        return 'Last week'
      }
      return `${weeksAgo} weeks ago`
    }

    const groups = activities.items.reduce((acc, activity) => {
      let weeksAgo =
        activity &&
        activity.financialWeekOrderBy &&
        activity.financialWeekOrderBy.weeksAgo
      weeksAgo =
        weeksAgo === null || weeksAgo === undefined ? 'unknown' : weeksAgo

      if (!acc[weeksAgo]) {
        if (weeksAgo === 'unknown') {
          acc[weeksAgo] = {
            title: 'Unknown Period/Week',
            items: []
          }
        } else {
          const { period, week } = activity.financialWeekOrderBy
          acc[weeksAgo] = {
            title: `${weeksAgoToText(weeksAgo)} (P${period} W${week})`,
            items: []
          }
        }
      }
      acc[weeksAgo].items.push(activity)
      return acc
    }, {})

    const activitiesList = Object.keys(groups).map(weeksAgo => (
      <div key={v4()} className="ln-u-soft-bottom">
        <h4 className="ln-u-soft-top">{groups[weeksAgo].title}</h4>
        {groups[weeksAgo].items.map(item => (
          <ActivityCard key={item.id} item={item} />
        ))}
      </div>
    ))
    body = (
      <>
        {activitiesList}
        <Pagination
          fullWidth
          showFirstAndLast
          showPages
          current={pageNumber}
          total={activities.pageCount}
          onChange={(x, e) => {
            e.preventDefault()
            changeFilterPage(x)
          }}
        />
      </>
    )
  }

  return (
    <div>
      {loading}
      <div>{body}</div>
    </div>
  )
}

const mapStateToProps = state => ({
  filters: state.filter.applied,
  pageNumber: state.filter.pageNumber,
  activities: state.activities.activities,
  error: state.activities.error,
  gettingActivities: state.activities.gettingActivities
})

const mapDispatchToProps = dispatch => ({
  getHistoricActivities: (store, page) => {
    dispatch(requestHistoricActivities(store.code, page))
  },
  changeFilterPage: pageNumber => {
    dispatch(filterChangePage(pageNumber))
  }
})

WeeklyGroupedActivities.propTypes = {
  getHistoricActivities: PropTypes.func.isRequired,
  changeFilterPage: PropTypes.func.isRequired,
  filters: PropTypes.array,
  pageNumber: PropTypes.number,
  activities: PropTypes.object,
  error: PropTypes.bool,
  gettingActivities: PropTypes.bool
}

WeeklyGroupedActivities.defaultProps = {
  filters: [],
  pageNumber: 1,
  activities: {},
  error: false,
  gettingActivities: false
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeeklyGroupedActivities)
