import React from 'react'
import PropTypes from 'prop-types'
import { Card } from '@jsluna/card'
import TasksByStoreReportRow from './TasksByStoreReportRow'

const TasksByStoreReport = ({ data }) => {
  const sortedComplianceByStoreArray =
    data &&
    [...data].sort((a, b) =>
      (a.total > 0 ? a.completed / a.total : 0) >=
      (b.total > 0 ? b.completed / b.total : 0)
        ? 1
        : -1
    )

  return (
    <Card>
      {sortedComplianceByStoreArray.map(x => (
        <TasksByStoreReportRow key={x.storeCode} {...x} />
      ))}
    </Card>
  )
}

TasksByStoreReport.propTypes = {
  data: PropTypes.array.isRequired
}

export default TasksByStoreReport
