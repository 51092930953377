import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { OutlinedButton } from '@jsluna/button'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import {
  ProgressIndicator,
  ProgressSpinner,
  ProgressMessage
} from '@jsluna/progress'
import { getStoreFromStorage } from 'utils/currentStore'
import { Link } from 'react-router-dom'
import { ROUTE_RISK } from 'utils/constants'
import ReportCard from './ReportCard'
import PeriodWeekSelector from './PeriodWeekSelector'
import ActionAttributeReport from './ActionAttributeReport'

const calculateOutstanding = (completed, total) =>
  total > 0 ? total - completed : 0

const calculateCompletionRate = (completed, total) => {
  let rate = 0

  if (completed > 0) {
    rate = Math.round((completed / total) * 100)
  }

  return `Completion rate: ${rate}%`
}

export const ActionReport = ({
  loading,
  error,
  riskAction,
  selectedFinancialWeek,
  setSelectedFinancialWeek,
  attributes
}) => {
  if (error)
    return <span>We failed to fetch the Actions report, please try again!</span>
  if (loading) {
    return (
      <ProgressIndicator loading preventFocus>
        <ProgressSpinner className="ln-u-push-right-sm" />
        <ProgressMessage>Loading Actions Report...</ProgressMessage>
      </ProgressIndicator>
    )
  }

  const store = getStoreFromStorage()

  const { overviewSummary, complianceByAttribute } = riskAction

  const {
    numberOfActivities: total,
    numberOfActivitiesCompleted: completed,
    numberOfNewActivities: created,
    numberOfOverdueActivities: overdue
  } = overviewSummary || {}

  const outstanding = calculateOutstanding(completed, total)
  const completionRate = calculateCompletionRate(completed, total)

  return (
    <>
      <GridWrapper matrix verticalAlign="center">
        <GridItem size="1/1" className="ln-u-hard-bottom">
          <PeriodWeekSelector
            selectedFinancialWeek={selectedFinancialWeek}
            setSelectedFinancialWeek={setSelectedFinancialWeek}
          />
        </GridItem>
        <GridItem size="1/1">
          <Card>
            <GridItem size={{ default: '1/1', md: '1/4' }}>
              <strong>Actions in store</strong>
              <span className="ln-u-display-none ln-u-display-inline@md">
                <br />
                {completionRate}
              </span>
            </GridItem>
            <GridItem size={{ default: '1/1', md: '3/4' }}>
              <GridWrapper matrix equalHeight>
                <GridItem size={{ default: '1/3' }}>
                  <ReportCard
                    text="Created this week"
                    value={created}
                    colour="risk-compliance-report-card--blue"
                  />
                </GridItem>
                <GridItem size={{ default: '1/3' }}>
                  <ReportCard
                    text="All outstanding"
                    value={outstanding}
                    colour="risk-compliance-report-card--plum"
                  />
                </GridItem>
                <GridItem size={{ default: '1/3' }}>
                  <ReportCard
                    text="Overdue"
                    value={overdue}
                    colour="risk-compliance-report-card--red"
                  />
                </GridItem>
              </GridWrapper>
            </GridItem>
            <GridItem
              className="ln-u-display-none@md"
              size={{ default: '1/1' }}
            >
              {completionRate}
            </GridItem>
            <GridItem size="1/1" className="ln-u-margin-ends">
              <b>View open actions</b>
              <p>Any open actions can be found on the Risk page</p>
            </GridItem>
            <GridItem
              size={{ default: '1/1', md: '2/4' }}
              offsetRight={{ default: '', md: '1/4' }}
              offsetLeft={{ default: '', md: '1/4' }}
              className="ln-u-margin-bottom*2"
            >
              <Link to={ROUTE_RISK}>
                <OutlinedButton fullWidth color="dark">
                  View open actions
                </OutlinedButton>
              </Link>
            </GridItem>
            {store && !store.code.startsWith('A') && complianceByAttribute && (
              <GridItem size="1/1" className="ln-u-margin-top*2">
                <ActionAttributeReport
                  attributes={attributes?.filter(x => x.name !== 'Risk Type')}
                  complianceByAttribute={complianceByAttribute}
                />
              </GridItem>
            )}
          </Card>
        </GridItem>
      </GridWrapper>
    </>
  )
}

const mapStateToProps = state => ({
  loading: state.report.loading,
  error: state.report.error,
  attributes: state.attributes.attributeCategories,
  riskAction: state.report.riskAction
})

ActionReport.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  selectedFinancialWeek: PropTypes.number.isRequired,
  setSelectedFinancialWeek: PropTypes.func.isRequired,
  attributes: PropTypes.array,
  riskAction: PropTypes.object
}

ActionReport.defaultProps = {
  loading: false,
  error: false,
  attributes: [],
  riskAction: null
}

export default connect(mapStateToProps, null)(ActionReport)
