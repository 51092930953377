import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import BackIcon from '../assets/BackIcon'

const BackLink = ({ linkTo, onClick, className }) => {
  if (onClick) {
    return (
      <div className={`backLink ${className}`}>
        <a
          href="/"
          onClick={e => {
            e.preventDefault()
            onClick()
          }}
        >
          <BackIcon /> Back
        </a>
      </div>
    )
  }

  return (
    <div className="backLink">
      <Link to={linkTo}>
        <BackIcon /> Back
      </Link>
    </div>
  )
}

BackLink.propTypes = {
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
}

BackLink.defaultProps = {
  linkTo: '#',
  onClick: undefined,
  className: ''
}

export default BackLink
