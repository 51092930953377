import React from 'react'
import { Button } from '@jsluna/button'
import { Container, FlagWrapper, FlagComponent, FlagBody } from '@jsluna/grid'
import { ToggleButtonGroup, ToggleButton } from '@jsluna/toggle-button'
import { ArrowLeft, ArrowRight, Calendar } from '@jsluna/icons'
import { PLANNING_WEEK_VIEW, PLANNING_PERIOD_VIEW } from 'utils/constants'
import { useAnalytics } from '@jsainsburyplc/retail-analytics'
import PropTypes from 'prop-types'
import FilterHeader from '../../Filtering/FilterHeader'

const PaginationContainer = ({
  title,
  pageForward,
  pageForwardDisabled,
  pageBackward,
  pageBackwardDisabled,
  pageToday,
  view,
  onChangeView,
  onToggleAllCalendarItems,
  showAllCalendarItems
}) => {
  const { sendEvent } = useAnalytics()

  const handleOnToggleAllCalendarItems = () => {
    if (showAllCalendarItems) {
      sendEvent('show_less_calender', {
        label: 'showLess'
      })
    }
    onToggleAllCalendarItems(!showAllCalendarItems)
  }

  const handleViewOnChange = value => {
    if (value === 'period') {
      sendEvent('planning_period_view', {
        label: 'period'
      })
    }
    if (value === 'week') {
      sendEvent('planning_week_view', {
        label: 'week'
      })
    }
  }

  return (
    <Container className="c-pagination-container">
      <div className="u-week-view-filter">
        <FilterHeader />
      </div>
      <FlagWrapper>
        <FlagBody className="u-week-view-title" alignment="bottom">
          <h3 className="c-calendar-title ln-u-soft-top">{title}</h3>
        </FlagBody>
        <FlagComponent className="u-pagination-buttons" alignment="bottom">
          <Button
            id="backButton"
            className="u-white-background ln-u-margin-right"
            disabled={pageBackwardDisabled}
            onClick={pageBackward}
          >
            <ArrowLeft />
          </Button>
          <Button
            id="todayButton"
            className="u-white-background"
            onClick={pageToday}
          >
            <Calendar size="large" />
            <span className="activity-icon-padder">Today</span>
          </Button>
          <Button
            id="forwardButton"
            className="u-white-background ln-u-margin-left"
            disabled={pageForwardDisabled}
            onClick={pageForward}
          >
            <ArrowRight />
          </Button>
          <Button
            onClick={() => handleOnToggleAllCalendarItems()}
            variant="outlined"
            color="dark"
            className="ln-u-margin-sides*2"
          >
            Show {showAllCalendarItems ? 'less' : 'more'}
          </Button>
          <ToggleButtonGroup
            inline
            alpha
            label="Results view"
            className="ln-u-margin-left*2"
            value={view}
            onChange={(event, value) => {
              onChangeView(value)
              handleViewOnChange(value)
            }}
          >
            <ToggleButton value={PLANNING_WEEK_VIEW}>
              <span>Week</span>
            </ToggleButton>
            <ToggleButton value={PLANNING_PERIOD_VIEW}>
              <span>Period</span>
            </ToggleButton>
          </ToggleButtonGroup>
        </FlagComponent>
      </FlagWrapper>
    </Container>
  )
}

PaginationContainer.propTypes = {
  title: PropTypes.string.isRequired,
  pageForward: PropTypes.func.isRequired,
  pageBackward: PropTypes.func.isRequired,
  pageBackwardDisabled: PropTypes.bool.isRequired,
  pageToday: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  onChangeView: PropTypes.func.isRequired,
  pageForwardDisabled: PropTypes.bool.isRequired,
  onToggleAllCalendarItems: PropTypes.func.isRequired,
  showAllCalendarItems: PropTypes.bool.isRequired
}

export default PaginationContainer
