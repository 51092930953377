import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Label } from '@jsluna/form'
import { submitFeedback, deleteFeedback } from 'actions'
import { ToggleButton, ToggleButtonGroup } from '@jsluna/toggle-button'
import { useAnalytics } from '@jsainsburyplc/retail-analytics'

const Feedback = ({
  activityId,
  isHelpful,
  submittingFeedback,
  removeFeedback,
  sendFeedback,
  activityTitle
}) => {
  const { sendEvent } = useAnalytics()

  const isHelpfulValue = () => {
    if (isHelpful) {
      return 'Yes'
    }
    if (isHelpful === false) {
      // Null is a vaild value, therefore we do explicit checks for false
      return 'No'
    }
    return ''
  }

  const handleRemoveFeedbackRequest = () => {
    sendEvent('feedback_submitted_removed', {
      title: activityTitle
    })
    removeFeedback(activityId)
  }

  const handleFeedbackRequest = value => {
    sendEvent(`feedback_submitted_${value ? 'helpful' : 'unhelpful'}`, {
      title: activityTitle
    })
    sendFeedback(activityId, value)
  }

  return (
    <Card className="ln-u-margin-top">
      <GridWrapper matrix className="activity-page-padder">
        <GridItem size="1/1">
          <Label htmlFor="feedback" className="ln-u-margin-top">
            Was this helpful?
          </Label>
          <ToggleButtonGroup
            label="Yes / No"
            value={isHelpfulValue()}
            onChange={(event, value) => {
              if (!submittingFeedback) {
                if (
                  (isHelpful && value === 'Yes') ||
                  (isHelpful === false && value === 'No')
                ) {
                  handleRemoveFeedbackRequest()
                } else handleFeedbackRequest(value === 'Yes')
              }
            }}
          >
            <ToggleButton key="Yes" value="Yes">
              {'Yes'}
            </ToggleButton>
            <ToggleButton key="No" value="No">
              {'No'}
            </ToggleButton>
          </ToggleButtonGroup>
        </GridItem>
      </GridWrapper>
    </Card>
  )
}

function mapStateToProps(state) {
  return {
    submittingFeedback: state.activities.submittingFeedback,
    activityTitle: state.activities.currentActivity.title
  }
}

function mapDispatchToProps(dispatch) {
  return {
    sendFeedback: (activityId, isHelpful) =>
      dispatch(submitFeedback(activityId, isHelpful)),
    removeFeedback: activityId => dispatch(deleteFeedback(activityId))
  }
}

Feedback.propTypes = {
  isHelpful: PropTypes.bool,
  activityId: PropTypes.number.isRequired,
  removeFeedback: PropTypes.func.isRequired,
  sendFeedback: PropTypes.func.isRequired,
  submittingFeedback: PropTypes.bool,
  activityTitle: PropTypes.string.isRequired
}

Feedback.defaultProps = {
  isHelpful: null,
  submittingFeedback: false
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
