import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { GridItem, GridWrapper } from '@jsluna/grid'
import { Cancel } from '@jsluna/icons'
import {
  filterPanelToggle,
  filterRemove,
  filterReset
} from '../../../actions/filter'

const maxPillDisplayForMobile = 1
const maxPillDisplayForDesktop = 3

const renderForMobile = (filters, removeFilter, resetFilter) => {
  if (filters.length === maxPillDisplayForMobile) {
    return (
      <button
        onClick={() => removeFilter(filters[0])}
        className="filter-pill filter-pill--item"
        type="button"
      >
        {filters[0].label} <Cancel />
      </button>
    )
  }

  return (
    <button
      onClick={() => resetFilter()}
      className="filter-pill filter-pill--item"
      type="button"
    >
      {`${filters.length} filters`} <Cancel />
    </button>
  )
}

const renderForDesktop = (filters, removeFilter) => {
  const pills = filters.slice(0, maxPillDisplayForDesktop).map(item => (
    <button
      key={`pill-${item.value}`}
      onClick={() => removeFilter(item)}
      className="filter-pill filter-pill--item"
      type="button"
    >
      {item.label} <Cancel />
    </button>
  ))

  if (filters.length <= maxPillDisplayForDesktop) {
    return pills
  }

  const overflow = (
    <button
      key="pill-overflow"
      className="filter-pill filter-pill--item"
      type="button"
    >
      <span className="ln-u-padding-right*1/2">
        {`${filters.length - maxPillDisplayForDesktop} other`}
      </span>
    </button>
  )

  return [pills, overflow]
}

const renderHeader = (
  filters,
  isMobile,
  removeFilter,
  resetFilter,
  currentSection
) => {
  if (!filters.length) return null
  let updatedFilters = filters.filter(
    x =>
      x.value !== null &&
      (!x.pages || x.pages.includes(currentSection.toLowerCase()))
  )
  if (currentSection !== 'history') {
    updatedFilters = updatedFilters.filter(
      x => x.filterType !== 'fromDate' && x.filterType !== 'toDate'
    )
  }
  return isMobile
    ? renderForMobile(updatedFilters, removeFilter, resetFilter)
    : renderForDesktop(updatedFilters, removeFilter)
}

export const FilterHeader = ({
  isMobile,
  isPanelOpen,
  togglePanel,
  filters,
  removeFilter,
  resetFilter,
  currentSection,
  heading
}) => {
  const handleToggle = panelOpen => {
    togglePanel({ isOpen: panelOpen })
    if (panelOpen) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
  return (
    <GridWrapper>
      <GridItem size="1/1">
        {heading}
        {renderHeader(
          filters,
          isMobile,
          removeFilter,
          resetFilter,
          currentSection
        )}
        {isMobile && (
          <button
            className="filter-pill filter-pill--toggle"
            onClick={() => handleToggle(!isPanelOpen)}
            type="button"
          >
            All filters
          </button>
        )}
      </GridItem>
    </GridWrapper>
  )
}

const mapStateToProps = state => ({
  isMobile: state.activities.isMobile,
  isPanelOpen: !state.activities.isMobile || state.filter.isPanelOpen,
  filters: state.filter.applied,
  currentSection: state.activities.currentSection
})

const mapDispatchToProps = dispatch => ({
  togglePanel: toggle => {
    dispatch(filterPanelToggle(toggle))
  },
  removeFilter: item => {
    dispatch(filterRemove(item))
  },
  resetFilter: () => {
    dispatch(filterReset())
  }
})

FilterHeader.propTypes = {
  isMobile: PropTypes.bool,
  isPanelOpen: PropTypes.bool,
  filters: PropTypes.array,
  togglePanel: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  currentSection: PropTypes.string.isRequired,
  heading: PropTypes.any
}

FilterHeader.defaultProps = {
  heading: null,
  isMobile: false,
  isPanelOpen: false,
  filters: []
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterHeader)
