import React from 'react'
import PropTypes from 'prop-types'
import { TODO_TYPE_ATTRIBUTES_CATEGORY } from 'utils/constants'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import Banner from '../Units/Banner'
import Title from '../Units/Title'
import Summary from '../Units/Summary'
import Description from '../Units/Description'
import Media from '../Units/Media'
import Attributes from '../Units/Attributes'
import Feedback from '../Units/Feedback'
import FromToRange from '../Units/FromToRange'
import MarkAsRead from '../Units/MarkAsRead'
import ActivityRestrictionInfo from '../Units/ActivityRestriction/ActivityRestrictionInfo'
import ActivityRestrictionDetail from '../Units/ActivityRestriction/ActivityRestrictionDetail'

const NeedToKnow = ({ activity }) => {
  if (!activity) return null
  return (
    <div className="ln-u-padding-bottom*4">
      <Banner activity={activity} />
      <Card>
        <GridWrapper matrix className="activity-page-padder">
          <GridItem size="1/1">
            <FromToRange from={activity.activeFromDate} to={activity.dueDate} />
            <ActivityRestrictionInfo audience={activity?.audience} />
            <Title title={activity.title} />
            <Summary summary={activity.summary} />
            <Description description={activity.description} />
            <Media
              hasMedia={activity.hasMedia}
              mediaAttachments={activity.mediaAttachments}
            />
            <Attributes
              attributes={activity.attributes}
              ignoredCategories={[TODO_TYPE_ATTRIBUTES_CATEGORY]}
            />
            <ActivityRestrictionDetail audience={activity?.audience} />
          </GridItem>
        </GridWrapper>
      </Card>
      <MarkAsRead activity={activity} />
      <Feedback activityId={activity.id} isHelpful={activity.isHelpful} />
    </div>
  )
}

NeedToKnow.propTypes = {
  activity: PropTypes.object
}

NeedToKnow.defaultProps = {
  activity: null
}

export default NeedToKnow
