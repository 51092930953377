import React, { useState, useEffect } from 'react'
import { FilledButton, ButtonGroupWrapper } from '@jsluna/button'
import { CheckboxField } from '@jsluna/form'
import { Container } from '@jsluna/grid'
import { Modal, ModalHeading } from '@jsluna/modal'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  toggleFilterModal,
  updateSelectedAttributes,
  requestAttributeCategories
} from 'actions'

export const FilterModal = ({
  selectedAttributes,
  updateAttributeCategories,
  showFilterModal,
  dismissFilterModal,
  attributeCategories,
  getAttributeCategories
}) => {
  const [selectedAttrs, setSelectedAttrs] = useState(selectedAttributes)
  useEffect(() => {
    if (attributeCategories === null) {
      getAttributeCategories(true)
    }
  }, [])
  return (
    <Container>
      <Modal
        fullScreen
        handleClose={() => {
          dismissFilterModal()
          setSelectedAttrs(selectedAttributes)
        }}
        preventFocus
        open={showFilterModal}
        className="filtering-overflow"
      >
        <ModalHeading element="h3">Filter activities by:</ModalHeading>
        <div className="filtering-overflow">
          {attributeCategories &&
            attributeCategories.length > 0 &&
            attributeCategories.map(cat => (
              <div key={cat.id}>
                <h6>{cat.name}</h6>
                {cat.activityAttributes && cat.activityAttributes.length > 0 ? (
                  <div>
                    <CheckboxField
                      name="checkbox-field"
                      options={cat.activityAttributes.map(x => ({
                        label: `${x.icon} ${x.name}`,
                        value: x.name,
                        defaultChecked: selectedAttributes.includes(x),
                        onClick: () =>
                          setSelectedAttrs(
                            selectedAttrs.includes(x)
                              ? selectedAttrs.filter(y => y.id !== x.id)
                              : [...selectedAttrs, x]
                          )
                      }))}
                      outlined
                      fullWidth
                    />
                  </div>
                ) : (
                  <p>No attributes for this category!</p>
                )}
              </div>
            ))}
        </div>
        <ButtonGroupWrapper actionBar>
          <FilledButton
            id="applyFilters"
            onClick={() => {
              updateAttributeCategories(selectedAttrs)
              dismissFilterModal()
            }}
          >
            Apply filters
          </FilledButton>
        </ButtonGroupWrapper>
      </Modal>
    </Container>
  )
}

const mapStateToProps = state => ({
  attributeCategories: state.attributes.attributeCategories,
  selectedAttributes: state.activities.selectedAttributes,
  showFilterModal: state.activities.showFilterModal
})

const mapDispatchToProps = dispatch => ({
  dismissFilterModal: () => dispatch(toggleFilterModal(false)),
  updateAttributeCategories: selectedAttributes =>
    dispatch(updateSelectedAttributes(selectedAttributes)),
  getAttributeCategories: activeOnly =>
    dispatch(requestAttributeCategories(activeOnly))
})

FilterModal.propTypes = {
  attributeCategories: PropTypes.array,
  selectedAttributes: PropTypes.array.isRequired,
  showFilterModal: PropTypes.bool.isRequired,
  dismissFilterModal: PropTypes.func.isRequired,
  updateAttributeCategories: PropTypes.func.isRequired,
  getAttributeCategories: PropTypes.func.isRequired
}

FilterModal.defaultProps = {
  attributeCategories: null
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterModal)
