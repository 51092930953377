import React from 'react'
import PropTypes from 'prop-types'
import { SurveyRenderer } from '@jsainsburyplc/activity-survey'
import { Heading3 } from '@jsluna/typography'

export const SurveyContent = ({
  questions,
  setResponse,
  setIsResponseValid
}) => (
  <div className="ln-u-padding-top*2">
    <Heading3>Questions to be answered</Heading3>
    <SurveyRenderer
      questions={questions}
      setResponse={setResponse}
      setIsResponseValid={setIsResponseValid}
      showErrors={false}
    />
  </div>
)

SurveyContent.propTypes = {
  questions: PropTypes.array.isRequired,
  setResponse: PropTypes.func.isRequired,
  setIsResponseValid: PropTypes.func.isRequired
}

export default SurveyContent
