import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
  OutlinedButton,
  FilledButton
} from '@jsluna/button'
import { Container } from '@jsluna/grid'
import { Modal, ModalHeading } from '@jsluna/modal'
import PropTypes from 'prop-types'
import { toggleImageConfirmationModalPopup } from 'actions'

export const ImageConfirmationModal = ({
  showImageConfirmationModal,
  dismissImageConfirmationModalPopup,
  history
}) => (
  <div>
    <Container>
      <Modal
        fullScreen
        restrictClose
        alert
        handleClose={() => {
          dismissImageConfirmationModalPopup()
        }}
        preventFocus
        open={showImageConfirmationModal}
      >
        <ModalHeading element="h3">This survey requires an image</ModalHeading>
        <p>
          Before you get stuck in, please be aware this survey will require an
          image to be fully complete.
        </p>
        <p>
          You can upload an image that already exists on this computer,
          otherwise we would recommend a camera enabled device to complete this
          survey
        </p>
        <ButtonGroupWrapper actionBar>
          <ButtonGroupPrimary>
            <FilledButton
              id="continueButton"
              onClick={() => dismissImageConfirmationModalPopup()}
            >
              Continue
            </FilledButton>
          </ButtonGroupPrimary>
          <ButtonGroupSecondary>
            <OutlinedButton
              onClick={() => {
                dismissImageConfirmationModalPopup()
                history.goBack()
              }}
            >
              Go back
            </OutlinedButton>
          </ButtonGroupSecondary>
        </ButtonGroupWrapper>
      </Modal>
    </Container>
  </div>
)

const mapStateToProps = state => ({
  showImageConfirmationModal: state.activities.showImageConfirmationModal
})

const mapDispatchToProps = dispatch => ({
  dismissImageConfirmationModalPopup: () =>
    dispatch(toggleImageConfirmationModalPopup(false))
})

ImageConfirmationModal.propTypes = {
  showImageConfirmationModal: PropTypes.bool.isRequired,
  dismissImageConfirmationModalPopup: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ImageConfirmationModal)
)
