import * as actions from '../actions'

const colleagueLayoutReducer = (state = [], action) => {
  switch (action.type) {
    case actions.CHANGE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab
      }

    default:
      return state
  }
}

export default colleagueLayoutReducer
