import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'
import { requestActivityQuestions } from 'actions'

export const LegacySurveyContent = ({
  activity,
  questions,
  getCurrentActivityQuestions,
  isFetchingQuestions
}) => {
  useEffect(() => {
    if (activity !== null) {
      getCurrentActivityQuestions(activity.referenceId)
    }
  }, [])

  return (
    <div className="ln-u-padding-top*2">
      {isFetchingQuestions && (
        <ProgressIndicator
          style={{
            position: 'relative',
            zIndex: 3
          }}
          className="ln-u-soft-bottom"
          loading
        >
          <ProgressSpinner />
          Loading Survey...
        </ProgressIndicator>
      )}
      {questions !== null &&
        questions.length > 0 &&
        questions.map(question => (
          <div
            key={question.number}
            className="ln-u-padding-top ln-u-padding-bottom*2 question-container"
          >
            <h4>
              Q{question.number}. {question.title}
            </h4>
            {question.answerComponent}
          </div>
        ))}
    </div>
  )
}

const mapStateToProps = state => ({
  questions: state.activities.currentActivityQuestions.questions,
  isFetchingQuestions: state.activities.isFetchingCurrentActivityQuestions
})

const mapDispatchToProps = dispatch => ({
  getCurrentActivityQuestions: activityId =>
    dispatch(requestActivityQuestions(activityId))
})

LegacySurveyContent.propTypes = {
  activity: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  getCurrentActivityQuestions: PropTypes.func.isRequired,
  isFetchingQuestions: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(LegacySurveyContent)
