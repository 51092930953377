import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import createItemHolders from './CreateItemHolders'

const dayId = day => day && day.date.format('ddd D')
const dayName = day => day && day.date.format('ddd')

const isPastDay = day => day && day.isBefore(moment().startOf('day'))
const isToday = day => day && day.isSame(moment(), 'day')

const WeekViewDayHolder = ({ day, history, includeHeader }) => {
  const holdersToCreate =
    day.items.length > 0
      ? day.items.reduce((a, b) => (a.position > b.position ? a : b)).position +
        1
      : 0

  return (
    <>
      <div
        className={`c-day-card ln-u-soft-bottom${
          day.date ? ' ln-u-margin-sides' : ''
        } ${
          isPastDay(day.date) ? ' c-past-day' : ' ln-u-bg-color-grey-v-light'
        }`}
      >
        {includeHeader && day.date && (
          <div className="ln-u-text-align-center ln-u-bg-color-white ln-u-padding-top*4 ln-u-padding-bottom">
            <h6 className="c-calendar-item" style={{ marginBottom: 0 }}>
              {dayName(day)}
              <span
                id={dayId(day)}
                style={{ display: 'inline-block', float: 'right' }}
              >
                &nbsp;
              </span>
            </h6>
          </div>
        )}
        {day.date && (
          <div>
            <h6 className="c-calendar-item ln-u-text-align-center ln-u-padding-top">
              <span
                className={`c-cal-date ${isToday(day.date) ? 'today' : ''}`}
              >
                {day.date.format('D')}
              </span>
            </h6>
          </div>
        )}
        {day.items &&
          day.items.length > 0 &&
          createItemHolders(day, history, holdersToCreate, false)}
      </div>
    </>
  )
}

WeekViewDayHolder.propTypes = {
  day: PropTypes.shape({
    date: PropTypes.object,
    items: PropTypes.array
  }),
  history: PropTypes.object,
  includeHeader: PropTypes.bool
}

WeekViewDayHolder.defaultProps = {
  day: {
    date: null,
    items: []
  },
  history: null,
  includeHeader: false
}

export default WeekViewDayHolder
