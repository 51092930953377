import moment from 'moment'
import { FILTER_PAGES } from 'utils/constants'

export const filterTypes = {
  attribute: 'attributeIds', // these options are api fetched
  context: 'contexts',
  type: 'types',
  status: 'statuses',
  fromDate: 'fromDate',
  toDate: 'toDate'
}

export const contextOptions = [
  {
    label: 'Today',
    value: 'Current',
    icon: null,
    pages: ['search']
  },
  {
    label: 'Planned',
    value: 'Future',
    icon: null,
    pages: ['search']
  },
  {
    label: 'History',
    value: 'Past',
    icon: null,
    pages: ['search']
  }
]

export const typeOptions = [
  {
    label: 'Activities',
    value: 'Activities',
    icon: null,
    pages: ['history', 'search', 'today', 'planning']
  },
  {
    label: 'Communications',
    value: 'Communications',
    icon: null,
    pages: ['history', 'search', 'today']
  },
  {
    label: 'Need to knows',
    value: 'NeedToKnows',
    icon: null,
    pages: ['planning', 'history']
  },
  {
    label: 'Risk tasks',
    value: 'Risks',
    icon: null,
    pages: ['history', 'search', 'risk'],
    audienceRestricted: true
  },
  {
    label: 'Risk actions',
    value: 'Actions',
    icon: null,
    pages: ['history', 'search', 'risk'],
    audienceRestricted: true
  }
]

export const statusOptions = [
  {
    label: 'Missed',
    value: 'Incomplete',
    icon: null,
    pages: ['history', 'search']
  },
  {
    label: 'Unread',
    value: 'Unread',
    icon: null,
    pages: ['history']
  },
  {
    label: 'Complete',
    value: 'Complete',
    icon: null,
    pages: ['history', 'search']
  },
  {
    label: 'Started',
    value: 'Started',
    icon: null,
    pages: ['today', 'planning', 'search']
  },
  {
    label: 'Read',
    value: 'Read',
    icon: null,
    pages: ['history']
  },
  {
    label: 'Assigned to me',
    value: 'AssignedToMe',
    icon: null,
    pages: ['today', 'history', 'planning', 'search', 'risk']
  },
  {
    label: 'Overdue',
    value: 'Overdue',
    icon: null,
    pages: ['today', 'search', 'risk']
  },
  {
    label: 'Urgent',
    value: 'Urgent',
    icon: null,
    pages: ['today', 'search']
  },
  {
    label: 'Updated',
    value: 'Updated',
    icon: null,
    pages: ['today', 'history', 'planning', 'search']
  }
]

const options = [...contextOptions, ...typeOptions, ...statusOptions]

export const getFilterOptionsByPage = (page, canAccessRisk) => {
  if (!page || !Object.values(FILTER_PAGES).includes(page)) {
    return {
      types: canAccessRisk
        ? typeOptions
        : typeOptions.filter(x => !x.audienceRestricted),
      statuses: statusOptions
    }
  }
  return {
    types: canAccessRisk
      ? typeOptions.filter(x => x.pages.includes(page))
      : typeOptions.filter(
          x => x.pages.includes(page) && !x.audienceRestricted
        ),
    statuses: statusOptions.filter(x => x.pages.includes(page))
  }
}

export const getDateOption = (date, type) => ({
  filterType: type,
  label: `${type === filterTypes.fromDate ? 'From' : 'To'} Date: ${moment(
    date
  ).format('ddd Do MMM')}`,
  value: date
})

export default options
