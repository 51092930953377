import React from 'react'
import PropTypes from 'prop-types'

const WarningIcon = ({ width, height, viewBox }) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g
      id="Activity-build-(store-app)"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Store---DT---No-connection"
        transform="translate(-696.000000, -196.000000)"
      >
        <g id="Group-2" transform="translate(575.000000, 196.000000)">
          <g id="icon/error" transform="translate(121.000000, 0.000000)">
            <circle id="Oval" fill="#DB3E34" cx="24" cy="24" r="24" />
            <path
              d="M27.3024,13.9968 C27.3024,14.112 27.264,14.2272 27.264,14.3424
                L25.1136,28.2816 L22.8864,28.2816 L20.736,14.3424 C20.736,14.2272 20.6976,14.112 20.6976,13.9968
                C20.6976,12.2688 22.4256,11.232 24,11.232 C25.5744,11.232 27.3024,12.2688 27.3024,13.9968
                Z M27.0336,33.312 C27.0336,35.0016 25.6896,36.3456 24,36.3456 C22.3104,36.3456 20.9664,35.0016
                20.9664,33.312 C20.9664,31.6224 22.3104,30.2784 24,30.2784 C25.6896,30.2784 27.0336,31.6224 27.0336,33.312 Z"
              id="!"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

WarningIcon.defaultProps = {
  width: 50,
  height: 50,
  viewBox: '0 0 50 50'
}

WarningIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string
}

export default WarningIcon
