import React from 'react'
import PropTypes from 'prop-types'
import { Heading2, Heading3 } from '@jsluna/typography'

const Title = ({ title, smallerVariant }) => {
  if (!title) return null

  if (smallerVariant) {
    return (
      <Heading3 element="div" className="ln-u-margin-top">
        {title}
      </Heading3>
    )
  }

  return (
    <Heading2 element="div" className="ln-u-margin-top">
      {title}
    </Heading2>
  )
}

Title.propTypes = {
  title: PropTypes.string,
  smallerVariant: PropTypes.bool
}

Title.defaultProps = {
  title: null,
  smallerVariant: false
}

export default Title
