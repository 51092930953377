import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { clearBrowserCheckCookie } from '@jsainsburyplc/retail-unsupported-browser'
import authConfig from './authConfig'
import {
  setColleagueSettingsToStorage,
  getColleagueSettingsFromStorage
} from '../utils/currentStore'

const setupMsalInstance = () => {
  const msalInstance = new PublicClientApplication(authConfig)

  msalInstance.initialize().then(() => {
    const account = msalInstance.getAllAccounts()
    if (account.length === 0 || account.length > 1) {
      return
    }
    if (!msalInstance.getActiveAccount() && account.length === 1) {
      msalInstance.setActiveAccount(account[0])
    }

    msalInstance.enableAccountStorageEvents()

    msalInstance.addEventCallback(
      event => {
        if (event.eventType === EventType.ACCOUNT_REMOVED) {
          msalInstance.disableAccountStorageEvents()
          localStorage.clear()
          window.location.reload()
        }
        if (
          event.eventType === EventType.LOGIN_SUCCESS &&
          event?.payload?.account
        ) {
          const accountInfo = event.payload
          msalInstance.setActiveAccount(accountInfo)
          const colleagueSettings = getColleagueSettingsFromStorage()
          if (colleagueSettings === null) {
            setColleagueSettingsToStorage(() => {
              clearBrowserCheckCookie()
            })
          }
        }
      },
      error => {
        // eslint-disable-next-line no-console
        console.log('authentication error', error)
      }
    )
  })

  return msalInstance
}

const msalInstance = setupMsalInstance()
export default msalInstance
