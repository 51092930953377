import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Swipeable } from 'react-swipeable'
import Sticky from 'react-sticky-el'
import { toggleFilterModal } from 'actions'
import { PLANNING_PERIOD_VIEW, PLANNING_WEEK_VIEW } from 'utils/constants'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'
import MobilePlanningViewHeader from './Mobile'
import PaginationContainer from './PaginationContainer'
import MobilePaginationContainer from './Mobile/MobilePaginationContainer'

export const Header = ({
  title,
  pageForward,
  pageForwardDisabled,
  pageBackward,
  pageBackwardDisabled,
  pageToday,
  activities,
  isMobile,
  view,
  onChangeView,
  isFetching,
  onToggleAllCalendarItems,
  showAllCalendarItems
}) => {
  const pagination = isMobile ? (
    <MobilePaginationContainer
      pageForward={pageForward}
      pageForwardDisabled={pageForwardDisabled}
      pageBackward={pageBackward}
      pageBackwardDisabled={pageBackwardDisabled}
      pageToday={pageToday}
      title={title}
    />
  ) : (
    <PaginationContainer
      pageForward={pageForward}
      pageForwardDisabled={pageForwardDisabled}
      pageBackward={pageBackward}
      pageBackwardDisabled={pageBackwardDisabled}
      pageToday={pageToday}
      title={title}
      view={view}
      onChangeView={onChangeView}
      onToggleAllCalendarItems={onToggleAllCalendarItems}
      showAllCalendarItems={showAllCalendarItems}
    />
  )
  return (
    <div>
      <div>
        {isMobile ? (
          <Sticky style={{ zIndex: 1 }} positionRecheckInterval={1}>
            <Swipeable
              onSwiped={() =>
                onChangeView(
                  view === PLANNING_PERIOD_VIEW
                    ? PLANNING_WEEK_VIEW
                    : PLANNING_PERIOD_VIEW
                )
              }
              preventDefaultTouchmoveEvent
            >
              {pagination}
              <div
                className="ln-u-bg-color-white ln-u-border-bottom c-header-container"
                style={{
                  height: view === PLANNING_PERIOD_VIEW ? 270 : 125
                }}
              >
                <ProgressIndicator
                  className="mobile-panel-loading-indicator"
                  page
                  loading={isFetching}
                >
                  <ProgressSpinner />
                </ProgressIndicator>
                <MobilePlanningViewHeader activities={activities} view={view} />
                <div className="mobile-panel-drag-control">
                  <div className="mobile-panel-drag-bar" />
                </div>
              </div>
            </Swipeable>
          </Sticky>
        ) : (
          pagination
        )}
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  showFilterModal: () => dispatch(toggleFilterModal(true))
})

const mapStateToProps = state => ({
  isMobile: state.activities.isMobile,
  isFetching: state.activities.isFetching,
  showAllCalendarItems: state.activities.showAllCalendarItems
})

Header.propTypes = {
  title: PropTypes.string.isRequired,
  pageForward: PropTypes.func.isRequired,
  pageBackward: PropTypes.func.isRequired,
  pageBackwardDisabled: PropTypes.bool.isRequired,
  pageToday: PropTypes.func.isRequired,
  activities: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  onChangeView: PropTypes.func.isRequired,
  pageForwardDisabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onToggleAllCalendarItems: PropTypes.func.isRequired,
  showAllCalendarItems: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
