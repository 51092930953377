import React from 'react'
import { StatusCard } from '@jsluna/card'
import { FlagWrapper, FlagBody } from '@jsluna/grid'
import PropTypes from 'prop-types'
import { VIEW_ACTIVITY_ROUTE, ACTIVITY_TYPE_INFO } from 'utils/constants'

const ModalItem = ({ activity, history }) => (
  <StatusCard
    key={activity.id}
    className={`c-more-activities-status-card ${
      activity.isCompleted ||
      activity.isMissed ||
      (activity.isPast && activity.type === ACTIVITY_TYPE_INFO)
        ? 'c-activity-past'
        : ''
    } ln-u-margin-ends ln-u-border`}
  >
    <FlagWrapper>
      <FlagBody>
        <a
          role="presentation"
          onClick={() => history.push(`${VIEW_ACTIVITY_ROUTE}/${activity.id}`)}
          style={{ cursor: 'pointer' }}
        >
          <b>{activity.title.substring(0, 50)}</b>
        </a>
      </FlagBody>
    </FlagWrapper>
  </StatusCard>
)

ModalItem.propTypes = {
  activity: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default ModalItem
