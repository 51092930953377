export const TODAY = 'Today'
export const TODAY_ROUTE = 'today'
export const PLANNING = 'Planning'
export const PLANNING_ROUTE = 'planning'
export const COMPLETED = 'Completed'
export const HISTORY = 'History'
export const MISSED = 'Missed'
export const SEARCH = '/search'
export const CHANGE_STORE = '/changestore'
export const LOGOUT = '/logout'

export const ROUTE_TODAY = '/today'
export const ROUTE_COMPLETED = '/completed'
export const ROUTE_MISSED = '/missed'
export const ROUTE_HISTORY = '/history'
export const ROUTE_RISK_COMPLIANCE = '/risk-compliance'
export const ROUTE_RISK = '/risk'
export const ROUTE_PLANNING = '/planning'
export const ROUTE_SEARCH = '/search'

export const VIEW_ACTIVITY_ROUTE = '/viewactivity'

export const ACTIVITY_TYPE_TODO = 'Todo'
export const ACTIVITY_TYPE_INFO = 'NeedToKnow'
export const ACTIVITY_TYPE_SURVEY = 'Survey'
export const ACTIVITY_TYPE_AUDIT = 'Audit'
export const ACTIVITY_TYPE_ACTION = 'Action'

export const QUESTION_TYPE_TEXTAREA = 'TextArea'
export const QUESTION_TYPE_IMAGE = 'Image'
export const IMAGE_UPLOADING_TEXT = 'Uploading Survey Images...'
export const IMAGE_RETRY_UPLOADING_TEXT = 'Retrying Uploading Survey Images...'
export const MAX_IMAGE_UPLOAD_RETRYS = 5
export const RETRY_TIMEOUT = 2000
const errorNicety = 'please try again later ...'
export const COMPLETE_ACTIVITY_ERROR_MESSAGE = `Couldn't complete activity at this moment, ${errorNicety}`
export const UNDO_COMPLETE_ACTIVITY_ERROR_MESSAGE = `Couldn't undo-complete activity at this moment, ${errorNicety}`
export const SUBMIT_FEEDBACK_ERROR_MESSAGE = `Couldn't submit feedback for activity at this moment, ${errorNicety}`
export const START_ACTIVITY_ERROR_MESSAGE = `Couldn't start activity at this moment, ${errorNicety}`
export const ASSIGN_ACTIVITY_ERROR_MESSAGE = `Couldn't assign activity at this moment, ${errorNicety}`
export const CLEAR_ACTIVITY_ASSIGNMENT_ERROR_MESSAGE = `Couldn't unassign activity at this moment, ${errorNicety}`

// export const GOOGLE_ANALYTICS_ACTIVITY_DISMISSED_TUTORIAL_EVENT = 'Dismissed Tutorial';
// export const GOOGLE_ANALYTICS_ACTIVITY_DISMISSED_TUTORIAL_LABEL = 'User left tutorial without completing it';

// export const GOOGLE_ANALYTICS_ACTIVITY_FINISHED_TUTORIAL_EVENT = 'Finished Tutorial';
// export const GOOGLE_ANALYTICS_ACTIVITY_FINISHED_TUTORIAL_LABEL = 'User completed the tutorial';

export const MAXIMUM_WEEKVIEW_DAY_BUILDING_ITERATIONS = 7
export const NEED_TO_KNOW_OFFSET = 3
export const DAYS_TO_BUILD = 7
export const PERIOD_WEEKS_TO_BUILD = 4

export const RANGE_REVIEW_PREFIX = 'range review:'

export const GROUPED_ITEM_TYPE = 'groupedItem'

export const CALENDAR_TYPES = {
  week: 'week',
  period: 'period'
}

export const MAXIMUM_PERIOD_VIEW_VISIBLE_ITEMS = 3
export const MAXIMUM_WEEK_VIEW_VISIBLE_ITEMS = 6

export const PLANNING_WEEK_VIEW = 'week'
export const PLANNING_PERIOD_VIEW = 'period'
export const MAXIMUM_PERIODVIEW_WEEK_BUILDING_ITERATIONS = 4

export const ASSIGNMENT_ACTION_ADD = 'ASSIGNMENT_ACTION_ADD'
export const ASSIGNMENT_ACTION_REMOVE = 'ASSIGNMENT_ACTION_REMOVE'

export const GLOBAL_SEARCH_PAGE_LIMIT = 10

export const FAILED_EVENT_DATA_SUBMISSION =
  'Failed to submit data. Please try again.'

export const FAILED_AUDIT_SUBMISSION =
  'Failed to submit audit record. Please try again.'

export const MAX_RETRY = 3

export const COMPLETED_PAGE_SIZE = 30
export const MISSED_PAGE_SIZE = 30
export const HISTORY_PAGE_SIZE = 30

export const SATISFIED_TEXT = 'Satisfied'
export const SATISFIED_VALUE = 'Satisfied'
export const NOTSATISFIED_TEXT = 'Not satisfied'
export const NOTSATISFIED_VALUE = 'NotSatisfied'
export const ADHOC_ACTION_NEW_MSG = 'created a new action'
export const ACTION_NEW_MSG = 'responded and created a new action'
export const ACTION_DEFER_MSG =
  'responded and changed the completion date on an action'
export const ACTION_COMPLETE_MSG = 'responded and completed the action'
export const ACTION_SATISFIED_MSG = 'responded and '

export const EVENT_AUDIT_SATISFACTION = 'SatisfactionResponse'
export const EVENT_AUDIT_DEFER_DATE = 'DueDateDeferred'
export const EVENT_AUDIT_COMPLETION = 'ActionCompleted'
export const EVENT_FOLLOW_UP_ACTION_CREATED = 'FollowActionCreated'
export const EVENT_ADHOC_ACTION_CREATED = 'AdhocTaskCreated'

export const ACTIVITY_SOURCE_RISK = 'risk'
export const ACTIVITY_SOURCE_COLLEAGUE = 'colleague'

export const FILE_TYPE_IMAGE = 'image'
export const FILE_TYPE_PDF = 'pdf'

export const ATTRIBUTES = {
  argos: 'Argos',
  beauty: 'Beauty',
  christmas: 'Christmas',
  countdownToChristmas: 'Countdown to Christmas',
  customerExperience: 'Customer Experience',
  easter: 'Easter',
  event: 'Event',
  food: 'Food',
  foodProduction: 'Food Production',
  foodServices: 'Food Services',
  gmAndClothing: 'GM & Clothing',
  groceriesOnline: 'Groceries Online',
  habitat: 'Habitat',
  lineManagers: 'Line Managers',
  nightTwilightShift: 'Night / twilight shift',
  risk: 'Risk',
  runningTheStore: 'Running the Store'
}

export const RISK_IGNORED_ATTRIBUTES = [
  ATTRIBUTES.christmas,
  ATTRIBUTES.countdownToChristmas,
  ATTRIBUTES.easter,
  ATTRIBUTES.event,
  ATTRIBUTES.lineManagers
]

export const FILTER_PAGES = {
  planning: 'planning',
  history: 'history',
  risk: 'risk',
  search: 'search',
  today: 'today'
}

export const DISTRIBUTION_CENTRES_GROUP = 'All Distribution Centres'

export const SAINSBURYS_ARGOS_SIS_GROUP = 'All Argos Store In Store stores'

const getEnvironment = () => {
  const { host } = window.location
  if (host.match('^[a-zA-Z0-9]*-dev.retail-azure.js-devops.co.uk$')) {
    return 'dev'
  }

  if (host.match('^[a-zA-Z0-9]*-test.retail-azure.js-devops.co.uk$')) {
    return 'test'
  }

  if (host.match('^[a-zA-Z0-9]*-preprod.retail-azure.js-devops.co.uk$')) {
    return 'preprod'
  }

  if (host.match('^[a-zA-Z0-9]*.sainsburys.co.uk$')) {
    return 'prod'
  }

  return 'dev'
}

export const IDENTITY_URL =
  getEnvironment() === 'prod'
    ? 'https://login.retail-prod.js-devops.co.uk'
    : 'https://login.retail.js-devops.co.uk'

export const ENVIRONMENT = getEnvironment()

export const SSC_STORE_GROUP = 'SSCs'

export const RISK_TRIAL_GROUP_LIST = [
  'All Argos Standalone stores',
  'Clearance Bargains',
  'RFC',
  'Bradwell Media Centre',
  'All Argos Small Store Format Stores',
  'All Habitat stores',
  'All Argos Local Fulfilment Centres',
  'eBay',
  DISTRIBUTION_CENTRES_GROUP,
  SSC_STORE_GROUP,
  'Region - C09',
  'All stores'
]

export const TODO_TYPE_ATTRIBUTES_CATEGORY = 'Todo Type'

export const RISK_TYPE_ATTRIBUTES_CATEGORY = 'Risk Type'

export const TASK_TYPE_ATTRIBUTES_CATEGORY = 'Task Type'

export const SSC_STORE_CODE = 'SSC'

export const FILE_UPLOAD_SURVEY_TYPE = 'FileUpload'
