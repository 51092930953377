import React from 'react'
import { FILTER_PAGES } from 'utils/constants'
import FilterLayout from '../Filtering/FilterLayout'
import WeeklyGroupedActivities from './WeeklyGroupedActivities'

const WeeklyGroupedActivitiesWithFilters = () => (
  <FilterLayout page={FILTER_PAGES.history} header={<h2>History</h2>}>
    <WeeklyGroupedActivities />
  </FilterLayout>
)

export default WeeklyGroupedActivitiesWithFilters
