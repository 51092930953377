import React from 'react'

const Skeleton = () => (
  <div>
    <div className="text-input__loading">
      <div className="text-input__loading--line activity-title" />
      <div className="ln-u-soft-top">
        <div className="text-input__loading--line activity-label-info" />
        <div className="text-input__loading--line activity-label-info" />
      </div>
      <div className="ln-u-soft-top">
        <div className="text-input__loading--line activity-desc" />
        <div className="text-input__loading--line activity-desc" />
        <div className="text-input__loading--line activity-desc" />
      </div>
      <div className="ln-u-soft-top">
        <div className="text-input__loading--line" />
        <div className="text-input__loading--line activity-assign-control" />
      </div>
    </div>
  </div>
)

export default Skeleton
