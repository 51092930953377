import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StatusCard } from '@jsluna/card'
import { FlagBody, FlagWrapper } from '@jsluna/grid'
import GroupedActivitiesModal from './ActivitiesPreview/GroupedActivitiesModal'

const GroupedItemHolder = ({ className, item, history }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <a
        role="presentation"
        style={{ cursor: 'pointer' }}
        onClick={() => setIsModalOpen(true)}
      >
        <StatusCard
          className={className}
          status={item.isUrgent ? 'danger' : 'none'}
        >
          <FlagWrapper>
            <FlagBody>
              <div
                className="ln-u-text-align-left"
                style={{
                  paddingLeft: '0.5rem',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                <b
                  style={{
                    marginBottom: '0rem',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }}
                >
                  <span className="c-grouped-item__count">
                    {item.items.length}
                  </span>
                  {item.title}
                </b>
              </div>
            </FlagBody>
          </FlagWrapper>
        </StatusCard>
      </a>
      <GroupedActivitiesModal
        group={item}
        isOpen={isModalOpen}
        activities={item.items}
        onClose={() => setIsModalOpen(false)}
        history={history}
      />
    </>
  )
}

GroupedItemHolder.propTypes = {
  className: PropTypes.string.isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    duration: PropTypes.number,
    position: PropTypes.number,
    title: PropTypes.string,
    dueDate: PropTypes.string,
    spaceAbove: PropTypes.number,
    attributes: PropTypes.array,
    isCompleted: PropTypes.bool,
    isMissed: PropTypes.bool,
    isPast: PropTypes.bool,
    isUrgent: PropTypes.bool,
    items: PropTypes.array
  }).isRequired,
  history: PropTypes.object.isRequired
}

export default GroupedItemHolder
