import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import ActivitiesAPI from 'api'
import { connect } from 'react-redux'
import StoresApi from '../api/storesApi'
import { setCurrentStore } from '../store/current-store/actions'
import { CHANGE_STORE, LOGOUT } from './constants'

const userStoreLoginLoggedKey = 'userStoreLoginLogged'
const userStoreLoginHasBeenLogged = () => {
  const userStoreLogin = JSON.parse(
    localStorage.getItem(userStoreLoginLoggedKey)
  )
  return userStoreLogin && userStoreLogin.logged
}

export const logUserStoreLogin = storeCode => {
  if (storeCode !== null) {
    StoresApi.postUserIsLoggedIn(storeCode)
    localStorage.setItem(
      userStoreLoginLoggedKey,
      JSON.stringify({ logged: true })
    )
  }
}

export const resetUserStoreLogin = () =>
  localStorage.setItem(
    userStoreLoginLoggedKey,
    JSON.stringify({ logged: false })
  )

const colleagueSettingsStr = 'colleagueSettings'

export const getStoreFromStorage = () => {
  const settings = JSON.parse(localStorage.getItem(colleagueSettingsStr))
  return settings
    ? { code: settings.storeCode, name: settings.storeName }
    : null
}

export const setColleagueSettingsToStorage = callback => {
  ActivitiesAPI.getColleagueSettings().then(response => {
    localStorage.setItem('colleagueSettings', JSON.stringify(response))
    if (callback) {
      callback()
    }
    return response
  })
}

export const getColleagueSettingsFromStorage = () =>
  JSON.parse(localStorage.getItem(colleagueSettingsStr)) || null

export const setStoreToStorage = newStore => {
  const currentSettings = JSON.parse(localStorage.getItem(colleagueSettingsStr))
  const newSettings = {
    ...currentSettings,
    storeCode: newStore.code,
    storeName: newStore.name
  }
  localStorage.setItem(colleagueSettingsStr, JSON.stringify(newSettings))
  if (!currentSettings || currentSettings.storeCode !== newStore.code) {
    logUserStoreLogin(newStore.code)
  }
}

export const forceStoreSelection = WrappedComponent => {
  const hoc = class extends React.Component {
    constructor(props) {
      super(props)

      this.setCurrentStore = this.setCurrentStore.bind(this)
    }

    componentDidMount() {
      const currentColleague = getColleagueSettingsFromStorage()
      if (currentColleague) {
        if (!userStoreLoginHasBeenLogged()) {
          logUserStoreLogin(currentColleague.storeCode)
          this.setCurrentStore()
        }
      } else {
        setColleagueSettingsToStorage(this.setCurrentStore)
      }
    }

    setCurrentStore() {
      const store = getStoreFromStorage()
      if (store.code === null && store.name === null) {
        this.props.history.push(CHANGE_STORE)
      }
      this.props.setStore(store)
    }

    render() {
      const colleagueSettings = getColleagueSettingsFromStorage()
      if (colleagueSettings === null) {
        return <WrappedComponent {...this.props} />
      }
      if (
        getStoreFromStorage().code === null &&
        window.location.pathname !== LOGOUT &&
        window.location.pathname !== CHANGE_STORE
      ) {
        return <Redirect to={CHANGE_STORE} />
      }
      return <WrappedComponent {...this.props} />
    }
  }
  hoc.propTypes = {
    history: PropTypes.object.isRequired,
    setStore: PropTypes.func.isRequired
  }
  const mapDispatchToProps = dispatch => ({
    setStore: store => dispatch(setCurrentStore(store))
  })
  return connect(null, mapDispatchToProps)(hoc)
}
