import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card } from '@jsluna/card'
import { fetchAuditEventRequest } from 'actions'
import Skeleton from './Skeleton'

const AuditFetcher = ({
  children,
  activity,
  history,
  isFetching,
  getAudit,
  audit
}) => {
  useEffect(() => {
    getAudit(activity.referenceId)
  }, [])

  if (!children) return null

  if (isFetching)
    return (
      <Card>
        <Skeleton />
      </Card>
    )

  return <>{React.cloneElement(children, { activity, history, audit })}</>
}

const mapStateToProps = state => ({
  audit: state.activities.audit,
  isFetching: state.activities.isFetching
})

const mapDispatchToProps = dispatch => ({
  getAudit: activityReference =>
    dispatch(fetchAuditEventRequest(activityReference))
})

AuditFetcher.propTypes = {
  getAudit: PropTypes.func.isRequired,
  activity: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  children: PropTypes.any,
  audit: PropTypes.array
}

AuditFetcher.defaultProps = {
  children: null,
  audit: null
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditFetcher)
