import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import {
  ProgressIndicator,
  ProgressSpinner,
  ProgressMessage
} from '@jsluna/progress'
import { ComplianceChart } from '@jsainsburyplc/activity-compliance'
import ReportCard from './ReportCard'
import PeriodWeekSelector from './PeriodWeekSelector'
import TasksByStoreReport from './TasksByStoreReport'

const calculateIncomplete = (completed, total) =>
  total > 0 ? total - completed : 0

const calculateCompletionRate = (completed, total) => {
  let rate = 0

  if (total > 0) {
    rate = completed / total
  }

  return rate
}

export const RegionTaskReport = ({
  loading,
  error,
  overviewLoading,
  overviewError,
  financialWeeks,
  riskRegionTaskCompliance,
  riskRegionTaskOverviewCompliance,
  selectedFinancialWeek,
  setSelectedFinancialWeek
}) => {
  if (error || overviewError)
    return <span>We failed to fetch the Tasks report, please try again!</span>

  const completed = riskRegionTaskCompliance
    .map(x => x.completed)
    .reduce((p, a) => p + a, 0)

  const total = riskRegionTaskCompliance
    .map(x => x.total)
    .reduce((p, a) => p + a, 0)

  const incomplete = calculateIncomplete(completed, total)
  const completionRate = calculateCompletionRate(completed, total)

  const currentWeekLabel = financialWeeks
    .filter(x => x.key === selectedFinancialWeek)
    .map(y => `P${y.periodNumber} WK${y.weekEnumerator}`)

  return (
    <GridWrapper matrix verticalAlign="center">
      {riskRegionTaskOverviewCompliance.length > 0 && (
        <GridItem size="1/1">
          <Card>
            <GridWrapper>
              <GridItem size={{ default: '1/1', sm: '1/2' }}>
                <h3 className="ln-u-flush-bottom">Risk task compliance</h3>
                <span>Previous 8 weeks</span>
              </GridItem>
              <GridItem size="1/1">
                <ComplianceChart
                  data={riskRegionTaskOverviewCompliance}
                  error={error}
                />
              </GridItem>
            </GridWrapper>
          </Card>
        </GridItem>
      )}
      {!overviewLoading && (
        <GridItem size="1/1" className="ln-u-hard-bottom">
          <PeriodWeekSelector
            selectedFinancialWeek={selectedFinancialWeek}
            setSelectedFinancialWeek={setSelectedFinancialWeek}
          />
        </GridItem>
      )}
      {loading ? (
        <div className="ln-u-padding-top">
          <ProgressIndicator loading preventFocus>
            <ProgressSpinner className="ln-u-push-right-sm" />
            <ProgressMessage>Loading Tasks Report...</ProgressMessage>
          </ProgressIndicator>
        </div>
      ) : (
        <GridItem size="1/1">
          <Card>
            <GridWrapper>
              <GridItem size="1/1">
                <h6>Task completion rate for {currentWeekLabel}</h6>
              </GridItem>
              <GridItem size={{ default: '1/3', md: '1/3' }}>
                <ReportCard
                  text="Completed"
                  type="number"
                  value={completed}
                  colour="risk-compliance-report-card--green"
                />
              </GridItem>
              <GridItem size={{ default: '1/3', md: '1/3' }}>
                <ReportCard
                  text="Incomplete"
                  type="number"
                  value={incomplete}
                  colour="risk-compliance-report-card--red"
                />
              </GridItem>
              <GridItem size={{ default: '1/3', md: '1/3' }}>
                <ReportCard
                  text="Completion"
                  type="percentage"
                  value={completionRate}
                  colour="risk-compliance-report-card--green"
                />
              </GridItem>
              <GridItem size="1/1">
                <TasksByStoreReport data={riskRegionTaskCompliance} />
              </GridItem>
            </GridWrapper>
          </Card>
        </GridItem>
      )}
    </GridWrapper>
  )
}

const mapStateToProps = state => ({
  financialWeeks: state.financialCalendar.financialWeeks.map((x, i) => ({
    ...x,
    key: i
  })),
  loading: state.report.loading,
  error: state.report.error,
  overviewLoading: state.report.regionOverviewLoading,
  overviewError: state.report.error,
  riskRegionTaskCompliance: state.report.riskRegionTaskCompliance,
  riskRegionTaskOverviewCompliance:
    state.report.riskRegionTaskOverviewCompliance
})

RegionTaskReport.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  overviewLoading: PropTypes.bool,
  overviewError: PropTypes.bool,
  financialWeeks: PropTypes.array,
  riskRegionTaskCompliance: PropTypes.array,
  riskRegionTaskOverviewCompliance: PropTypes.array,
  selectedFinancialWeek: PropTypes.number.isRequired,
  setSelectedFinancialWeek: PropTypes.func.isRequired
}

RegionTaskReport.defaultProps = {
  loading: false,
  error: false,
  overviewLoading: false,
  overviewError: false,
  financialWeeks: [],
  riskRegionTaskCompliance: [],
  riskRegionTaskOverviewCompliance: []
}

export default connect(mapStateToProps, null)(RegionTaskReport)
