import React from 'react'
import PropTypes from 'prop-types'
import { GridItem, GridWrapper } from '@jsluna/grid'

const TasksByStoreReportRow = ({ storeName, completed, total }) => {
  const completion = total > 0 ? completed / total : 0

  return (
    <GridWrapper className="ln-u-margin-top*3">
      <GridItem size="1/2">
        <span className="ln-u-ellipsis">{storeName}</span>
      </GridItem>
      <GridItem
        size="1/2"
        className="ln-u-text-align-right ln-u-padding-top*1/2"
      >
        <span>
          <strong>
            {completed} / {total} ({Math.round(completion * 100)}%)
          </strong>
        </span>
      </GridItem>
      <GridItem size="1/1">
        <div className="report-bar">
          <div
            className="report-bar--completion"
            style={{ '--completion': `${completion}` }}
          />
        </div>
      </GridItem>
    </GridWrapper>
  )
}

TasksByStoreReportRow.propTypes = {
  storeName: PropTypes.string,
  completed: PropTypes.number,
  total: PropTypes.number
}

TasksByStoreReportRow.defaultProps = {
  storeName: '',
  completed: 0,
  total: 0
}

export default TasksByStoreReportRow
