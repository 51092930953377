import React from 'react'
import PropTypes from 'prop-types'
import {
  AsyncAutocompleteField,
  MultiAutocompleteField
} from '@jsluna/autocomplete'
import { v1 } from 'uuid'
import { connect } from 'react-redux'
import {
  assignActivity,
  removeAssignment,
  fetchColleagueAssignmentsRequest
} from 'actions'
import ActivitiesAPI from 'api'
import { getStoreFromStorage } from 'utils/currentStore'
import {
  getActionPerformed,
  assignColleagueToActivity,
  removeColleagueFromActivity
} from 'utils/activity/assignment'
import {
  ASSIGNMENT_ACTION_ADD,
  ASSIGNMENT_ACTION_REMOVE,
  ACTIVITY_TYPE_ACTION,
  ACTIVITY_TYPE_AUDIT,
  ACTIVITY_TYPE_TODO
} from 'utils/constants'

export class AssignmentControl extends React.Component {
  constructor(props) {
    super(props)

    this.handleAutoCompleteAction = this.handleAutoCompleteAction.bind(this)
  }

  componentDidMount() {
    this.props.getColleagueAssignments(this.props.activityReferenceId)
  }

  handleAutoCompleteAction(selectedArray) {
    const actionPerformed = getActionPerformed(
      selectedArray,
      this.defaultSelected
    )
    switch (actionPerformed) {
      case ASSIGNMENT_ACTION_ADD:
        assignColleagueToActivity(
          this.props.activityReferenceId,
          selectedArray,
          this.defaultSelected,
          this.props.assign
        )
        break
      case ASSIGNMENT_ACTION_REMOVE:
        removeColleagueFromActivity(
          this.props.activityReferenceId,
          selectedArray,
          this.defaultSelected,
          this.props.remove
        )
        break
      default:
        break
    }
  }

  render() {
    const {
      colleagueAssignments,
      assigningActivity,
      activityType,
      activitySource
    } = this.props

    const limitToRiskAudience =
      activityType === ACTIVITY_TYPE_AUDIT ||
      activityType === ACTIVITY_TYPE_ACTION ||
      (activityType === ACTIVITY_TYPE_TODO && activitySource === 'colleague')

    const loadOptions = value =>
      ActivitiesAPI.getColleagues(
        getStoreFromStorage().code,
        value.replace(/ +/g, '+'),
        limitToRiskAudience
      )
        .then(response => response.json())
        .then(response =>
          response.map(x => ({
            label: `${x.preferredName.length ? x.preferredName : x.firstName} ${
              x.lastName
            }`,
            value: x.azureId
          }))
        )

    this.defaultSelected = colleagueAssignments.map(item => ({
      label: item.assignedToName,
      value: item.assignedToId
    }))
    return (
      <AsyncAutocompleteField
        name="assignment-control"
        placeholder={`${
          this.defaultSelected.length === 0 || assigningActivity
            ? 'Enter colleague name(s)'
            : ''
        }`}
        loadOptions={value => value.length > 0 && loadOptions(value)}
        minChars={3}
        label="Assign to a colleague"
        key={v1()}
        onSelect={selectedArray => this.handleAutoCompleteAction(selectedArray)}
        noResultsMessage="No colleagues found"
        disabled={assigningActivity}
      >
        <MultiAutocompleteField
          key={v1()}
          defaultSelectedOptions={this.defaultSelected}
        />
      </AsyncAutocompleteField>
    )
  }
}

function mapStateToProps(state) {
  return {
    colleagueAssignments: state.activities.currentActivity.colleagueAssignments,
    activityReferenceId: state.activities.currentActivity.referenceId,
    assigningActivity: state.activities.currentActivity.assigningActivity,
    activitySource: state.activities.currentActivity.source,
    activityType: state.activities.currentActivity.type
  }
}

function mapDispatchToProps(dispatch) {
  return {
    assign: (refId, colleagueId, colleagueName) =>
      dispatch(assignActivity(refId, colleagueId, colleagueName)),
    remove: (activityReference, unassignedToId) =>
      dispatch(removeAssignment(activityReference, unassignedToId)),
    getColleagueAssignments: refId =>
      dispatch(fetchColleagueAssignmentsRequest(refId))
  }
}

AssignmentControl.propTypes = {
  assign: PropTypes.func.isRequired,
  colleagueAssignments: PropTypes.array,
  activityReferenceId: PropTypes.string.isRequired,
  activitySource: PropTypes.string,
  activityType: PropTypes.string,
  remove: PropTypes.func.isRequired,
  getColleagueAssignments: PropTypes.func.isRequired,
  assigningActivity: PropTypes.bool
}

AssignmentControl.defaultProps = {
  colleagueAssignments: [],
  assigningActivity: false,
  activitySource: null,
  activityType: null
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentControl)
