import fetchAuth from 'utils/fetchAuth'
import { Scopes } from '../authentication/authConfig'

const fetchTutorials = (
  url,
  options = {},
  scope = Scopes.ActivityTutorialApiReadWriteAll
) => fetchAuth(url, options, scope)

export default fetchTutorials
