import publicClientApplication from './msalInstance'
import authConfig from './authConfig'
import { ROUTE_TODAY } from '../utils/constants'

const getAccount = account =>
  account ||
  publicClientApplication.getActiveAccount() ||
  (publicClientApplication.getAllAccounts().length === 1 &&
    publicClientApplication.getAllAccounts()[0])

const AuthApi = {
  acquireTokenSilent(scopes, account) {
    // eslint-disable-next-line no-param-reassign
    account = getAccount()
    return publicClientApplication.acquireTokenSilent({
      scopes,
      account
    })
  },
  acquireTokenRedirect(scopes, account) {
    // eslint-disable-next-line no-param-reassign
    account = getAccount(account)
    return publicClientApplication.acquireTokenRedirect({
      scopes,
      account
    })
  },
  logoutRedirect(account) {
    // eslint-disable-next-line no-param-reassign
    account = getAccount(account)
    return publicClientApplication.logoutRedirect({
      authority: authConfig.auth.authority,
      account,
      postLogoutRedirectUri: `${ROUTE_TODAY}`
    })
  }
}

export default AuthApi
