export const FINANCIAL_WEEKS_LOADING = 'FINANCIAL_WEEKS_LOADING'
export const FINANCIAL_WEEKS_REQUEST = 'FINANCIAL_WEEKS_REQUEST'
export const FINANCIAL_WEEKS_SUCCESS = 'FINANCIAL_WEEKS_SUCCESS'
export const FINANCIAL_WEEKS_FAILURE = 'FINANCIAL_WEEKS_FAILURE'

export const RISK_TASK_REPORT_LOADING = 'RISK_TASK_REPORT_LOADING'
export const RISK_TASK_REPORT_REQUEST = 'RISK_TASK_REPORT_REQUEST'
export const RISK_TASK_REPORT_SUCCESS = 'RISK_TASK_REPORT_SUCCESS'
export const RISK_TASK_REPORT_FAILURE = 'RISK_TASK_REPORT_FAILURE'

export const RISK_TASK_OVERVIEW_REPORT_LOADING =
  'RISK_TASK_OVERVIEW_REPORT_LOADING'
export const RISK_TASK_OVERVIEW_REPORT_REQUEST =
  'RISK_TASK_OVERVIEW_REPORT_REQUEST'
export const RISK_TASK_OVERVIEW_REPORT_SUCCESS =
  'RISK_TASK_OVERVIEW_REPORT_SUCCESS'
export const RISK_TASK_OVERVIEW_REPORT_FAILURE =
  'RISK_TASK_OVERVIEW_REPORT_FAILURE'

export const RISK_ACTION_REPORT_LOADING = 'RISK_ACTION_REPORT_LOADING'
export const RISK_ACTION_REPORT_REQUEST = 'RISK_ACTION_REPORT_REQUEST'
export const RISK_ACTION_REPORT_SUCCESS = 'RISK_ACTION_REPORT_SUCCESS'
export const RISK_ACTION_REPORT_FAILURE = 'RISK_ACTION_REPORT_FAILURE'

export const RISK_REGION_TASK_REPORT_LOADING = 'RISK_REGION_TASK_REPORT_LOADING'
export const RISK_REGION_TASK_REPORT_REQUEST = 'RISK_REGION_TASK_REPORT_REQUEST'
export const RISK_REGION_TASK_REPORT_SUCCESS = 'RISK_REGION_TASK_REPORT_SUCCESS'
export const RISK_REGION_TASK_REPORT_FAILURE = 'RISK_REGION_TASK_REPORT_FAILURE'

export const RISK_REGION_TASK_OVERVIEW_REPORT_LOADING =
  'RISK_REGION_TASK_OVERVIEW_REPORT_LOADING'
export const RISK_REGION_TASK_OVERVIEW_REPORT_REQUEST =
  'RISK_REGION_TASK_OVERVIEW_REPORT_REQUEST'
export const RISK_REGION_TASK_OVERVIEW_REPORT_SUCCESS =
  'RISK_REGION_TASK_OVERVIEW_REPORT_SUCCESS'
export const RISK_REGION_TASK_OVERVIEW_REPORT_FAILURE =
  'RISK_REGION_TASK_OVERVIEW_REPORT_FAILURE'

export const financialWeeksLoading = () => ({
  type: FINANCIAL_WEEKS_LOADING
})

export const financialWeeksRequest = () => ({
  type: FINANCIAL_WEEKS_REQUEST
})

export const financialWeeksSuccess = payload => ({
  type: FINANCIAL_WEEKS_SUCCESS,
  payload
})

export const financialWeeksFailure = err => ({
  type: FINANCIAL_WEEKS_FAILURE,
  err
})

export const riskTaskReportLoading = () => ({
  type: RISK_TASK_REPORT_LOADING
})

export const riskTaskReportRequest = (fromDate, toDate, withAttributes) => ({
  type: RISK_TASK_REPORT_REQUEST,
  fromDate,
  toDate,
  withAttributes
})

export const riskTaskReportSuccess = payload => ({
  type: RISK_TASK_REPORT_SUCCESS,
  payload
})

export const riskTaskReportFailure = err => ({
  type: RISK_TASK_REPORT_FAILURE,
  err
})

export const riskTaskOverviewReportLoading = () => ({
  type: RISK_TASK_OVERVIEW_REPORT_LOADING
})

export const riskTaskOverviewReportRequest = (fromDate, toDate) => ({
  type: RISK_TASK_OVERVIEW_REPORT_REQUEST,
  fromDate,
  toDate
})

export const riskTaskOverviewReportSuccess = payload => ({
  type: RISK_TASK_OVERVIEW_REPORT_SUCCESS,
  payload
})

export const riskTaskOverviewReportFailure = err => ({
  type: RISK_TASK_OVERVIEW_REPORT_FAILURE,
  err
})

export const riskActionReportLoading = () => ({
  type: RISK_ACTION_REPORT_LOADING
})

export const riskActionReportRequest = (fromDate, toDate, withAttributes) => ({
  type: RISK_ACTION_REPORT_REQUEST,
  fromDate,
  toDate,
  withAttributes
})

export const riskActionReportSuccess = payload => ({
  type: RISK_ACTION_REPORT_SUCCESS,
  payload
})

export const riskActionReportFailure = err => ({
  type: RISK_ACTION_REPORT_FAILURE,
  err
})

export const riskRegionTaskReportLoading = () => ({
  type: RISK_REGION_TASK_REPORT_LOADING
})

export const riskRegionTaskReportRequest = (fromDate, toDate) => ({
  type: RISK_REGION_TASK_REPORT_REQUEST,
  fromDate,
  toDate
})

export const riskRegionTaskReportSuccess = payload => ({
  type: RISK_REGION_TASK_REPORT_SUCCESS,
  payload
})

export const riskRegionTaskReportFailure = err => ({
  type: RISK_REGION_TASK_REPORT_FAILURE,
  err
})

export const riskRegionTaskOverviewReportLoading = () => ({
  type: RISK_REGION_TASK_OVERVIEW_REPORT_LOADING
})

export const riskRegionTaskOverviewReportRequest = (fromDate, toDate) => ({
  type: RISK_REGION_TASK_OVERVIEW_REPORT_REQUEST,
  fromDate,
  toDate
})

export const riskRegionTaskOverviewReportSuccess = payload => ({
  type: RISK_REGION_TASK_OVERVIEW_REPORT_SUCCESS,
  payload
})

export const riskRegionTaskOverviewReportFailure = err => ({
  type: RISK_REGION_TASK_OVERVIEW_REPORT_FAILURE,
  err
})
