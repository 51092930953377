import StoresApi from '../api/storesApi'

export const GET_STORE_GROUPS_REQUEST = 'GET_STORE_GROUPS_REQUEST'
export const GET_STORE_GROUPS_SUCCESS = 'GET_STORE_GROUPS_SUCCESS'
export const GET_STORE_GROUPS_FAILURE = 'GET_STORE_GROUPS_FAILURE'

export const getStoreGroupsRequest = () => ({
  type: GET_STORE_GROUPS_REQUEST
})

export const getStoreGroupsSuccess = storeGroups => ({
  type: GET_STORE_GROUPS_SUCCESS,
  storeGroups
})

export const getStoreGroupsFailure = () => ({
  type: GET_STORE_GROUPS_FAILURE
})

export const getStoreGroups = storeCode => dispatch => {
  dispatch(getStoreGroupsRequest())

  return StoresApi.getStoreGroupsByStoreCode(storeCode)
    .then(res => {
      dispatch(getStoreGroupsSuccess(res))
    })
    .catch(() => {
      dispatch(getStoreGroupsFailure())
    })
}
