import { put, call } from 'redux-saga/effects'
import {
  BrowserAuthError,
  InteractionRequiredAuthError
} from '@azure/msal-browser'
import {
  acquireTokenSilentSuccess,
  acquireTokenRedirect,
  acquireTokenSilentFailure
} from '../../authentication/actions'
import AuthApi from '../../authentication/authApi'

export function* acquireTokenSilent({ scopes, account }) {
  try {
    const authenticationResult = yield call(
      AuthApi.acquireTokenSilent,
      scopes,
      account
    )
    yield put(acquireTokenSilentSuccess(authenticationResult))
  } catch (error) {
    if (
      error instanceof InteractionRequiredAuthError ||
      error instanceof BrowserAuthError
    ) {
      yield put(acquireTokenRedirect(scopes, account))
    }
    yield put(acquireTokenSilentFailure(scopes, account))
  }
}

export function* acquireTokenByRedirect({ scopes, account }) {
  yield call(AuthApi.acquireTokenRedirect, scopes, account)
}

export default acquireTokenSilent
