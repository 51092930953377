import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { v4 } from 'uuid'
import { Card } from '@jsluna/card'
import {
  FlagWrapper,
  FlagComponent,
  FlagBody,
  GridWrapper,
  GridItem
} from '@jsluna/grid'
import { Cancel, Tick } from '@jsluna/icons'
import {
  ProgressIndicator,
  ProgressMessage,
  ProgressSpinner
} from '@jsluna/progress'
import { MediaAttachments } from '@jsainsburyplc/activity-media-display'
import { fetchAssignmentEventsPage } from 'actions'
import build, { icon as icons } from '../Builders/historyBuilder'

const renderLoader = () => (
  <Card className="ln-u-margin-top">
    <GridWrapper matrix className="activity-page-padder">
      <GridItem size="1/1">
        <h3>History of activity</h3>
        <ProgressIndicator loading preventFocus>
          <ProgressSpinner size="small" className="ln-u-push-right-sm" />
          <ProgressMessage>Loading history...</ProgressMessage>
        </ProgressIndicator>
      </GridItem>
    </GridWrapper>
  </Card>
)

const renderIcon = icon => {
  switch (icon) {
    case icons.cancel:
      return <Cancel size="large" className="ln-u-margin-right" />
    case icons.tick:
      return <Tick size="large" className="ln-u-margin-right" />
    default:
      return null
  }
}

export const History = ({
  loading,
  getAssignmentEventPage,
  assignmentEvents,
  activity,
  audit,
  showSatisfaction,
  showActionOnComplete,
  showOverdue,
  showCompleted
}) => {
  useEffect(() => {
    if (activity) {
      getAssignmentEventPage(activity.referenceId)
    }
  }, [])

  if (!activity) return null

  if (loading) return renderLoader()

  const events = build(
    activity,
    audit,
    assignmentEvents,
    showSatisfaction,
    showActionOnComplete,
    showOverdue,
    showCompleted
  )

  return (
    <Card className="ln-u-margin-top">
      <GridWrapper matrix className="activity-page-padder">
        <GridItem size="1/1">
          <h3>History of activity</h3>
          {events.map(x => (
            <FlagWrapper key={v4()} className="ln-u-margin-top*2">
              <FlagComponent alignment="top">
                <div className={`activity-page-event ${x.styles}`}>
                  {renderIcon(x.icon)} {x.status}
                </div>
              </FlagComponent>
              <FlagBody>
                <div className="ln-u-soft-left">{x.description}</div>
                {x.comments && (
                  <div className="ln-u-soft-left ln-u-soft-top-xs">
                    {x.comments}
                  </div>
                )}
                {x.media && (
                  <div className="ln-u-soft-left ln-u-soft-top-xs">
                    <MediaAttachments media={x.media} showLabel={false} />
                  </div>
                )}
              </FlagBody>
            </FlagWrapper>
          ))}
        </GridItem>
      </GridWrapper>
    </Card>
  )
}

const mapStateToProps = state => ({
  loading: state.activities.fetchingAssignmentEvents,
  assignmentEvents: state.activities.assignmentEventsPage
})

const mapDispatchToProps = dispatch => ({
  getAssignmentEventPage: activityReference =>
    dispatch(fetchAssignmentEventsPage(activityReference, 1, 100))
})

History.propTypes = {
  loading: PropTypes.bool,
  getAssignmentEventPage: PropTypes.func.isRequired,
  assignmentEvents: PropTypes.object,
  activity: PropTypes.object,
  audit: PropTypes.array,
  showSatisfaction: PropTypes.bool,
  showActionOnComplete: PropTypes.bool,
  showOverdue: PropTypes.bool,
  showCompleted: PropTypes.bool
}

History.defaultProps = {
  loading: false,
  assignmentEvents: null,
  activity: null,
  audit: null,
  showSatisfaction: false,
  showActionOnComplete: false,
  showOverdue: false,
  showCompleted: true
}

export default connect(mapStateToProps, mapDispatchToProps)(History)
