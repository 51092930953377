import React from 'react'
import PropTypes from 'prop-types'
import { ACTIVITY_TYPE_TODO } from 'utils/constants'
import ActivityCard from '../../Shared/ActivityCard/ActivityCard'
import TodoItem from '../../Activities/TodoItem/TodoItem'

export const Results = ({ items }) => {
  const currentActivities = []
  const futureActivities = []
  const pastActivities = []

  if (items.length === 0) return null

  items.map(item => {
    if (item.isCurrent) {
      return currentActivities.push(item)
    }
    if (item.isFuture) {
      return futureActivities.push(item)
    }
    return pastActivities.push(item)
  })

  const getActivityCard = item => {
    if (item.completedOn || item.isMissed) {
      return <ActivityCard key={item.id} item={item} />
    }

    return item.type === ACTIVITY_TYPE_TODO ? (
      <TodoItem item={item} key={item.id} isTodayView={false} />
    ) : (
      <ActivityCard item={item} key={item.id} isTodayView={false} />
    )
  }

  return (
    <div>
      {currentActivities.length > 0 && (
        <div id="current" className="ln-u-padding-bottom*2">
          <h3>Today</h3>
          {currentActivities.map(item => getActivityCard(item))}
        </div>
      )}
      {futureActivities.length > 0 && (
        <div className="ln-u-padding-bottom*2">
          <h3>Planned</h3>
          {futureActivities.map(item => getActivityCard(item))}
        </div>
      )}
      {pastActivities.length > 0 && (
        <div className="ln-u-padding-bottom*2">
          <h3>History</h3>
          {pastActivities.map(item => getActivityCard(item))}
        </div>
      )}
    </div>
  )
}

Results.propTypes = {
  items: PropTypes.array
}

Results.defaultProps = {
  items: []
}

export default Results
