import {
  BrowserAuthError,
  InteractionRequiredAuthError
} from '@azure/msal-browser'
import { ROUTE_404 } from 'utils/clientHost'
import history from '../configureHistory'
import AuthApi from '../authentication/authApi'
import { Scopes } from '../authentication/authConfig'

export const createHeadersForRequest = (addContentType = true) => {
  const myHeaders = new Headers()
  if (addContentType) myHeaders.append('content-type', 'application/json')
  myHeaders.append('Accept', 'application/json')
  return myHeaders
}

function handleErrors(response) {
  if (!response.ok) {
    if (response.status === 404) {
      history.push(ROUTE_404)
      return null
    }
    throw Error(response.statusText)
  }
  return response
}

const callApi = (url, accessToken, options) => {
  // eslint-disable-next-line no-param-reassign
  options = options || {}
  // eslint-disable-next-line no-param-reassign
  options.headers = options?.headers || createHeadersForRequest()
  options?.headers?.append('Authorization', `Bearer ${accessToken}`)
  return fetch(url, options).then(response => handleErrors(response))
}

export default (
  url,
  options = { headers: createHeadersForRequest() },
  scopes = Scopes.ActivityLegacyApiReadWriteAll
) =>
  AuthApi.acquireTokenSilent(scopes)
    .then(accessTokenResponse => {
      const { accessToken } = accessTokenResponse
      return callApi(url, accessToken, options)
    })
    .catch(error => {
      if (
        error instanceof InteractionRequiredAuthError ||
        error instanceof BrowserAuthError
      ) {
        AuthApi.acquireTokenRedirect(scopes).catch(tokenRedirectError => {
          throw tokenRedirectError
        })
      }
      throw error
    })
