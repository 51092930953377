import React from 'react'
import PropTypes from 'prop-types'
import { Calendar } from '@jsluna/icons'
import formatDateTime, {
  parsedDateForTimeSpan,
  TIME_FORMAT,
  dateIsToday
} from 'utils/datetimeformat'
import { ACTIVITY_TYPE_INFO } from '../../../../utils/constants'

const render = (text, keyDate, completedBy = null) => (
  <div>
    <Calendar size="large" />
    <span className="activity-icon-padder">
      {text} {formatDateTime(keyDate)}
      {completedBy && ` by ${completedBy}`}
    </span>
  </div>
)
const renderTodayWithTimespan = (text, activeFromDate, dueDate) => (
  <div>
    <Calendar size="large" />
    <span className="activity-icon-padder">
      {text} {activeFromDate.format(TIME_FORMAT)} -{' '}
      {dueDate.format(TIME_FORMAT)}
    </span>
  </div>
)
const KeyDate = ({
  completedOn,
  completedBy,
  dueDate,
  isCommunication,
  visibleFromDate,
  type,
  activeFromDate
}) => {
  if (isCommunication && visibleFromDate && type === ACTIVITY_TYPE_INFO)
    return render('Published', visibleFromDate)
  if (completedOn) return render('Completed', completedOn, completedBy)
  if (dueDate) {
    if (
      dueDate.split('T').pop() !== '23:30:00' &&
      dateIsToday(parsedDateForTimeSpan(dueDate))
    ) {
      return renderTodayWithTimespan(
        'Due today,',
        parsedDateForTimeSpan(activeFromDate),
        parsedDateForTimeSpan(dueDate)
      )
    }
    return render('Due', dueDate)
  }
  return null
}

KeyDate.propTypes = {
  completedOn: PropTypes.string,
  completedBy: PropTypes.string,
  dueDate: PropTypes.string,
  isCommunication: PropTypes.bool,
  visibleFromDate: PropTypes.string,
  type: PropTypes.string,
  activeFromDate: PropTypes.string
}

KeyDate.defaultProps = {
  completedOn: null,
  completedBy: null,
  dueDate: null,
  isCommunication: false,
  visibleFromDate: null,
  type: 'Todo',
  activeFromDate: ''
}

export default KeyDate
