import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Like } from '@jsluna/icons'
import { OutlinedButton } from '@jsluna/button'
import { ROUTE_PLANNING, ROUTE_RISK } from '../../../../utils/constants'

const NothingToSeeWithNoFilters = ({ hasRiskPage, history }) => (
  <div className="nts-message-body">
    <h1 className=" ln-u-text-align-center">
      <Like className="nts-message-icon" size="large" />
    </h1>
    <div className="ln-u-padding-sides*2">
      <h3 className="ln-u-text-align-center">
        That&apos;s it for today, but what about the rest of the week?
      </h3>
      <p>There&apos;s no other activities or communications today.</p>
      <p>
        Take a look at Planning {hasRiskPage && 'and Risks '}to see what else is
        happening this week and get ahead.
      </p>
      <OutlinedButton
        fullWidth
        className="ln-u-margin-bottom"
        onClick={() => history.push(ROUTE_PLANNING)}
      >
        Planning
      </OutlinedButton>
      {hasRiskPage && (
        <OutlinedButton fullWidth onClick={() => history.push(ROUTE_RISK)}>
          Risk
        </OutlinedButton>
      )}
    </div>
  </div>
)

NothingToSeeWithNoFilters.propTypes = {
  hasRiskPage: PropTypes.bool,
  history: PropTypes.object.isRequired
}

NothingToSeeWithNoFilters.defaultProps = {
  hasRiskPage: false
}

export default withRouter(NothingToSeeWithNoFilters)
