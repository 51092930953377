import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  buildCalendar,
  buildSchedule,
  getSelectedDayFromPeriodWeeks
} from '../../../../utils/Calendar/planning'
import PeriodViewDayHolder from '../Calendar/PeriodViewDayHolder'
import WeekGroupedItems from './WeekGroupedItems'
import DayGroupedItems from './DayGroupedItems'
import { CALENDAR_TYPES } from '../../../../utils/constants'

const PeriodView = ({
  periodActivities,
  history,
  isMobile,
  selectedDay,
  isPanelOpen,
  showAllCalendarItems
}) => {
  const [weeks, setWeeks] = useState(null)
  const [schedule, setSchedule] = useState(null)
  useEffect(() => {
    buildCalendar(
      moment(periodActivities.periodStartDate).startOf('day'),
      periodActivities.items.filter(x => x.matchesFilter),
      CALENDAR_TYPES.period,
      showAllCalendarItems
    ).then(res => setWeeks(res))

    buildSchedule(
      moment(periodActivities.periodStartDate).startOf('day'),
      periodActivities.items.filter(x => x.matchesFilter),
      CALENDAR_TYPES.period
    ).then(res => setSchedule(res))
  }, [periodActivities, showAllCalendarItems])

  return (
    weeks != null &&
    schedule !== null && (
      <div>
        {!isMobile ? (
          <>
            <div className="ln-u-bg-color-white ln-u-soft-bottom">
              {weeks.map((week, i) => (
                <div
                  className={`c-planning-view${
                    isPanelOpen ? '-condensed' : ''
                  } period`}
                  key={week[0].date.format()}
                >
                  {week.map(day => (
                    <PeriodViewDayHolder
                      history={history}
                      key={day.date.format('ddd D')}
                      day={day}
                      includeHeader={i === 0}
                      showAllCalendarItems={showAllCalendarItems}
                    />
                  ))}
                </div>
              ))}
            </div>
            <WeekGroupedItems weeks={schedule} />
          </>
        ) : (
          <>
            <h2>Schedule</h2>
            <DayGroupedItems
              day={getSelectedDayFromPeriodWeeks(schedule, selectedDay.date)}
            />
          </>
        )}
      </div>
    )
  )
}

const mapStateToProps = state => ({
  isMobile: state.activities.isMobile,
  selectedDay: state.activities.selectedCalendarDay,
  isPanelOpen: !state.activities.isMobile || state.filter.isPanelOpen,
  showAllCalendarItems: state.activities.showAllCalendarItems
})

PeriodView.propTypes = {
  periodActivities: PropTypes.shape({
    items: PropTypes.array,
    periodStartDate: PropTypes.string.isRequired
  }),
  history: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  selectedDay: PropTypes.object.isRequired,
  isPanelOpen: PropTypes.bool,
  showAllCalendarItems: PropTypes.bool
}

PeriodView.defaultProps = {
  periodActivities: {
    items: []
  },
  isPanelOpen: false,
  showAllCalendarItems: false
}

export default connect(mapStateToProps)(PeriodView)
