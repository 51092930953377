import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setCurrentStore } from 'store/current-store/actions'
import StoreList from './StoreList'
import { filterReset } from '../../../../actions/filter'

const mapStateToProps = state => ({
  stores: state.stores.list,
  currentStore: state.currentStore,
  loading: state.stores.loading,
  requestPerformed: state.stores.requestPerformed
})

const mapDispatchToProps = dispatch => ({
  selectStore: store => {
    dispatch(setCurrentStore(store))
  },
  resetFilters: () => dispatch(filterReset())
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StoreList)
)
