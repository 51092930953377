import * as actions from '../actions'

const initialState = {
  item: null
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_NOTIFICATION:
      return {
        ...state,
        item: action.notification
      }
    case actions.CLOSE_NOTIFICATION:
      return {
        ...state,
        item: null
      }
    default:
      return {
        ...state
      }
  }
}

export default notificationReducer
