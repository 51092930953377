import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'
import PropTypes from 'prop-types'
import moment from 'moment'
import { PLANNING_WEEK_VIEW, PLANNING_PERIOD_VIEW } from 'utils/constants'
import FailedToFetch from 'components/Areas/ErrorPages/FailedToFetch'
import {
  requestActivitiesForWeek,
  requestActivitiesForPeriod,
  navigateToNextWeek,
  navigateToPrevWeek,
  changePlanningView,
  toggleAllCalendarItems
} from '../../../actions'
import Header from './Header'
import WeekView from './WeekView'
import PeriodView from './PeriodView'
import {
  groupActivitiesByDayInWeek,
  groupActivitiesByWeekInPeriod
} from '../../../utils/Calendar/planning'

export const Planning = ({
  weekInView,
  periodInView,
  onNavigateNextWeek,
  onNavigatePrevWeek,
  onNavigateToWeek,
  onNavigateToPeriod,
  onSwitchPlanningView,
  onToggleAllCalendarItems,
  weekActivities,
  periodActivities,
  isFetching,
  history,
  hasError,
  view,
  isMobile,
  filters
}) => {
  useEffect(() => {
    switch (view) {
      case PLANNING_WEEK_VIEW:
        onNavigateToWeek(weekInView)
        break
      case PLANNING_PERIOD_VIEW:
        onNavigateToPeriod(periodInView)
        break
      default:
        break
    }
  }, [view, filters])

  const handlePageForward = () => {
    switch (view) {
      case PLANNING_WEEK_VIEW:
        onNavigateNextWeek(weekInView)
        break
      case PLANNING_PERIOD_VIEW:
        onNavigateToPeriod(moment(periodActivities.periodEndDate).add(1, 'day'))
        break
      default:
        break
    }
  }

  const handlePageBackward = () => {
    switch (view) {
      case PLANNING_WEEK_VIEW:
        onNavigatePrevWeek(weekInView)
        break
      case PLANNING_PERIOD_VIEW:
        onNavigateToPeriod(
          moment(periodActivities.periodStartDate).add(-1, 'days')
        )
        break
      default:
        break
    }
  }

  const handleViewToday = () => {
    switch (view) {
      case PLANNING_WEEK_VIEW:
        onNavigateToWeek(moment().startOf('week'))
        break
      case PLANNING_PERIOD_VIEW:
        onNavigateToPeriod(moment().add(-1, 'days'))
        break
      default:
        break
    }
  }

  const handleChangeView = targetView => {
    onSwitchPlanningView(targetView)
    switch (targetView) {
      case PLANNING_WEEK_VIEW:
        onNavigateToWeek(weekInView)
        break
      case PLANNING_PERIOD_VIEW:
        onNavigateToPeriod(periodInView)
        break
      default:
        break
    }
  }

  const minimumDate = moment().startOf('week').add(-56, 'days')

  const populatePageData = () => {
    const pageData = {
      pageTitle: '',
      dayGroupedItems: [],
      weekGroupedItems: [],
      currentItems: [],
      componentToDisplay: '',
      pageForwardIsDisabled: false,
      pageBackwardIsDisabled: false,
      shouldShowPlanningView: false
    }

    switch (view) {
      case PLANNING_WEEK_VIEW: {
        const weekActivitiesObj = Object.assign({}, weekActivities)
        weekActivitiesObj.items =
          weekActivitiesObj.items !== undefined
            ? weekActivitiesObj.items.filter(x => x.matchesFilter)
            : []
        pageData.pageTitle =
          weekActivitiesObj.pageTitle === undefined
            ? ''
            : weekActivitiesObj.pageTitle
        pageData.pageForwardIsDisabled =
          !weekActivitiesObj.nextWeekDataIsAvailable
        pageData.pageBackwardIsDisabled =
          moment(weekInView).diff(minimumDate, 'days') < 0
        pageData.currentItems = groupActivitiesByDayInWeek(
          weekInView,
          weekActivitiesObj
        )
        pageData.componentToDisplay = (
          <WeekView id="weekview" history={history} />
        )
        break
      }
      case PLANNING_PERIOD_VIEW: {
        const periodActivitiesObj = Object.assign({}, periodActivities)
        periodActivitiesObj.items =
          periodActivitiesObj.items !== undefined
            ? periodActivities.items.filter(x => x.matchesFilter)
            : []
        pageData.pageTitle =
          periodActivitiesObj.pageTitle === undefined
            ? ''
            : periodActivitiesObj.pageTitle
        pageData.pageForwardIsDisabled =
          !periodActivitiesObj.nextPeriodDataIsAvailable
        pageData.pageBackwardIsDisabled =
          moment(periodActivities.periodEndDate)
            .add(1, 'day')
            .diff(minimumDate, 'days') < 0
        pageData.currentItems = groupActivitiesByWeekInPeriod(
          moment(periodActivitiesObj.periodStartDate).startOf('day'),
          periodActivitiesObj.items
        )
        pageData.componentToDisplay = (
          <PeriodView
            periodActivities={periodActivitiesObj}
            history={history}
          />
        )
        break
      }
      default:
        break
    }
    return pageData
  }

  const pageData = populatePageData()
  const shouldShowPlanningView =
    (weekActivities || periodActivities) && !hasError
  return (
    <div>
      <div style={{ overFlowX: 'hidden' }}>
        {hasError && <FailedToFetch />}
        {isFetching && !isMobile ? (
          <ProgressIndicator
            style={{
              position: 'relative',
              zIndex: 3,
              left: '40vw',
              marginTop: '10rem',
              marginLeft: 0,
              marginRight: 0
            }}
            loading
          >
            <ProgressSpinner />
            Loading...
          </ProgressIndicator>
        ) : (
          shouldShowPlanningView && (
            <div>
              <Header
                pageForward={handlePageForward}
                pageForwardDisabled={pageData.pageForwardIsDisabled}
                pageBackward={handlePageBackward}
                pageBackwardDisabled={pageData.pageBackwardIsDisabled}
                pageToday={handleViewToday}
                title={pageData.pageTitle}
                activities={pageData.currentItems}
                view={view}
                onChangeView={handleChangeView}
                onToggleAllCalendarItems={onToggleAllCalendarItems}
              />
              {pageData.componentToDisplay}
            </div>
          )
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  weekInView: state.activities.weekInView,
  isFetching: state.activities.isFetching,
  weekActivities: state.activities.weekActivities,
  periodActivities: state.activities.periodActivities,
  periodInView: state.activities.periodInView,
  hasError: state.activities.error || false,
  view: state.activities.planningView,
  isMobile: state.activities.isMobile,
  filters: state.filter.applied
})

const mapDispatchToProps = dispatch => ({
  onNavigateNextWeek: weekInView => {
    dispatch(navigateToNextWeek(weekInView))
  },
  onNavigatePrevWeek: weekInView => {
    dispatch(navigateToPrevWeek(weekInView))
  },
  onNavigateToWeek: week => {
    dispatch(requestActivitiesForWeek(week))
  },
  onNavigateToPeriod: period => {
    dispatch(requestActivitiesForPeriod(period))
  },
  onSwitchPlanningView: view => {
    dispatch(changePlanningView(view))
  },
  onToggleAllCalendarItems: showAll => {
    dispatch(toggleAllCalendarItems(showAll))
  }
})

Planning.propTypes = {
  weekInView: PropTypes.object.isRequired,
  periodInView: PropTypes.object.isRequired,
  onNavigateNextWeek: PropTypes.func.isRequired,
  onNavigatePrevWeek: PropTypes.func.isRequired,
  onNavigateToWeek: PropTypes.func.isRequired,
  onNavigateToPeriod: PropTypes.func.isRequired,
  onSwitchPlanningView: PropTypes.func.isRequired,
  onToggleAllCalendarItems: PropTypes.func.isRequired,
  weekActivities: PropTypes.object,
  periodActivities: PropTypes.object,
  isFetching: PropTypes.bool,
  history: PropTypes.object.isRequired,
  hasError: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  filters: PropTypes.array
}

Planning.defaultProps = {
  weekActivities: {
    items: [],
    pageTitle: ''
  },
  periodActivities: {
    items: [],
    periodNumber: '',
    nextPeriodDataIsAvailable: true
  },
  isFetching: true,
  filters: []
}

export default connect(mapStateToProps, mapDispatchToProps)(Planning)
