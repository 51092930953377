const baseUrl = `${
  process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost:5000'
}`
export const ACTIVITY_SIGNALR_HUB_URL = `${baseUrl}/signalr/hubs/activity`

export const ADD_USER_TO_STORE_GROUP = 'AddUserToStoreGroup'
export const REMOVE_USER_FROM_STORE_GROUP = 'RemoveUserFromStoreGroup'

export const EVENT_ACTIVITY_UPDATED = 'ActivityUpdated'

export const EVENT_ACTIVITIES_ADDED = 'ActivitiesAdded'
export const EVENT_ACTIVITIES_DELETED = 'ActivitiesDeleted'
export const EVENT_ACTIVITY_DELETED = 'ActivityDeleted'
export const EVENT_NOTIFICATION_RECEIVED = 'ReceiveNotification'
export const EVENT_ACTIVITY_STARTED = 'ActivityStarted'
export const EVENT_ACTIVITY_COMPLETED = 'ActivityCompleted'
export const EVENT_ACTIVITY_ASSIGNED = 'ActivityAssigned'
export const EVENT_ACTIVITY_UNASSIGNED = 'ActivityUnassigned'
export const EVENT_ACTIVITY_COMPLETION_UNDONE = 'ActivityUndoCompletion'
