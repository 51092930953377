import React from 'react'
import PropTypes from 'prop-types'
import { formatDateTimeWithYear } from 'utils/datetimeformat'
import { Calendar } from '@jsluna/icons'

const FromToRange = ({ from, to }) => {
  if (!from || !to) return null

  return (
    <div style={{ display: 'flex' }}>
      <Calendar size="large" />
      <span className="activity-icon-padder">
        {`${formatDateTimeWithYear(from)} - ${formatDateTimeWithYear(to)}`}
      </span>
    </div>
  )
}

FromToRange.propTypes = {
  to: PropTypes.string,
  from: PropTypes.string
}

FromToRange.defaultProps = {
  to: null,
  from: null
}

export default FromToRange
