import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { GridWrapper, GridItem } from '@jsluna/grid'
import {
  buildCalendar,
  buildSchedule
} from '../../../../utils/Calendar/planning'
import WeekViewDayHolder from '../Calendar/WeekViewDayHolder'
import DayGroupedItems from '../../Activities/DayGroupedItems'
import { CALENDAR_TYPES } from '../../../../utils/constants'

const WeekView = ({
  weekInView,
  activities,
  history,
  isPanelOpen,
  showAllCalendarItems
}) => {
  const [week, setWeek] = useState(null)
  const [schedule, setSchedule] = useState(null)
  const weekActivities =
    activities.items !== undefined
      ? activities.items.filter(x => x.matchesFilter)
      : []

  useEffect(() => {
    buildCalendar(
      weekInView,
      weekActivities,
      CALENDAR_TYPES.week,
      showAllCalendarItems
    ).then(res => setWeek(res))
    buildSchedule(weekInView, weekActivities, CALENDAR_TYPES.week).then(res =>
      setSchedule(res)
    )
  }, [weekInView, activities, showAllCalendarItems])

  return (
    <div>
      {week != null && schedule != null && (
        <GridWrapper>
          <GridItem size="1/1">
            <div
              className={`c-planning-view${
                isPanelOpen ? '-condensed' : ''
              } ln-u-bg-color-white panel-is-open`}
            >
              {week.map(day => (
                <WeekViewDayHolder
                  history={history}
                  key={day.date.format('ddd D')}
                  day={day}
                  includeHeader
                  showAllCalendarItems={showAllCalendarItems}
                />
              ))}
            </div>
          </GridItem>
          <GridItem size="1/1">
            <GridWrapper
              horizontalAlign="center"
              id="activities-content-container"
            >
              <GridItem size={{ md: '4/5' }}>
                <div className="ln-u-soft-top-sm" id="day-grouped-items">
                  <h2>Schedule</h2>
                  <DayGroupedItems weekActivities={schedule} />
                </div>
              </GridItem>
            </GridWrapper>
          </GridItem>
        </GridWrapper>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  weekInView: state.activities.weekInView,
  activities: state.activities.weekActivities,
  isPanelOpen: !state.activities.isMobile || state.filter.isPanelOpen,
  showAllCalendarItems: state.activities.showAllCalendarItems
})

WeekView.propTypes = {
  weekInView: PropTypes.object.isRequired,
  activities: PropTypes.shape({
    items: PropTypes.array,
    pageTitle: PropTypes.string
  }),
  history: PropTypes.object.isRequired,
  isPanelOpen: PropTypes.bool,
  showAllCalendarItems: PropTypes.bool
}

WeekView.defaultProps = {
  activities: {
    items: [],
    pageTitle: ''
  },
  isPanelOpen: false,
  showAllCalendarItems: false
}

export default connect(mapStateToProps, null)(WeekView)
