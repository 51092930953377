import { getResponse } from './helpers'

export const isValidContentData = activity =>
  activity.content &&
  activity.content.data &&
  getResponse(activity?.content?.data) !== null

export const getCheckListItems = activity => {
  try {
    const parsedData = getResponse(activity?.content?.data)
    return parsedData?.items
  } catch {
    return null
  }
}
