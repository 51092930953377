import React from 'react'
import PropTypes from 'prop-types'

const Summary = ({ summary }) =>
  summary && <div className="activity-wrap-description">{summary}</div>

Summary.propTypes = {
  summary: PropTypes.string
}

Summary.defaultProps = {
  summary: null
}

export default Summary
