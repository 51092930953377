import * as actions from '../actions'

const initialState = {
  loading: false,
  error: false,
  overviewLoading: false,
  overviewError: false,
  riskTaskCompliance: {},
  riskTaskOverviewCompliance: {},
  riskAction: {},
  riskRegionTaskCompliance: [],
  riskRegionTaskOverviewCompliance: [],
  regionOverviewLoading: false
}

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.RISK_TASK_REPORT_LOADING:
    case actions.RISK_ACTION_REPORT_LOADING:
    case actions.RISK_REGION_TASK_REPORT_LOADING:
      return {
        ...state,
        loading: true,
        error: false
      }
    case actions.RISK_TASK_OVERVIEW_REPORT_LOADING:
    case actions.RISK_REGION_TASK_OVERVIEW_REPORT_LOADING:
      return {
        ...state,
        overviewLoading: true,
        overviewError: false
      }
    case actions.RISK_TASK_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        riskTaskCompliance: action.payload
      }
    case actions.RISK_TASK_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        riskTaskCompliance: {}
      }
    case actions.RISK_TASK_OVERVIEW_REPORT_SUCCESS:
      return {
        ...state,
        overviewLoading: false,
        riskTaskOverviewCompliance: action.payload
      }
    case actions.RISK_TASK_OVERVIEW_REPORT_FAILURE:
      return {
        ...state,
        overviewLoading: false,
        overviewError: true,
        riskTaskOverviewCompliance: {}
      }
    case actions.RISK_ACTION_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        riskAction: action.payload
      }
    case actions.RISK_ACTION_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        riskAction: {}
      }
    case actions.RISK_REGION_TASK_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        riskRegionTaskCompliance: action.payload
      }
    case actions.RISK_REGION_TASK_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        riskRegionTaskCompliance: []
      }
    case actions.RISK_REGION_TASK_OVERVIEW_REPORT_SUCCESS:
      return {
        ...state,
        regionOverviewLoading: false,
        riskRegionTaskOverviewCompliance: action.payload
      }
    case actions.RISK_REGION_TASK_OVERVIEW_REPORT_FAILURE:
      return {
        ...state,
        regionOverviewLoading: false,
        error: true,
        riskRegionTaskOverviewCompliance: []
      }
    default:
      return {
        ...state
      }
  }
}

export default reportReducer
