import moment from 'moment'
import {
  DAYS_TO_BUILD,
  PERIOD_WEEKS_TO_BUILD,
  ACTIVITY_TYPE_TODO,
  ACTIVITY_TYPE_INFO,
  RANGE_REVIEW_PREFIX
} from 'utils/constants'
import { v4 } from 'uuid'
import { CALENDAR_TYPES, GROUPED_ITEM_TYPE } from '../constants'

export const groupActivitiesByDayInWeek = (startDate, activities) => {
  const weekActivities = []
  for (let i = 0; i < 7; i += 1) {
    const day = {
      date: startDate.clone().add(i, 'day'),
      items: []
    }
    if (activities.items) {
      day.items = activities.items.filter(
        x =>
          day.date.isSame(x.activeFromDate, 'day') ||
          day.date.isBetween(x.activeFromDate, x.dueDate)
      )
    }
    weekActivities.push(day)
  }
  return weekActivities
}

export const groupActivitiesByWeekInPeriod = (startDate, activities) => {
  const periodActivities = []
  for (let i = 0; i < 4; i += 1) {
    const weekActivities = []
    for (let ii = 0; ii < 7; ii += 1) {
      const daysToAdd = ii + i * 7
      const day = {
        date: startDate.clone().add(daysToAdd, 'day'),
        items: []
      }
      if (activities.items) {
        day.items = activities.items.filter(
          x =>
            day.date.isSame(x.activeFromDate, 'day') ||
            day.date.isBetween(x.activeFromDate, x.dueDate)
        )
      }
      weekActivities.push(day)
    }
    periodActivities.push(weekActivities)
  }
  return periodActivities
}

const generateWeeks = async (startDate, items, weeksToGenerate) => {
  const weeks = []
  for (let i = 0; i < weeksToGenerate; i += 1) {
    const week = []
    for (let j = 0; j < DAYS_TO_BUILD; j += 1) {
      const date = moment(startDate).add(i * DAYS_TO_BUILD + j, 'days')
      const day = { date, items: [] }
      week.push(day)
    }
    weeks.push(week)
  }

  items.forEach(item => {
    const activeFromDate = moment(item.activeFromDate)
    const dueDate = moment(item.dueDate)

    weeks.forEach(week => {
      week.forEach(day => {
        const itemIsActiveOnThisDay =
          day.date.isSame(activeFromDate, 'day') ||
          day.date.isBetween(activeFromDate, dueDate)

        if (!itemIsActiveOnThisDay) return

        day.items.push({ ...item, position: undefined, duration: undefined })
      })
    })
  })

  return weeks
}

const addPosition = async weeks => {
  const updatedWeeks = [...weeks]
  weeks.forEach((week, weekIndex) => {
    week.forEach((day, dayIndex) => {
      day.items.forEach((item, itemIndex) => {
        let itemPosition = -1
        for (
          let i = 0;
          i < updatedWeeks[weekIndex][dayIndex].items.length;
          i += 1
        ) {
          if (!day.items.find(x => x.position === i)) {
            itemPosition = i
            break
          }
        }
        updatedWeeks[weekIndex][dayIndex].items[itemIndex].position =
          itemPosition
      })
    })
  })
  return updatedWeeks
}

const sortOnPriority = (a, b) => {
  if (a.isMissed && !b.isCompleted && !b.isMissed) return 1
  if (b.isMissed && !a.isCompleted && !a.isMissed) return -1

  if (a.isCompleted && !b.isCompleted && !b.isMissed) return 1
  if (b.isCompleted && !a.isCompleted && !a.isMissed) return -1

  if (a.isUrgent && !b.isUrgent) return -1
  if (!a.isUrgent && b.isUrgent) return 1

  if (
    (a.type === ACTIVITY_TYPE_TODO || a.type === GROUPED_ITEM_TYPE) &&
    b.type === ACTIVITY_TYPE_INFO
  )
    return -1
  if (
    a.type === ACTIVITY_TYPE_INFO &&
    (b.type === ACTIVITY_TYPE_TODO || b.type === GROUPED_ITEM_TYPE)
  )
    return 1

  if (moment(a.activeFromDate).isBefore(moment(b.activeFromDate))) return -1

  return 1
}

const addShouldStartOnThisDay = async (weeks, items = []) => {
  const updatedWeeks = [...weeks]

  const filteredItems = []

  weeks.forEach((week, weekIndex) => {
    if (items.length) {
      const minDay = updatedWeeks[weekIndex][0].date
      const maxDay = updatedWeeks[weekIndex][
        updatedWeeks[weekIndex].length - 1
      ].date.add(24, 'hours')

      filteredItems[weekIndex] = items
        .filter(
          x =>
            moment(x.activeFromDate).isBetween(minDay, maxDay) ||
            moment(x.dueDate).isBetween(minDay, maxDay) ||
            minDay.isBetween(moment(x.activeFromDate), moment(x.dueDate)) ||
            maxDay.isBetween(moment(x.activeFromDate), moment(x.dueDate))
        )
        .sort(sortOnPriority)
        .map((x, i) => ({
          id: x.id,
          position: i,
          title: x.title,
          af: x.activeFromDate,
          dd: x.dueDate
        }))
    }

    week.forEach((day, dayIndex) => {
      day.items.forEach((item, itemIndex) => {
        const previousDay = weeks[weekIndex][dayIndex - 1]

        const itemIsInPreviousDay =
          previousDay && previousDay.items.find(x => x.id === item.id)

        if (filteredItems.length) {
          updatedWeeks[weekIndex][dayIndex].items[itemIndex].position =
            filteredItems[weekIndex].find(
              x =>
                x.id === updatedWeeks[weekIndex][dayIndex].items[itemIndex].id
            )
              ? filteredItems[weekIndex].find(
                  x =>
                    x.id ===
                    updatedWeeks[weekIndex][dayIndex].items[itemIndex].id
                ).position
              : undefined
        }

        updatedWeeks[weekIndex][dayIndex].items[
          itemIndex
        ].shouldStartOnThisDay = !itemIsInPreviousDay
      })
    })
  })
  return updatedWeeks
}

const addItemDuration = async weeks => {
  const updatedWeeks = [...weeks]
  weeks.forEach((week, weekIndex) => {
    week.forEach((day, dayIndex) => {
      day.items.forEach((item, itemIndex) => {
        if (item.duration !== undefined) {
          return
        }
        let duration = 1
        for (let i = 1; i < DAYS_TO_BUILD - dayIndex; i += 1) {
          const futureDay = weeks[weekIndex][dayIndex + i]

          const itemIsInFutureDay =
            futureDay && futureDay.items.find(x => x.id === item.id)

          const itemHasSamePositionInFutureDay =
            itemIsInFutureDay &&
            futureDay.items.find(x => x.id === item.id).position ===
              item.position

          if (!itemHasSamePositionInFutureDay) {
            break
          }

          duration += 1
        }
        updatedWeeks[weekIndex][dayIndex].items[itemIndex].duration = duration
      })
    })
  })
  return updatedWeeks
}

const addDayProps = async (weeks, items = []) => {
  let updatedWeeks = [...weeks]
  updatedWeeks = await addPosition(updatedWeeks)
  updatedWeeks = await addShouldStartOnThisDay(updatedWeeks, items)
  updatedWeeks = await addItemDuration(updatedWeeks)
  return updatedWeeks
}

const resolveMultiDayPosition = async (
  multiDayCalendar,
  maxSingleDayItemsForWeek
) => {
  let unPositionedItems = 0
  const updatedWeek = [...multiDayCalendar]
  multiDayCalendar.forEach((week, w) => {
    week.forEach((day, d) => {
      day.items.forEach((item, i) => {
        if (!(updatedWeek[w][d].items[i].position === undefined)) {
          updatedWeek[w][d].items[i].position += maxSingleDayItemsForWeek[w] + 1
        } else {
          unPositionedItems += 1
          updatedWeek[w][d].items[i].position =
            maxSingleDayItemsForWeek[w] - unPositionedItems
        }
      })
    })
  })

  return updatedWeek
}

const mergeSingleAndMultiDayWeeks = (single, multi, type) => {
  const mergedWeeks = [...single]
  single.forEach((week, w) => {
    week.forEach((day, d) => {
      mergedWeeks[w][d].items = [...single[w][d].items, ...multi[w][d].items]
    })
  })

  return type === CALENDAR_TYPES.week ? mergedWeeks[0] : mergedWeeks
}

export const getSelectedDayFromPeriodWeeks = (weeks, selectedDay) =>
  weeks.flat().find(x => x.date.isSame(selectedDay, 'day'))

const isSingleDay = (from, due) =>
  moment(from).format('YYYY-MM-DD') === moment(due).format('YYYY-MM-DD')

const groupItems = async items => {
  const itemsCopy = [...items]

  const rangeReviewItemTemplate = {
    title: 'Range review',
    attributes: [],
    items: [],
    type: GROUPED_ITEM_TYPE,
    activeFromDate: null,
    dueDate: null,
    isCompleted: false,
    isMissed: false,
    isPast: false,
    isUrgent: false,
    duration: 0,
    priority: '0',
    matchesFilter: true
  }

  const groupedItems = []

  items.forEach(item => {
    let matched = false
    if (item.title.toLowerCase().startsWith(RANGE_REVIEW_PREFIX)) {
      const index = itemsCopy.indexOf(item)
      itemsCopy.splice(index, 1)

      if (groupedItems.length > 0) {
        groupedItems.forEach((x, i) => {
          if (
            x.activeFromDate === item.activeFromDate &&
            x.dueDate === item.dueDate &&
            x.isCompleted === item.isCompleted &&
            x.isMissed === item.isMissed &&
            x.isPast === item.isPast &&
            x.isUrgent === item.isUrgent
          ) {
            groupedItems[i].items.push(item)
            matched = true
          }
        })
      }

      if (!matched) {
        groupedItems.push({
          ...rangeReviewItemTemplate,
          id: v4(),
          activeFromDate: item.activeFromDate,
          dueDate: item.dueDate,
          items: [item],
          duration: item.duration,
          isCompleted: item.isCompleted,
          isMissed: item.isMissed,
          isPast: item.isPast,
          isUrgent: item.isUrgent
        })
      }
    }
  })

  return itemsCopy.concat(groupedItems)
}

export const buildCalendar = async (
  startDate,
  items,
  type,
  showAllCalendarItems
) => {
  let filteredItems = [...items]

  if (!showAllCalendarItems) {
    filteredItems = items.filter(x => !x.isMissed && !x.isCompleted)
  }

  const groupedItems = await groupItems(filteredItems)

  const singleDayItems = groupedItems.filter(x =>
    isSingleDay(x.activeFromDate, x.dueDate)
  )
  const multiDayItems = groupedItems.filter(
    x => !isSingleDay(x.activeFromDate, x.dueDate)
  )
  const weeksToGenerate =
    type === CALENDAR_TYPES.week ? 1 : PERIOD_WEEKS_TO_BUILD
  const maxSingleDayItemsForWeek = []
  let singleDayCalendar = []
  let multiDayCalendar = []

  singleDayCalendar = await generateWeeks(
    startDate,
    [...singleDayItems].sort(sortOnPriority),
    weeksToGenerate
  )
  singleDayCalendar = await addDayProps(singleDayCalendar)

  singleDayCalendar.forEach((week, w) => {
    maxSingleDayItemsForWeek[w] = Math.max(...week.map(day => day.items.length))
  })

  multiDayCalendar = await generateWeeks(
    startDate,
    [...multiDayItems].sort(sortOnPriority),
    weeksToGenerate
  )
  multiDayCalendar = await addDayProps(
    multiDayCalendar,
    [...multiDayItems].sort(sortOnPriority)
  )
  multiDayCalendar = await resolveMultiDayPosition(
    multiDayCalendar,
    maxSingleDayItemsForWeek
  )

  return mergeSingleAndMultiDayWeeks(singleDayCalendar, multiDayCalendar, type)
}

export const buildSchedule = async (startDate, items, type) => {
  const weeksToGenerate =
    type === CALENDAR_TYPES.week ? 1 : PERIOD_WEEKS_TO_BUILD
  const schedule = await generateWeeks(
    startDate,
    items.sort(sortOnPriority),
    weeksToGenerate
  )

  return type === CALENDAR_TYPES.week ? schedule[0] : schedule
}
