import { call, put } from 'redux-saga/effects'
import { riskReportApi } from 'api'
import { getStoreFromStorage } from 'utils/currentStore'
import * as actions from 'actions'

export function* getRiskTaskReport({
  fromDate,
  toDate,
  withAttributes = true
}) {
  try {
    yield put(actions.riskTaskReportLoading())

    const store = getStoreFromStorage()
    if (!store) throw new Error('store not set')

    const payload = yield call(
      riskReportApi.fetchRiskTaskReport,
      store.code,
      fromDate,
      toDate,
      withAttributes
    )
    yield put(actions.riskTaskReportSuccess(payload))
  } catch (err) {
    yield put(actions.riskTaskReportFailure(err))
  }
}

export function* getRiskTaskOverviewReport({ fromDate, toDate }) {
  try {
    yield put(actions.riskTaskOverviewReportLoading())

    const store = getStoreFromStorage()
    if (!store) throw new Error('store not set')

    const payload = yield call(
      riskReportApi.fetchRiskTaskOverviewReport,
      store.code,
      fromDate,
      toDate
    )
    yield put(actions.riskTaskOverviewReportSuccess(payload))
  } catch (err) {
    yield put(actions.riskTaskOverviewReportFailure(err))
  }
}

export function* getRiskActionReport({
  fromDate,
  toDate,
  withAttributes = true
}) {
  try {
    yield put(actions.riskActionReportLoading())

    const store = getStoreFromStorage()
    if (!store) throw new Error('store not set')

    const payload = yield call(
      riskReportApi.fetchRiskActionReport,
      store.code,
      fromDate,
      toDate,
      withAttributes
    )
    yield put(actions.riskActionReportSuccess(payload))
  } catch (err) {
    yield put(actions.riskActionReportFailure(err))
  }
}

export function* getRiskRegionTaskReport({ fromDate, toDate }) {
  try {
    yield put(actions.riskRegionTaskReportLoading())

    const store = getStoreFromStorage()
    if (!store) throw new Error('store not set')

    const payload = yield call(
      riskReportApi.fetchRegionRiskTaskReport,
      store.code,
      fromDate,
      toDate
    )
    yield put(actions.riskRegionTaskReportSuccess(payload))
  } catch (err) {
    yield put(actions.riskRegionTaskReportFailure(err))
  }
}

export function* getRiskRegionTaskOverviewReport({ fromDate, toDate }) {
  try {
    yield put(actions.riskRegionTaskOverviewReportLoading())

    const store = getStoreFromStorage()
    if (!store) throw new Error('store not set')

    const payload = yield call(
      riskReportApi.fetchRegionRiskTaskOverviewReport,
      store.code,
      fromDate,
      toDate
    )
    yield put(actions.riskRegionTaskOverviewReportSuccess(payload))
  } catch (err) {
    yield put(actions.riskRegionTaskOverviewReportFailure(err))
  }
}

export default {
  getRiskTaskReport,
  getRiskTaskOverviewReport,
  getRiskActionReport,
  getRiskRegionTaskReport,
  getRiskRegionTaskOverviewReport
}
