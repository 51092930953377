import * as actions from '../actions'

const initialState = {
  loading: false,
  financialWeeks: [],
  error: false
}

const financialCalendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FINANCIAL_WEEKS_LOADING:
      return {
        ...state,
        loading: true,
        error: false
      }
    case actions.FINANCIAL_WEEKS_SUCCESS:
      return {
        ...state,
        loading: false,
        financialWeeks: action.payload
      }
    case actions.FINANCIAL_WEEKS_FAILURE:
      return {
        ...state,
        loading: false,
        financialWeeks: [],
        error: true
      }
    default:
      return {
        ...state
      }
  }
}

export default financialCalendarReducer
