import React from 'react'
import { StatusCard } from '@jsluna/card'
import { FlagWrapper, FlagComponent } from '@jsluna/grid'

const SkeletonItem = () => (
  <div className="ln-u-margin-bottom">
    <StatusCard id="todo-item-status-card" status="none">
      <FlagWrapper>
        <FlagComponent>
          <div className="text-input__loading">
            <div className="text-input__loading--line" />
            <div className="text-input__loading--line" />
            <div className="text-input__loading--line" />
          </div>
        </FlagComponent>
      </FlagWrapper>
    </StatusCard>
  </div>
)

export default SkeletonItem
