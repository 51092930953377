export const FETCH_AUDIT_EVENTS = 'FETCH_AUDIT_EVENTS'
export const FETCH_AUDIT_EVENTS_SUCCESS = 'FETCH_AUDIT_EVENTS_SUCCESS'
export const FETCH_AUDIT_EVENTS_FAILURE = 'FETCH_AUDIT_EVENTS_FAILURE'

export const fetchAuditEventRequest = instanceId => ({
  type: FETCH_AUDIT_EVENTS,
  instanceId
})

export const fetchAuditEventSuccess = audit => ({
  type: FETCH_AUDIT_EVENTS_SUCCESS,
  audit
})

export const fetchAuditEventFailure = err => ({
  type: FETCH_AUDIT_EVENTS_FAILURE,
  err
})
