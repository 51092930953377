import { takeLatest, takeEvery } from 'redux-saga/effects'

import * as activitiesActions from 'actions'
import storeSaga from './stores/saga'
import storesActions from './stores/actions'
import {
  ACQUIRE_TOKEN_SILENT,
  ACQUIRE_TOKEN_REDIRECT
} from '../authentication/actions'
import {
  acquireTokenSilent,
  acquireTokenByRedirect
} from './authentication/saga'
import activitiesSaga, {
  fetchActivitiesByWeek,
  fetchActivitiesByPeriod,
  filterActivitiesByAttributes,
  getStoreRiskCompliance
} from './activities/saga'
import activitySaga, {
  fetchActivityQuestions,
  requestCompleteActivityQuestions,
  requestActivityQuestionFileUpload,
  requestActivityMedia,
  setInstanceViewed,
  changeInstanceDueDate,
  fetchAuditEvents,
  createAdhocAction,
  requestCompleteActivity
} from './activity/saga'
import searchSaga from './search/saga'
import riskSaga from './risk/saga'
import historicActivitiesSaga from './historicActivities/saga'
import requestAttributeCategories from './attributes/saga'
import { submitEvent, submitEventThenComplete } from './event/saga'
import getFinancialWeeks from './financialCalendar/saga'
import {
  getRiskTaskReport,
  getRiskTaskOverviewReport,
  getRiskActionReport,
  getRiskRegionTaskReport,
  getRiskRegionTaskOverviewReport
} from './report/saga'
import { canAccessRisk } from './audience/saga'

function* sagas() {
  yield takeLatest(storesActions.fetch, storeSaga)
  yield takeLatest(
    activitiesActions.ACTIVITIES_FOR_PERIOD_REQUEST,
    fetchActivitiesByPeriod
  )
  yield takeLatest(
    activitiesActions.ACTIVITIES_FOR_WEEK_REQUEST,
    fetchActivitiesByWeek
  )
  yield takeLatest(activitiesActions.NAVIGATE_NEXT_WEEK, fetchActivitiesByWeek)
  yield takeLatest(activitiesActions.NAVIGATE_PREV_WEEK, fetchActivitiesByWeek)
  yield takeLatest(activitiesActions.ACTIVITIES_REQUEST, activitiesSaga)
  yield takeLatest(
    activitiesActions.FETCH_COLLEAGUE_ASSIGNMENTS_REQUEST,
    activitySaga
  )
  yield takeLatest(
    activitiesActions.ACTIVITY_QUESTIONS_REQUEST,
    fetchActivityQuestions
  )
  yield takeLatest(
    activitiesActions.ACTIVITY_QUESTIONS_COMPLETE_REQUEST,
    requestCompleteActivityQuestions
  )
  yield takeLatest(
    activitiesActions.ACTIVITY_COMPLETE_REQUEST,
    requestCompleteActivity
  )
  yield takeLatest(
    activitiesActions.ATTRIBUTE_CATEGORIES_REQUEST,
    requestAttributeCategories
  )
  yield takeLatest(activitiesActions.FINANCIAL_WEEKS_REQUEST, getFinancialWeeks)
  yield takeLatest(
    activitiesActions.RISK_TASK_REPORT_REQUEST,
    getRiskTaskReport
  )
  yield takeLatest(
    activitiesActions.RISK_TASK_OVERVIEW_REPORT_REQUEST,
    getRiskTaskOverviewReport
  )
  yield takeLatest(
    activitiesActions.RISK_ACTION_REPORT_REQUEST,
    getRiskActionReport
  )
  yield takeLatest(
    activitiesActions.RISK_REGION_TASK_REPORT_REQUEST,
    getRiskRegionTaskReport
  )
  yield takeLatest(
    activitiesActions.RISK_REGION_TASK_OVERVIEW_REPORT_REQUEST,
    getRiskRegionTaskOverviewReport
  )
  yield takeLatest(activitiesActions.SUBMIT_EVENT_DATA_REQUEST, submitEvent)
  yield takeLatest(
    activitiesActions.SUBMIT_EVENT_DATA_THEN_COMPLETE_REQUEST,
    submitEventThenComplete
  )
  yield takeEvery(
    activitiesActions.ACTIVITY_QUESTIONS_FILE_UPLOAD_REQUEST,
    requestActivityQuestionFileUpload
  )
  yield takeEvery(
    activitiesActions.UPDATED_SELECTED_ATTRIBUTES,
    filterActivitiesByAttributes
  )
  yield takeEvery(
    activitiesActions.REQUEST_ACTIVITY_MEDIA,
    requestActivityMedia
  )
  yield takeEvery(
    activitiesActions.SET_ACTIVITY_VIEWED_REQUEST,
    setInstanceViewed
  )
  yield takeEvery(
    activitiesActions.CHANGE_INSTANCE_DUE_DATE_REQUEST,
    changeInstanceDueDate
  )
  yield takeEvery(
    activitiesActions.GET_STORE_RISK_COMPLIANCE_REQUEST,
    getStoreRiskCompliance
  )
  yield takeEvery(activitiesActions.FETCH_AUDIT_EVENTS, fetchAuditEvents)
  yield takeEvery(activitiesActions.CREATE_ACTION_REQUEST, createAdhocAction)
  yield takeLatest(
    activitiesActions.ACTIVITIES_HISTORY_REQUEST,
    historicActivitiesSaga
  )
  yield takeLatest(activitiesActions.SEARCH_ACTIVITIES_REQUEST, searchSaga)
  yield takeLatest(activitiesActions.RISK_ACTIVITIES_REQUEST, riskSaga)
  yield takeLatest(activitiesActions.RISK_ACCESS_REQUEST, canAccessRisk)

  yield takeEvery(ACQUIRE_TOKEN_SILENT, acquireTokenSilent)
  yield takeEvery(ACQUIRE_TOKEN_REDIRECT, acquireTokenByRedirect)
}

export default sagas
