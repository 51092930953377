import React from 'react'
import PropTypes from 'prop-types'
import {
  MsalProvider,
  MsalAuthenticationTemplate,
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import msalInstance from './msalInstance'

const AuthWrapper = ({ children }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <p>You are not signed in! Please sign in.</p>
      </UnauthenticatedTemplate>
    </MsalAuthenticationTemplate>
  </MsalProvider>
)

AuthWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthWrapper
