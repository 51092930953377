import moment from 'moment'
import React from 'react'

const CurrentDayHeader = () => {
  const day = moment(new Date())
  return (
    <div>
      <span className="ln-u-font-weight-bold">{day.format('dddd')}</span>
      <h2>{day.format('D MMMM yyyy')}</h2>
    </div>
  )
}

export default CurrentDayHeader
