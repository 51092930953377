import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  QUESTION_TYPE_IMAGE,
  IMAGE_UPLOADING_TEXT,
  IMAGE_RETRY_UPLOADING_TEXT,
  SSC_STORE_CODE
} from 'utils/constants'
import { useAnalytics } from '@jsainsburyplc/retail-analytics'
import { FilledButton } from '@jsluna/button'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'
import { requestCompleteActivityQuestions } from 'actions'
import AssignmentControl from '../Units/AssignmentControl'
import AlreadyCompletedModal from './Survey/AlreadyCompletedModal'
import ImageConfirmationModal from './Survey/ImageConfirmationModal'

export const ProcessLegacySurvey = ({
  activity,
  currentStore,
  currentActivityQuestions,
  completeActivityQuestions,
  isFetchingQuestions,
  isUploadingImages,
  isRetryingUploadingImages,
  isCompletingActivity
}) => {
  const { sendEvent } = useAnalytics()

  if (
    !activity ||
    activity.isCompleted ||
    activity.isMissed ||
    currentStore?.code === SSC_STORE_CODE
  )
    return null

  const { questions } = currentActivityQuestions

  const allRequiredQuestionsAnswered =
    questions && questions.filter(f => f.isRequired).every(q => q.isValid)

  const handleComplete = () => {
    sendEvent('activity_complete', {
      label: activity.title,
      type: activity.type.toLowerCase()
    })
    completeActivityQuestions(activity.referenceId, questions)
  }

  const renderConditionalUploadText = () => {
    if (
      !(
        questions !== null &&
        questions.some(x => x.questionType === QUESTION_TYPE_IMAGE)
      )
    ) {
      return ''
    }

    if (isRetryingUploadingImages) {
      return IMAGE_RETRY_UPLOADING_TEXT
    }

    if (isUploadingImages) {
      return IMAGE_UPLOADING_TEXT
    }

    return ''
  }

  return (
    <>
      <Card className="ln-u-margin-top">
        <GridWrapper matrix className="activity-page-padder">
          <GridItem size="1/1">
            <h3>Action this activity</h3>
            <AssignmentControl />
            <FilledButton
              id="completeButton"
              fullWidth
              type="button"
              disabled={
                activity.hasBeenCompletedWhileViewing ||
                isFetchingQuestions ||
                !allRequiredQuestionsAnswered ||
                isUploadingImages ||
                isRetryingUploadingImages ||
                isCompletingActivity
              }
              onClick={() => handleComplete()}
            >
              Complete activity
            </FilledButton>
            {(isUploadingImages || isRetryingUploadingImages) && (
              <ProgressIndicator
                style={{
                  position: 'relative',
                  zIndex: 3
                }}
                className="ln-u-soft-bottom"
                loading
              >
                <ProgressSpinner />
                {renderConditionalUploadText()}
              </ProgressIndicator>
            )}
          </GridItem>
        </GridWrapper>
      </Card>
      <AlreadyCompletedModal
        activityCompleted={activity.hasBeenCompletedWhileViewing}
      />
      <ImageConfirmationModal />
    </>
  )
}

const mapStateToProps = state => ({
  currentStore: state.currentStore,
  currentActivityQuestions: state.activities.currentActivityQuestions,
  isFetchingQuestions: state.activities.isFetchingCurrentActivityQuestions,
  fileResponse: state.activities.currentActivityFileUploadResponse,
  isUploadingImages: state.activities.showUploadingSpinner,
  isRetryingUploadingImages: state.activities.showRetryingSpinner,
  isCompletingActivity: state.activities.completingActivity
})

const mapDispatchToProps = dispatch => ({
  completeActivityQuestions: (activityId, responses) =>
    dispatch(requestCompleteActivityQuestions(activityId, responses))
})

ProcessLegacySurvey.propTypes = {
  activity: PropTypes.object.isRequired,
  currentStore: PropTypes.object.isRequired,
  currentActivityQuestions: PropTypes.object.isRequired,
  completeActivityQuestions: PropTypes.func.isRequired,
  isFetchingQuestions: PropTypes.bool.isRequired,
  isUploadingImages: PropTypes.bool.isRequired,
  isRetryingUploadingImages: PropTypes.bool.isRequired,
  isCompletingActivity: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessLegacySurvey)
