import { call, put, select } from 'redux-saga/effects'
import ActivitiesAPI from 'api'
import * as actions from 'actions'
import { getStoreFromStorage } from 'utils/currentStore'
import { addMatchesFilterProp } from 'utils/attributes'
import moment from 'moment'
import { NEED_TO_KNOW_OFFSET } from 'utils/constants'

export function* fetchActivities({ storeCode }) {
  try {
    const filters = yield select(state =>
      state.filter.applied.filter(
        x => !x.pages || x.pages.includes(state.activities.currentSection)
      )
    )

    const activities = yield call(
      ActivitiesAPI.getTodayActivities,
      storeCode,
      1,
      filters,
      NEED_TO_KNOW_OFFSET
    )
    yield put(actions.receiveActivities(activities))
    const selectedAttibutes = yield select(
      state => state.activities.selectedAttributes
    )
    yield put(actions.updateSelectedAttributes(selectedAttibutes))
  } catch (err) {
    yield put(actions.requestActivitiesFailure())
  }
}

export function* fetchActivitiesByWeek() {
  try {
    const filters = yield select(state =>
      state.filter.applied.filter(
        x => !x.pages || x.pages.includes(state.activities.currentSection)
      )
    )
    const weekInView = yield select(state => state.activities.weekInView)
    const activities = yield call(
      ActivitiesAPI.getPlanningWeekActivities,
      getStoreFromStorage().code,
      1,
      moment(weekInView).format('YYYY-MM-DD HH:mm'),
      filters
    )
    yield put(actions.requestActivitiesForWeekSuccess(activities))
    const selectedAttibutes = yield select(
      state => state.activities.selectedAttributes
    )
    yield put(actions.updateSelectedAttributes(selectedAttibutes))
  } catch (err) {
    yield put(actions.requestActivitiesForWeekFailure())
  }
}

export function* fetchActivitiesByPeriod() {
  try {
    const filters = yield select(state =>
      state.filter.applied.filter(
        x => !x.pages || x.pages.includes(state.activities.currentSection)
      )
    )
    const periodDate = yield select(state => state.activities.periodInView)
    const activities = yield call(
      ActivitiesAPI.getPlanningPeriodActivities,
      getStoreFromStorage().code,
      1,
      moment(periodDate).format('YYYY-MM-DD HH:mm'),
      filters
    )
    yield put(actions.requestActivitiesForPeriodSuccess(activities))
    const selectedAttibutes = yield select(
      state => state.activities.selectedAttributes
    )
    yield put(actions.updateSelectedAttributes(selectedAttibutes))
  } catch (err) {
    yield put(actions.requestActivitiesForPeriodFailure())
  }
}

export function* filterActivitiesByAttributes({ selectedAttributes }) {
  try {
    const filteredActivities = addMatchesFilterProp(
      yield select(state => state.activities.activities),
      selectedAttributes
    )

    const filteredWeekActivities = addMatchesFilterProp(
      yield select(state => state.activities.weekActivities),
      selectedAttributes
    )

    const filteredPeriodActivities = addMatchesFilterProp(
      yield select(state => state.activities.periodActivities),
      selectedAttributes
    )

    yield put(
      actions.updateSelectedAttributesSuccess(
        filteredActivities,
        filteredPeriodActivities,
        filteredWeekActivities
      )
    )
  } catch (err) {
    yield put(actions.updateSelectedAttributesFailure(err))
  }
}

export function* getStoreRiskCompliance({ storeCode, attributeId }) {
  try {
    const riskCompliance = yield call(
      ActivitiesAPI.getStoreRiskCompliance,
      storeCode,
      attributeId
    )
    yield put(actions.getStoreRiskComplianceSuccess(riskCompliance))
  } catch (err) {
    yield put(actions.getStoreRiskComplianceFailure(err))
  }
}

export default fetchActivities
