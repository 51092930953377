import React from 'react'
import Logout from 'utils/logout'

const redirect = () => {
  Logout()
  return <div />
}

const LogOut = () => {
  redirect()
}

export default LogOut
