import ActivitiesAPI from '../api'
import * as Constants from '../utils/constants'
import history from '../configureHistory'

export * from './activityAssignment'
export * from './activityView'
export * from './audience'
export * from './compliance'
export * from './filter'
export * from './risk'
export * from './search'
export * from './storeGroups'
export * from './checklist'

export const ACTIVITY_REQUEST = 'ACTIVITY_REQUEST'
export const ACTIVITY_SUCCESS = 'ACTIVITY_SUCCESS'
export const ACTIVITY_FAILURE = 'ACTIVITY_FAILURE'

export const ACTIVITY_QUESTIONS_REQUEST = 'ACTIVITY_QUESTIONS_REQUEST'
export const ACTIVITY_QUESTIONS_SUCCESS = 'ACTIVITY_QUESTIONS_SUCCESS'
export const ACTIVITY_QUESTIONS_FAILURE = 'ACTIVITY_QUESTIONS_FAILURE'

export const ACTIVITIES_REQUEST = 'ACTIVITIES_REQUEST'
export const ACTIVITIES_SUCCESS = 'ACTIVITIES_SUCCESS'
export const ACTIVITIES_FAILURE = 'ACTIVITIES_FAILURE'

export const ACTIVITIES_FOR_WEEK_REQUEST = 'ACTIVITIES_FOR_WEEK_REQUEST'
export const ACTIVITIES_FOR_WEEK_SUCCESS = 'ACTIVITIES_FOR_WEEK_SUCCESS'
export const ACTIVITIES_FOR_WEEK_FAILURE = 'ACTIVITIES_FOR_WEEK_FAILURE'

export const ACTIVITIES_FOR_PERIOD_REQUEST = 'ACTIVITIES_FOR_PERIOD_REQUEST'
export const ACTIVITIES_FOR_PERIOD_SUCCESS = 'ACTIVITIES_FOR_PERIOD_SUCCESS'
export const ACTIVITIES_FOR_PERIOD_FAILURE = 'ACTIVITIES_FOR_PERIOD_FAILURE'

export const ACTIVITY_COMPLETE_REQUEST = 'ACTIVITY_COMPLETE_REQUEST'
export const ACTIVITY_COMPLETE_SUCCESS = 'ACTIVITY_COMPLETE_SUCCESS'
export const ACTIVITY_COMPLETE_FAILURE = 'ACTIVITY_COMPLETE_FAILURE'

export const ACTIVITY_QUESTIONS_COMPLETE_REQUEST =
  'ACTIVITY_QUESTIONS_COMPLETE_REQUEST'
export const ACTIVITY_QUESTIONS_COMPLETE_SUCCESS =
  'ACTIVITY_QUESTIONS_COMPLETE_SUCCESS'
export const ACTIVITY_QUESTIONS_COMPLETE_FAILURE =
  'ACTIVITY_QUESTIONS_COMPLETE_FAILURE'

export const CURRENT_STORE_SET = 'CURRENT_STORE_SET'

export const ACTIVITIES_HISTORY_REQUEST = 'ACTIVITIES_HISTORY_REQUEST'
export const ACTIVITIES_HISTORY_SUCCESS = 'ACTIVITIES_HISTORY_SUCCESS'
export const ACTIVITIES_HISTORY_FAILURE = 'ACTIVITIES_HISTORY_FAILURE'

export const ACTIVITY_START_REQUEST = 'ACTIVITY_START_REQUEST'
export const ACTIVITY_START_FAILURE = 'ACTIVITY_START_FAILURE'
export const ACTIVITY_START_SUCCESS = 'ACTIVITY_START_SUCCESS'

export const ACTIVITY_UNDOCOMPLETE_REQUEST = 'ACTIVITY_UNDOCOMPLETE_REQUEST'
export const ACTIVITY_UNDOCOMPLETE_SUCCESS = 'ACTIVITY_UNDOCOMPLETE_SUCCESS'
export const ACTIVITY_UNDOCOMPLETE_FAILURE = 'ACTIVITY_UNDOCOMPLETE_FAILURE'

export const ACTIVITY_SUBMITFEEDBACK_REQUEST = 'ACTIVITY_SUBMITFEEDBACK_REQUEST'
export const ACTIVITY_SUBMITFEEDBACK_SUCCESS = 'ACTIVITY_SUBMITFEEDBACK_SUCCESS'
export const ACTIVITY_SUBMITFEEDBACK_FAILURE = 'ACTIVITY_SUBMITFEEDBACK_FAILURE'

export const ACTIVITY_DELETEFEEDBACK_REQUEST = 'ACTIVITY_DELETEFEEDBACK_REQUEST'
export const ACTIVITY_DELETEFEEDBACK_SUCCESS = 'ACTIVITY_DELETEFEEDBACK_SUCCESS'
export const ACTIVITY_DELETEFEEDBACK_FAILURE = 'ACTIVITY_DELETEFEEDBACK_FAILURE'

export const FETCH_COLLEAGUE_ASSIGNMENTS_REQUEST =
  'FETCH_COLLEAGUE_ASSIGNMENTS_REQUEST'
export const FETCH_COLLEAGUE_ASSIGNMENTS_SUCCESS =
  'FETCH_COLLEAGUE_ASSIGNMENTS_SUCCESS'
export const FETCH_COLLEAGUE_ASSIGNMENTS_FAILURE =
  'FETCH_COLLEAGUE_ASSIGNMENTS_FAILURE'

export const ATTRIBUTE_CATEGORIES_REQUEST = 'ATTRIBUTE_CATEGORIES_REQUEST'
export const ATTRIBUTE_CATEGORIES_SUCCESS = 'ATTRIBUTE_CATEGORIES_SUCCESS'
export const ATTRIBUTE_CATEGORIES_FAILURE = 'ATTRIBUTE_CATEGORIES_FAILURE'

export const AZURE_CURRENTUSER_SET = 'AZURE_CURRENTUSER_SET'

export const CONNECTION_ONLINE = 'CONNECTION_ONLINE'
export const CONNECTION_OFFLINE = 'CONNECTION_OFFLINE'

export const SET_IS_MOBILE = 'SET_IS_MOBILE'

export const CHANGE_ACTIVE_TAB = 'CHANGE_ACTIVE_TAB'

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'

export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION'

export const SELECT_CALENDAR_DAY = 'SELECT_CALENDAR_DAY'

export const CURRENT_ACTIVITY_HAS_BEEN_COMPLETED =
  'CURRENT_ACTIVITY_HAS_BEEN_COMPLETED'

export const TOGGLE_COMPLETED_SURVEY_POPUP = 'TOGGLE_COMPLETED_SURVEY_POPUP'
export const TOGGLE_CANT_COMPLETE_MODAL = 'TOGGLE_CANT_COMPLETE_MODAL'

export const UPDATE_ACTIVITY_QUESTION = 'UPDATE_ACTIVITY_QUESTION'

export const ACTIVITY_QUESTIONS_FILE_UPLOAD_SUCCESS =
  'ACTIVITY_QUESTIONS_FILE_UPLOAD_SUCCESS'
export const ACTIVITY_QUESTIONS_FILE_UPLOAD_REQUEST =
  'ACTIVITY_QUESTIONS_FILE_UPLOAD_REQUEST'
export const ACTIVITY_QUESTIONS_FILE_UPLOAD_FAILURE =
  'ACTIVITY_QUESTIONS_FILE_UPLOAD_FAILURE'
export const SHOW_UPLOADING_SURVEY_SPINNER = 'SHOW_UPLOADING_SURVEY_SPINNER'
export const SHOW_RETRYING_SURVEY_SPINNER = 'SHOW_RETRYING_SURVEY_SPINNER'

export const TOGGLE_IMAGECONFIRMATION_POPUP = 'TOGGLE_IMAGECONFIRMATION_POPUP'

export const TOGGLE_FILTER_MODAL = 'TOGGLE_FILTER_MODAL'

export const UPDATED_SELECTED_ATTRIBUTES = 'UPDATED_SELECTED_ATTRIBUTES'
export const UPDATED_SELECTED_ATTRIBUTES_SUCCESS =
  'UPDATED_SELECTED_ATTRIBUTES_SUCCESS'
export const UPDATED_SELECTED_ATTRIBUTES_FAILURE =
  'UPDATED_SELECTED_ATTRIBUTES_FAILURE'

export const REQUEST_ACTIVITY_MEDIA = 'REQUEST_ACTIVITY_MEDIA'
export const REQUEST_ACTIVITY_MEDIA_SUCCESS = 'REQUEST_ACTIVITY_MEDIA_SUCCESS'
export const REQUEST_ACTIVITY_MEDIA_FAILURE = 'REQUEST_ACTIVITY_MEDIA_FAILURE'

export const SET_ACTIVITY_VIEWED_REQUEST = 'SET_ACTIVITY_VIEWED_REQUEST'
export const SET_ACTIVITY_VIEWED_SUCCESS = 'SET_ACTIVITY_VIEWED_SUCCESS'
export const SET_ACTIVITY_VIEWED_FAILURE = 'SET_ACTIVITY_VIEWED_FAILURE'

export const SUBMIT_EVENT_DATA_REQUEST = 'SUBMIT_EVENT_DATA_REQUEST'
export const SUBMIT_EVENT_DATA_SUCCESS = 'SUBMIT_EVENT_DATA_SUCCESS'
export const SUBMIT_EVENT_DATA_FAILURE = 'SUBMIT_EVENT_DATA_FAILURE'

export const SUBMIT_EVENT_DATA_THEN_COMPLETE_REQUEST =
  'SUBMIT_EVENT_DATA_THEN_COMPLETE_REQUEST'

export const CHANGE_INSTANCE_DUE_DATE_REQUEST =
  'CHANGE_INSTANCE_DUE_DATE_REQUEST'
export const CHANGE_INSTANCE_DUE_DATE_SUCCESS =
  'CHANGE_INSTANCE_DUE_DATE_SUCCESS'
export const CHANGE_INSTANCE_DUE_DATE_FAILURE =
  'CHANGE_INSTANCE_DUE_DATE_FAILURE'

export const TOGGLE_NEW_ACTION_MODAL = 'TOGGLE_NEW_ACTION_MODAL'

export const GET_STORE_RISK_COMPLIANCE_REQUEST =
  'GET_STORE_RISK_COMPLIANCE_REQUEST'
export const GET_STORE_RISK_COMPLIANCE_SUCCESS =
  'GET_STORE_RISK_COMPLIANCE_SUCCESS'
export const GET_STORE_RISK_COMPLIANCE_FAILURE =
  'GET_STORE_RISK_COMPLIANCE_FAILURE'

export const CREATE_ACTION_REQUEST = 'CREATE_ACTION_REQUEST'
export const CREATE_ACTION_SUCCESS = 'CREATE_ACTION_SUCCESS'
export const CREATE_ACTION_FAILURE = 'CREATE_ACTION_FAILURE'

export const UPLOAD_MEDIA_SUCCESS = 'UPLOAD_MEDIA_SUCCESS'
export const UPLOAD_MEDIA_FAILURE = 'UPLOAD_MEDIA_FAILURE'

export const TOGGLE_ROUTE_CHANGE = 'TOGGLE_ROUTE_CHANGE'

export const NAVIGATE_NEXT_WEEK = 'NAVIGATE_NEXT_WEEK'
export const NAVIGATE_PREV_WEEK = 'NAVIGATE_PREV_WEEK'

export const CHANGE_PLANNING_VIEW = 'CHANGE_PLANNING_VIEW'

export const TOGGLE_ALL_CALENDAR_ITEMS = 'TOGGLE_ALL_CALENDAR_ITEMS'

export const SET_ACTIVITY_MARKASREAD_REQUEST = 'SET_ACTIVITY_MARKASREAD_REQUEST'
export const SET_ACTIVITY_MARKASREAD_SUCCESS = 'SET_ACTIVITY_MARKASREAD_SUCCESS'
export const SET_ACTIVITY_MARKASREAD_FAILURE = 'SET_ACTIVITY_MARKASREAD_FAILURE'

export const setCurrentAzureUser = (username, azureId, useremail) => ({
  type: AZURE_CURRENTUSER_SET,
  username,
  azureId,
  useremail
})

export const currentActivityHasBeenCompleted = () => ({
  type: CURRENT_ACTIVITY_HAS_BEEN_COMPLETED
})

export const createNotification = (message, status) => ({
  type: CREATE_NOTIFICATION,
  notification: {
    message,
    status
  }
})

export const closeNotification = () => ({
  type: CLOSE_NOTIFICATION
})

export const changeActiveTab = activeTab => ({
  type: CHANGE_ACTIVE_TAB,
  activeTab
})

export const startActivityRequest = id => ({
  type: ACTIVITY_START_REQUEST,
  id
})

export const startActivitySuccess = () => ({
  type: ACTIVITY_START_SUCCESS
})

export const startActivityFailure = () => ({
  type: ACTIVITY_START_FAILURE
})

export const requestActivities = (storeCode, section) => ({
  type: ACTIVITIES_REQUEST,
  storeCode,
  section
})

export const receiveActivities = activities => ({
  type: ACTIVITIES_SUCCESS,
  activities
})

export const requestActivitiesFailure = () => ({
  type: ACTIVITIES_FAILURE
})

export const requestActivity = activityId => ({
  type: ACTIVITY_REQUEST,
  activityId
})

export const receiveActivity = activity => ({
  type: ACTIVITY_SUCCESS,
  activity
})

export const requestActivitiyFailure = () => ({
  type: ACTIVITY_FAILURE
})

export const requestActivityQuestions = activityId => ({
  type: ACTIVITY_QUESTIONS_REQUEST,
  activityId
})

export const receiveActivityQuestions = questions => ({
  type: ACTIVITY_QUESTIONS_SUCCESS,
  questions
})

export const requestActivityQuestionsFailure = () => ({
  type: ACTIVITY_QUESTIONS_FAILURE
})

export const requestCompleteActivity = (activityId, type, response = null) => ({
  type: ACTIVITY_COMPLETE_REQUEST,
  activityId,
  activityType: type,
  response
})

export const requestCompleteActivitySuccess = () => ({
  type: ACTIVITY_COMPLETE_SUCCESS
})

export const requestCompleteActivityFailure = () => ({
  type: ACTIVITY_COMPLETE_FAILURE
})

export const requestCompleteActivityQuestions = (activityId, responses) => ({
  type: ACTIVITY_QUESTIONS_COMPLETE_REQUEST,
  activityId,
  responses
})

export const requestCompleteActivityQuestionsSuccess = () => ({
  type: ACTIVITY_QUESTIONS_COMPLETE_SUCCESS
})

export const completeActivityQuestionsFailure = () => ({
  type: ACTIVITY_QUESTIONS_COMPLETE_FAILURE
})

export const showUploadingActivitySpinner = showUploadingSpinner => ({
  type: SHOW_UPLOADING_SURVEY_SPINNER,
  showUploadingSpinner
})

export const showRetryingActivitySpinner = showRetryingSpinner => ({
  type: SHOW_RETRYING_SURVEY_SPINNER,
  showRetryingSpinner
})

export const requestCompleteActivityQuestionsFailure = () => dispatch => {
  dispatch(showRetryingActivitySpinner(false))
  dispatch(showUploadingActivitySpinner(false))
  dispatch(completeActivityQuestionsFailure())
}

export const requestUndoCompleteActivity = () => ({
  type: ACTIVITY_UNDOCOMPLETE_REQUEST
})

export const requestUndoCompleteActivitySuccess = () => ({
  type: ACTIVITY_UNDOCOMPLETE_SUCCESS
})

export const requestUndoCompleteActivityFailure = () => ({
  type: ACTIVITY_UNDOCOMPLETE_FAILURE
})

export const connectionIsOnline = () => ({
  type: CONNECTION_ONLINE
})

export const connectionIsOffline = () => ({
  type: CONNECTION_OFFLINE
})

export const requestActivityMedia = activityId => ({
  type: REQUEST_ACTIVITY_MEDIA,
  activityId
})

export const requestActivityMediaSuccess = media => ({
  type: REQUEST_ACTIVITY_MEDIA_SUCCESS,
  media
})

export const requestActivityMediaFailure = err => ({
  type: REQUEST_ACTIVITY_MEDIA_FAILURE,
  err
})

export const setInstanceViewed = referenceId => ({
  type: SET_ACTIVITY_VIEWED_REQUEST,
  referenceId
})

export const setInstanceViewedSuccess = referenceId => ({
  type: SET_ACTIVITY_VIEWED_SUCCESS,
  referenceId
})

export const setInstanceViewedFailure = err => ({
  type: SET_ACTIVITY_VIEWED_FAILURE,
  err
})

export const sendNotification = (message, status) => dispatch => {
  dispatch(createNotification(message, status))
}

export const fetchActivity = activityId => dispatch => {
  dispatch(requestActivity(activityId))
  return ActivitiesAPI.getActivity(activityId)
    .then(json => {
      dispatch(receiveActivity(json))
      if (json.hasMedia) {
        dispatch(requestActivityMedia(json.referenceId))
      }
      return json.isViewed
    })
    .then(isViewed => {
      if (!isViewed) {
        dispatch(setInstanceViewed(activityId))
      }
    })
    .catch(() => dispatch(requestActivitiyFailure()))
}

export const startActivity = activityId => dispatch => {
  dispatch(startActivityRequest(activityId))
  return ActivitiesAPI.startActivity(activityId)
    .then(() => {
      dispatch(startActivitySuccess())
      dispatch(fetchActivity(activityId))
    })
    .catch(() => {
      dispatch(startActivityFailure())
      dispatch(
        createNotification(Constants.START_ACTIVITY_ERROR_MESSAGE, 'danger')
      )
    })
}

export const completeActivity =
  (activityId, response = null) =>
  dispatch =>
    ActivitiesAPI.completeActivity(activityId, response)
      .then(res => res.json())
      .then(successfullyCompleted => {
        if (successfullyCompleted) {
          dispatch(requestCompleteActivitySuccess(successfullyCompleted))
          dispatch(showRetryingActivitySpinner(false))
          dispatch(showUploadingActivitySpinner(false))
          history.goBack()
        } else {
          location.reload()
        }
      })
      .catch(() => {
        dispatch(requestCompleteActivityFailure())
        dispatch(
          createNotification(
            Constants.COMPLETE_ACTIVITY_ERROR_MESSAGE,
            'danger'
          )
        )
      })

export const undoCompleteActivity = activityId => dispatch => {
  dispatch(requestUndoCompleteActivity(activityId))
  return ActivitiesAPI.undoCompleteActivity(activityId)
    .then(response => response.json())
    .then(successfullyUncompleted => {
      if (successfullyUncompleted) {
        dispatch(requestUndoCompleteActivitySuccess(successfullyUncompleted))
        history.goBack()
      } else {
        location.reload()
      }
    })
    .catch(() => {
      dispatch(requestUndoCompleteActivityFailure())
      dispatch(
        createNotification(
          Constants.UNDO_COMPLETE_ACTIVITY_ERROR_MESSAGE,
          'danger'
        )
      )
    })
}

export const requestHistoricActivities = (storeCode, page) => ({
  type: ACTIVITIES_HISTORY_REQUEST,
  storeCode,
  page,
  section: Constants.FILTER_PAGES.history
})

export const receiveHistoricActivities = activities => ({
  type: ACTIVITIES_HISTORY_SUCCESS,
  activities
})

export const requestHistoricActivitiesFailure = () => ({
  type: ACTIVITIES_HISTORY_FAILURE
})

export const submitFeedbackRequest = (activityId, isHelpful) => ({
  type: ACTIVITY_SUBMITFEEDBACK_REQUEST,
  activityId,
  isHelpful
})

export const submitFeedbackSuccess = isHelpful => ({
  type: ACTIVITY_SUBMITFEEDBACK_SUCCESS,
  isHelpful
})

export const submitFeedbackFailure = () => ({
  type: ACTIVITY_SUBMITFEEDBACK_FAILURE
})
export const submitFeedback = (activityId, isHelpful) => dispatch => {
  dispatch(submitFeedbackRequest(activityId, isHelpful))
  return ActivitiesAPI.submitFeedback(activityId, isHelpful)
    .then(() => dispatch(submitFeedbackSuccess(isHelpful)))
    .catch(() => {
      dispatch(submitFeedbackFailure())
      dispatch(
        createNotification(Constants.SUBMIT_FEEDBACK_ERROR_MESSAGE, 'danger')
      )
    })
}

export const deleteFeedbackRequest = activityId => ({
  type: ACTIVITY_DELETEFEEDBACK_REQUEST,
  activityId
})

export const deleteFeedbackSuccess = () => ({
  type: ACTIVITY_DELETEFEEDBACK_SUCCESS
})

export const deleteFeedbackFailure = () => ({
  type: ACTIVITY_DELETEFEEDBACK_FAILURE
})

export const deleteFeedback = activityId => dispatch => {
  dispatch(deleteFeedbackRequest(activityId))
  return ActivitiesAPI.deleteFeedback(activityId)
    .then(() => dispatch(deleteFeedbackSuccess()))
    .catch(() => dispatch(deleteFeedbackFailure()))
}

export const setIsMobile = isMobile => ({
  type: SET_IS_MOBILE,
  isMobile
})

export const requestActivitiesForWeek = weekDate => ({
  type: ACTIVITIES_FOR_WEEK_REQUEST,
  weekDate,
  section: Constants.PLANNING_ROUTE
})

export const requestAttributeCategories = activeOnly => ({
  type: ATTRIBUTE_CATEGORIES_REQUEST,
  activeOnly
})

export const requestAttributeCategoriesSuccess = attributes => ({
  type: ATTRIBUTE_CATEGORIES_SUCCESS,
  attributes
})

export const requestAttributeCategoriesFailure = () => ({
  type: ATTRIBUTE_CATEGORIES_FAILURE
})

export const requestActivitiesForWeekSuccess = activities => ({
  type: ACTIVITIES_FOR_WEEK_SUCCESS,
  activities
})

export const requestActivitiesForWeekFailure = () => ({
  type: ACTIVITIES_FOR_WEEK_FAILURE
})

export const requestActivitiesForPeriod = periodDate => ({
  type: ACTIVITIES_FOR_PERIOD_REQUEST,
  periodDate,
  section: Constants.PLANNING_ROUTE
})

export const requestActivitiesForPeriodSuccess = activities => ({
  type: ACTIVITIES_FOR_PERIOD_SUCCESS,
  activities
})

export const requestActivitiesForPeriodFailure = () => ({
  type: ACTIVITIES_FOR_PERIOD_FAILURE
})

export const fetchColleagueAssignmentsRequest = refId => ({
  type: FETCH_COLLEAGUE_ASSIGNMENTS_REQUEST,
  refId
})

export const fetchColleagueAssignmentsSuccess = colleagueAssignments => ({
  type: FETCH_COLLEAGUE_ASSIGNMENTS_SUCCESS,
  colleagueAssignments
})

export const fetchColleagueAssignmentsFailure = error => ({
  type: FETCH_COLLEAGUE_ASSIGNMENTS_FAILURE,
  error
})

export const selectCalendarDay = day => ({
  type: SELECT_CALENDAR_DAY,
  day
})

export const toggleCompletedModalPopup = showCompletedActivityModal => ({
  type: TOGGLE_COMPLETED_SURVEY_POPUP,
  showCompletedActivityModal
})

export const updateActivityQuestion = question => ({
  type: UPDATE_ACTIVITY_QUESTION,
  question
})

export const receiveActivityQuestionFileResponse = fileResponse => ({
  type: ACTIVITY_QUESTIONS_FILE_UPLOAD_SUCCESS,
  fileResponse
})

export const requestCompleteActivityFileQuestions = (activityId, fileData) => ({
  type: ACTIVITY_QUESTIONS_FILE_UPLOAD_REQUEST,
  activityId,
  fileData
})

export const requestCompleteActivityFileQuestionsFailure = err => ({
  type: ACTIVITY_QUESTIONS_FILE_UPLOAD_FAILURE,
  err
})

export const toggleImageConfirmationModalPopup =
  showImageConfirmationModal => ({
    type: TOGGLE_IMAGECONFIRMATION_POPUP,
    showImageConfirmationModal
  })

export const toggleFilterModal = showFilterModal => ({
  type: TOGGLE_FILTER_MODAL,
  showFilterModal
})

export const updateSelectedAttributes = selectedAttributes => ({
  type: UPDATED_SELECTED_ATTRIBUTES,
  selectedAttributes
})

export const updateSelectedAttributesSuccess = (
  filteredActivities,
  filteredPeriodActivities,
  filteredWeekActivities
) => ({
  type: UPDATED_SELECTED_ATTRIBUTES_SUCCESS,
  filteredActivities,
  filteredPeriodActivities,
  filteredWeekActivities
})

export const updateSelectedAttributesFailure = err => ({
  type: UPDATED_SELECTED_ATTRIBUTES_FAILURE,
  err
})

export const submitEventRequest = (activityId, eventType, event) => ({
  type: SUBMIT_EVENT_DATA_REQUEST,
  activityId,
  eventType,
  event
})

export const submitEventSuccess = activityId => ({
  type: SUBMIT_EVENT_DATA_SUCCESS,
  activityId
})

export const submitEventFailure = err => ({
  type: SUBMIT_EVENT_DATA_FAILURE,
  err
})

export const submitEventRequestThenComplete = (
  activityId,
  eventType,
  event,
  rerouter
) => ({
  type: SUBMIT_EVENT_DATA_THEN_COMPLETE_REQUEST,
  activityId,
  eventType,
  event,
  rerouter
})

export const changeInstanceDueDateRequest = (referenceId, dueDate) => ({
  type: CHANGE_INSTANCE_DUE_DATE_REQUEST,
  referenceId,
  dueDate
})

export const changeInstanceDueDateSuccess = referenceId => ({
  type: CHANGE_INSTANCE_DUE_DATE_SUCCESS,
  referenceId
})

export const changeInstanceDueDateFailure = err => ({
  type: CHANGE_INSTANCE_DUE_DATE_FAILURE,
  err
})

export const toggleCantCompleteModal = toggleCantComplete => ({
  type: TOGGLE_CANT_COMPLETE_MODAL,
  toggleCantComplete
})

export const getStoreRiskComplianceRequest = (storeCode, attributeId) => ({
  type: GET_STORE_RISK_COMPLIANCE_REQUEST,
  storeCode,
  attributeId
})

export const getStoreRiskComplianceSuccess = storeRiskCompliance => ({
  type: GET_STORE_RISK_COMPLIANCE_SUCCESS,
  storeRiskCompliance
})

export const getStoreRiskComplianceFailure = err => ({
  type: GET_STORE_RISK_COMPLIANCE_FAILURE,
  err
})

export const toggleNewActionModal = showNewActionModal => ({
  type: TOGGLE_NEW_ACTION_MODAL,
  showNewActionModal
})

export const createActionRequest = request => ({
  type: CREATE_ACTION_REQUEST,
  request
})

export const createActionSuccess = response => ({
  type: CREATE_ACTION_SUCCESS,
  response
})

export const createActionFailure = err => ({
  type: CREATE_ACTION_FAILURE,
  err
})

export const uploadMediaSuccess = response => ({
  type: UPLOAD_MEDIA_SUCCESS,
  response
})

export const uploadMediaFailure = err => ({
  type: UPLOAD_MEDIA_FAILURE,
  err
})

export const requestNavigateToNextWeek = weekInView => ({
  type: NAVIGATE_NEXT_WEEK,
  weekInView
})

export const requestNavigateToPrevWeek = weekInView => ({
  type: NAVIGATE_PREV_WEEK,
  weekInView
})

export const changePlanningView = planningView => ({
  type: CHANGE_PLANNING_VIEW,
  planningView
})

export const navigateToNextWeek = weekInView => dispatch => {
  const nextWeek = weekInView.add(1, 'week').startOf('week')
  dispatch(requestNavigateToNextWeek(nextWeek))
}

export const navigateToPrevWeek = weekInView => dispatch => {
  const prevWeek = weekInView.add(-1, 'week').startOf('week')
  dispatch(requestNavigateToPrevWeek(prevWeek))
}

export const toggleAllCalendarItems = showAll => ({
  type: TOGGLE_ALL_CALENDAR_ITEMS,
  showAll
})

export const setMarkAsReadRequest = instanceId => ({
  type: SET_ACTIVITY_MARKASREAD_REQUEST,
  instanceId
})

export const setMarkAsReadSuccess = (readOn, isRead) => ({
  type: SET_ACTIVITY_MARKASREAD_SUCCESS,
  readOn,
  isRead
})

export const setMarkAsReadFailure = () => ({
  type: SET_ACTIVITY_MARKASREAD_FAILURE
})

export const setMarkAsRead = (instanceId, isRead) => dispatch => {
  dispatch(setMarkAsReadRequest(instanceId))
  return ActivitiesAPI.setMarkInstanceRead(instanceId)
    .then(response => {
      dispatch(setMarkAsReadSuccess(response, isRead))
    })
    .catch(() => {
      dispatch(setMarkAsReadFailure())
    })
}
