import React from 'react'
import PropTypes from 'prop-types'
import { MediaSelector } from '@jsainsburyplc/activity-media-upload'
import { TextArea } from '@jsluna/form'
import SensitiveDataText from '../../../../Risk/SensitiveDataText'

const Evidence = ({ setEvidenceText, setEvidenceMedia }) => {
  const handleCommentChange = event => {
    setEvidenceText(event.target.value)
  }

  const handleImageChange = event => {
    setEvidenceMedia(event)
  }
  return (
    <>
      <label
        htmlFor="SatisfiedTextFeedback"
        className="ln-c-label ln-u-flush-bottom"
      >
        {'Add a comment to support your observation'}
      </label>
      <SensitiveDataText className="ln-u-margin-bottom" />
      <TextArea
        name="SatisfiedTextFeedback"
        id="SatisfiedTextFeedback"
        style={{ marginBottom: '2rem' }}
        onChange={e => handleCommentChange(e)}
      />
      <MediaSelector
        title="Add a photo to support your observation"
        buttonText="Upload image"
        onMediaUpdated={e => handleImageChange(e)}
        style={{ margin: '2rem 0 2rem 0' }}
        maxUploads={5}
        imageOnly
      />
    </>
  )
}

Evidence.propTypes = {
  setEvidenceText: PropTypes.func.isRequired,
  setEvidenceMedia: PropTypes.func.isRequired
}

export default Evidence
