import React from 'react'
import PropTypes from 'prop-types'
import PageNotFound from './PageNotFound'

const ErrorPage = props => {
  const { errorCode } = props.match.params
  switch (errorCode) {
    case '404':
      return <PageNotFound />
    default:
      return (
        <h1 className="ln-u-text-align-center">
          An unknown error (code: {errorCode}) occurred
        </h1>
      )
  }
}

ErrorPage.propTypes = {
  match: PropTypes.object.isRequired
}

export default ErrorPage
