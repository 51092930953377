import { call, put } from 'redux-saga/effects'
import { riskReportApi } from 'api'
import * as actions from 'actions'

function* getFinancialWeeks() {
  try {
    yield put(actions.financialWeeksLoading())
    const financialWeeks = yield call(riskReportApi.fetchFinancialWeeks)
    yield put(actions.financialWeeksSuccess(financialWeeks))
  } catch (err) {
    yield put(actions.financialWeeksFailure(err))
  }
}

export default getFinancialWeeks
