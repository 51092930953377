import React from 'react'
import PropTypes from 'prop-types'
import { GridItem, GridWrapper } from '@jsluna/grid'
import { IconFor } from '@jsainsburyplc/activity-icons'

const AttributeReportRow = ({ attribute, completed, total }) => {
  const completion = total > 0 ? completed / total : 0

  return (
    <GridWrapper className="ln-u-margin-top">
      <GridItem size="1/2">
        <span className="ln-u-ellipsis">
          <IconFor value={attribute} /> {attribute}
        </span>
      </GridItem>
      <GridItem
        size="1/2"
        className="ln-u-text-align-right ln-u-padding-top*1/2"
      >
        <span>
          <strong>
            {completed} / {total} ({Math.round(completion * 100)}%)
          </strong>
        </span>
      </GridItem>
      <GridItem size="1/1">
        <div className="report-bar">
          <div
            className="report-bar--completion"
            style={{ '--completion': `${completion}` }}
          />
        </div>
      </GridItem>
    </GridWrapper>
  )
}

AttributeReportRow.propTypes = {
  attribute: PropTypes.string,
  completed: PropTypes.number,
  total: PropTypes.number
}

AttributeReportRow.defaultProps = {
  attribute: '',
  completed: 0,
  total: 0
}

export default AttributeReportRow
