import { call, put } from 'redux-saga/effects'
import { audienceApi } from 'api'
import * as actions from 'actions'

export function* canAccessRisk() {
  try {
    yield put(actions.riskAccessLoading())
    const payload = yield call(audienceApi.canAccessRisk)
    yield put(actions.riskAccessSuccess(payload))
  } catch (err) {
    yield put(actions.riskAccessFailure(err))
  }
}

export default {
  canAccessRisk
}
