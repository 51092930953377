import React from 'react'
import PropTypes from 'prop-types'
import { startActivity } from 'actions'
import { OutlinedButton } from '@jsluna/button'
import { useAnalytics } from '@jsainsburyplc/retail-analytics'
import { connect } from 'react-redux'

export const Start = ({ activity, start }) => {
  const { sendEvent } = useAnalytics()

  const handleStartActivityRequest = () => {
    sendEvent('activity_started', {
      label: activity.title,
      type: activity.type.toLowerCase()
    })
    start(activity.referenceId)
  }

  return (
    <OutlinedButton fullWidth onClick={() => handleStartActivityRequest()}>
      Start activity
    </OutlinedButton>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    start: activityReference => dispatch(startActivity(activityReference))
  }
}

Start.propTypes = {
  activity: PropTypes.object.isRequired,
  start: PropTypes.func.isRequired
}

export default connect(null, mapDispatchToProps)(Start)
