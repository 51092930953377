import React from 'react'
import { FilledButton } from '@jsluna/button'
import { checkConnectionStatus } from 'utils/connectivity'
import WarningIcon from '../../../assets/WarningIcon'

const ConnectionDropped = () => (
  <div className="no-connection-container">
    <WarningIcon />
    <h4>No internet connection</h4>
    <p>
      We can&apos;t load any content, as you&apos;ve no internet connection.
      <br />
      Reconnect to the internet and then reload.
    </p>
    <FilledButton
      className="no-connection-refresh-button"
      onClick={() => checkConnectionStatus()}
    >
      Refresh
    </FilledButton>
  </div>
)

export default ConnectionDropped
