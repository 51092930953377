import React from 'react'
import PropTypes from 'prop-types'
import { List } from '@jsluna/list'
import MobileHeaderDayItem from './MobileHeaderDayItem'

const WeekList = ({
  weekItems,
  currentSelectedDate,
  onSelectItem,
  bottomSpacing,
  includeTitle
}) => (
  <List
    type="matrix"
    id="mobile-header-days-unordered-list"
    className={`ln-u-text-align-center ln-u-bg-color-white ln-u-padding-bottom*${bottomSpacing} ln-u-padding-left`}
  >
    {weekItems.map(day => (
      <MobileHeaderDayItem
        includeTitle={includeTitle}
        key={day.date}
        date={day.date}
        isSelected={currentSelectedDate.format() === day.date.format()}
        hasItems={day.items.length > 0}
        onClick={selectedDate => onSelectItem(selectedDate)}
      />
    ))}
  </List>
)

WeekList.propTypes = {
  weekItems: PropTypes.array.isRequired,
  currentSelectedDate: PropTypes.object.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  bottomSpacing: PropTypes.number,
  includeTitle: PropTypes.bool
}

WeekList.defaultProps = {
  bottomSpacing: 1,
  includeTitle: true
}

export default WeekList
