import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Modal, ModalHeading } from '@jsluna/modal'
import ModalItem from './ModalItem'

const GroupedActivitiesModal = ({
  group,
  activities,
  isOpen,
  onClose,
  history
}) => {
  const haveActivities = activities.length > 0
  return (
    <Modal
      className="c-modal-no-backdrop"
      fullScreen={false}
      small
      handleClose={onClose}
      open={isOpen}
    >
      <ModalHeading element="h3" className="c-grouped-modal__header">
        {activities.length} {group.title}
        {activities.length === 1 ? '' : 's'}
      </ModalHeading>
      <p>
        {moment(group.activeFromDate).format('ddd D MMM')} -{' '}
        {moment(group.dueDate).format('ddd D MMM')}
      </p>
      <div>
        {haveActivities ? (
          activities.map(item => (
            <ModalItem activity={item} history={history} key={item.id} />
          ))
        ) : (
          <p>No activities available for this day.</p>
        )}
      </div>
    </Modal>
  )
}
GroupedActivitiesModal.propTypes = {
  group: PropTypes.object.isRequired,
  activities: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

GroupedActivitiesModal.defaultProps = {
  activities: [],
  isOpen: false
}

export default GroupedActivitiesModal
