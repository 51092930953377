import React, { useState, useEffect } from 'react'
import { ButtonGroupPrimary, FilledButton, TextButton } from '@jsluna/button'
import { Modal, ModalHeading } from '@jsluna/modal'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'

import moment from 'moment'
import 'react-dates/initialize'
import { SingleDateTimePicker } from '@jsainsburyplc/retail-datetime-picker'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  changeInstanceDueDateRequest,
  submitEventRequest,
  fetchAuditEventRequest
} from 'actions'
import { Label, TextAreaField } from '@jsluna/form'
import SensitiveDataText from '../../../../Risk/SensitiveDataText'

export const CantCompleteModal = ({
  showModal,
  toggleModal,
  changeDate,
  isMobile,
  reference,
  isUpdating,
  actionDescription,
  submitEvidence,
  evidenceText,
  evidenceMedia,
  satisfaction,
  getAudit,
  sendEvidence
}) => {
  const [selectedDate, setSelectedDate] = useState(null)
  const [dateChanged, setDateChanged] = useState(false)
  const [reason, setReason] = useState(evidenceText || '')

  const handleDateChange = () => {
    changeDate(reference, selectedDate)

    if (sendEvidence) {
      submitEvidence(reference, 'DueDateDeferred', {
        satisfaction,
        text: reason,
        media: evidenceMedia,
        action: {
          description: actionDescription,
          dueDate: selectedDate
        }
      })
    }

    setDateChanged(true)
    setReason('')

    toggleModal(false)
  }

  useEffect(() => {
    if (dateChanged) getAudit(reference)
  }, [handleDateChange])

  const getPickerProps = () => {
    const initialProps = {
      orientation: isMobile ? 'vertical' : 'horizontal',
      openDirection: isMobile ? 'down' : 'up',
      numberOfMonths: isMobile ? 1 : 2
    }

    return initialProps
  }

  const handleClose = () => {
    setReason('')
    toggleModal(false)
  }

  return (
    <Modal
      fullScreen
      restrictClose
      open={showModal}
      className="modal-allow-overflow"
      preventFocus
    >
      <ModalHeading>Can&apos;t complete this action yet?</ModalHeading>
      <b
        className="ln-c-label"
        style={{ paddingTop: !isMobile ? '80px' : '0px' }}
      >
        Change completion date to
      </b>
      <SingleDateTimePicker
        defaultTime="23:30"
        pickerClass="DateTimePicker-colleague datetime-picker datetime-picker-top"
        selectedDate={selectedDate}
        valueChanged={d => setSelectedDate(d)}
        datePickerProps={getPickerProps()}
        isOutsideRange={day => day.isBefore(moment().startOf('day'))}
      />
      <Label
        htmlFor="changing-due-date"
        className="ln-u-margin-top*2 ln-u-flush-bottom"
      >
        Why are you changing the due date?
      </Label>
      <SensitiveDataText />
      <TextAreaField
        name="changing-due-date-text-box"
        className="ln-u-padding-top"
        value={reason}
        onChange={e => setReason(e.target.value)}
      />
      <ButtonGroupPrimary className="modal-button-group">
        <TextButton
          onClick={() => handleClose()}
          disabled={isUpdating}
          fullWidth={isMobile}
        >
          Cancel
        </TextButton>
        <FilledButton
          fullWidth={isMobile}
          onClick={() => handleDateChange()}
          disabled={selectedDate === null || reason.length === 0 || isUpdating}
        >
          {isUpdating ? (
            <ProgressIndicator
              style={{ position: 'relative', zIndex: 3 }}
              loading
            >
              <ProgressSpinner
                style={{ maxHeight: '1.5rem', maxWidth: '1.5rem' }}
              />
              Updating...
            </ProgressIndicator>
          ) : (
            'Update'
          )}
        </FilledButton>
      </ButtonGroupPrimary>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  changeDate: (activityReference, dueDate) =>
    dispatch(changeInstanceDueDateRequest(activityReference, dueDate)),
  submitEvidence: (activityReference, eventType, event) =>
    dispatch(submitEventRequest(activityReference, eventType, event)),
  getAudit: activityReference =>
    dispatch(fetchAuditEventRequest(activityReference))
})

const mapStateToProps = state => ({
  isMobile: state.activities.isMobile,
  isUpdating: state.activities.showUpdatingSpinner
})

CantCompleteModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  changeDate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  reference: PropTypes.string.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  actionDescription: PropTypes.string,
  submitEvidence: PropTypes.func.isRequired,
  evidenceText: PropTypes.string,
  evidenceMedia: PropTypes.array,
  satisfaction: PropTypes.string,
  getAudit: PropTypes.func.isRequired,
  sendEvidence: PropTypes.bool
}

CantCompleteModal.defaultProps = {
  actionDescription: '',
  evidenceText: '',
  evidenceMedia: [],
  satisfaction: '',
  sendEvidence: false
}

export default connect(mapStateToProps, mapDispatchToProps)(CantCompleteModal)
