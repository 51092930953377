import React from 'react'
import { Like } from '@jsluna/icons'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FilledButton } from '@jsluna/button'
import { filterReset } from '../../../../actions'

const NothingToSeeWithNoFilters = ({ resetFilter }) => (
  <div className="nts-message-body">
    <h1 className=" ln-u-text-align-center">
      <Like className="nts-message-icon" size="large" />
    </h1>
    <div className="ln-u-padding-sides*2">
      <h3 className="ln-u-text-align-center">
        There&apos;s nothing to see here
      </h3>
      <p>There&apos;s no activities which match your filter.</p>
      <p>
        Please refilter in order to find the activity you&apos;re looking for.
      </p>
      <FilledButton
        fullWidth
        className="ln-u-margin-bottom"
        onClick={() => resetFilter()}
      >
        Clear filters
      </FilledButton>
    </div>
  </div>
)

const mapDispatchToProps = dispatch => ({
  resetFilter: () => {
    dispatch(filterReset())
  }
})

NothingToSeeWithNoFilters.propTypes = {
  resetFilter: PropTypes.func.isRequired
}

export default connect(null, mapDispatchToProps)(NothingToSeeWithNoFilters)
