import fetchAuth, { createHeadersForRequest } from 'utils/fetchAuth'
import serverPath from 'utils/apiHost'
import clientPath from 'utils/clientHost'
import { getStoreFromStorage } from 'utils/currentStore'
import {
  handleMediaResponse,
  createForm,
  generateUniqueStoreFileName,
  generateActivitiesFilterModelParams
} from './utils/helpers'

export const audienceApi = {
  canAccessRisk() {
    return fetchAuth(`${serverPath}/audience/risk`, {
      headers: createHeadersForRequest(),
      method: 'GET'
    }).then(response => response.json())
  }
}

export const riskReportApi = {
  fetchFinancialWeeks() {
    return fetchAuth(
      `${serverPath}/riskreport/GetFinancialWeeksDropDownValues`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  fetchRiskTaskReport(storeCode, fromDate, toDate, withAttributes) {
    return fetchAuth(
      `${serverPath}/riskreport/GetRiskTaskComplianceForDateRange/${storeCode}/${fromDate}/${toDate}/${withAttributes}`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  fetchRiskTaskOverviewReport(storeCode, fromDate, toDate) {
    return fetchAuth(
      `${serverPath}/riskreport/GetRiskTaskComplianceByWeekForDateRange/${storeCode}/${fromDate}/${toDate}`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  fetchRiskActionReport(storeCode, fromDate, toDate, withAttributes) {
    return fetchAuth(
      `${serverPath}/riskreport/GetRiskActionValuesForDateRange/${storeCode}/${fromDate}/${toDate}/${withAttributes}`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  fetchRegionRiskTaskOverviewReport(storeCode, fromDate, toDate) {
    return fetchAuth(
      `${serverPath}/riskreport/GetRegionRiskTaskComplianceByWeekForDateRange/${storeCode}/${fromDate}/${toDate}`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  fetchRegionRiskTaskReport(storeCode, fromDate, toDate) {
    return fetchAuth(
      `${serverPath}/riskreport/GetRegionRiskTaskComplianceForDateRange/${storeCode}/${fromDate}/${toDate}`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  }
}

const ActivitiesAPI = {
  getTodayActivities(storeCode, pageNumber, filterModel, needToKnowOffset) {
    let queryParams = generateActivitiesFilterModelParams(filterModel)
    if (needToKnowOffset > 0) {
      const bindChar = !queryParams || !queryParams.length ? `?` : `&`
      if (bindChar === '?') {
        queryParams = `${bindChar}needToKnowOffset=${needToKnowOffset}`
      } else {
        queryParams += `${bindChar}needToKnowOffset=${needToKnowOffset}`
      }
    }

    return fetchAuth(
      `${serverPath}/activity/${storeCode}/GetTodayActivities/${pageNumber}${
        queryParams || ''
      }`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  getActivity(id) {
    return fetchAuth(`${serverPath}/activity/GetActivity/${id}`, {
      headers: createHeadersForRequest(),
      method: 'GET'
    }).then(response => response.json())
  },
  fetchActivityMedia(activityId) {
    return fetchAuth(
      `${serverPath}/activity/GetActivityInstanceMediaAttachments/${activityId}`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  getActivityQuestions(id) {
    return fetchAuth(`${serverPath}/activity/GetActivityQuestions/${id}`, {
      headers: createHeadersForRequest(),
      method: 'GET'
    }).then(response => response.json())
  },
  completeActivity(id, response = null) {
    return fetchAuth(`${serverPath}/activity/CompleteActivity/${id}`, {
      headers: createHeadersForRequest(),
      method: 'POST',
      body: JSON.stringify(response)
    })
  },
  submitActivityQuestionResponses(id, responses) {
    return fetchAuth(
      `${serverPath}/activity/SubmitActivityQuestionResponses/${id}`,
      {
        headers: createHeadersForRequest(),
        method: 'POST',
        body: JSON.stringify(responses)
      }
    )
  },
  submitActivityQuestionFileData(id, fileData) {
    return fetchAuth(
      `${serverPath}/activity/SubmitActivityQuestionFileData/${id}`,
      {
        headers: createHeadersForRequest(false),
        method: 'POST',
        body: fileData
      }
    ).then(response => response.json())
  },
  submitResponseFileData(id, files) {
    return fetchAuth(`${serverPath}/activity/SubmitResponseFileData/${id}`, {
      headers: createHeadersForRequest(false),
      method: 'POST',
      body: files
    }).then(response => response.json())
  },
  getHistoryActivities(
    storeCode,
    pageNumber,
    filterModel,
    riskEnabled,
    needToKnowOffset
  ) {
    let queryParams = generateActivitiesFilterModelParams(filterModel)
    if (needToKnowOffset > 0) {
      const bindChar = !queryParams || !queryParams.length ? `?` : `&`
      if (bindChar === '?') {
        queryParams = `${bindChar}needToKnowOffset=${needToKnowOffset}`
      } else {
        queryParams += `${bindChar}needToKnowOffset=${needToKnowOffset}`
      }
    }
    return fetchAuth(
      `${serverPath}/activity/${storeCode}/GetHistoryActivities/${pageNumber}/${riskEnabled}${
        queryParams || ''
      }`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  undoCompleteActivity(id) {
    const undoResponse = true
    return fetchAuth(`${serverPath}/activity/UndoCompleteActivity/${id}`, {
      headers: createHeadersForRequest(),
      method: 'POST',
      body: undoResponse
    })
  },
  startActivity(id) {
    return fetchAuth(`${serverPath}/activity/startactivity/${id}`, {
      headers: createHeadersForRequest(),
      method: 'POST'
    })
  },
  submitFeedback(instanceId, isHelpful) {
    return fetchAuth(`${serverPath}/activity/submitfeedback`, {
      headers: createHeadersForRequest(),
      method: 'POST',
      body: JSON.stringify({
        instanceId,
        isHelpful
      })
    })
  },
  deleteFeedback(id) {
    return fetchAuth(
      `${serverPath}/activity/deleteInstancefeedback/?instanceId=${id}`,
      {
        headers: createHeadersForRequest(),
        method: 'DELETE'
      }
    )
  },
  healthCheck() {
    return fetchAuth(`${clientPath}`, {
      headers: createHeadersForRequest(),
      method: 'GET'
    })
  },
  getColleagues(storeCode, searchStr, limitToRiskAudience) {
    return fetchAuth(
      `${serverPath}/activity/getColleagues/${storeCode}/${searchStr}/${limitToRiskAudience}`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    )
  },
  assignActivity(instanceReferenceId, assignedToId) {
    return fetchAuth(`${serverPath}/activity/assignColleague/`, {
      headers: createHeadersForRequest(),
      method: 'POST',
      body: JSON.stringify({
        instanceReferenceId,
        assignedToId
      })
    })
  },
  removeAssignment(referenceId, unassignedToId) {
    return fetchAuth(`${serverPath}/activity/RemoveAssignment`, {
      headers: createHeadersForRequest(),
      method: 'POST',
      body: JSON.stringify({
        referenceId,
        unassignedToId
      })
    })
  },
  getInstanceAssignmentEventsPage(instanceId, pageNumber, pageSize, storeCode) {
    return fetchAuth(
      `${serverPath}/activity/getAssignmentEventsPage/${instanceId}/${storeCode}/${pageNumber}/${pageSize}`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    )
  },
  getPlanningWeekActivities(storeCode, pageNumber, weekStartDate, filterModel) {
    const queryParams = generateActivitiesFilterModelParams(filterModel)
    return fetchAuth(
      `${serverPath}/activity/${storeCode}/GetPlanningWeekActivities/${pageNumber}/${weekStartDate}${
        queryParams || ''
      }`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  getPlanningPeriodActivities(
    storeCode,
    pageNumber,
    periodStartDate,
    filterModel
  ) {
    const queryParams = generateActivitiesFilterModelParams(filterModel)
    return fetchAuth(
      `${serverPath}/activity/${storeCode}/GetPlanningPeriodActivities/${pageNumber}/${periodStartDate}${
        queryParams || ''
      }`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  fetchColleagueAssignments(instanceReferenceId) {
    return fetchAuth(
      `${serverPath}/activity/getColleagueAssignments/${instanceReferenceId}`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  getActivitiesBySearch(storeCode, pageNumber, filterModel, searchCriteria) {
    const queryParams = generateActivitiesFilterModelParams(filterModel)
    const searchCriteriaParam = `${
      queryParams ? '&' : '?'
    }searchCriteria=${searchCriteria}`
    return fetchAuth(
      `${serverPath}/activity/${storeCode}/GetActivitiesBySearch/${pageNumber}${
        queryParams || ''
      }${searchCriteriaParam}`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  getColleagueSettings() {
    return fetchAuth(`${serverPath}/info`, {
      headers: createHeadersForRequest(),
      method: 'GET'
    }).then(response => response.json())
  },
  getAttributeCategories(activeOnly) {
    const storeFromLocalStorage = getStoreFromStorage()
    return fetchAuth(
      `${serverPath}/activity/getActivityAttributeCategories/${
        storeFromLocalStorage ? storeFromLocalStorage.code : null
      }/${activeOnly}`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  setInstanceViewed(referenceId) {
    return fetchAuth(
      `${serverPath}/activity/setInstanceViewed/${referenceId}`,
      {
        headers: createHeadersForRequest(),
        method: 'POST'
      }
    )
  },
  submitEvent(activityId, eventType, event) {
    return fetchAuth(
      `${serverPath}/activity/CreateActivityEvent/${activityId}/${eventType}`,
      {
        headers: createHeadersForRequest(),
        method: 'POST',
        body: JSON.stringify(event)
      }
    )
  },
  uploadEventMedia(fileData) {
    return fetchAuth(`${serverPath}/activity/UploadEventMedia`, {
      headers: createHeadersForRequest(false),
      method: 'POST',
      body: fileData
    }).then(response => response.json())
  },
  changeInstanceDueDate(activityId, dueDate) {
    return fetchAuth(
      `${serverPath}/activity/ChangeInstanceDueDate/${activityId}`,
      {
        headers: createHeadersForRequest(),
        method: 'POST',
        body: JSON.stringify(dueDate)
      }
    )
  },
  getAuditActivityEvents(instanceId, excludeChildEvents) {
    return fetchAuth(
      `${serverPath}/activity/GetAuditActivityEvents/${instanceId}?excludeChildEvents=${excludeChildEvents}`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  getStoreRiskCompliance(storeCode, attributeId) {
    return fetchAuth(
      `${serverPath}/activity/getStoreRiskCompliance/${storeCode}/${attributeId}`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  createAdhocTask(request) {
    return fetchAuth(`${serverPath}/activity/CreateAdhocTask`, {
      headers: createHeadersForRequest(),
      method: 'POST',
      body: JSON.stringify(request)
    }).then(response => response.json())
  },
  submitMediaAttachmentsFileData: (imageData, storeCode) => {
    const url = `${serverPath}/activity/UploadEventMedia`
    return fetchAuth(url, {
      headers: createHeadersForRequest(false),
      method: 'POST',
      body: createForm(generateUniqueStoreFileName(storeCode), imageData)
    }).then(response => handleMediaResponse(response, imageData))
  },
  getRiskActivities(storeCode, pageNumber, filterModel) {
    const queryParams = generateActivitiesFilterModelParams(filterModel)
    return fetchAuth(
      `${serverPath}/activity/${storeCode}/GetRiskActivities/${pageNumber}${
        queryParams || ''
      }`,
      {
        headers: createHeadersForRequest(),
        method: 'GET'
      }
    ).then(response => response.json())
  },
  getFinancialWeekByDate() {
    return fetchAuth(`${serverPath}/activity/GetFinancialWeekByDate`, {
      headers: createHeadersForRequest(),
      method: 'GET'
    }).then(response => response.json())
  },
  setMarkInstanceRead(instanceId) {
    const storeFromLocalStorage = getStoreFromStorage()
    const storeCode = storeFromLocalStorage ? storeFromLocalStorage?.code : null
    return fetchAuth(
      `${serverPath}/activity/SetInstanceRead?instanceId=${instanceId}&storeCode=${storeCode}`,
      {
        headers: createHeadersForRequest(),
        method: 'POST',
        body: ''
      }
    ).then(response => response.json())
  },
  submitChecklistResponse(id, checklistItems = null) {
    const response = { checklistItems }
    return fetchAuth(`${serverPath}/activity/SubmitChecklistResponse/${id}`, {
      headers: createHeadersForRequest(),
      method: 'POST',
      body: JSON.stringify(response)
    }).then(result => result.json())
  }
}

export default ActivitiesAPI
