import { call, put, select } from 'redux-saga/effects'
import ActivitiesAPI from 'api'
import * as actions from 'actions'
import {
  SAINSBURYS_ARGOS_SIS_GROUP,
  SSC_STORE_GROUP,
  TASK_TYPE_ATTRIBUTES_CATEGORY
} from '../../utils/constants'

export default function* requestAttributeCategories({ activeOnly }) {
  try {
    const groups = yield select(state => state.storeGroups.groups)
    const attributes = yield call(
      ActivitiesAPI.getAttributeCategories,
      activeOnly
    )

    if (
      groups &&
      !groups.includes(SAINSBURYS_ARGOS_SIS_GROUP) &&
      !groups.includes(SSC_STORE_GROUP)
    ) {
      for (let i = 0; i < attributes.length; i += 1) {
        if (attributes[i].name === 'Job Families') {
          attributes[i].activityAttributes = attributes[
            i
          ].activityAttributes.filter(x => x.name !== 'Argos')
        }
      }
    }

    yield put(
      actions.requestAttributeCategoriesSuccess(
        attributes.filter(x => x.name !== TASK_TYPE_ATTRIBUTES_CATEGORY)
      )
    )
  } catch (err) {
    yield put(actions.requestAttributeCategoriesFailure())
  }
}
