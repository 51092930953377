import actions from './actions'

const stores = (state = { list: [] }, action) => {
  switch (action.type) {
    case actions.SET_STORES:
      return { list: action.stores, requestPerformed: true }
    case actions.fetchPending:
      return { loading: true, list: [], requestPerformed: false }
    case actions.reset:
      return { list: [], requestPerformed: false }
    default:
      return state
  }
}

export default stores
