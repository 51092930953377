import React from 'react'
import { Card } from '@jsluna/card'
import {
  ProgressIndicator,
  ProgressSpinner,
  ProgressMessage
} from '@jsluna/progress'

const LoadingBar = () => (
  <ProgressIndicator loading preventFocus>
    <ProgressSpinner className="ln-u-push-right-sm" />
    <ProgressMessage>Loading...</ProgressMessage>
  </ProgressIndicator>
)

const Loading = message => {
  if (!message) return <LoadingBar />
  return (
    <Card>
      <p>{message.message}</p>
      <LoadingBar />
    </Card>
  )
}

export default Loading
