import React from 'react'
import PropTypes from 'prop-types'
import { RISK_IGNORED_ATTRIBUTES } from 'utils/constants'
import AttributeReportRow from './AttributeReportRow'

const AttributeReport = ({ attributes, complianceByAttribute }) => {
  const rows =
    attributes &&
    attributes
      .reduce(
        (result, item) =>
          result.concat(
            item.activityAttributes
              .filter(
                attribute => !RISK_IGNORED_ATTRIBUTES.includes(attribute.name)
              )
              .map(attribute => {
                const complianceForAttribute = complianceByAttribute.find(
                  compliance => compliance.name === attribute.name
                )
                return {
                  attribute: attribute.name,
                  completed: complianceForAttribute
                    ? complianceForAttribute.numberOfCompletedActivities
                    : 0,
                  total: complianceForAttribute
                    ? complianceForAttribute.numberOfActivities
                    : 0
                }
              })
          ),
        []
      )
      .sort((a, b) => (a.attribute > b.attribute ? 1 : -1))

  const sortedRows =
    rows &&
    [...rows].sort((a, b) =>
      (a.total > 0 ? a.completed / a.total : 0) >=
      (b.total > 0 ? b.completed / b.total : 0)
        ? 1
        : -1
    )

  return (
    sortedRows && (
      <div className="ln-u-margin-top*4">
        {sortedRows.map(row => (
          <AttributeReportRow key={row.attribute} {...row} />
        ))}
      </div>
    )
  )
}

AttributeReport.propTypes = {
  attributes: PropTypes.array,
  complianceByAttribute: PropTypes.array,
  reportType: PropTypes.string
}

AttributeReport.defaultProps = {
  attributes: [],
  complianceByAttribute: []
}

export default AttributeReport
