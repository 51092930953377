const hasSessionStorage = () => window.sessionStorage

const keys = {
  filters: 'filters'
}

export const getFilters = () => {
  if (!hasSessionStorage()) return []
  const filters = window.sessionStorage.getItem(keys.filters)
  if (!filters) return []
  return JSON.parse(filters)
}

export const setFilters = ({ filters }) => {
  if (!hasSessionStorage()) return false
  if (!filters) return false
  window.sessionStorage.setItem(keys.filters, JSON.stringify(filters))
  return true
}

const browserStorage = {
  getFilters,
  setFilters
}

export default browserStorage
