import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { NotificationList } from '@jsluna/notification'
import { closeNotification } from 'actions'
import Notification from './Notification'

const Notifications = ({ notification, handleClose }) => (
  <NotificationList open>
    {notification !== null && (
      <Notification
        status={notification.status}
        message={notification.message}
        isOpen
        handleClose={() => handleClose()}
      />
    )}
  </NotificationList>
)

function mapDispatchToProps(dispatch) {
  return {
    handleClose: () => dispatch(closeNotification())
  }
}
function mapStateToProps(state) {
  return {
    notification: state.notifications.item
  }
}

Notifications.propTypes = {
  notification: PropTypes.object,
  handleClose: PropTypes.func.isRequired
}

Notifications.defaultProps = {
  notification: null
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
