import React from 'react'
import PropTypes from 'prop-types'

const BackIcon = ({ width, height, viewBox }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
  >
    <path
      stroke="none"
      fillRule="nonzero"
      d="M2.414 9l7.293-7.293A1 1 0 0 0 8.293.293l-8 8a1 1 0 0 0 0 1.414l8 8a1 1 0 1 0 1.414-1.414L2.414 9z"
    />
  </svg>
)

BackIcon.defaultProps = {
  width: 10,
  height: 23,
  viewBox: '0 0 10 18'
}

BackIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  viewBox: PropTypes.string
}

export default BackIcon
