export const RISK_ACCESS_LOADING = 'RISK_ACCESS_LOADING'
export const RISK_ACCESS_REQUEST = 'RISK_ACCESS_REQUEST'
export const RISK_ACCESS_SUCCESS = 'RISK_ACCESS_SUCCESS'
export const RISK_ACCESS_FAILURE = 'RISK_ACCESS_FAILURE'

export const riskAccessLoading = () => ({
  type: RISK_ACCESS_LOADING
})

export const riskAccessRequest = () => ({
  type: RISK_ACCESS_REQUEST
})

export const riskAccessSuccess = payload => ({
  type: RISK_ACCESS_SUCCESS,
  payload
})

export const riskAccessFailure = err => ({
  type: RISK_ACCESS_FAILURE,
  err
})
