import React from 'react'
import PropTypes from 'prop-types'

const SensitiveDataText = ({ className }) => (
  <div className={className}>
    As a reminder please do not include any sensitive personal data about
    colleagues or customers within the response
  </div>
)

SensitiveDataText.propTypes = {
  className: PropTypes.string
}

SensitiveDataText.defaultProps = {
  className: null
}

export default SensitiveDataText
