import React from 'react'
import PropTypes from 'prop-types'

const CreationReason = ({ creationReason }) => {
  if (!creationReason) return null

  return <div className="ln-u-margin-top">{creationReason}</div>
}

CreationReason.propTypes = {
  creationReason: PropTypes.string
}

CreationReason.defaultProps = {
  creationReason: null
}

export default CreationReason
