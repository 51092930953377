import { call, put, select } from 'redux-saga/effects'
import ActivitiesAPI from 'api'
import { searchActivitiesSuccess, searchActivitiesFailure } from 'actions'

function* getActivitiesBySearch({ storeCode, pageNumber, searchText }) {
  try {
    const filters = yield select(state => state.filter.applied)
    const searchResults = yield call(
      ActivitiesAPI.getActivitiesBySearch,
      storeCode,
      pageNumber,
      filters,
      searchText
    )
    yield put(searchActivitiesSuccess(searchResults))
  } catch (err) {
    yield put(searchActivitiesFailure(err))
  }
}

export default getActivitiesBySearch
