import React from 'react'
import PropTypes from 'prop-types'

const NoResults = ({ search }) => (
  <div>
    <div>
      We couldn&apos;t find any activities matching your search{' '}
      <b>&ldquo;{search}&ldquo;</b> and your selected filters.
    </div>
    <div>
      Suggestions:
      <ul>
        <li>Make sure that all words are spelled correctly.</li>
        <li>Try a more general search term.</li>
        <li>Narrow down your selection of filters.</li>
      </ul>
    </div>
  </div>
)

NoResults.propTypes = {
  search: PropTypes.string
}

NoResults.defaultProps = {
  search: ''
}

export default NoResults
