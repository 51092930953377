import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeading } from '@jsluna/modal'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'
import { GridItem, GridWrapper } from '@jsluna/grid'
import { FilledButton, TextButton } from '@jsluna/button'
import { EVENT_AUDIT_SATISFACTION } from 'utils/constants'
import Evidence from '../Evidence/Evidence'

const EvidenceModal = ({
  isOpen,
  closeModal,
  setEvidenceMedia,
  setEvidenceText,
  isUploadingImages,
  isValid,
  handleSubmitEvidence
}) => (
  <Modal fullScreen open={isOpen} alert handleClose={() => closeModal(false)}>
    <ModalHeading element="h3">Provide more details</ModalHeading>
    <GridWrapper>
      <GridItem size="1/1">
        <Evidence
          setEvidenceMedia={e => setEvidenceMedia(e)}
          setEvidenceText={e => setEvidenceText(e)}
        />
      </GridItem>
      <GridItem size="1/1">
        {isUploadingImages && (
          <ProgressIndicator
            style={{
              position: 'relative',
              zIndex: 3
            }}
            className="ln-u-soft-bottom"
            loading
          >
            <ProgressSpinner />
            Uploading evidence...
          </ProgressIndicator>
        )}
      </GridItem>
      <GridItem size="1/1" className="ln-u-soft-top">
        <FilledButton
          id="submitButton"
          fullWidth
          onClick={() => handleSubmitEvidence(EVENT_AUDIT_SATISFACTION)}
          disabled={!isValid}
        >
          Submit
        </FilledButton>
      </GridItem>
      <GridItem size="1/1" className="ln-u-padding-top">
        <TextButton
          id="cancelButton"
          color="dark"
          fullWidth
          onClick={() => closeModal()}
        >
          Cancel
        </TextButton>
      </GridItem>
    </GridWrapper>
  </Modal>
)

EvidenceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  setEvidenceMedia: PropTypes.func.isRequired,
  setEvidenceText: PropTypes.func.isRequired,
  isUploadingImages: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  handleSubmitEvidence: PropTypes.func.isRequired
}

export default EvidenceModal
