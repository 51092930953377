import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ActivityCard from '../../Shared/ActivityCard/ActivityCard'

export const TodoItem = ({ item, isMobile }) => {
  const started =
    item.canBeMarkedInProgress && item.markedInProgressByName !== null
  const completable = !item.canBeMarkedInProgress || started
  return (
    <div data-type={item.type}>
      <>
        {completable && isMobile && (
          <div>
            <ActivityCard item={item} />
          </div>
        )}
        {(!completable || (completable && !isMobile)) && (
          <div className="u-hide-on-print">
            <ActivityCard item={item} />
          </div>
        )}
      </>
    </div>
  )
}

const mapStateToProps = state => ({
  isMobile: state.activities.isMobile
})

TodoItem.propTypes = {
  item: PropTypes.object,
  isMobile: PropTypes.bool.isRequired
}

TodoItem.defaultProps = {
  item: null
}

export default connect(mapStateToProps, null)(TodoItem)
