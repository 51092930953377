import { call, put, select } from 'redux-saga/effects'
import ActivitiesAPI from 'api'
import * as actions from '../../actions'

function* getRiskActivities({ storeCode, page }) {
  try {
    const filters = yield select(state =>
      state.filter.applied.filter(x => !x.pages || x.pages.includes('risk'))
    )
    const activities = yield call(
      ActivitiesAPI.getRiskActivities,
      storeCode,
      page,
      filters
    )
    yield put(actions.receiveRiskActivities(activities))
  } catch (err) {
    yield put(actions.requestRiskActivitiesFailure())
  }
}

export default getRiskActivities
