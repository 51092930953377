import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { selectCalendarDay } from 'actions'
import { PLANNING_WEEK_VIEW, PLANNING_PERIOD_VIEW } from 'utils/constants'
import { getSelectedDayFromPeriodWeeks } from 'utils/Calendar/planning'
import { v1 } from 'uuid'
import WeekList from './WeekList'

export class MobilePlanningViewHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDate: this.getDefaultDate(),
      userClickedDay: false
    }
    this.headerHeight = 225
    this.dayElementHeaderYPositions = []

    this.daysHeaderContainer = React.createRef()
    this.handleScroll = this.handleScroll.bind(this)
    this.changeSelectedDateWhileScrolling =
      this.changeSelectedDateWhileScrolling.bind(this)
    this.configureDayElementHeaderPositions =
      this.configureDayElementHeaderPositions.bind(this)
    this.scrollToDate = this.scrollToDate.bind(this)
    this.getDefaultDate = this.getDefaultDate.bind(this)
    this.setDefaultDate = this.setDefaultDate.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    if (window.name !== 'nodejs') {
      if (this.props.view === PLANNING_WEEK_VIEW) {
        this.configureDayElementHeaderPositions()
        this.props.onSelectCalendarDay(
          this.props.activities.find(x =>
            x.date.isSame(this.state.selectedDate, 'day')
          )
        )
      } else if (this.props.view === PLANNING_PERIOD_VIEW) {
        this.props.onSelectCalendarDay(
          this.props.activities
            .flat()
            .find(x => x.date.isSame(this.state.selectedDate, 'day'))
        )
      }
    }
  }

  componentDidUpdate() {
    if (this.props.calendarDayShouldUpdate) {
      const dates = this.configureDates()
      this.setDefaultDate(
        this.state.selectedDate.isBetween(dates.firstDay, dates.lastDay)
          ? this.state.selectedDate
          : null,
        () => {
          if (this.props.view === PLANNING_WEEK_VIEW) {
            const selectedDateWithActivities = this.props.activities.find(x =>
              x.date.isSame(this.state.selectedDate, 'day')
            )
            if (selectedDateWithActivities) {
              this.props.onSelectCalendarDay(selectedDateWithActivities)
              this.scrollToDate(this.state.selectedDate)
            }
          } else if (this.props.view === PLANNING_PERIOD_VIEW) {
            const selectedDateWithActivities = getSelectedDayFromPeriodWeeks(
              this.props.activities,
              this.state.selectedDate
            )
            if (selectedDateWithActivities) {
              this.props.onSelectCalendarDay(selectedDateWithActivities)
            }
          }
        }
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  getDefaultDate() {
    const today = moment().startOf('day')
    const dates = this.configureDates()
    return today.isBetween(dates.firstDay, dates.lastDay)
      ? today
      : dates.firstDay
  }

  setDefaultDate(date, callback) {
    this.setState(
      {
        selectedDate: date || this.getDefaultDate()
      },
      () => {
        if (callback) {
          callback()
        }
      }
    )
  }

  scrollToDate(date) {
    const elId = `day-grouped-items-${date.format('MMMM Do YYYY')}`
    const el = document.getElementById(elId)
    const elRect = el !== null ? el.getBoundingClientRect() : 0
    const y = window.scrollY + elRect.y - this.headerHeight
    window.scrollTo({ top: y, behavior: 'auto' })
  }

  configureDates() {
    switch (this.props.view) {
      case PLANNING_WEEK_VIEW:
        return {
          firstDay: this.props.activities[0].date,
          lastDay:
            this.props.activities[this.props.activities.length - 1].date.endOf(
              'day'
            )
        }
      case PLANNING_PERIOD_VIEW:
        return {
          firstDay: this.props.activities[0][0].date,
          lastDay:
            this.props.activities[this.props.activities.length - 1][
              this.props.activities[0].length - 1
            ].date.endOf('day')
        }
      default:
        return {}
    }
  }

  handleScroll() {
    if (this.props.isFetching) {
      return
    }
    if (this.state.userClickedDay) {
      this.setState({ userClickedDay: false })
      return
    }
    if (this.props.view === PLANNING_WEEK_VIEW) {
      this.configureDayElementHeaderPositions()
      this.changeSelectedDateWhileScrolling()
    }
  }

  changeSelectedDateWhileScrolling() {
    for (let i = 0; i < this.dayElementHeaderYPositions.length; i += 1) {
      const scrolledPastDay = this.dayElementHeaderYPositions[i].yPos <= 0
      if (scrolledPastDay) {
        this.setState({ selectedDate: this.dayElementHeaderYPositions[i].date })
      }
    }
  }

  configureDayElementHeaderPositions() {
    this.dayElementHeaderYPositions = this.props.activities.map(day => {
      const dayElement = document.getElementById(
        `day-grouped-items-${day.date.format('MMMM Do YYYY')}`
      )
      return {
        date: day.date,
        yPos:
          dayElement !== null
            ? dayElement.getBoundingClientRect().top
            : 0 - this.headerHeight
      }
    })
  }

  render() {
    const { activities, view } = this.props
    return (
      <div
        id="mobile-planning-view-days-header"
        ref={this.daysHeaderContainer}
        className="c-mobile-planning-view-header"
      >
        {view === PLANNING_WEEK_VIEW && (
          <WeekList
            weekItems={activities}
            currentSelectedDate={this.state.selectedDate}
            onSelectItem={selectedDate => {
              this.setState({ selectedDate, userClickedDay: true }, () =>
                this.scrollToDate(selectedDate)
              )
              this.props.onSelectCalendarDay(
                activities.find(x => x.date === selectedDate)
              )
            }}
          />
        )}
        {view === PLANNING_PERIOD_VIEW &&
          activities.map((week, index) => (
            <WeekList
              key={v1()}
              weekItems={week}
              includeTitle={index === 0}
              currentSelectedDate={this.state.selectedDate}
              bottomSpacing={4}
              onSelectItem={selectedDate => {
                this.setState({ selectedDate, userClickedDay: true })
                this.props.onSelectCalendarDay(
                  week.find(x => x.date === selectedDate)
                )
              }}
            />
          ))}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  calendarDayShouldUpdate: state.activities.calendarDayShouldUpdate,
  isFetching: state.activities.isFetching
})

const mapDispatchToProps = dispatch => ({
  onSelectCalendarDay: day => {
    dispatch(selectCalendarDay(day))
  }
})

MobilePlanningViewHeader.propTypes = {
  activities: PropTypes.array.isRequired,
  view: PropTypes.string.isRequired,
  onSelectCalendarDay: PropTypes.func.isRequired,
  calendarDayShouldUpdate: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired
}

MobilePlanningViewHeader.defaultProps = {
  calendarDayShouldUpdate: false
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobilePlanningViewHeader)
