import React from 'react'
import { PropTypes } from 'prop-types'

const StoreSearch = ({ searchText, searchStores, location, searchChanged }) => (
  <div className="store-search">
    <h2>Select your store</h2>
    <div className="ln-c-form-group">
      <input
        value={searchText}
        type="search"
        className="ln-c-text-input"
        placeholder="Search for stores"
        onChange={({ target }) =>
          searchChanged(searchStores, location, target.value)
        }
      />
    </div>
  </div>
)

StoreSearch.propTypes = {
  searchText: PropTypes.string.isRequired,
  searchStores: PropTypes.func.isRequired,
  location: PropTypes.object,
  searchChanged: PropTypes.func.isRequired
}

StoreSearch.defaultProps = {
  location: { success: false }
}

export default StoreSearch
