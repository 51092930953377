export const actions = {
  SET_CURRENT_STORE: 'SET_CURRENT_STORE'
}

export const setCurrentStore = store => ({
  type: actions.SET_CURRENT_STORE,
  store
})
export const getCurrentStore = state => state.currentStore

export default actions
