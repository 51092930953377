import React from 'react'
import PropTypes from 'prop-types'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import Banner from '../Units/Banner'
import Title from '../Units/Title'
import DueDate from '../Units/DueDate'
import Summary from '../Units/Summary'
import Media from '../Units/Media'
import Attributes from '../Units/Attributes'
import ProcessAction from '../Processes/ProcessAction'
import History from '../Units/History'
import Feedback from '../Units/Feedback'

const Action = ({ activity, history, audit }) => {
  if (!activity) return null

  return (
    <div className="ln-u-padding-bottom*4">
      <Banner activity={activity} />
      <Card>
        <GridWrapper matrix className="activity-page-padder">
          <GridItem size="1/1">
            <DueDate dueDate={activity.dueDate} />
            <Title title={activity.description} smallerVariant />
            <Summary summary={activity.summary} />
            <Media
              hasMedia={activity.hasMedia}
              mediaAttachments={activity.mediaAttachments}
            />
            <Attributes attributes={activity.attributes} />
          </GridItem>
        </GridWrapper>
      </Card>

      <ProcessAction activity={activity} history={history} audit={audit} />
      <History activity={activity} audit={audit} showOverdue showSatisfaction />
      <Feedback activityId={activity.id} isHelpful={activity.isHelpful} />
    </div>
  )
}

Action.propTypes = {
  history: PropTypes.object,
  activity: PropTypes.object,
  audit: PropTypes.array
}

Action.defaultProps = {
  history: null,
  activity: null,
  audit: []
}

export default Action
