import { call, put } from 'redux-saga/effects'
import StoresApi from 'api/storesApi'
import actions from './actions'

const queryStores = params =>
  call(StoresApi.getStores, params && (params.query || params.code))
const queryByLocation = (query, lat, lon) =>
  call(StoresApi.getStores, query, lat, lon)

function* fetchStores(action = {}) {
  yield put({ type: actions.fetchPending })

  let stores
  if (
    action.params &&
    action.params.query &&
    action.params.lat &&
    action.params.lon
  ) {
    stores = yield queryByLocation(
      action.params.query,
      action.params.lat,
      action.params.lon
    )
  } else {
    stores = yield queryStores(action.params)
  }
  yield put({ type: actions.SET_STORES, stores })
}

export default fetchStores
