import apiHost from 'utils/apiHost'
import fetchAuth, { createHeadersForRequest } from 'utils/fetchAuth'

const getStores = query =>
  fetchAuth(`${apiHost}/store/Get?searchCriteria=${query}`)
    .then(r => r.json())
    .then(
      r =>
        r
          .slice(0, 10) // TODO: This should be done in the controller
          .map(s => ({ name: s.storeName, code: s.storeCode })) || []
    )
    .catch(() =>
      // handleError(`Get Failed for getStores - ${error.message}`);
      []
    )

const postUserIsLoggedIn = async storeCode => {
  await fetchAuth(`${apiHost}/store/userLoggedIntoStore/${storeCode}`, {
    headers: createHeadersForRequest(),
    method: 'POST'
  })
}

const getStoreCodesByStoreGroups = () => null

const getStoreGroupsByStoreCode = storeCode =>
  fetchAuth(`${apiHost}/store/GetStoreGroupsByStoreCode/${storeCode}`, {
    headers: createHeadersForRequest(false),
    method: 'GET'
  }).then(response => response.json())

const StoresApi = {
  getStores,
  postUserIsLoggedIn,
  getStoreCodesByStoreGroups,
  getStoreGroupsByStoreCode
}

export default StoresApi
