import { getStoreFromStorage, setStoreToStorage } from 'utils/currentStore'
import { actions } from './actions'

const stores = (state = getStoreFromStorage(), action) => {
  switch (action.type) {
    case actions.SET_CURRENT_STORE: {
      const store = { code: action.store.code, name: action.store.name }
      setStoreToStorage(store)
      return store
    }
    default:
      return state
  }
}

export default stores
