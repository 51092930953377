import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { OutlinedButton } from '@jsluna/button'
import { GridItem } from '@jsluna/grid'
import API from 'api'
import { toggleNewActionModal } from 'actions'
import FilterLayout from '../Filtering/FilterLayout'
import Risk from './Risk'

const WeeklyGroupedActivitiesWithFilters = ({ isMobile, toggleNewAction }) => {
  const [periodWeekData, setPeriodWeekData] = useState(null)

  useEffect(() => {
    API.getFinancialWeekByDate().then(response => setPeriodWeekData(response))
  }, [])

  return (
    <FilterLayout
      header={
        <div>
          <GridItem size={{ md: '3/5', lg: '4/5' }} className="ln-u-hard-left">
            <div>
              {periodWeekData && (
                <div className={isMobile ? 'ln-u-soft-left' : ''}>
                  <p style={{ marginBottom: '0px' }}>
                    {moment(periodWeekData.fromDate).format('ddd DD MMM')} -{' '}
                    {moment(periodWeekData.toDate).format('ddd DD MMM')}
                  </p>
                  <h3>
                    P{periodWeekData.periodNumber}, WK
                    {periodWeekData.weekEnumerator} (JS)
                  </h3>
                </div>
              )}
            </div>
          </GridItem>
          <GridItem size={{ md: '2/5', lg: '1/5' }} className="ln-u-hard-left">
            <div
              style={{ textAlign: 'right' }}
              className={
                isMobile
                  ? 'ln-u-soft-left ln-u-soft-right ln-u-soft-bottom'
                  : ''
              }
            >
              <OutlinedButton
                className="create-action-btn"
                style={{ backgroundColor: 'white' }}
                onClick={() => toggleNewAction()}
                fullWidth
              >
                Create action
              </OutlinedButton>
            </div>
          </GridItem>
        </div>
      }
    >
      <Risk />
    </FilterLayout>
  )
}

WeeklyGroupedActivitiesWithFilters.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  toggleNewAction: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  toggleNewAction: () => dispatch(toggleNewActionModal(true))
})

const mapStateToProps = state => ({
  isMobile: state.activities.isMobile
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeeklyGroupedActivitiesWithFilters)
