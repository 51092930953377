import {
  ATTRIBUTE_CATEGORIES_REQUEST,
  ATTRIBUTE_CATEGORIES_SUCCESS,
  ATTRIBUTE_CATEGORIES_FAILURE
} from '../actions'

const initialState = {
  attributeCategories: null,
  isFetchingAttributeCategories: false,
  error: false
}

const attributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ATTRIBUTE_CATEGORIES_REQUEST:
      return {
        ...state,
        isFetchingAttributeCategories: true,
        error: false
      }
    case ATTRIBUTE_CATEGORIES_SUCCESS:
      return {
        ...state,
        isFetchingAttributeCategories: false,
        attributeCategories: action.attributes,
        error: false
      }
    case ATTRIBUTE_CATEGORIES_FAILURE:
      return {
        ...state,
        isFetchingAttributeCategories: false,
        error: true
      }
    default:
      return {
        ...state
      }
  }
}

export default attributesReducer
