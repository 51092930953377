import React from 'react'
import PropTypes from 'prop-types'
import { FlagWrapper, FlagComponent, FlagBody } from '@jsluna/grid'
import { Alert, AlertIcon } from '@jsluna/labs'
import { ErrorCircle, InfoCircle, Tick, Time } from '@jsluna/icons'
import { formatDateTimeWithYear } from 'utils/datetimeformat'

const content = (icon, statement, context) => (
  <FlagWrapper className="ln-u-margin">
    <FlagComponent>
      <AlertIcon>{icon}</AlertIcon>
    </FlagComponent>
    <FlagBody className="ln-u-margin-sides*2">
      <div>
        <strong>{statement}</strong>
      </div>
      <div>{context()}</div>
    </FlagBody>
  </FlagWrapper>
)

const Banner = ({ activity }) => {
  if (!activity) return null

  if (activity.isCompleted) {
    return (
      <Alert success squared>
        {content(
          <Tick size="large" />,
          'Completed',
          () =>
            `${formatDateTimeWithYear(activity.completedOn)} by ${
              activity.completedBy
            }`
        )}
      </Alert>
    )
  }

  if (activity.isUrgent) {
    return (
      <Alert error squared>
        {content(
          <InfoCircle size="large" />,
          'This is an urgent activity',
          () => 'Please prioritise and complete as soon as you can'
        )}
      </Alert>
    )
  }

  if (activity.isMissed) {
    return (
      <Alert error squared>
        {content(
          <ErrorCircle size="large" />,
          'This activity has been missed',
          () => `Due ${formatDateTimeWithYear(activity.dueDate)}`
        )}
      </Alert>
    )
  }

  if (activity.isOverdue) {
    return (
      <Alert error squared>
        {content(
          <Time size="large" />,
          'This activity is overdue',
          () => `Due ${formatDateTimeWithYear(activity.dueDate)}`
        )}
      </Alert>
    )
  }

  if (activity.colleagueAssignmentSummary) {
    return (
      <Alert type="info" squared>
        {content(
          <InfoCircle size="large" />,
          'Assigned',
          () => `Assigned to ${activity.colleagueAssignmentSummary}`
        )}
      </Alert>
    )
  }

  return null
}

Banner.propTypes = {
  activity: PropTypes.object
}

Banner.defaultProps = {
  activity: null
}

export default Banner
