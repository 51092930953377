import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ChecklistRenderer } from '@jsainsburyplc/activity-checklist'
import { Heading3 } from '@jsluna/typography'
import { connect } from 'react-redux'
import moment from 'moment'
import { setChecklistResponse } from 'actions'
import {
  ProgressIndicator,
  ProgressMessage,
  ProgressSpinner
} from '@jsluna/progress'
import parseHeadingDate from 'utils/datetimeformat'
import { getResponse } from 'utils/helpers'

export const ChecklistContent = ({
  activity,
  items,
  currentUser,
  currentUserId,
  submittingChecklist,
  submitChecklistResponse,
  failedToSubmitChecklist
}) => {
  const [currentItem, setCurrentItem] = useState('')
  if (!activity || !items || items?.length === 0) return null

  const displayDate = date => {
    if (date && date !== 'undefined') {
      return `${parseHeadingDate(new Date(date))}`
    }
    return ''
  }
  const jsonResponse = getResponse(activity?.response)
  const selectedItems = jsonResponse?.payload?.checklistItems
  const disabled = activity?.isCompleted || activity.isMissed
  function formattedItems() {
    if (selectedItems) {
      return selectedItems.map(item => ({
        userId: item.userId,
        userName: item.userName,
        itemId: item.itemId,
        selected: item.selected,
        dateTime: item.dateTime,
        formattedDateTime: displayDate(item.dateTime)
      }))
    }
    return []
  }
  const handleChecklistChange = values => {
    if (values) {
      const updatedResponse = values.map(item => {
        const filteredItem = selectedItems?.find(i => i.itemId === item.itemId)
        setCurrentItem(item.userId === '' ? item.itemId : '')
        return {
          itemId: item.itemId,
          selected: item.selected,
          userId: item.userId === '' ? `${currentUserId}` : item.userId,
          userName: item.userName === '' ? `${currentUser}` : item.userName,
          dateTime:
            filteredItem &&
            filteredItem.selected === item.selected &&
            item.userId !== ''
              ? filteredItem?.dateTime
              : moment().toISOString()
        }
      })
      submitChecklistResponse(activity?.id, updatedResponse)
    }
  }
  const loader = (
    <ProgressIndicator loading preventFocus>
      <ProgressSpinner className="ln-u-push-right-sm" />
      <ProgressMessage>Loading...</ProgressMessage>
    </ProgressIndicator>
  )

  return (
    <>
      <div className="ln-u-margin-top">
        <div
          className={`ln-u-margin-left*2 ln-u-padding-top*2 ${
            disabled ? 'checklist-header-disabled' : 'checklist-header'
          }`}
        >
          <Heading3>Please complete the following</Heading3>
        </div>

        {submittingChecklist
          ? loader
          : items && (
              <ChecklistRenderer
                items={items}
                selectedItems={formattedItems()}
                currentItem={currentItem}
                disabled={disabled}
                onChangeItem={values => handleChecklistChange(values)}
                error={failedToSubmitChecklist}
              />
            )}
      </div>
    </>
  )
}

ChecklistContent.propTypes = {
  activity: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired
    })
  ),
  currentUser: PropTypes.string,
  currentUserId: PropTypes.string,
  submittingChecklist: PropTypes.bool,
  failedToSubmitChecklist: PropTypes.bool,
  submitChecklistResponse: PropTypes.func.isRequired
}

ChecklistContent.defaultProps = {
  items: [],
  submittingChecklist: false,
  failedToSubmitChecklist: false,
  currentUser: '',
  currentUserId: ''
}

function mapStateToProps(state) {
  return {
    submittingChecklist: state.activities.submittingChecklist,
    failedToSubmitChecklist: state.activities.failedToSubmitChecklist,
    currentUser: state.authentication.idTokenClaims?.name ?? '',
    currentUserId: state.authentication.idTokenClaims?.id ?? ''
  }
}

function mapDispatchToProps(dispatch) {
  return {
    submitChecklistResponse: (instanceId, response) =>
      dispatch(setChecklistResponse(instanceId, response))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistContent)
