import React from 'react'
import PropTypes from 'prop-types'
import { Table } from '@jsluna/table'
import { IconFor } from '@jsainsburyplc/activity-icons'
import { RISK_IGNORED_ATTRIBUTES } from 'utils/constants'

const calculateOutstanding = (completed, total) =>
  total > 0 ? total - completed : 0

const columns = [
  {
    name: '',
    accessor: row => ({ value: row.attribute }),
    // eslint-disable-next-line react/prop-types
    render: ({ value }) => (
      <span>
        <IconFor value={value} /> {value}
      </span>
    )
  },
  { name: 'Created actions', accessor: row => ({ value: row.created }) },
  { name: 'Open actions', accessor: row => ({ value: row.outstanding }) },
  { name: 'Overdue actions', accessor: row => ({ value: row.overdue }) }
]

const ActionAttributeReport = ({ attributes, complianceByAttribute }) => {
  const rows =
    attributes &&
    attributes
      .reduce(
        (result, item) =>
          result.concat(
            item.activityAttributes
              .filter(
                attribute => !RISK_IGNORED_ATTRIBUTES.includes(attribute.name)
              )
              .map(attribute => {
                const complianceForAttribute = complianceByAttribute.find(
                  compliance => compliance.name === attribute.name
                )

                const {
                  numberOfActivities: total,
                  numberOfActivitiesCompleted: completed,
                  numberOfNewActivities: created,
                  numberOfOverdueActivities: overdue
                } = complianceForAttribute || {}

                return {
                  attribute: attribute.name,
                  created: complianceForAttribute ? created : 0,
                  outstanding: complianceForAttribute
                    ? calculateOutstanding(completed, total)
                    : 0,
                  overdue: complianceForAttribute ? overdue : 0
                }
              })
          ),
        []
      )
      .sort((a, b) => (a.attribute > b.attribute ? 1 : -1))

  return <Table rowKey="attribute" data={rows} columns={columns} />
}

ActionAttributeReport.propTypes = {
  attributes: PropTypes.array,
  complianceByAttribute: PropTypes.array
}

ActionAttributeReport.defaultProps = {
  attributes: null,
  complianceByAttribute: null
}

export default ActionAttributeReport
