import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Card } from '@jsluna/card'
import { FlagBody, FlagComponent, FlagWrapper } from '@jsluna/grid'
import { ArrowRight } from '@jsluna/icons'
import { VIEW_ACTIVITY_ROUTE } from 'utils/constants'
import ActivityRestrictionInfo from '../../ViewActivity/Units/ActivityRestriction/ActivityRestrictionInfo'
import JobFamilies from './JobFamilies'
import Title from './Title'
import Summary from './Summary'
import KeyDate from './KeyDate'
import AssignedTo from './AssignedTo'
import Tags from './Tags'
import {
  RISK_TYPE_ATTRIBUTES_CATEGORY,
  TODO_TYPE_ATTRIBUTES_CATEGORY
} from '../../../../utils/constants'

const cardStyle = item =>
  item.isOverdue || item.isUrgent
    ? 'activity-card activity-card--danger'
    : 'activity-card'

const filterAttributes = attributes =>
  attributes.filter(
    x =>
      x.category !== TODO_TYPE_ATTRIBUTES_CATEGORY &&
      x.category !== RISK_TYPE_ATTRIBUTES_CATEGORY
  )

const ActivityCard = ({ item }) => {
  if (!item) return null

  return (
    <Link className="activity-title" to={`${VIEW_ACTIVITY_ROUTE}/${item.id}`}>
      <Card className={cardStyle(item)}>
        <FlagWrapper>
          <FlagComponent style={{ width: '100%' }}>
            <ActivityRestrictionInfo audience={item?.audience} />
            <JobFamilies attributes={filterAttributes(item.attributes)} />
            <Title {...item} />
            <Summary summary={item.summary} />
            <KeyDate
              completedOn={item.completedOn}
              completedBy={item.completedByName}
              dueDate={item.dueDate}
              visibleFromDate={item.visibleFromDate}
              isCommunication={item.isCommunication}
              type={item.type}
              activeFromDate={item.activeFromDate}
            />
            <AssignedTo
              assignedTo={item.colleagueAssignmentSummary}
              startedBy={item.markedInProgressByName}
            />
            <Tags item={item} />
          </FlagComponent>
          <FlagBody className="ln-u-text-align-right">
            <ArrowRight />
          </FlagBody>
        </FlagWrapper>
      </Card>
    </Link>
  )
}

ActivityCard.propTypes = {
  item: PropTypes.object
}

ActivityCard.defaultProps = {
  item: null
}

export default ActivityCard
