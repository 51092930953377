import React, { useEffect, useReducer, useState } from 'react'
import {
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
  FilledButton,
  TextButton
} from '@jsluna/button'
import { TextInputField, TextAreaField } from '@jsluna/form'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Modal, ModalHeading } from '@jsluna/modal'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import 'react-dates/initialize'
import { SingleDateTimePicker } from '@jsainsburyplc/retail-datetime-picker'
import { AttributeSelector } from '@jsainsburyplc/activity-attribute-selector'
import { MediaSelector } from '@jsainsburyplc/activity-media-upload'
import { getStoreFromStorage } from 'utils/currentStore'
import { RISK_IGNORED_ATTRIBUTES } from 'utils/constants'
import { v1 } from 'uuid'
import ColleagueSearch from '../ColleagueSearch'
import SensitiveDataText from '../../../../Risk/SensitiveDataText'

export const AdhocActionModal = ({
  isMobile,
  isOpen,
  closeNewActionModal,
  submitAction,
  isUploadingImages,
  attributeCategories
}) => {
  const [allAttributes, setAllAttributes] = useState([])

  useEffect(() => {
    if (
      attributeCategories &&
      attributeCategories.length > 0 &&
      attributeCategories.filter(x => x.name === 'Job Families').length === 1
    ) {
      const jobFamilies = attributeCategories
        .filter(x => x.name === 'Job Families')[0]
        .activityAttributes.filter(
          attribute => !RISK_IGNORED_ATTRIBUTES.includes(attribute.name)
        )
        .map(y => ({
          label: y.name,
          value: y.id
        }))
      setAllAttributes(jobFamilies)
    }
  }, [attributeCategories])

  const initialState = {
    evidence: '',
    images: [],
    title: '',
    description: '',
    date: null,
    assignments: [],
    attributes: [],
    showError: false
  }

  function adhocActionReducer(state, action) {
    switch (action.type) {
      case 'updateEvidence':
        return action.evidence
      case 'updateImages':
        return action.images
      case 'updateTitle':
        return action.title
      case 'updateDescription':
        return action.description
      case 'updateDate':
        return action.date
      case 'updateAssignments':
        return action.assignments
      case 'updateshowError':
        return action.showError
      case 'updateAttributes':
        return action.attributes
      case 'resetState':
        return initialState
      default:
        throw new Error()
    }
  }

  const [state, dispatch] = useReducer(adhocActionReducer, initialState)

  const handleClose = () => {
    dispatch({ type: 'resetState' })
    closeNewActionModal()
  }

  const hasValidAttributes = !getStoreFromStorage()?.code?.startsWith('A')
    ? state.attributes.length > 0
    : true

  const isFormValid = () =>
    state.evidence !== '' &&
    state.title !== '' &&
    state.description !== '' &&
    state.assignments.length > 0 &&
    state.date !== null &&
    hasValidAttributes

  const handleSubmit = () => {
    if (!state.showError) {
      dispatch({
        type: 'updateshowError',
        showError: { ...state, showError: true }
      })
    }
    if (!isFormValid()) return

    const request = {
      activityId: 1,
      creationReason: state.evidence,
      mediaAttachments: state.images,
      title: state.title,
      description: state.description,
      dueDate: state.date,
      storeAssignments: JSON.stringify({
        StoreCodeAssignmentItems: [getStoreFromStorage()?.code]
      }),
      AssignToIds: state.assignments.map(x => x.value),
      attributeIds: state.attributes.map(x => x.value)
    }

    submitAction(request)
    closeNewActionModal()
  }

  const getPickerProps = () => {
    const initialProps = {
      orientation: isMobile ? 'vertical' : 'horizontal',
      openDirection: 'up',
      numberOfMonths: 2
    }

    return initialProps
  }

  return (
    <Modal restrictClose fullScreen open={isOpen} alert>
      <GridWrapper verticalAlign="middle" className="ln-u-push-bottom">
        <GridItem size="1/1">
          <ModalHeading element="h3" className="ln-u-margin-bottom*1/2">
            Provide evidence and create a new action
          </ModalHeading>
          <SensitiveDataText className="ln-u-margin-bottom*2" />
        </GridItem>
        <>
          {!getStoreFromStorage()?.code?.startsWith('A') && (
            <GridItem size="1/1">
              <AttributeSelector
                key={v1()}
                attributes={allAttributes}
                onSelect={attrs => {
                  dispatch({
                    type: 'updateAttributes',
                    attributes: { ...state, attributes: attrs }
                  })
                }}
                selectedAttributes={state.attributes}
                error={
                  state.showError &&
                  state.attributes.length === 0 &&
                  'Please select an area that needs to do this'
                }
              />
            </GridItem>
          )}
          <GridItem size="1/1">
            <TextAreaField
              name="evidence-text-box"
              error={
                state.showError &&
                state.evidence === '' &&
                'Please indicate why you are creating this action'
              }
              label="Why are you creating this action"
              onChange={e =>
                dispatch({
                  type: 'updateEvidence',
                  evidence: { ...state, evidence: e.target.value }
                })
              }
            />
          </GridItem>
          <GridItem size="1/1">
            <MediaSelector
              title="Attach supporting imagery (optional)"
              buttonText="Upload image"
              onMediaUpdated={e =>
                dispatch({
                  type: 'updateImages',
                  images: { ...state, images: e }
                })
              }
              style={{ margin: '2rem 0 2rem 0' }}
              maxUploads={5}
              imageOnly
            />
          </GridItem>
        </>

        <GridItem size="1/1">
          <p className="ln-u-soft-top">
            Create a new action to help solve the issue you recorded in store
          </p>
          <TextInputField
            type="textcreateActionRequest"
            label="Action Title"
            id="text-input-1"
            name="text-input-1"
            error={
              state.showError && state.title === '' && 'Please add action title'
            }
            maxLength={100}
            class="ln-c-text-input"
            onChange={e =>
              dispatch({
                type: 'updateTitle',
                title: { ...state, title: e.target.value }
              })
            }
          />
          <TextAreaField
            name="directions-text-box"
            label="Action directions"
            error={
              state.showError &&
              !state.description &&
              'Please add action directions'
            }
            onChange={e =>
              dispatch({
                type: 'updateDescription',
                description: { ...state, description: e.target.value }
              })
            }
            maxLength={550}
          />
        </GridItem>
        <GridItem size="1/1">
          <GridWrapper>
            <GridItem size={{ default: '1/1', sm: '3/5' }}>
              <ColleagueSearch
                onSearchItemSelected={selectedArray =>
                  dispatch({
                    type: 'updateAssignments',
                    assignments: { ...state, assignments: selectedArray }
                  })
                }
                selectedOptions={state.assignments}
                error={
                  state.showError && state.assignments.length === 0
                    ? 'Please select a colleague'
                    : ''
                }
              />
            </GridItem>
            <GridItem size={{ default: '1/1', sm: '2/5' }}>
              <b className="ln-c-label">Must be completed by</b>
              <SingleDateTimePicker
                defaultTime="23:30"
                pickerClass="DateTimePicker-colleague datetime-picker datetime-picker-left"
                valueChanged={d =>
                  dispatch({ type: 'updateDate', date: { ...state, date: d } })
                }
                datePickerProps={getPickerProps()}
                hasError={state.showError && !state.date}
                errorMsg={
                  state.showError && !state.date ? 'Please select a date' : ''
                }
                isOutsideRange={day => day.isBefore(moment().startOf('day'))}
              />
            </GridItem>
          </GridWrapper>
        </GridItem>
        <GridItem size="1/1">
          <div>
            {isUploadingImages && (
              <ProgressIndicator
                style={{
                  position: 'relative',
                  zIndex: 3
                }}
                className="ln-u-soft-bottom"
                loading
              >
                <ProgressSpinner />
                {'Uploading...'}
              </ProgressIndicator>
            )}
          </div>
          <ButtonGroupWrapper actionbar>
            <ButtonGroupSecondary>
              <TextButton onClick={() => handleClose()}>Cancel</TextButton>
            </ButtonGroupSecondary>
            <ButtonGroupPrimary>
              <FilledButton id="createButton" onClick={() => handleSubmit()}>
                Create
              </FilledButton>
            </ButtonGroupPrimary>
          </ButtonGroupWrapper>
        </GridItem>
      </GridWrapper>
    </Modal>
  )
}

const mapStateToProps = state => ({
  isUploadingImages: state.activities.showUploadingSpinner,
  isMobile: state.activities.isMobile,
  attributeCategories: state.attributes.attributeCategories
})

AdhocActionModal.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeNewActionModal: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  isUploadingImages: PropTypes.bool.isRequired,
  attributeCategories: PropTypes.array
}

AdhocActionModal.defaultProps = {
  attributeCategories: []
}

export default connect(mapStateToProps, null)(AdhocActionModal)
