import {
  GET_STORE_GROUPS_REQUEST,
  GET_STORE_GROUPS_SUCCESS,
  GET_STORE_GROUPS_FAILURE
} from '../actions/storeGroups'

const initialState = {
  groups: null,
  isFetching: false,
  error: false
}

const storeGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STORE_GROUPS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false
      }
    case GET_STORE_GROUPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        groups: action.storeGroups,
        error: false
      }
    case GET_STORE_GROUPS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true
      }
    default:
      return {
        ...state
      }
  }
}

export default storeGroupsReducer
