import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  ProgressIndicator,
  ProgressMessage,
  ProgressSpinner
} from '@jsluna/progress'
import { riskTaskReportRequest } from 'actions'
import { Card } from '@jsluna/card'
import { FlagBody, FlagComponent, FlagWrapper } from '@jsluna/grid'
import moment from 'moment'

export const RiskProgressDisplay = ({
  loading,
  error,
  getRiskTaskReport,
  riskTaskCompliance,
  openActionsCount,
  openTasksCount,
  filters
}) => {
  const startOfWeek = moment()
    .day('Sunday')
    .startOf('day')
    .format('YYYY-MM-DDTHH:mm:ss')
  const endOfWeek = moment()
    .day('Saturday')
    .startOf('day')
    .format('YYYY-MM-DDTHH:mm:ss')

  const { complianceSummary } = riskTaskCompliance
  const { numberOfCompletedActivities: completed, numberOfActivities: total } =
    complianceSummary || {}

  const riskCompletionPercentage =
    completed === 0 ? '0' : (completed * 100) / total

  useEffect(() => {
    getRiskTaskReport(startOfWeek, endOfWeek, false)
  }, [])

  const hasPageFilterApplied =
    filters &&
    filters.length > 0 &&
    filters.filter(
      x =>
        x.filterType === 'attributeIds' || (x.pages && x.pages.includes('risk'))
    ).length > 0

  return (
    <Card className="activity-card">
      <FlagWrapper>
        <FlagComponent style={{ position: 'relative' }}>
          <div className="ln-u-padding">
            {loading ? (
              <ProgressIndicator loading preventFocus>
                <ProgressSpinner className="ln-u-push-right-sm" size="large" />
                <ProgressMessage>Loading...</ProgressMessage>
              </ProgressIndicator>
            ) : (
              <ProgressIndicator loading preventFocus>
                <ProgressSpinner
                  size="large"
                  value={total === 0 ? 100 : riskCompletionPercentage}
                />
              </ProgressIndicator>
            )}
          </div>
          {!loading && (
            <div className="filter-amount ln-u-text-align-center">
              {completed}/{total}
            </div>
          )}
        </FlagComponent>
        {!loading && !error && (
          <FlagBody className="ln-u-push-left">
            <h4 className="ln-u-margin-bottom">
              {completed} of {total} tasks completed (
              {Math.round(riskCompletionPercentage)}%)
            </h4>
            {openTasksCount + completed !== total && (
              <div>
                This includes {total - (openTasksCount + completed)} tasks,
                which may be{' '}
                {hasPageFilterApplied && 'hidden by your applied filters or'} in
                the future / already passed
              </div>
            )}
            <div>
              {openActionsCount} open action{openActionsCount !== 1 ? 's' : ''}
            </div>
          </FlagBody>
        )}
      </FlagWrapper>
    </Card>
  )
}

RiskProgressDisplay.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  getRiskTaskReport: PropTypes.func.isRequired,
  riskTaskCompliance: PropTypes.object.isRequired,
  openActionsCount: PropTypes.number.isRequired,
  openTasksCount: PropTypes.number.isRequired,
  filters: PropTypes.array
}

RiskProgressDisplay.defaultProps = {
  filters: []
}

const mapStateToProps = state => ({
  loading: state.report.loading,
  error: state.report.error,
  riskTaskCompliance: state.report.riskTaskCompliance,
  filters: state.filter.applied
})

const mapDispatchToProps = dispatch => ({
  getRiskTaskReport: (fromDate, toDate, withAttributes) =>
    dispatch(riskTaskReportRequest(fromDate, toDate, withAttributes))
})

export default connect(mapStateToProps, mapDispatchToProps)(RiskProgressDisplay)
