import { call, put, select } from 'redux-saga/effects'
import {
  FILTER_PAGES,
  RISK_TRIAL_GROUP_LIST,
  NEED_TO_KNOW_OFFSET
} from 'utils/constants'
import ActivitiesAPI from 'api'
import * as actions from '../../actions'

function* getHistoricActivities({ storeCode, page }) {
  const groups = yield select(state => state.storeGroups.groups)

  const riskEnabled =
    groups && groups.some(group => RISK_TRIAL_GROUP_LIST.includes(group))

  try {
    const filters = yield select(state =>
      state.filter.applied.filter(
        x => !x.pages || x.pages.includes(FILTER_PAGES.history)
      )
    )
    const activities = yield call(
      ActivitiesAPI.getHistoryActivities,
      storeCode,
      page,
      filters,
      riskEnabled ?? false,
      NEED_TO_KNOW_OFFSET
    )
    yield put(actions.receiveHistoricActivities(activities))
  } catch (err) {
    yield put(actions.requestHistoricActivitiesFailure())
  }
}

export default getHistoricActivities
