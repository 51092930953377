import React from 'react'

const StoreDisplay = (store, index, currentStore, selectStore) => (
  <li key={index} className="ln-o-bare-list__item">
    <a
      tabIndex="0"
      href={`#${store.code}`}
      onClick={e => {
        selectStore(store)
        e.preventDefault()
      }}
    >
      <span className="displayName">
        {store.name} ({store.code})
      </span>
    </a>
  </li>
)

export default StoreDisplay
