import React from 'react'
import PropTypes from 'prop-types'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { SATISFIED_VALUE } from 'utils/constants'

import AssignmentControl from '../Units/AssignmentControl'
import AuditHandler from './Audit/AuditHandler'
import Undo from '../Units/Undo'

const isSatisfied = audit => {
  const payload =
    audit && audit.length && JSON.parse(audit[audit.length - 1].eventPayload)

  return payload?.satisfaction === SATISFIED_VALUE
}

const ProcessAudit = ({ activity, history, audit }) => {
  if (!activity) return null

  const { isCompleted, isMissed } = activity

  const showComplete = !isCompleted && !isMissed
  const showUndo = isSatisfied(audit) && isCompleted && !isMissed

  if (!showComplete && !showUndo) return null

  return (
    <Card className="ln-u-margin-top">
      <GridWrapper matrix className="activity-page-padder">
        <GridItem size="1/1">
          {showComplete && <AssignmentControl />}
          {showComplete && (
            <AuditHandler activity={activity} history={history} audit={audit} />
          )}
          {showUndo && <Undo activity={activity} />}
        </GridItem>
      </GridWrapper>
    </Card>
  )
}

ProcessAudit.propTypes = {
  history: PropTypes.object.isRequired,
  activity: PropTypes.object,
  audit: PropTypes.array
}

ProcessAudit.defaultProps = {
  activity: null,
  audit: []
}

export default ProcessAudit
