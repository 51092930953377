import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getStoreFromStorage } from 'utils/currentStore'
import { Pagination } from '@jsluna/pagination'
import { searchActivities, searchActivitiesUpdatePage } from '../../../actions'
import Results from './Results'
import FilterLayout from '../Filtering/FilterLayout'
import NoResults from './NoResults'
import SkeletonItem from '../Activities/SkeletonItem'
import ItemHeader from '../Activities/ItemHeader'

export const SearchPage = ({
  filters,
  match,
  getSearchResults,
  updatePageNumber,
  searchResults,
  pageNumber,
  pageCount,
  isSearching
}) => {
  useEffect(() => {
    const store = getStoreFromStorage()
    if (store !== null) {
      getSearchResults(store, pageNumber, match.params.searchText)
    }
  }, [filters, pageNumber, match.params.searchText])

  const skeletonItems = () => {
    const loopedItems = []
    for (let i = 0; i < 3; i += 1) {
      loopedItems.push(<SkeletonItem key={i} />)
    }
    return loopedItems
  }

  return (
    <div className="search-page-container">
      <FilterLayout header={<h2>Search Results</h2>}>
        {isSearching ? (
          <div className="ln-u-push-bottom-lg">
            <ItemHeader />
            {skeletonItems()}
          </div>
        ) : (
          <>
            <div className="ln-u-soft-ends">
              {searchResults.length > 0 ? (
                <Results items={searchResults} />
              ) : (
                <NoResults search={match.params.searchText} />
              )}
            </div>
            {pageCount > 1 && (
              <Pagination
                fullWidth
                showFirstAndLast
                showPages
                current={pageNumber}
                total={pageCount}
                onChange={(x, e) => {
                  e.preventDefault()
                  updatePageNumber(x)
                }}
              />
            )}
          </>
        )}
      </FilterLayout>
    </div>
  )
}

const mapStateToProps = state => ({
  filters: state.filter.applied,
  searchResults: state.search.results.items,
  pageCount: state.search.results.pageCount,
  pageNumber: state.search.pageNumber,
  isSearching: state.search.isSearching
})

const mapDispatchToProps = dispatch => ({
  getSearchResults: (store, pageNumber, searchText) =>
    dispatch(searchActivities(store.code, pageNumber, searchText)),
  updatePageNumber: pageNumber =>
    dispatch(searchActivitiesUpdatePage(pageNumber))
})

SearchPage.propTypes = {
  filters: PropTypes.array,
  match: PropTypes.object.isRequired,
  getSearchResults: PropTypes.func.isRequired,
  updatePageNumber: PropTypes.func.isRequired,
  searchResults: PropTypes.array,
  pageNumber: PropTypes.number,
  pageCount: PropTypes.number,
  isSearching: PropTypes.bool.isRequired
}

SearchPage.defaultProps = {
  filters: [],
  searchResults: [],
  pageNumber: 1,
  pageCount: 1
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage)
