import React from 'react'
import PropTypes from 'prop-types'
import { parseHeadingDate } from 'utils/datetimeformat'
import * as Constants from 'utils/constants'
import { useAnalytics } from '@jsainsburyplc/retail-analytics'
import ActivityCard from '../Shared/ActivityCard/ActivityCard'
import TodoItem from './TodoItem/TodoItem'

const DayGroupedItems = ({ weekActivities }) => {
  const { sendEvent } = useAnalytics()

  const itemsFilteredByTypes = (items, types) =>
    items.filter(x => types.includes(x.type))
  const itemsOfTypeExistForThisDay = (items, types) =>
    itemsFilteredByTypes(items, types).length > 0
  return (
    <div className="ln-u-soft-top">
      {weekActivities &&
        weekActivities.map(day => (
          <div key={day.date}>
            <div
              id={`day-grouped-items-${day.date.format('MMMM Do YYYY')}`}
              className="ln-u-soft-left-sm ln-u-body-1-fixed ln-u-push-bottom-sm"
            >
              <h6>{parseHeadingDate(new Date(day.date))}</h6>
            </div>
            {itemsOfTypeExistForThisDay(day.items, [
              Constants.ACTIVITY_TYPE_TODO,
              Constants.ACTIVITY_TYPE_SURVEY
            ]) && (
              <div
                className="ln-u-push-bottom-xl"
                role="presentation"
                onClick={() =>
                  sendEvent('schedule_view_activity', {
                    label: 'scheduleViewActivity'
                  })
                }
              >
                {itemsFilteredByTypes(day.items, [
                  Constants.ACTIVITY_TYPE_TODO,
                  Constants.ACTIVITY_TYPE_SURVEY
                ]).map(item => (
                  <TodoItem item={item} key={item.id} />
                ))}
              </div>
            )}
            {itemsOfTypeExistForThisDay(day.items, [
              Constants.ACTIVITY_TYPE_INFO
            ]) && (
              <div
                className="ln-u-push-bottom-xl"
                role="presentation"
                onClick={() =>
                  sendEvent('schedule_view_activity', {
                    label: 'scheduleViewActivity'
                  })
                }
              >
                {itemsFilteredByTypes(day.items, [
                  Constants.ACTIVITY_TYPE_INFO
                ]).map(item => (
                  <ActivityCard item={item} key={item.id} />
                ))}
              </div>
            )}
            {day.items.length === 0 && (
              <p className="ln-u-soft-sides-sm">No activities for this day</p>
            )}
          </div>
        ))}
    </div>
  )
}
DayGroupedItems.propTypes = {
  weekActivities: PropTypes.array.isRequired
}

export default DayGroupedItems
