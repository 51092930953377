import React from 'react'
import PropTypes from 'prop-types'

const ReportCard = ({ text, value, colour, type }) => (
  <div className="risk-compliance-report-card">
    <div>
      <div
        className={`risk-compliance-report-card-value ${
          value >= 1 ? colour : ''
        }`}
      >
        {type === 'percentage' ? `${Math.round(value * 100)}%` : value}
      </div>
      <div>{text}</div>
    </div>
  </div>
)

ReportCard.propTypes = {
  text: PropTypes.string.isRequired,
  colour: PropTypes.string.isRequired,
  value: PropTypes.number,
  type: PropTypes.string
}

ReportCard.defaultProps = {
  value: 0,
  type: 'number'
}

export default ReportCard
