export const ACQUIRE_TOKEN_SILENT = 'ACQUIRE_TOKEN_SILENT'
export const ACQUIRE_TOKEN_SILENT_SUCCESS = 'ACQUIRE_TOKEN_SILENT_SUCCESS'
export const ACQUIRE_TOKEN_SILENT_FAILURE = 'ACQUIRE_TOKEN_SILENT_FAILURE'
export const ACQUIRE_TOKEN_REDIRECT = 'ACQUIRE_TOKEN_REDIRECT'

export const acquireTokenSilentRequest = (scopes, account) => ({
  type: ACQUIRE_TOKEN_SILENT,
  scopes,
  account
})

export const acquireTokenSilentSuccess = authenticationResult => ({
  type: ACQUIRE_TOKEN_SILENT_SUCCESS,
  authenticationResult
})

export const acquireTokenSilentFailure = () => ({
  type: ACQUIRE_TOKEN_SILENT_FAILURE
})

export const acquireTokenRedirect = (scopes, account) => ({
  type: ACQUIRE_TOKEN_REDIRECT,
  scopes,
  account
})

export default {
  acquireTokenSilentRequest,
  acquireTokenSilentSuccess,
  acquireTokenSilentFailure,
  acquireTokenRedirect
}
