import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const MobileHeaderDayItem = ({
  date,
  isSelected,
  onClick,
  hasItems,
  includeTitle
}) => {
  const title = date.format('ddd')
  const dateDayNumber = date.format('D')
  const id = date.format()
  const isPastDay = day => day && day.isBefore(moment().startOf('day'))
  const isToday = day => day && day.isSame(moment(), 'day')
  return (
    <a
      role="listbox"
      id={id}
      className={`ln-u-display-inline-block ${
        isPastDay(date) ? 'c-mobile-past-day' : ''
      }`}
      onClick={() => onClick(date)}
      tabIndex={-1}
      style={{ cursor: 'pointer' }}
    >
      {includeTitle && (
        <h6
          className="c-calendar-item mobile-header-day-item-title"
          style={{ marginBottom: '0.25rem' }}
        >
          <small>{title}</small>
        </h6>
      )}
      <div
        className={`c-mobile-day-card ${
          isToday(date) ? 'today' : ''
        } ln-u-padding-ends ${
          isSelected ? 'ln-u-bg-color-orange-dark ln-u-color-white' : ''
        }`}
        style={{
          borderRadius: '5px',
          width: '46px',
          maxWidth: '46px',
          height: '46px'
        }}
      >
        <h6 className="c-calendar-item" style={{ marginBottom: 0 }}>
          <small>{dateDayNumber}</small>
        </h6>
        <p
          className={`ln-u-text-align-center ${
            isSelected ? 'ln-u-color-white' : ''
          }`}
          style={{ marginTop: 0, marginBottom: 0, height: '1rem' }}
        >
          <span
            className={
              hasItems
                ? `dot ${
                    isSelected ? 'ln-u-bg-color-white' : 'ln-u-bg-color-grey'
                  }`
                : 'dot'
            }
            style={{ verticalAlign: 'top', paddingBottom: '2px' }}
          />
        </p>
      </div>
    </a>
  )
}
MobileHeaderDayItem.propTypes = {
  date: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  hasItems: PropTypes.bool.isRequired,
  includeTitle: PropTypes.bool
}

MobileHeaderDayItem.defaultProps = {
  includeTitle: true
}

export default MobileHeaderDayItem
