import React from 'react'
import PropTypes from 'prop-types'
import { MediaAttachments } from '@jsainsburyplc/activity-media-display'

const Media = ({ hasMedia, mediaAttachments }) => {
  if (!hasMedia || !mediaAttachments) return null

  return (
    <div className="ln-u-margin-top">
      <MediaAttachments media={mediaAttachments} />
    </div>
  )
}

Media.propTypes = {
  hasMedia: PropTypes.bool,
  mediaAttachments: PropTypes.array
}

Media.defaultProps = {
  hasMedia: false,
  mediaAttachments: null
}

export default Media
