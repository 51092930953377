import React from 'react'
import PropTypes from 'prop-types'
import {
  ACTIVITY_SOURCE_COLLEAGUE,
  ACTIVITY_TYPE_ACTION,
  ACTIVITY_TYPE_AUDIT,
  ACTIVITY_TYPE_INFO,
  ACTIVITY_TYPE_SURVEY,
  ACTIVITY_TYPE_TODO
} from 'utils/constants'
import { Card } from '@jsluna/card'
import Action from './Pages/Action'
import AdhocAction from './Pages/AdhocAction'
import Audit from './Pages/Audit'
import NeedToKnow from './Pages/NeedToKnow'
import Survey from './Pages/Survey'
import Todo from './Pages/Todo'
import AuditFetcher from './AuditFetcher'

const ActivityPageSwitcher = ({ activity, history }) => {
  if (!activity) return null

  switch (activity.type) {
    case ACTIVITY_TYPE_ACTION: {
      return (
        <AuditFetcher activity={activity} history={history}>
          <Action />
        </AuditFetcher>
      )
    }
    case ACTIVITY_TYPE_AUDIT: {
      return (
        <AuditFetcher activity={activity} history={history}>
          <Audit />
        </AuditFetcher>
      )
    }
    case ACTIVITY_TYPE_INFO: {
      return <NeedToKnow activity={activity} />
    }
    case ACTIVITY_TYPE_SURVEY: {
      return <Survey activity={activity} history={history} />
    }
    case ACTIVITY_TYPE_TODO: {
      if (activity.source === ACTIVITY_SOURCE_COLLEAGUE) {
        return (
          <AuditFetcher activity={activity} history={history}>
            <AdhocAction />
          </AuditFetcher>
        )
      }

      return <Todo activity={activity} history={history} />
    }
    default:
      return (
        <Card>
          <div className="ln-u-text-align-center">
            Sorry, an error has occurred processing this activity!
          </div>
        </Card>
      )
  }
}

ActivityPageSwitcher.propTypes = {
  history: PropTypes.object.isRequired,
  activity: PropTypes.object
}

ActivityPageSwitcher.defaultProps = {
  activity: null
}

export default ActivityPageSwitcher
