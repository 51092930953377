import React from 'react'
import FilterLayout from '../Filtering/FilterLayout'
import Planning from './index'

const PlanningWithFilters = props => (
  <div>
    <FilterLayout>
      <Planning {...props} />
    </FilterLayout>
  </div>
)

export default PlanningWithFilters
