import * as actions from '../authentication/actions'

const initialState = {
  idTokenClaims: {
    name: null,
    id: null,
    email: null
  },
  isError: false,
  isAuthStateInitializationInProgress: true
}

const getEmail = idTokenClaims =>
  idTokenClaims?.email ||
  (idTokenClaims?.preferred_username?.includes('@')
    ? idTokenClaims.preferred_username
    : null)

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.ACQUIRE_TOKEN_SILENT:
      return {
        ...state,
        isError: false
      }
    case actions.ACQUIRE_TOKEN_SILENT_SUCCESS:
      return {
        ...state,
        idTokenClaims: {
          name: action.authenticationResult?.idTokenClaims?.name,
          id: action.authenticationResult?.idTokenClaims?.oid,
          email: getEmail(action.authenticationResult?.idTokenClaims)
        },
        isError: false,
        isAuthStateInitializationInProgress: false
      }
    case actions.ACQUIRE_TOKEN_SILENT_FAILURE:
      return {
        ...state,
        isAuthStateInitializationInProgress: false,
        isError: true
      }
    default:
      return {
        ...state
      }
  }
}
