import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFilters, setFilters } from '../../../../utils/browserStorage'
import { filterHydrate } from '../../../../actions/filter'

const FilterHydrator = ({ hydrateFilter, appliedFilters }) => {
  useEffect(() => {
    const storedFilters = getFilters()
    hydrateFilter(storedFilters)
  }, [])

  useEffect(() => {
    setFilters({ filters: appliedFilters })
  }, [appliedFilters])

  return null
}

const mapStateToProps = state => ({
  appliedFilters: state.filter.applied
})

const mapDispatchToProps = dispatch => ({
  hydrateFilter: filters => {
    dispatch(filterHydrate(filters))
  }
})

FilterHydrator.propTypes = {
  hydrateFilter: PropTypes.func.isRequired,
  appliedFilters: PropTypes.array
}

FilterHydrator.defaultProps = {
  appliedFilters: []
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterHydrator)
