import * as Constants from 'utils/constants'

export const RISK_ACTIVITIES_REQUEST = 'RISK_ACTIVITIES_REQUEST'
export const RISK_ACTIVITIES_SUCCESS = 'RISK_ACTIVITIES_SUCCESS'
export const RISK_ACTIVITIES_FAILURE = 'RISK_ACTIVITIES_FAILURE'

export const RISK_CHANGE_PAGE = 'RISK_CHANGE_PAGE'

export const requestRiskActivities = (storeCode, page, filters) => ({
  type: RISK_ACTIVITIES_REQUEST,
  storeCode,
  page,
  filters,
  section: Constants.FILTER_PAGES.risk
})

export const receiveRiskActivities = activities => ({
  type: RISK_ACTIVITIES_SUCCESS,
  activities
})

export const requestRiskActivitiesFailure = () => ({
  type: RISK_ACTIVITIES_FAILURE
})

export const RiskChangePage = pageNumber => ({
  type: RISK_CHANGE_PAGE,
  pageNumber
})
