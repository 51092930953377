import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card } from '@jsluna/card'
import { GridWrapper, GridItem } from '@jsluna/grid'
import {
  ProgressIndicator,
  ProgressSpinner,
  ProgressMessage
} from '@jsluna/progress'
import { ComplianceChart } from '@jsainsburyplc/activity-compliance'
import { getStoreFromStorage } from 'utils/currentStore'
import ReportCard from './ReportCard'
import AttributeReport from './AttributeReport'
import PeriodWeekSelector from './PeriodWeekSelector'

const calculateIncomplete = (completed, total) =>
  total > 0 ? total - completed : 0

const calculateCompletionRate = (completed, total) => {
  let rate = 0

  if (total > 0) {
    rate = completed / total
  }

  return rate
}

export const TaskReport = ({
  loading,
  error,
  overviewLoading,
  overviewError,
  attributes,
  financialWeeks,
  riskTaskCompliance,
  riskTaskOverviewCompliance,
  selectedFinancialWeek,
  setSelectedFinancialWeek
}) => {
  if (error || overviewError)
    return <span>We failed to fetch the Tasks report, please try again!</span>

  const { complianceSummary, complianceByAttribute } = riskTaskCompliance

  const { numberOfCompletedActivities: completed, numberOfActivities: total } =
    complianceSummary || {}

  const incomplete = calculateIncomplete(completed, total)
  const completionRate = calculateCompletionRate(completed, total)

  const currentWeekLabel = financialWeeks
    .filter(x => x.key === selectedFinancialWeek)
    .map(y => `P${y.periodNumber} WK${y.weekEnumerator}`)

  const store = getStoreFromStorage()

  return (
    <GridWrapper matrix verticalAlign="center">
      {riskTaskOverviewCompliance.length > 0 && !overviewLoading && !loading && (
        <GridItem size="1/1">
          <Card>
            <GridWrapper>
              <GridItem size={{ default: '1/1', sm: '1/2' }}>
                <h3 className="ln-u-flush-bottom">Risk task compliance</h3>
                <span>Previous 8 weeks</span>
              </GridItem>
              <GridItem size="1/1">
                <ComplianceChart
                  data={riskTaskOverviewCompliance}
                  error={error}
                />
              </GridItem>
            </GridWrapper>
          </Card>
        </GridItem>
      )}
      {!overviewLoading && (
        <GridItem size="1/1" className="ln-u-hard-bottom">
          <PeriodWeekSelector
            selectedFinancialWeek={selectedFinancialWeek}
            setSelectedFinancialWeek={setSelectedFinancialWeek}
          />
        </GridItem>
      )}
      {loading ? (
        <GridItem size="1/1" className="ln-u-padding-top">
          <ProgressIndicator loading preventFocus>
            <ProgressSpinner className="ln-u-push-right-sm" />
            <ProgressMessage>Loading Tasks Report...</ProgressMessage>
          </ProgressIndicator>
        </GridItem>
      ) : (
        <GridItem size="1/1">
          <Card>
            <GridWrapper>
              <GridItem size="1/1">
                <h6>Task completion rate for {currentWeekLabel}</h6>
              </GridItem>
              <GridItem size={{ default: '1/3', md: '1/3' }}>
                <ReportCard
                  text="Completed"
                  type="number"
                  value={completed}
                  colour="risk-compliance-report-card--green"
                />
              </GridItem>
              <GridItem size={{ default: '1/3', md: '1/3' }}>
                <ReportCard
                  text="Incomplete"
                  type="number"
                  value={incomplete}
                  colour="risk-compliance-report-card--red"
                />
              </GridItem>
              <GridItem size={{ default: '1/3', md: '1/3' }}>
                <ReportCard
                  text="Completion"
                  type="percentage"
                  value={completionRate}
                  colour="risk-compliance-report-card--green"
                />
              </GridItem>
              {store && !store.code.startsWith('A') && (
                <GridItem size="1/1">
                  <AttributeReport
                    attributes={attributes?.filter(x => x.name !== 'Risk Type')}
                    complianceByAttribute={complianceByAttribute}
                  />
                </GridItem>
              )}
            </GridWrapper>
          </Card>
        </GridItem>
      )}
    </GridWrapper>
  )
}

const mapStateToProps = state => ({
  financialWeeks: state.financialCalendar.financialWeeks.map((x, i) => ({
    ...x,
    key: i
  })),
  loading: state.report.loading,
  error: state.report.error,
  overviewLoading: state.report.overviewLoading,
  overviewError: state.report.overviewError,
  attributes: state.attributes.attributeCategories,
  riskTaskCompliance: state.report.riskTaskCompliance,
  riskTaskOverviewCompliance: state.report.riskTaskOverviewCompliance
})

TaskReport.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  overviewLoading: PropTypes.bool,
  overviewError: PropTypes.bool,
  attributes: PropTypes.array,
  financialWeeks: PropTypes.array,
  riskTaskCompliance: PropTypes.array,
  riskTaskOverviewCompliance: PropTypes.array,
  selectedFinancialWeek: PropTypes.number.isRequired,
  setSelectedFinancialWeek: PropTypes.func.isRequired
}

TaskReport.defaultProps = {
  loading: false,
  error: false,
  overviewLoading: false,
  overviewError: false,
  attributes: [],
  financialWeeks: [],
  riskTaskCompliance: [],
  riskTaskOverviewCompliance: []
}

export default connect(mapStateToProps, null)(TaskReport)
