import React from 'react'
import PropTypes from 'prop-types'
import { SelectField } from '@jsluna/form'
import { connect } from 'react-redux'

const PeriodWeekSelector = ({
  financialWeeks,
  selectedFinancialWeek,
  setSelectedFinancialWeek
}) => (
  <SelectField
    name="select-period-week"
    options={financialWeeks.map(x => ({
      label: `P${x.periodNumber} WK${x.weekEnumerator}`,
      value: x.key
    }))}
    label="Change week"
    placeholder={false}
    value={selectedFinancialWeek.toString()}
    onChange={({ target }) =>
      setSelectedFinancialWeek(parseInt(target.value, 10))
    }
  />
)

const mapStateToProps = state => ({
  financialWeeks: state.financialCalendar.financialWeeks.map((x, i) => ({
    ...x,
    key: i
  }))
})

PeriodWeekSelector.propTypes = {
  financialWeeks: PropTypes.array.isRequired,
  selectedFinancialWeek: PropTypes.number.isRequired,
  setSelectedFinancialWeek: PropTypes.func.isRequired
}

export default connect(mapStateToProps, null)(PeriodWeekSelector)
