import React from 'react'
import PropTypes from 'prop-types'
import { Heading6 } from '@jsluna/typography'
import {
  ACTIVITY_TYPE_AUDIT,
  ACTIVITY_SOURCE_COLLEAGUE,
  ACTIVITY_TYPE_ACTION
} from 'utils/constants'

const Title = ({ type, templateId, title, description, source }) => {
  if (!title) return null

  const parsedTitle =
    source === ACTIVITY_SOURCE_COLLEAGUE && type === ACTIVITY_TYPE_ACTION
      ? description
      : `${type === ACTIVITY_TYPE_AUDIT ? `${templateId} - ` : ''}${title}`

  return <Heading6 element="div">{parsedTitle}</Heading6>
}

Title.propTypes = {
  type: PropTypes.string,
  templateId: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  source: PropTypes.string
}

Title.defaultProps = {
  type: null,
  templateId: null,
  title: null,
  description: null,
  source: null
}

export default Title
