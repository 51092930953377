import { getStoreFromStorage } from 'utils/currentStore'
import ActivitiesAPI from 'api'
import React from 'react'
import {
  AsyncAutocompleteField,
  MultiAutocompleteField
} from '@jsluna/autocomplete'
import { GridWrapper, GridItem } from '@jsluna/grid'
import PropTypes from 'prop-types'

const ColleagueSearch = ({ selectedOptions, onSearchItemSelected, error }) => {
  const limitToRiskAudience = true

  const loadOptions = options =>
    ActivitiesAPI.getColleagues(
      getStoreFromStorage().code,
      options.replace(/ +/g, '+'),
      limitToRiskAudience
    )
      .then(response => response.json())
      .then(response =>
        response.map(x => ({
          label: `${x.preferredName.length ? x.preferredName : x.firstName} ${
            x.lastName
          }`,
          value: x.azureId
        }))
      )

  return (
    <div>
      <GridWrapper>
        <GridItem>
          <label className="ln-c-label" htmlFor="store-store-group-search">
            Who needs to do this?
          </label>
          <div
            className={
              selectedOptions.length === 0 && error.length > 0
                ? 'u-search-control error'
                : ''
            }
          >
            <AsyncAutocompleteField
              name="store-store-group-search"
              placeholder={selectedOptions.length > 0 ? '' : 'Please select'}
              loadOptions={value => value.length > 0 && loadOptions(value)}
              onSelect={searchResults => onSearchItemSelected(searchResults)}
              label=""
              minChars={2}
              error={error}
            >
              <MultiAutocompleteField
                defaultSelectedOptions={selectedOptions.map(x => ({
                  label: x,
                  value: x
                }))}
                className={error && 'ln-c-form-group has-error'}
              />
            </AsyncAutocompleteField>
          </div>
        </GridItem>
      </GridWrapper>
    </div>
  )
}

ColleagueSearch.propTypes = {
  selectedOptions: PropTypes.array,
  onSearchItemSelected: PropTypes.func.isRequired,
  error: PropTypes.string
}

ColleagueSearch.defaultProps = {
  selectedOptions: [],
  error: ''
}

export default ColleagueSearch
