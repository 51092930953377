import { connect } from 'react-redux'
import { changeActiveTab } from 'actions'
import Header from './Header'
import { getStoreGroups } from '../../actions'

const mapStateToProps = (state, props) => ({
  currentStore: state.currentStore,
  currentUser: props.currentUser,
  activeTab: state.colleagueLayout.activeTab,
  currentActivity: state.activities.currentActivity,
  isMobile: state.activities.isMobile,
  storeGroups: state.storeGroups.groups,
  canAccessRisk: state.audience.canAccessRisk
})
const mapDispatchToProps = dispatch => ({
  changeActiveTab: tab => dispatch(changeActiveTab(tab)),
  getStoreGroups: storeCode => dispatch(getStoreGroups(storeCode))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
